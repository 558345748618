import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service'
import { Proveedor } from 'src/app/classes/proveedor';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { errors} from 'src/app/constants/constants.errors'

interface Proveedores {
  proveedor: Proveedor
  seleccion: boolean
}

@Component({
  selector: 'app-search-proveedor',
  templateUrl: './search-proveedor.component.html',
  styleUrls: ['./search-proveedor.component.scss'],
   
})


export class SearchProveedorComponent {
  dataSource: Proveedores[] = [];
  public columns: string[]
  public rutProveedor: string = "";
  public nombreProveedor: string ="";
  public seleccionado: boolean = false;
  public spinnerMessage: string = "Loading ..."

  constructor(
    public dialogRef: MatDialogRef<SearchProveedorComponent>,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public data: Proveedor,
    private spinner: NgxSpinnerService,
  ) {
    this.columns = ['nrut', 'vendor_name', 'seleccion']
    this.dataSource = [];
    
  }

  cancelClick() {
    this.dialogRef.close()
  }

  buscarProveedores() {
    var tipo :string="rut"; //por default va a buscar por el rut
    var value : string ="";
    if (this.rutProveedor.length < 3 && this.nombreProveedor.length<3) {
      this.commons.error('Error',errors.MinXCaracteres(3,'rut o nombre','proveedores').message,'Aceptar', true)
      return
    }
    this.spinnerMessage = 'Cargando Proveedores ...'
    this.spinner.show('searchSpinner')
    if (this.rutProveedor.length >0) {
      tipo="rut";
      value=this.rutProveedor;
    }
    if (this.nombreProveedor.length >0) {
      tipo="name";
      value=this.nombreProveedor
    }

    this.srv.callBackendGet<Proveedor[]>(`/proveedores/${tipo}/${value}`).subscribe({
      next: listaProveedores => {
        this.dataSource = listaProveedores.map((ele) => {
          let prov: Proveedor = new Proveedor()
          prov.setValues(ele.nrut,ele.vendor_name,ele.xdvrut, ele.vpc_tech_key)
          this.rutProveedor=""
          this.nombreProveedor=""
          return {proveedor: prov, seleccion: false}}
          )
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error',errors.ProveedoresNoEncontrados.message, 'Aceptar', true)
          this.dataSource = []
        } else {
          this.commons.error('Error',err,'Aceptar', true)
          this.dataSource = [];
        }
        this.spinner.hide('searchSpinner')
      },
      complete: () => {
        this.spinner.hide('searchSpinner')
      }
    }
    )
  }

  onCheckChange(prov: Proveedores) {
    prov.seleccion = !prov.seleccion
    this.data=prov.proveedor
    this.seleccionado = true;
  }
}
