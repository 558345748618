<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'estadosAcuerdo'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<table class="thclose">
  <tr>
      <th class="left">
        <mat-card-title>{{title}}</mat-card-title>
      </th>
      <th class="header-right">
          <button mat-raised-button color="warn" class="pad" [mat-dialog-close]="null">X</button>
      </th>
  </tr>
</table>
<div class="matdialog">
  <br>
  <table mat-table [dataSource]="estadosAcuerdo" aria-describedby="Historial de Estados Acuerdo" class="tabla">
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef class="background">Estado</th>
      <td mat-cell *matCellDef="let element">{{element.estado}}</td>
    </ng-container>
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef class="background">Fecha</th>
      <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
    </ng-container>
    <ng-container matColumnDef="usuario">
      <th mat-header-cell *matHeaderCellDef class="background">Usuario</th>
      <td mat-cell *matCellDef="let element"> {{element.usuario}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
    <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>
  </table>
  <br>
  <br>
</div>