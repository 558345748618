import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service'
import { NgxSpinnerService } from "ngx-spinner";
import { CommonsModule } from 'src/app/services/commons/commons.service';

export enum SearchType {
  TIENDA,
  LOCAL
}
@Component({
  selector: 'app-search-tienda',
  templateUrl: './search-tienda.component.html',
  styleUrls: ['./search-tienda.component.scss']
})

export class SearchTiendaComponent implements OnInit, OnDestroy {
  public spinnerMessage: string = "Loading ..."
  public dataSource = new MatTableDataSource<string>([""]);
  public cadenaDataSource: Interfaces.SelectableItem[] = []
  public regionDataSource: Interfaces.SelectableItem[] = []
  public distritoDataSource: Interfaces.SelectableItem[] = []
  public tiendaDataSource: Interfaces.SelectableItem[] = []
  public localDataSource: Interfaces.SelectableItem[] = []
  public columns: string[] = []
  private _searchType!: SearchType
  public title!: string
  public titleCadena!: string
  private _spinnerCounter: number = 0
  public tipo: string = "tienda";
  public seleccionado: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SearchTiendaComponent>,
    private srv: RebateBackendService,
    private spinner: NgxSpinnerService,
    private commonSrv: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public data: Interfaces.FilaTablaSearch
  ) {
    this.data = { cadena: null, distrito: [], local: [], region: [], tienda: [] }
  }

  set spinnerCounter(value: number) {
    this.spinnerMessage = 'Cargando datos ...';
    (value > 0) ? this._spinnerCounter++ : this._spinnerCounter--
    (this._spinnerCounter > 0) ? this.spinner.show("searchSpinner") : this.spinner.hide("searchSpinner")
  }

  ngOnDestroy(): void {
    if (this.cadenaDataSource.length > 0) this.data.cadena = this.cadenaDataSource[0];
    this.data.region = this.regionDataSource.filter(element => element.selected);
    this.data.distrito = this.distritoDataSource.filter(element => element.selected);
    this.data.tienda = this.tiendaDataSource.filter(element => element.selected);
    this.data.local = this.localDataSource.filter(element => element.selected);
  }

  ngOnInit(): void {
    this.spinnerMessage = 'Cargando Cadena ...'
    this.spinner.show('searchSpinner')

    this.srv.callBackendGet<Interfaces.DatosTiendaLocal[]>("/organizaciones/1").subscribe({
      next: (data) => {
        this.cadenaDataSource.push({ name: data[0].org_name_full, value: String(data[0].org_lvl_child), parent: null, selected: false })
        this.onCadenaChange(this.cadenaDataSource[0].value)
        this.titleCadena = this.cadenaDataSource[0].name
      },
      error: (err) => {
        this.commonSrv.error('Error cargando datos', err.message, 'Aceptar', true)
        this.spinner.hide('searchSpinner')
      },
      complete: () => {
        this.spinner.hide('searchSpinner')
      }
    })
  }

  cancelClick() {
    this.dialogRef.close()
  }

  set searchType(value: SearchType) {
    this._searchType = value;
    this.columns = (this.searchType == SearchType.TIENDA) ? ['region', 'distrito', 'tienda'] : ['region', 'distrito', 'tienda', 'local'];
    this.title = (this.searchType == SearchType.TIENDA) ? 'Seleccionar Tienda' : 'Seleccinar Local';
    this.tipo = (this.searchType == SearchType.TIENDA) ? 'tienda' : 'local'
  }

  get searchType(): SearchType {
    return this._searchType
  }

  onCadenaChange(event: string) {
    this.srv.callBackendGet<Interfaces.DatosTiendaLocal[]>(`/organizaciones/${event}/regiones`).subscribe({
      next: data => data.forEach(ele => this.regionDataSource.push({ name: `${ele.org_lvl_number} ${ele.org_name_full}`, value: String(ele.org_lvl_child), parent: null, selected: false })),
      error: _ => { }
    })
  }

  checkOk(tiendalocal:Interfaces.SelectableItem[]){
    //funcion que valida si debe o no mostrar el boton ok
    //dependiendo del datasource que entra de los selecteditems es por el que valida.
    this.seleccionado=false;
    tiendalocal.forEach(tienloc=>{
      if (tienloc.selected){
        this.seleccionado=true
      }
    })
  }

  onCheckChange(value: string, type: string, checked: boolean) {
    if (value != '') {
      switch (type) {
        case 'region':
          this.regionUpdate(checked, value)
          break;
        case 'distrito':
          this.distritoUpdate(checked, value)
          break;
        case 'tienda':
          this.tiendaUpdate(checked, value)
          break;
        default:
          break;
      }
    }
    //se agrega validacion para que boton ok no aparezca hasta que se seleccione o una tienda o un local
    //dependiendo del tipo
    switch (this.tipo) {
      case 'tienda':
        this.checkOk(this.tiendaDataSource)
        break;
      case 'local':
        this.checkOk(this.localDataSource)
        break;
    }
  }

  setAll(value: boolean, source: 'region' | 'distrito' | 'tienda' | 'local') {
    this.getListDataSource(source).forEach(ele => ele.selected = value)
  }

  someSelected(source: 'region' | 'distrito' | 'tienda' | 'local'): boolean {
    return this.getListDataSource(source).some(ele => ele.selected) && !this.getListDataSource(source).every(ele => ele.selected) && this.getListDataSource(source).length > 0
  }

  everySelected(source: 'region' | 'distrito' | 'tienda' | 'local'): boolean {
    return this.getListDataSource(source).every(ele => ele.selected) && this.getListDataSource(source).length > 0
  }

  getListDataSource(name: string): Interfaces.SelectableItem[] {
    switch (name) {
      case 'region':
        return this.regionDataSource
      case 'distrito':
        return this.distritoDataSource
      case 'tienda':
        return this.tiendaDataSource
      case 'local':
        return this.localDataSource
      default:
        return []
    }
  }

  private regionUpdate(checked: boolean, value: string) {
    if (checked) {
      this.addData(value, this.distritoDataSource, `/organizaciones/${value}/distritos`)
    } else {
      this.distritoDataSource = this.distritoDataSource.filter(element => (element.parent != value))
      this.tiendaDataSource = this.tiendaDataSource.filter(element => this.distritoDataSource.map(parentElement => parentElement.value).includes(element.parent as string))
      this.localDataSource = this.localDataSource.filter(element => this.tiendaDataSource.map(parentElement => parentElement.value).includes(element.parent as string))
    }
  }

  private distritoUpdate(checked: boolean, value: string) {
    if (checked) {
      this.addData(value, this.tiendaDataSource, `/organizaciones/${value}/tiendas`)
    } else {
      this.tiendaDataSource = this.tiendaDataSource.filter(element => (element.parent != value))
      this.localDataSource = this.localDataSource.filter(element => this.tiendaDataSource.map(parentElement => parentElement.value).includes(element.parent as string))
    }
  }

  private tiendaUpdate(checked: boolean, value: string) {
    if (this.searchType == SearchType.LOCAL) {
      if (checked) {
        this.addData(value, this.localDataSource, `/organizaciones/${value}/locales`)
      } else {
        this.localDataSource = this.localDataSource.filter(element => (element.parent != value))
      }

    }
  }

  addData(parent: string, ds: Interfaces.SelectableItem[], path: string) {
    this.spinnerCounter = 1
    this.srv.callBackendGet<Interfaces.DatosTiendaLocal[]>(path).subscribe({
      next: (data) => { data.forEach(ele => ds.push({ name: `${ele.org_lvl_number} ${ele.org_name_full}`, value: String(ele.org_lvl_child), parent: parent, selected: false })) },
      error: () => this.spinnerCounter = -1,
      complete: () => this.spinnerCounter = -1
    })
  }
}
