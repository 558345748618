<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'searchBonificacionesSpinner'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>

<table class="tabla" aria-describedby="">
    <tr>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Código Acuerdo</mat-label>
                <input matInput type="text" [(ngModel)]="nAcuerdo">
            </mat-form-field>
        </td>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Tipo Liquidación</mat-label>
                <mat-select [(value)]="tpLiquidacion">
                    <mat-option [value]="item.c_parametro"
                        *ngFor="let item of tipoLiquidacion, let i=index">{{item.a_valor}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Usuario</mat-label>
                <input matInput type="text" [(ngModel)]="userTech" (focusout)="usuarioUpper()">
            </mat-form-field>
        </td>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Jer/Sku</mat-label>
                <input matInput type="text" [(ngModel)]="jerSku">
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td class="celda celda_texto" colspan="2">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Proveedor</mat-label>
                <input matInput type="text" [(ngModel)]="rutFullProv" (focusout)="buscarProveedor($event)"
                    placeholder="Rut Proveedor">
                <button mat-icon-button matSuffix (click)="openDialogProv()" matTooltip="Buscar Proveedor">
                    <mat-icon matSuffix>search</mat-icon>
                </button>
            </mat-form-field>
            <mat-label>{{proveedor.vendor_name}}</mat-label>
        </td>
        <td class="celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'" *ngIf="showDates">
                <mat-label>Fecha Desde</mat-label>
                <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY" [(ngModel)]="fechaDesde">
                <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>
            </mat-form-field>
        </td>
        <td class="celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'" *ngIf="showDates">
                <mat-label>Fecha Hasta</mat-label>
                <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY" [(ngModel)]="fechaHasta">
                <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td colspan="3" class="celda_texto">

        </td>
        <td class="botones">
            <button mat-raised-button color="primary" class="pad" (click)="buscarAcuerdos()">Buscar</button>
            <button mat-raised-button color="warn" class="pad" (click)="Limpiar()">Limpiar</button>
        </td>
    </tr>
</table>