import { Component, OnDestroy, OnInit, numberAttribute } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchTiendaComponent, SearchType } from 'src/app/components/simulador/search-tienda/search-tienda.component';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { Subscription, pipe } from 'rxjs';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Simulador } from 'src/app/classes/simulador';
import { SearchProveedorComponent } from 'src/app/components/simulador/search-proveedor/search-proveedor.component';
import { Proveedor } from 'src/app/classes/proveedor';
import { SearchMarcaComponent } from 'src/app/components/simulador/search-marca/search-marca.component';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { __values } from 'tslib';
import { CountryFormatPipe } from '../pipes/countryFormat.pipe';



@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss']
})

export class SimuladorComponent implements OnInit, OnDestroy {
  private subscription!: Subscription;
  public title?: string;
  public spinnerMessage: string = 'Loading...';
  public dataSource: Simulador;
  public readonly cabeceraTabla: string[] = ['num', 'jerarquia', 'unidad', 'porcentaje', 'aporte', 'aporte_total', 'cod_promocion'];
  public organizaciones!: Interfaces.OrganizacionOptions[]; //= [{a_valor:'tienda',c_parametro:'tienda', searchType:SearchType.TIENDA, selected:false}];
  public country!: string;
  public searchButtonTitle!: string;
  public sku: string = "";
  public aporte: string = "";
  public simular: boolean = false;
  public simulado: boolean = false;
  public makeAcuerdo: boolean = false;
  public tablaSimulador: Interfaces.FilaTablaSimulador[];
  public rutFullProv: string = '';

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule
  ) {
    this.dataSource = new Simulador(rbtService, commonService)
    this.tablaSimulador = this.dataSource.tabla

    //this.auth.getCountry().then(value => this.country = 'pe')


  }

  ngOnInit(): void {
    this.setTitle();
    //this.auth.getToken().then(token=>console.log(token))
    this.loading();
    this.auth.getCountry().then(value => {
      this.country = value
      this.dataSource.country = value
    }
    )
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
  }


  async loading() {
    this.spinner.show('simulador');
    this.spinnerMessage = "Cargando datos...";

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.OrganizacionOptions>(DropDownOptions.Organizacion)
        .then((data) => {
          this.organizaciones = data;
          this.organizaciones.forEach((ele, idx) => {
            ele.path = `/organizaciones/opciones/${ele.c_parametro}`
            ele.searchType = (ele.a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
            ele.selected = (idx == 0)
            if (ele.selected) {
              this.dataSource.organizacion = CommonsModule.stringToNumber(ele.c_parametro.toString())
              this.setSearchType(ele)
            }
          });
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let results = await Promise.allSettled([p1])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('simulador')
  }


  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
    //alert(this.country)
  }

  buscarProveedor(event: any) {
    this.spinner.show('simulador');
    this.spinnerMessage = "Verificando Proveedor";
    this.dataSource.proveedor.setProveedor(event.target.value)
      .then(() => this.rutFullProv = this.dataSource.proveedor.proveedorFullRut)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          this.commonService.error(errors.ErrorBuscarProveedor.message, (err.status === 404) ? errors.ProveedorNoExiste.message : err.message, 'Aceptar', true)
        } else {
          this.commonService.error(errors.ErrorBuscarProveedor.message, err, 'Aceptar', true)
        }
      })
      .finally(() => this.spinner.hide('simulador'))
  }

  setSearchType(option: Interfaces.OrganizacionOptions) {
    this.organizaciones.forEach(ele => ele.selected = (ele.c_parametro == this.dataSource.organizacion.toString()))
    this.searchButtonTitle = (option.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    if (['7', '8'].includes(String(this.dataSource.organizacion))) {
      this.dataSource.tiendas = [{ name: (option.searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
    } else {
      this.spinnerMessage = `Buscando ${(option.searchType == SearchType.LOCAL) ? 'locales' : 'tiendas'}...`
      this.spinner.show('simulador');
      this.dataSource.tiendas = [];
      this.rbtService.callBackendGet<Interfaces.DatosTiendaLocal[]>(option.path as string).subscribe({
        next: data => {
          data.forEach(ele => {
            this.dataSource.tiendas.push({
              name: `${ele.org_lvl_number} ${ele.org_name_full}`,
              value: ele.org_lvl_child.toString()
            })
          })
        },
        error: _ => { this.spinner.hide('simulador') },
        complete: () => { this.spinner.hide('simulador') }
      })
    }
  }

  openDialogTiendas() {
    const st = this.organizaciones.filter(ele => ele.selected)[0].searchType;
    this.commonService.openDialog<Interfaces.FilaTablaSearch, null>(SearchTiendaComponent, { searchType: st }).subscribe(
      {
        next: result => { if (result != null) {
          this.dataSource.tiendas = (st == SearchType.LOCAL) ? result.local : result.tienda 
          this.makeAcuerdo=false
        }
      }
      }
    )
  }

  openDialogProveedores() {
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { if (result != null) {
          this.dataSource.pbc = [result] 
          this.makeAcuerdo=false
        }
      }
      }
    )
  }


  openDialogProv() {
    this.dataSource.proveedor.setValues('','','','')
    this.rutFullProv=''
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { 
          if (result != null){ 
            var provs :Proveedor[]=[result]
            for (let prov of provs){
              this.dataSource.proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
              this.rutFullProv = this.dataSource.proveedor.proveedorFullRut
            } 
          }
        }
      }
    )
  }
  
  openDialogMarcas() {
    this.commonService.openDialog<Interfaces.Marca[], null>(SearchMarcaComponent).subscribe(
      {
        next: result => { if (result != undefined && result.length > 0) {
          this.dataSource.marcas = result
          this.makeAcuerdo=false
        }
      }
      }
    )
  }

  addProduct(rows: Interfaces.AddProductStruct[] = [{ sku: this.sku, prd_lvl_child: 0, aporte: 0, name_full: '' }]) {
    this.spinner.show('simulador')
    this.spinnerMessage = "Verificando Jerarquía/SKU"
    rows.forEach((prod, idx) => {

      let valor:string
      if (prod.aporte==0){
        valor=this.aporte
      }
      else{
        let parts = CommonsModule.numParts(this.country)
        valor = prod.aporte.toString().replaceAll(".",parts.decimals)
      }

      let aporte = CommonsModule.strToNum(this.country, valor, 'aporte')
      if (aporte.error != "") {
        /*if (prod.aporte==0){
          this.commonService.error("Error", aporte.error, 'Aceptar', true)
          return  
        }*/
      } else {
        if (!aporte.decimalFound){
          prod.aporte = CommonsModule.stringToNumber(aporte.miles)
        }
        else{
          prod.aporte=CommonsModule.stringToNumber(aporte.miles + aporte.decimalPart.replaceAll(aporte.decimalPart,".") + aporte.decimales)
        }
      }
    })

    this.dataSource.addProducts(rows)
      .then(
        (valor) => {
          if (valor) {
            this.updateDataSource()
          }
          this.simular = true;
          this.sku = '';
          this.aporte = ""
        },
        (reason) => {
          this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
        }
      )
      .catch(reason => {
        this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
      }
      )
      .finally(() => {
        this.spinner.hide('simulador');
        //this.updateDataSource() 
      })
  }

  cleanDataSource() {
    this.dataSource.cleanTabla();
    this.simular = false;
    this.makeAcuerdo = false;
    this.simulado = false;
    this.tablaSimulador = this.dataSource.tabla
    this.sku = ''
    this.aporte = ''
  }

  updateDataSource() {
    this.tablaSimulador = this.dataSource.tabla.filter(ele => ele.display)
  }

  realizaSimulacion() {
    this.spinner.show('simulador')
    this.spinnerMessage = "Realizando simulación..."
    this.dataSource.doSimulacion()
      .then(_ => {
        this.makeAcuerdo = true
        this.simulado = true;
      })
      .catch(reason => this.commonService.error("Error", reason, 'Aceptar', true))
      .finally(() => { this.spinner.hide('simulador'); this.updateDataSource() })
  }

  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.spinner.show('simulador');
      this.spinnerMessage = "Validando archivo Excel ...";
      this.cleanDataSource();
      CommonsModule.readExcel<Interfaces.SimuladorExcelStruct>(file)
        .then(data => {
          this.addProduct(data.map<Interfaces.AddProductStruct>(ele => {
            let montoExcel: number = ele.MONTO
            return { sku: String(ele['SKU/ JER']), aporte: montoExcel, prd_lvl_child: 0, name_full: '' }
          }))
        })
        .catch(reason => {
          this.spinner.hide('simulador')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }

  openDialogCrearAcuerdo() {
    let err: boolean = false;
    this.tablaSimulador.forEach(ele => {
      if (ele.aporte <= 0) {
        err = true;
      }
    })

    if (!err) {
      this.commonService.openDialog<Acuerdo, Simulador>(AcuerdoComponent, {
        organizacion: this.organizaciones.filter(ele => ele.selected)[0].a_valor, parametroVer: false, editarCopiar: false, btnCerrar: 'Cancelar'
        , title: 'Creación de acuerdo'
      }, { data: this.dataSource, height: '95%', disableClose: true }).subscribe(
        {
          next: result => {
            if (result != undefined && result.nAcuerdo > 0) {
              this.commonService.alert('accent', 'Número acuerdo generado', 'Número de acuerdo generado: ' + result.nAcuerdo, 'Aceptar', true)
            }
          }
        }
      )
    }
    else {
      this.commonService.error('Atención', 'No se puede crear el acuerdo, hay filas con aporte menores o iguales a cero, corrija y reintente.', 'Aceptar', true)
    }
  }

  showDataTiendas() {
    let st = this.organizaciones.filter(ele => ele.selected)[0].searchType
    let colTitle = (st == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    this.commonService.showData(this.dataSource.tiendas, [colTitle])
  }

  showDataPBC() {
    let data = this.dataSource.pbc.map(ele => { return { rut: ele.proveedorFullRut, nombre: ele.vendor_name } })
    this.commonService.showData(data, ["RUT", "Nombre"])
  }

  showDataMarcas() {
    this.commonService.showData(this.dataSource.marcas, ["Marca", "Descripción"])
  }

  validaFecha(event: any, campo: string) {
    let fecha: string = event.target.value
    if (fecha == null) {
      this.commonService.error("Error", "Fecha inválida", 'Aceptar', true);
      if (campo == 'desde') {
        this.dataSource.fechaDesde = new Date()
      } else {
        this.dataSource.fechaHasta = new Date()
      }
    }
  }

  // keyDown($event: any) {
  //   if ($event.keyCode >= 65 && $event.keyCode <= 90) {
  //     $event.preventDefault();
  //   }
  // }

  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode==='c')) {
      //console.log("si")
    }
    else{
      if ($event.metaKey && (charCode==='v' || charCode==='c')){
        //console.log("si command")
      }
      else{
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }    
      }
    }
  }

  onKeyUp($event: any) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
      this.aporte = this.commonService.transformarMonto(this.country,this.aporte, 'aporte')
    // }
  }
}