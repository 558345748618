export const environment = {
    production: false,
    backendUrl: 'https://dev.rebate.fbusinesscenter.com/api',
    debug: true,
    keyCloakUrl:'https://qa-access-key-corp.falabella.tech/auth/',
    keyCloackRealm:'corp',
    sse: false,
    useCountryPrefix: true,
    version: '1.2.10',
  };
