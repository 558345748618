import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service'
import { NgxSpinnerService } from "ngx-spinner";
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';

interface Marcas extends Interfaces.Marca {
  seleccion: boolean
}

@Component({
  selector: 'app-search-marca',
  templateUrl: './search-marca.component.html',
  styleUrls: ['./search-marca.component.scss']
})
export class SearchMarcaComponent implements OnDestroy {
  public dataSource : Marcas[]=[];
  public columns: string[]
  public codMarca: string="";
  public spinnerMessage: string = "Loading ..."
  public show: boolean = this.dataSource.some(mrc => mrc.seleccion);

  constructor(
    public dialogRef: MatDialogRef<SearchMarcaComponent>,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public data: Interfaces.Marca[],
    private spinner: NgxSpinnerService,
  ) {
    
    this.columns = ['cmarca', 'desmarca', 'seleccion']
    this.dataSource = [];
    this.data = [];
  }

  ngOnDestroy(): void {
    this.dataSource.filter(marca=>marca.seleccion).forEach(mrc => this.data.push({cmarca: mrc.cmarca, desmarca: mrc.desmarca}))
  }

  cancelClick() {
    this.dialogRef.close()
  }

  buscarMarcas(){
    this.spinnerMessage = 'Cargando Marcas ...'
    if (this.codMarca.length<2){
      this.commons.error('Error',errors.MinXCaracteres(2,'código','marcas').message, 'Aceptar', true)
      return
    }
    this.spinner.show('searchSpinner')
    this.codMarca=this.codMarca.toUpperCase();
    this.srv.callBackendGet<Marcas[]>(`/marca/${this.codMarca}`).subscribe({
      next: listaMarcas =>{
        this.dataSource=listaMarcas
        this.dataSource.forEach(marca => marca.seleccion=false)
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true) :
          this.commons.error(errors.MarcasNoEncontradas.message, err, 'Aceptar', true);
        }
        this.dataSource=[];
        this.spinner.hide('searchSpinner')
      },
      complete: ()=>{
        this.spinner.hide('searchSpinner')
      }
    }
    )
  }

  setAll(value:boolean) {
    this.dataSource.forEach(ele => ele.seleccion = value)
  }

  someSelected(): boolean {
    return this.dataSource.some(ele => ele.seleccion) && !this.dataSource.every(ele => ele.seleccion) && this.dataSource.length > 0
  }

  everySelected(): boolean {
    return this.dataSource.every(ele => ele.seleccion) && this.dataSource.length > 0
  }
}
