<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "ball-clip-rotate" [fullScreen] = "true" [name]="'searchSpinner'"><p style="color: white" > {{spinnerMessage}} </p></ngx-spinner>
<h1 mat-dialog-title>{{title}} - {{titleCadena}}</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="dataSource" aria-describedby="Buscador Tiendas o Locales">
    
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="setAll($event.checked, 'region')" [indeterminate]="someSelected('region')" [checked]="everySelected('region')"><b>Region</b></mat-checkbox></th>
      <td mat-cell *matCellDef="">
        <mat-selection-list #region>
          <mat-list-option [value]="l.value" [(selected)]="l.selected" [checkboxPosition]="'before'" (selectedChange)="onCheckChange(l.value,'region',$event)" *ngFor="let l of (regionDataSource)">
            {{l.name}}
          </mat-list-option>
        </mat-selection-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="distrito">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="setAll($event.checked, 'distrito')" [indeterminate]="someSelected('distrito')" [checked]="everySelected('distrito')"><b>Distrito</b></mat-checkbox></th>
      <td mat-cell *matCellDef="">
        <mat-selection-list #distrito>
          <mat-list-option [value]="l.value" [(selected)]="l.selected" [checkboxPosition]="'before'" (selectedChange)="onCheckChange(l.value,'distrito',$event)" *ngFor="let l of (distritoDataSource)">
            {{l.name}}
          </mat-list-option>
        </mat-selection-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="tienda">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="setAll($event.checked, 'tienda')" [indeterminate]="someSelected('tienda')" [checked]="everySelected('tienda')"><b>Tienda</b></mat-checkbox></th>
      <td mat-cell *matCellDef="">
        <mat-selection-list #tienda>
          <mat-list-option [value]="l.value" [(selected)]="l.selected" [checkboxPosition]="'before'" (selectedChange)="onCheckChange(l.value,'tienda',$event)" *ngFor="let l of (tiendaDataSource)">
            {{l.name}}
          </mat-list-option>
        </mat-selection-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="local">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="setAll($event.checked, 'local')" [indeterminate]="someSelected('local')" [checked]="everySelected('local')"><b>Local</b></mat-checkbox></th>
      <td mat-cell *matCellDef="">
        <mat-selection-list #local>
          <mat-list-option [value]="l.value" [(selected)]="l.selected" [checkboxPosition]="'before'" (selectedChange)="onCheckChange(l.value,'local',$event)" *ngFor="let l of (localDataSource)">
            {{l.name}}
          </mat-list-option>
        </mat-selection-list>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>

  </table>
</div>
<div mat-dialog-actions>
  <button mat-raised-button [color]="'warn'" [mat-dialog-close]="null">Cancel</button>
  <button mat-raised-button [color]="'primary'" [mat-dialog-close]="data" cdkFocusInitial *ngIf="seleccionado">Ok</button>
</div>