import { NgModule, inject } from '@angular/core';
import { ROUTES, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import m from 'src/app/config/menus'
import { AuthGuard } from './components/auth/auth.guard';
import { LandingComponent } from './components/landing/landing.component';
import { AuthService } from './services/auth/auth.service';
import { CustomRouteReuseStrategy } from './classes/CustomRouteReuseStrategy';

/*let routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    data: { roles: [] },
    canActivate: [AuthGuard],
    children: []
  },
]

m.menus.forEach((element) => {
  return element.options.forEach((ele) => {
    if (ele.componente != null) {
      routes[0].children?.push({
        path: ele.path,
        component: ele.componente,
        data: ele.data,
        canActivate: [AuthGuard]
      })
    }
  })
});*/

/*routes.push({
  path: '**',
  redirectTo: '/',
  pathMatch: 'full'
})*/
@NgModule({
  imports: [RouterModule.forRoot([], { useHash: true, onSameUrlNavigation:'reload' })],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy
    },
    {
      provide: ROUTES,
      useFactory: () => {
        let authService: AuthService = inject(AuthService)
        let routes: Routes = [{
          path: '',
          component: LandingComponent,
          data: { roles: [], countries: [] },
          canActivate: [AuthGuard],
          children: []
        }];
        m.menus.forEach((element) => {
          return element.options.forEach((ele) => {
            if (ele.componente != null) {
              const roles =  authService.getRoles()
              if (roles.some(role => ele.data.roles.includes(role))) {
                routes[0].children?.push({
                  path: ele.path,
                  component: ele.componente,
                  data: ele.data,
                  canActivate: [AuthGuard],
                  title: ele.data["title"]
                })
              }
            }
          })
        })
        routes.push({
          path: '**',
          redirectTo: ''
        })
        return [...routes]
      },
      multi: true
    }
  ]
})
export class AppRoutingModule {}
