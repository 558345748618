import * as Interfaces from 'src/app/interfaces/ISimulador'
import { RebateBackendService } from '../services/rebate/rebate.backend.service';
import { Proveedor } from './proveedor';
import { errors } from 'src/app/constants/constants.errors';
import { CommonsModule } from 'src/app/services/commons/commons.service'
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
export class Simulador {
    private _proveedor: Proveedor;
    public isJerarquia: boolean = false;
    public fechaDesde!: Date;
    public fechaHasta!: Date;
    public danadoVendible: boolean = false;
    public jerarquia: string = "";
    public aporteTotal: number = 0;
    public codPromocion: string = "";
    public organizacion: number = 0;
    public tiendas: Interfaces.Tiendas[] = [];
    public marcas: Interfaces.Marca[] = [];
    public pbc: Proveedor[] = [];
    public dv: boolean = false;
    public tabla: Interfaces.FilaTablaSimulador[] = []
    public totalSimulador: number = 0;
    public totalIngresado: number = 0;
    public country: string = '';
    public ajuste: string='';

    constructor(private backend: RebateBackendService, private commons: CommonsModule) {
        this._proveedor = new Proveedor(backend)
    }

    updateTable() {
        let tabla: Interfaces.FilaTablaSimulador[] = this.tabla;
        //this.tabla = [...this.tabla]
        this.tabla = tabla

    }


    get proveedor() { return this._proveedor }

    set proveedor(prov : Proveedor){
        this._proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
    }

    calculaTotal() {
        this.totalSimulador = 0;
        this.tabla.filter(ele => ele.display).forEach(sku =>
            this.totalSimulador += sku.aporte_total
        )
    }

    cleanTabla() {
        this.tabla = []
        this.isJerarquia = false;
        this.pbc = []
        this.marcas = []
        this.totalSimulador = 0
        this.totalIngresado = 0
    }


    private addProductPromise(ele: Interfaces.AddProductStruct[]): Promise<Interfaces.AddProductStruct[]> {
        let isJerar = false;
        let isSkuJerar = false
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...ele.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';
        return new Promise<Interfaces.AddProductStruct[]>((resolve, reject) => {
            let errs: Error[] = []

            ele.forEach((prod, idx) => {
                if (prod.sku == '') {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingJerarquiaOrSku))
                }
                if (prod.aporte <= 0) {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingAporte))
                }
                else {
                    if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                        if (prod.aporte % 1 != 0) {
                            errs.push(new Error(`Fila ${idx + 2}: ` + errors.AporteDecimal))
                        }
                    }
                }
                isSkuJerar = prod.sku.substring(0, 1) == 'J'
                if (isJerar != isSkuJerar) {
                    if (isJerar) { //no puede ingresar un producto con J en la tabla
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.ProductosSinjerar))
                    }
                    if (isSkuJerar) {
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.JerarSinProductos))
                    }
                }

            })

            if (errs.length == 0) {
                let pro: Interfaces.AddProductMass[] = [];
                ele.forEach((p, i) => {
                    if (p.name_full == '') {
                        pro.push({ sku: p.sku, seq: i.toString() })
                    }
                })
                this.buscaProductoMass(pro)
                    .then(data => {
                        data.forEach((dataprod, idx) => {
                            if (dataprod.status == 'error') {
                                errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongJerarquiaOrSku));
                            }
                            else {
                                let valor: string = dataprod.secuencia;
                                valor = valor.replaceAll(".", "@")
                                valor = valor.replaceAll(",", ".")
                                valor = valor.replaceAll("@", "")
                                let seq: number = CommonsModule.stringToNumber(valor)  //numero de fila 
                                ele[seq].name_full = dataprod.name_full
                                ele[seq].prd_lvl_child = dataprod.prd_lvl_child
                            }
                        })
                        if (errs.length > 0) {
                            reject(this.addProductError(errs))
                        }
                        else {
                            resolve(ele)
                        }
                    })
                    .catch(reason => {
                        errs = [];
                        errs.push(new Error(`${(reason as Error)}`));
                        reject(this.addProductError(errs))
                    })
            }
            else {
                reject(this.addProductError(errs))
            }
        })
    }

    async addProducts(data: Interfaces.AddProductStruct[]): Promise<boolean> {
        let errs: Error[] = [];
        return new Promise<boolean>((resolve, reject) => {
            this.addProductPromise(data)
                .then(datos => {
                    this.addProductOnComplete(datos)
                    resolve(true)
                }, reason => {
                    //errs.push(reason)
                    reject(reason)
                    //reject(this.addProductError(errs))
                })
        })
    }

    private addProductError(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private addProductOnComplete(data: Interfaces.AddProductStruct[]): Error | null {
        let isValid = true;
        let isJerar = false;
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...data.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';
        isValid = tmpData.every(sku => (sku.substring(0, 1) == 'J') == isJerar)

        if (isValid) {
            data.forEach(ele => {
                this.tabla.push({
                    jerarquia: ele.sku,
                    prd_lvl_child: ele.prd_lvl_child,
                    aporte_total: ele.aporte,
                    name_full: ele.name_full,
                    aporte: 0,
                    cod_promocion: '',
                    porcentaje: 0,
                    unidad: '',
                    display: true
                })

                this.totalIngresado = this.totalIngresado + ele.aporte;
                this.updateTable();
                //this.calculaTotal();
                if (!this.isJerarquia) this.isJerarquia = (ele.sku.substring(0, 1) == 'J')
            });
            return null
        } else {
            return (isJerar) ? errors.JerarSinProductos : errors.ProductosSinjerar;
        }
    }

    async doSimulacion(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let erroresValidacion: Error[] = this.validaPreSimulacion();
            if (erroresValidacion.length === 0) {
                let simulacion: Interfaces.Simulacion = {
                    sku: this.armarSku(this.tabla),
                    org: this.armarOrg(this.tiendas),
                    marca: this.armarMarcas(this.marcas),
                    rut: this.proveedor.nrut.toString(),
                    fdesde: Intl.DateTimeFormat('es-CL').format(this.fechaDesde),
                    fhasta: Intl.DateTimeFormat('es-CL').format(this.fechaHasta),
                    dv: (this.dv) ? 0 : 1,
                    tipoAcuerdo: 'AF',
                    tipoJerarquia: (this.isJerarquia) ? 'J' : '8',
                    cadenaRut: this.armarPBC()
                }
                if (environment.debug) {
                    console.log(JSON.stringify(simulacion))
                }
                this.postSimulacion(simulacion)
                    .then(data => resolve(data))
                    .catch(reason => {
                        if (reason instanceof HttpErrorResponse) {
                            this.totalSimulador = 0
                            this.totalIngresado = 0
                            reject(this.addProductError([new Error(reason.message)]).message)
                        }
                        else {
                            this.totalSimulador = 0
                            this.totalIngresado = 0
                            reject(reason)
                        }
                    })
                    .finally(() => {
                        this.calculaTotal()
                    }
                    )
            } else {
                reject(this.addProductError(erroresValidacion).message)
            }
        })
    }

    private validaPreSimulacion(): Error[] {
        let erroresFormulario: Error[] = []
        if (this.armarSku(this.tabla).length > 150000) {
            erroresFormulario.push(errors.ProductosSuperanMaximo);
        }
        if (this.fechaDesde == null) {
            erroresFormulario.push(errors.FechaDesdeError);
        }
        if (this.fechaHasta == null) {
            erroresFormulario.push(errors.FechaHastaError);
        }
        if ((this.fechaDesde != null && this.fechaHasta != null) && (this.fechaDesde > this.fechaHasta)) {
            erroresFormulario.push(errors.FechaDesdeMayorHasta);
        }
        if (this.proveedor.nrut.length === 0) {
            erroresFormulario.push(errors.MissingProveedor);
        }
        if (this.tabla.length === 0) {
            erroresFormulario.push(errors.MissingJerarquiaOrSku);
        }
        if (this.tiendas.length==0){
            erroresFormulario.push(errors.MissingTiendas);
        }

        if (this.isJerarquia) {
            if ((this.pbc.length === 0 || this.pbc[0].nrut.length === 0) && (this.marcas.length === 0 || this.marcas[0].cmarca.length === 0)) {
                erroresFormulario.push(errors.MissingPBCMarca);
            }
        }
        return erroresFormulario;
    }

    private postSimulacion(simulacion: Interfaces.Simulacion): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let respuesta: Interfaces.SimulacionRespuesta;
            this.backend.callBackendPost<Interfaces.SimulacionRespuesta>(`/simulador/`, [simulacion]).subscribe({
                next: (data) => respuesta = data,
                error: (err) => reject(err),
                complete: () => {
                    this.ajuste=respuesta.Ajuste
                    this.tabla = []
                    this.tabla = respuesta.SimuladorRes.map<Interfaces.FilaTablaSimulador>((ele, idx) => {
                        return {
                            aporte: (respuesta.Ajuste == "1" && idx == respuesta.SimuladorRes.length - 1) ? CommonsModule.stringToNumber(ele.Monto) : CommonsModule.stringToNumber(ele.MontoUnitario),
                            aporte_total: CommonsModule.stringToNumber(ele.Monto),
                            jerarquia: ele.Prd_lvl_number,
                            prd_lvl_child: ele.Prd_lvl_child,
                            name_full: ele.Prd_lvl_full,
                            porcentaje: 0,
                            cod_promocion: "",
                            unidad: ele.Unidades,
                            display: (respuesta.Ajuste == "1" && idx == respuesta.SimuladorRes.length - 1) ? false : true
                        }
                    })
                    if (respuesta.SimuladorRes.length > 0) {
                        let indx: number = this.tabla.length - 1;
                        if (this.tabla[indx].aporte == 0) {
                            this.tabla.splice(indx);
                        }
                        this.updateTable();
                        resolve(true)
                    } else {
                        reject(errors.SimulacionSinData)
                    }
                }
            })
        })
    }

    private armarSku(datos: Interfaces.FilaTablaSimulador[]): string {
        return datos.reduce((prv, curr) => {
            if (curr.display) {
                prv += (prv == "") ? `${curr.prd_lvl_child};${curr.aporte_total};0` : `,${curr.prd_lvl_child};${curr.aporte_total};0`
            }
            return prv

        }, "")
    }

    private armarOrg(organizaciones: Interfaces.Tiendas[]): string {
        return organizaciones.reduce((prv, curr) => {
            prv += (prv == "") ? curr.value : `,${curr.value}`
            return prv
        }, "")
    }

    private armarMarcas(marcasSeleccionadas: Interfaces.Marca[]): string {
        if (marcasSeleccionadas.length == 0) return "0";
        return marcasSeleccionadas.reduce((prv, curr) => {
            prv += (prv == "") ? curr.cmarca : `;${curr.cmarca}`
            return prv
        }, "")
    }

    private armarPBC(): string {
        return this.pbc.reduce((prv, curr) => {
            prv += (prv == "") ? curr.nrut : `;${curr.nrut}`
            return prv
        }, "")
    }

    buscaProductoMass(prods: Interfaces.AddProductMass[]): Promise<Interfaces.ProductInfo[]> {
        return new Promise<Interfaces.ProductInfo[]>((resolve, reject) => {
            this.commons.validateProducts(prods).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

}