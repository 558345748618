<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'searchSpinner'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<h1 mat-dialog-title>Seleccionar Marca</h1>
<div mat-dialog-content>
  <table aria-describedby="Buscador">
    <tr>
      <th></th>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Filtrar Marcas por código</mat-label>
          <input matInput type="text" placeholder="Ingrese código" [(ngModel)]="codMarca">
        </mat-form-field>
      </td>
      <td>
        <button mat-raised-button color="primary" (click)="buscarMarcas()">Filtrar</button>
      </td>
    </tr>
  </table>
  <table mat-table [dataSource]="dataSource" aria-describedby="Resultados">
    <ng-container matColumnDef="cmarca">
      <th mat-header-cell *matHeaderCellDef>Código</th>
      <td mat-cell *matCellDef="let element"> {{element.cmarca}} </td>
    </ng-container>
    <ng-container matColumnDef="desmarca">
      <th mat-header-cell *matHeaderCellDef>Descripción</th>
      <td mat-cell *matCellDef="let element"> {{element.desmarca}} </td>
    </ng-container>

    <ng-container matColumnDef="seleccion">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="setAll($event.checked)" [indeterminate]="someSelected()" [checked]="everySelected()"></mat-checkbox>Seleccion</th>
      <td mat-cell *matCellDef="let element">
        <mat-selection-list>
          <mat-list-option [value]="element.cmarca" [(selected)]="element.seleccion"
            [checkboxPosition]="'before'">
          </mat-list-option>
        </mat-selection-list>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" [mat-dialog-close]="null">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>