import { Component, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { ActivatedRoute } from '@angular/router';
import { VerEstadosAcuerdoComponent } from 'src/app/shared/components/ver-estados-acuerdo/ver-estados-acuerdo.component';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { VerBonificacionesAcuerdoComponent } from 'src/app/shared/components/ver-bonificaciones-acuerdo/ver-bonificaciones-acuerdo.component';

interface listaBonificaciones extends Interfaces.BnfDesfazadas {
  seleccion: boolean
}

@Component({
  selector: 'app-ver-bnf-desfazadas',
  templateUrl: './ver-bnf-desfazadas.component.html',
  styleUrls: ['./ver-bnf-desfazadas.component.scss']
})
export class VerBnfDesfazadasComponent {

  arraysAcuerdos: listaBonificaciones[] = [];
  dataSource: MatTableDataSource<listaBonificaciones>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public spinnerMessage: string = ''
  public ver: boolean = false;
  public acuerdo!: Acuerdo;
  public acuerdoSellOut!: AcuerdoSellOut;
  public title!: string;

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['linea', 'sublinea', 'nacuerdo', 'usuario', 'fecapr', 'proveedor', 'nomProveedor', 'vigDesde', 'vigHasta', 'estado', 'semaforo', 'bnfMes', 'bnfDesfazada', 'bnfTotal'];
    this.dataSource = new MatTableDataSource<listaBonificaciones>(this.arraysAcuerdos);
  }

  ngOnInit() {
    this.arraysAcuerdos = [];
    this.setTitle();
  }

  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  recive(arrraysAcuerdos: listaBonificaciones[]) {
    this.arraysAcuerdos = arrraysAcuerdos;
    this.dataSource = new MatTableDataSource<listaBonificaciones>(arrraysAcuerdos);
  }

}
