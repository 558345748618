<mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
<mat-dialog-content [ngClass]="'no-top-padding'">
<table mat-table [dataSource]="data" aria-describedby="Tabla de visuliazación de datos">
    <ng-container *ngFor="let columnName of columnNames" [matColumnDef]="columnName">
        <th mat-header-cell *matHeaderCellDef>{{columnName | capitalize}}</th>
        <td mat-cell *matCellDef="let row">{{ row[columnName] }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
</table>
</mat-dialog-content>