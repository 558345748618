import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';

type Profile = KeycloakProfile & {
  attributes: {
    user_country: string[],
    rbt_user_tech_key: string[]
  } | null

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public KeycloakEventType = KeycloakEventType

  constructor(private keyCloakService: KeycloakService) {
    this.printTokenInfo()
    this.getEvents().subscribe({
      next: evt => {
        if (evt.type == this.KeycloakEventType.OnTokenExpired) {
          this.refreshToken()
        }
        if (evt.type == this.KeycloakEventType.OnAuthRefreshSuccess) this.printTokenInfo()
      }
    })
  }

  private printToken() {
    this.getToken().then(token => console.log(`Keycloak Token: ${token}`))
  }

  private printExpirationDate() {
    console.log(`Token Expiration Date: ${new Date(this.getExpirationTime() * 1000)}`)
  }

  printTokenInfo() {
    if (environment.debug) {
      this.printToken()
      this.printExpirationDate()
      this.getUserProfileBase64().then(profile => {console.log(`Profile Base64: ${profile}`)})
    }
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      const userDetails: KeycloakTokenParsed | undefined = this.keyCloakService.getKeycloakInstance().idTokenParsed
      return userDetails
    } catch (e) {
      console.error('KeyCloak Error: ', e)
      return undefined
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keyCloakService.isLoggedIn()
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keyCloakService.loadUserProfile()
  }

  public login(): void {
    this.keyCloakService.login()
      .then(_ => this.printToken())
      .catch(err => console.log(`Error at Keycloak Login: ${err}`))
  }

  public logout(): void {
    this.keyCloakService.logout()
  }

  public redirectToProfile(): void {
    this.keyCloakService.getKeycloakInstance().accountManagement()
  }

  public getRoles(): string[] {
    return this.keyCloakService.getUserRoles()
  }

  public getToken(): Promise<string> {
    return this.keyCloakService.getToken()
  }

  public async getCountry(): Promise<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      this.loadUserProfile().then(full => {
        try {
          const prof: Profile = JSON.parse(JSON.stringify(full))
          if (prof.attributes?.user_country instanceof Array) {
            resolve(prof.attributes?.user_country[0])
          } else {
            reject(Error('country not found'))
          }
        } catch (error) {
          reject(error)
        }
        // try {
        //   const prof: KeycloakProfile = JSON.parse(JSON.stringify(full));
        //   if (prof.email == undefined) throw Error("user email not found");
        //   let emailSplitted = prof.email.split(".");
        //   let country = emailSplitted.pop();
        //   (country == undefined) ? reject("user email malformed") : resolve(country)
        // } catch (error) {
        //   reject(error)
        // }
      }).catch(err => {
        reject(err)
      })
    })
    return promise
  }

  public addTokenToHeader(headers: HttpHeaders): Observable<HttpHeaders> {
    return this.keyCloakService.addTokenToHeader(headers)
  }

  public getExpirationTime(): number {
    let tp = this.keyCloakService.getKeycloakInstance().tokenParsed
    return (tp != undefined && tp.exp != undefined) ? tp.exp : 0
  }

  public getEvents(): Subject<KeycloakEvent> {
    return this.keyCloakService.keycloakEvents$
  }

  public refreshToken() {
    this.keyCloakService.updateToken()
      .then(data => console.log(`Token Refreshed: => ${data}`))
      .catch(err => console.log(`Token Refresh Error => ${err}`))
  }

  public getSessionStartTime(): string {
    let sso_time = this.keyCloakService.getKeycloakInstance().tokenParsed?.auth_time
    if (sso_time != undefined) return new Date(sso_time * 1000).toString()
    return 'Not initialized'
  }

  public async getUserTechKey(): Promise<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      this.loadUserProfile().then(full => {
        try {
          const prof: Profile = JSON.parse(JSON.stringify(full));
          if (prof.attributes != undefined && prof.attributes.rbt_user_tech_key != undefined){
            resolve(prof.attributes.rbt_user_tech_key[0])
          }
          else{
            reject('UserTechKey no definido')
          }
        } catch (error) {
          reject(error)
        }
      }).catch(err => {
        reject(err)
      })
    })
    return promise
  }

  public async getUserProfileBase64(): Promise<string> {
    const promise: Promise<string> = new Promise((resolve, reject) => {
      this.loadUserProfile().then(full => {
        try {
          const b64String = Buffer.from(JSON.stringify(full),'binary').toString('base64')
          resolve(b64String)
        } catch (error) {
          reject(error)
        }
      }).catch(err => {
        reject(err)
      })
    })
    return promise
  }



}
