import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'countryFormat', pure: true })
export class CountryFormatPipe implements PipeTransform {
  transform(value: number | string, country: string = 'CL', defecto: string = '', redondeo: boolean, retorno: string = ''): string {
    if (value == '' || value == 0) {
      return (retorno == '0') ? "0" : ''
    }
    if (redondeo) {
      value = Math.round(Number(value))
    }
    else {
      value = Number(value)
    }
    let valor: string = "";
    switch (country.toUpperCase()) {
      case 'CL':
        valor = Intl.NumberFormat('es-CL').format(value)
        if (valor == "") {
          valor = "0"
        }
        return valor
      case 'CO':
        //se cambia tanto Perú como Colombia a formato de Chile, ya que el sistema anterior maneja todo como Chile.
        //por este motivo es que hay querys que sacan los valores ya formateados a formato de Chile y todos los otros Paises se adaptaron a este formato
        //para operar el sistema.
        //return Intl.NumberFormat('es-CO').format(value)
        valor = Intl.NumberFormat('es-CL').format(value)
        if (valor == "") {
          valor = "0"
        }
        return valor
      case 'PE':
        //return Intl.NumberFormat('es-PE').format(value)
        valor = Intl.NumberFormat('es-CL').format(value)
        if (valor == "") {
          valor = "0"
        }
        return valor

      default:
        alert("default")
        return Intl.NumberFormat(navigator.language).format(value)
    }
  }
}

@Pipe({ name: 'countryDateFormat' })
export class CountryDateFormatPipe implements PipeTransform {
        //se va a asignar para todos los paises como que fuera Chile ya que el sistema anterior trabaja de esa forma.
  transform(value: Date, country: string = 'CL') {
    switch (country.toUpperCase()) {
      case 'CL':
        return Intl.DateTimeFormat('es-CL').format(value)
      case 'CO':
        //return Intl.DateTimeFormat('es-CO').format(value)
        return Intl.DateTimeFormat('es-CL').format(value)
      case 'PE':
        //return Intl.DateTimeFormat('es-PE').format(value)
        return Intl.DateTimeFormat('es-CL').format(value)
      default:
        return Intl.DateTimeFormat(navigator.language).format(value)
    }
  }
}

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

function chunk(str: string, n: number) {
  var ret = [];
  var i;
  var len;
  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substring(i, i + n))
  }
  return ret
};

@Pipe({ name: 'largoMax' })
export class LargoMax implements PipeTransform {
  transform(value: string, max: number) {
    return chunk(value, max).join('\n')
  }
}