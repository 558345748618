import * as Interfaces from 'src/app/interfaces/ISimulador'
import { RebateBackendService } from '../services/rebate/rebate.backend.service';
import { Proveedor } from './proveedor';
import { CommonsModule } from 'src/app/services/commons/commons.service'
import { Simulador } from './simulador';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth/auth.service';

export class Acuerdo {
    public nAcuerdo: number = 0;
    public tpAcuerdo: number = 0;
    public nombreAcuerdo: string = '';
    public descrAcuerdo: string = '';
    public agrAcuerdo: number = 0;
    public subTipoAcuerdo: number = 0;
    public tipoRecupero: number = 0;
    public tipoLiquidacion: number = 0;
    public txtLiquidacion: string = ''
    public proveedor: Proveedor;
    public organizacion: number = 0;
    public descr_org: string = '';
    public isJerarquia: boolean = false;
    public fechaDesde: Date = new Date();
    public fechaHasta: Date = new Date();
    public danadoVendible: boolean = false;
    public tiendas: Interfaces.Tiendas[] = [];
    public marcas: Interfaces.Marca[] = [];
    public pbc: Proveedor[] = [];
    public dv: boolean = false;
    public tabla: Interfaces.FilaTablaSimulador[] = []
    public acrdReferencia: number
    public cargaArchivo: boolean = false;
    public file!: File;
    public fileBase64: string = "";
    public nombreArchivo: string = '';
    public old_acuerdo: number = 0;
    public accion: string = '';
    public origen!: string;
    private user_tech_key !: string;
    public estado: number = 0;
    public country: string = '';

    constructor(private backend: RebateBackendService,
        private commons: CommonsModule,
        private auth: AuthService) {
        this.nAcuerdo = 0
        this.proveedor = new Proveedor(backend)
        this.acrdReferencia = 0
        this.auth.getUserTechKey().then(value => this.user_tech_key = value)
    }

    public esSimulacion(simulador: Simulador) {
        this.proveedor = simulador.proveedor;
        this.isJerarquia = simulador.isJerarquia
        this.fechaDesde = simulador.fechaDesde
        this.fechaHasta = simulador.fechaHasta
        this.danadoVendible = simulador.danadoVendible
        this.organizacion = simulador.organizacion
        this.tiendas = simulador.tiendas
        this.marcas = simulador.marcas
        this.pbc = simulador.pbc
        this.dv = simulador.dv
        this.accion = ''
        this.old_acuerdo = 0
        //es simulacion por lo que el estado queda en 1 digitado
        this.estado = 1
        this.tabla = simulador.tabla.map(ele => {
            let resp = Object.assign({}, ele)
            //se agrega validacion de si se realiza ajuste en el sp de simulacion, ya que si no tiene ajuste debe colocar los valores con los decimales.
            if (simulador.ajuste=="1"){
                resp.aporte = Math.floor(resp.aporte)
            }
            return resp
        })
    }

    async doGuardar(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let erroresValidacion: Error[] = this.validaPreGuardado();
            if (erroresValidacion.length === 0) {
                let acrdo: Interfaces.Acuerdo = {
                    ctpacuerdo: this.tpAcuerdo,
                    cmbacuerdo: this.nombreAcuerdo,
                    vpctechkey: this.proveedor.vpc_tech_key,
                    dvigdesde: Intl.DateTimeFormat('es-CL').format(this.fechaDesde),
                    dvighasta: Intl.DateTimeFormat('es-CL').format(this.fechaHasta),
                    cagrupador: this.agrAcuerdo,
                    adescacuerdo: this.descrAcuerdo,
                    ctprecupero: this.tipoRecupero,
                    cestado: this.estado, //en duro 1
                    csubtipo: this.subTipoAcuerdo,
                    bauto: 'T', // en duro va T
                    cflagtope: 'F', //va F en duro hay que quitar de la pagina los chech de los topes.
                    cflagdvendible: (this.dv) ? 'T' : 'F',
                    //corigen: 'S', //S en duro
                    corigen: this.origen,
                    nacuerdoref: this.acrdReferencia,
                    usr_tech_key: parseInt(this.user_tech_key),
                    corganizacion: CommonsModule.stringToNumber(this.organizacion.toString()),
                    tiendaLocal: this.armarOrg(this.tiendas),
                    cproducto: 11, //va 11 en duro
                    codtope: 95, //va en duro 95
                    cliquidacion: 46, //va en duro
                    bonimalifi: 56,//56 va en duro
                    strProductos: this.armarSku(this.tabla),
                    aplicarsobre: 39, //39 en duro
                    tipoliquidacion: this.tipoLiquidacion,
                    txtliquidacion: this.txtLiquidacion,
                    marcas: this.armarMarcas(this.marcas),
                    seller: this.armarPBC(),
                    old_acuerdo: this.old_acuerdo,
                    accion: this.accion,
                    nfile: this.nombreArchivo,
                    file: this.fileBase64
                }
                if (environment.debug) {
                    console.log(JSON.stringify(acrdo))
                }
                //reject(JSON.stringify(acrdo))
                this.postGuardado(acrdo)
                    .then(data => resolve(data))
                    .catch(reason => {
                        if (reason instanceof HttpErrorResponse) reject(this.showErrors([new Error(reason.message)]))
                        else reject(reason)
                    })
                //resolve(true)
            }
            else {
                reject(this.showErrors(erroresValidacion))
            }
        })
    }

    private showErrors(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private validaPreGuardado(): Error[] {
        let erroresFormulario: Error[] = []
        if (this.fechaDesde == null) {
            erroresFormulario.push(errors.FechaDesdeError);
        }
        if (this.fechaHasta == null) {
            erroresFormulario.push(errors.FechaHastaError);
        }
        if ((this.fechaDesde != null && this.fechaHasta != null) && (this.fechaDesde > this.fechaHasta)) {
            erroresFormulario.push(errors.FechaDesdeMayorHasta);
        }
        if (this.proveedor.nrut.length === 0) {
            erroresFormulario.push(errors.MissingProveedor);
        }
        if (this.nombreAcuerdo.length === 0) {
            erroresFormulario.push(errors.MissinNombreAcuerdo);
        }
        else {
            if (this.nombreAcuerdo.length > 50) {
                erroresFormulario.push(errors.NombreAcuerdoLargo);
            }
        }
        if (this.fileBase64.length === 0) {
            erroresFormulario.push(errors.MissingFileRespaldo);
        }
        if (this.tabla.length === 0) {
            erroresFormulario.push(errors.MissingJerarquiaOrSku);
        }
        if (this.isJerarquia) {
            if ((this.pbc.length === 0 || this.pbc[0].nrut.length === 0) && (this.marcas.length === 0 || this.marcas[0].cmarca.length === 0)) {
                erroresFormulario.push(errors.MissingPBCMarca);
            }
            /*if (this.pbc.length === 0 || this.pbc[0].nrut.length === 0) {
                erroresFormulario.push(errors.MissingPBC);
            }*/
        }
        return erroresFormulario;
    }

    private postGuardado(acuerdo: Interfaces.Acuerdo): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            //console.log(JSON.stringify(acuerdo))
            let respuesta: Interfaces.GuardarAcuerdoRespuesta;
            this.backend.callBackendPost<Interfaces.GuardarAcuerdoRespuesta>(`/creaAcuerdoSimulador/`, [acuerdo]).subscribe({
                next: (data) => respuesta = data,
                error: (err) => reject(err),
                complete: () => {
                    if (respuesta.nacuerdo > 0) {
                        this.nAcuerdo = respuesta.nacuerdo
                        resolve(true)
                    } else {
                        reject(errors.ErrorGuardadoAcuerdo)
                    }
                }
            })
        })
    }

    private armarSku(datos: Interfaces.FilaTablaSimulador[]): string {

        return datos.reduce((prv, curr) => {
            prv += (prv == "") ? `${curr.jerarquia}:${curr.aporte.toFixed(2)}:0:${curr.unidad}:${curr.prd_lvl_child}:${curr.name_full}` : `|${curr.jerarquia}:${curr.aporte.toFixed(2)}:0:${curr.unidad}:${curr.prd_lvl_child}:${curr.name_full}`
            return prv
        }, "")
    }

    private armarOrg(organizaciones: Interfaces.Tiendas[]): string {
        return organizaciones.reduce((prv, curr) => {
            prv += (prv == "") ? curr.value : `,${curr.value}`
            return prv
        }, "")
    }

    private armarMarcas(marcasSeleccionadas: Interfaces.Marca[]): string {
        //if (marcasSeleccionadas.length == 0) return "0";
        return marcasSeleccionadas.reduce((prv, curr) => {
            prv += (prv == "") ? curr.cmarca : `;${curr.cmarca}`
            return prv
        }, "")
    }

    private armarPBC(): string {
        return this.pbc.reduce((prv, curr) => {
            prv += (prv == "") ? curr.vpc_tech_key : `;${curr.vpc_tech_key}`
            return prv
        }, "")
    }

    datosAcuerdoFijo(nAcuerdo: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let anio: number = 0;
            let mes: number = 0;
            let dia: number = 0;
            let fecha: string[] = [];
            this.backend.callBackendGet<Interfaces.AcuerdoSellIn[]>(`/acuerdoFijo/${nAcuerdo}`).subscribe({
                next: data => {
                    this.nAcuerdo = data[0].nacuerdo
                    this.tpAcuerdo = data[0].tip_id_acuerdo
                    this.nombreAcuerdo = data[0].nombre
                    this.descrAcuerdo = data[0].des_acuerdo
                    this.agrAcuerdo = data[0].c_agrupador
                    this.subTipoAcuerdo = data[0].c_subtipo
                    this.tipoRecupero = data[0].recupero
                    this.estado = data[0].c_estado
                    this.tipoLiquidacion = data[0].tipo_liquidacion
                    this.txtLiquidacion = data[0].texto_liquidacion
                    this.proveedor.setValues(data[0].nrut, data[0].des_proveedor, data[0].xdvrut, data[0].vpc_tech_key)
                    this.organizacion = data[0].org_cparametro
                    this.descr_org = data[0].org_desc_cparametro
                    let fec: string = data[0].d_vigdesde
                    fecha = fec.split("-")
                    anio = Number(fecha[2])
                    mes = Number(fecha[1]) - 1
                    dia = Number(fecha[0])
                    this.fechaDesde = new Date(anio, mes, dia);

                    fec = data[0].d_vighasta
                    fecha = fec.split("-")
                    anio = Number(fecha[2])
                    mes = Number(fecha[1]) - 1
                    dia = Number(fecha[0])


                    this.fechaHasta = new Date(anio, mes, dia);
                    if (['7', '8'].includes(String(this.organizacion))) {
                        if (data[0].tienda_local.length > 0) {
                            this.tiendas = [];
                            data[0].tienda_local.forEach(ele => {
                                this.tiendas.push({ name: ele.name, value: ele.value })
                            })
                        }
                        else {
                            this.tiendas = [{ name: (data[0].org_desc_cparametro.toUpperCase().includes('LOCAL')) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
                        }
                    }
                    else {
                        this.tiendas = data[0].tienda_local
                    }

                    this.danadoVendible = (data[0].c_flagdvendible == 'F') ? false : true;
                    this.marcas = data[0].marcas
                    this.pbc = []
                    data[0].proveedores.forEach(ele => {
                        let proveedor: Proveedor = new Proveedor();
                        proveedor.setValues(ele.nrut, ele.vendor_name, ele.xdvrut, ele.vpc_tech_key);
                        this.pbc.push(proveedor)
                    })
                    this.dv = (data[0].c_flagdvendible == 'F') ? false : true;
                    this.tabla = data[0].detalleAcuerdo.map<Interfaces.FilaTablaSimulador>(ele => {
                        this.isJerarquia = (ele.jerarquia.includes('J'))
                        return {
                            jerarquia: ele.jerarquia,
                            prd_lvl_child: ele.prd_lvl_child,
                            name_full: ele.name_full,
                            unidad: ele.unidad.toString(),
                            porcentaje: 0,
                            aporte: ele.porcentaje,
                            aporte_total: (ele.porcentaje * ele.unidad),
                            cod_promocion: '',
                            display: true
                        }
                    })

                    this.acrdReferencia = 0
                    this.nombreArchivo = data[0].nombre_archivo
                    this.fileBase64 = data[0].archivo
                },
                error: err => {
                    reject(err)

                },
                complete: () => {
                    if (this.organizacion == 0) {
                        this.organizacion = 7
                        this.descr_org = "Tienda"
                    }
                    resolve(true)
                }
            }
            )
        })
    }

    public descargarArchivo(base64: any, fileName: any) {
        let archivo: string[] = fileName.split('.');
        if (archivo.length > 1) {
            let ext = archivo[1];
            let blob = this.base64ToArrayBuffer(base64, 'text/plain');


            //var file = new Blob(blob, { type: 'application/' + ext });
            var file = blob
            var fileURL = URL.createObjectURL(file);

            // if you want to open PDF in new tab
            //window.open(fileURL);
            var a = document.createElement('a');
            a.href = fileURL;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            //saveAs(blob, fileName);
        }
    }


    base64ToArrayBuffer(base64: string, contentType = '') {
        let sliceSize = 512;
        let b64Data = base64.replace(/\s/g, ''); //IE compatibility...
        let binaryString = Buffer.from(b64Data, 'base64').toString('binary')
        let byteArrays = [];
        for (let offset = 0; offset < binaryString.length; offset += sliceSize) {
            let slice = binaryString.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }
    private addProductPromise(ele: Interfaces.AddProductStruct[]): Promise<Interfaces.AddProductStruct[]> {
        let isJerar = false;
        let isSkuJerar = false
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...ele.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J'; //hay J en la tabla por lo que no puedo ingresar productos.
        return new Promise<Interfaces.AddProductStruct[]>((resolve, reject) => {
            let errs: Error[] = []

            ele.forEach((prod, idx) => {
                if (prod.sku == '') {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingJerarquiaOrSku))
                }
                if (prod.aporte <= 0) {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingAporte))
                } else {
                    if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                        if (prod.aporte % 1 != 0) {
                            errs.push(new Error(`Fila ${idx + 2}: ` + errors.AporteDecimal))
                        }
                    }
                }

                isSkuJerar = prod.sku.substring(0, 1) == 'J'
                if (isJerar!=isSkuJerar){
                    if (isJerar){ //no puede ingresar un producto con J en la tabla
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.ProductosSinjerar))
                    }
                    if (isSkuJerar){
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.JerarSinProductos))
                    }
                }

            })


            if (errs.length == 0) {
                let pro: Interfaces.AddProductMass[] = [];
                ele.forEach((p, i) => {
                    if (p.name_full == '') {
                        pro.push({ sku: p.sku, seq: i.toString() })
                    }
                })
                this.buscaProductoMass(pro)
                    .then(data => {
                        data.forEach((dataprod, idx) => {
                            if (dataprod.status == 'error') {
                                errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongJerarquiaOrSku));
                            }
                            else {
                                let valor: string = dataprod.secuencia;
                                valor = valor.replaceAll(".", "@")
                                valor = valor.replaceAll(",", ".")
                                valor = valor.replaceAll("@", "")
                                let seq: number = CommonsModule.stringToNumber(valor)  //numero de fila 
                                ele[seq].name_full = dataprod.name_full
                                ele[seq].prd_lvl_child = dataprod.prd_lvl_child
                            }
                        })
                        if (errs.length > 0) {
                            reject(this.addProductError(errs))
                        }
                        else {
                            resolve(ele)
                        }
                    })
                    .catch(reason => {
                        errs = [];
                        errs.push(new Error(`${(reason as Error)}`));
                        reject(this.addProductError(errs))
                    })

            }
            else {
                reject(this.addProductError(errs))
            }
        })
    }

    async addProducts(data: Interfaces.AddProductStruct[]): Promise<boolean> {
        let errs: Error[] = [];
        return new Promise<boolean>((resolve, reject) => {
            this.addProductPromise(data)
                .then(datos => {
                    this.addProductOnComplete(datos)
                    resolve(true)
                }, reason => {
                    //errs.push(reason)
                    reject(reason)
                    //reject(this.addProductError(errs))
                })
        })
    }

    private addProductError(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private addProductOnComplete(data: Interfaces.AddProductStruct[]): Error | null {
        let isValid = true;
        let isJerar = false;
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...data.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';
        isValid = tmpData.every(sku => (sku.substring(0, 1) == 'J') == isJerar)
        if (isValid) {
            data.forEach(ele => {
                this.tabla.push({
                    jerarquia: ele.sku,
                    prd_lvl_child: ele.prd_lvl_child,
                    aporte_total: ele.aporte,
                    name_full: ele.name_full,
                    aporte: ele.aporte,
                    cod_promocion: '',
                    porcentaje: 0,
                    unidad: (ele.unidad != undefined) ? ele.unidad.toString() : '0',
                    display: true
                })
                //this.calculaTotal();
                if (!this.isJerarquia) this.isJerarquia = (ele.sku.substring(0, 1) == 'J')
            });
            return null
        } else {
            return (isJerar) ? errors.JerarSinProductos : errors.ProductosSinjerar;
        }
    }

    buscaProductoMass(prods: Interfaces.AddProductMass[]): Promise<Interfaces.ProductInfo[]> {
        return new Promise<Interfaces.ProductInfo[]>((resolve, reject) => {
            this.commons.validateProducts(prods).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

}