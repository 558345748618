import { Component, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';

interface listadoAcuerdo extends Interfaces.ListadoAcuerdos {
  seleccion: boolean
}

@Component({
  selector: 'app-copiar-acuerdo',
  templateUrl: './copiar-acuerdo.component.html',
  styleUrls: ['./copiar-acuerdo.component.scss']
})
export class CopiarAcuerdoComponent {
  private subscription!: Subscription;
  arraysAcuerdos: listadoAcuerdo[]=[];
  dataSource: MatTableDataSource<listadoAcuerdo>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public spinnerMessage:string = ''
  public ver: boolean=false;
  public acuerdo: Acuerdo;
  public acuerdoSellOut!: AcuerdoSellOut;

  public title!:string;
  private user_tech_key!: string;

  @ViewChild('paginator',{static:true}) paginator!: MatPaginator;

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['num','n_acuerdo', 'nomAcuerdo', 'tipoAcuerdo', 'agrupador', 'subtipo', 'vigDesde', 'vigHasta', 'proveedor', 'nomProveedor', 'tipoRecupero', 'estado', 'accionEditar', 'accionCopiar'];
    this.dataSource = new MatTableDataSource<listadoAcuerdo>(this.arraysAcuerdos);
    this.acuerdo=new Acuerdo(rbtService,commonService, auth);
    this.auth.getUserTechKey().then(valor => this.user_tech_key = valor)
  }

  ngOnInit(){
    this.arraysAcuerdos=[];
    this.setTitle();
  }

  private setTitle() {
    this.subscription = this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  recive(arrraysAcuerdos: listadoAcuerdo[]) {
    this.arraysAcuerdos=arrraysAcuerdos;
    this.dataSource = new MatTableDataSource<listadoAcuerdo>(arrraysAcuerdos);
  }

  setAll(value: boolean) {
    if (this.arraysAcuerdos.length>0){
      this.seleccionados = value;
      this.arraysAcuerdos.forEach(ele => ele.seleccion = value)  
    }
  }


  someSelected(): boolean {
    return this.arraysAcuerdos.some(ele => ele.seleccion) && !this.arraysAcuerdos.every(ele => ele.seleccion) && this.arraysAcuerdos.length > 0;
  }

  everySelected(): boolean {
    return this.arraysAcuerdos.every(ele => ele.seleccion) && this.arraysAcuerdos.length > 0;
  }

  check(value: boolean) {
    let acuerdosSeleccionados: listadoAcuerdo[] = this.arraysAcuerdos.filter(acrd => acrd.seleccion);
    if (acuerdosSeleccionados.length>0){
      this.seleccionados=true;
    }
    else{
      this.seleccionados=false;
    }
  }

  verAcuerdo(nacuerdo: number, tipoAcuerdo:number, addprod: boolean, paramVer: boolean, accion: string) {
    //aca se va a al backend y se va a crear un objeto simulador con los datos del acuerdo.
    this.spinner.show('aprobarAcuerdo');
    this.spinnerMessage = "Buscando datos de acuerdo..."
    this.cargarAcuerdo(nacuerdo, tipoAcuerdo)
      .then(() => {
        if (tipoAcuerdo==128){
          switch (accion){
            case '':
              this.acuerdoSellOut.accion='';
              this.acuerdoSellOut.old_acuerdo=0;
              break;
            case 'copiar':
              this.acuerdoSellOut.accion='';
              this.acuerdoSellOut.old_acuerdo=nacuerdo;
              break;
            case 'editar':
              this.acuerdoSellOut.accion='modificar';
              this.acuerdoSellOut.old_acuerdo=nacuerdo;
              break;
          }
          this.openDialogAbrirAcuerdoSellOut(this.acuerdoSellOut, addprod, paramVer, accion)
        }
        else{
          switch (accion){
            case '':
              this.acuerdo.accion='';
              this.acuerdo.old_acuerdo=0;
              break;
            case 'copiar':
              this.acuerdo.accion='';
              this.acuerdo.old_acuerdo=nacuerdo;
              
              break;
            case 'editar':
              this.acuerdo.accion='modificar';
              this.acuerdo.old_acuerdo=nacuerdo;
              break;
          }
          this.openDialogAbrirAcuerdoFijo(this.acuerdo, addprod, paramVer, accion)
        }
      })
      .catch(err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
        } else {
          if (err instanceof HttpErrorResponse && err.status === 400) {
            this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
          }
          else{
            this.commonService.error('Error', err.message, 'Aceptar', true)
          }
        }
      })
      .finally(
        ()=>{
          this.spinner.hide('aprobarAcuerdo'); 
        }
      )
  }

  async cargarAcuerdo(nacuerdo: number, tipoAcuerdo: number) {
    if (tipoAcuerdo==128){
      this.acuerdoSellOut=new AcuerdoSellOut(this.rbtService,this.commonService, this.auth)
      return new Promise<void>((res, rej) => {
        this.acuerdoSellOut.datosAcuerdoSellOut(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })     
    }
    else{
      this.acuerdo = new Acuerdo(this.rbtService, this.commonService, this.auth);
      return new Promise<void>((res, rej) => {
        this.acuerdo.datosAcuerdoFijo(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })  
    }
  }

  openDialogAbrirAcuerdoFijo(acuerdo: Acuerdo, agregaProd: boolean, paramVer: boolean, accion:string) {
    if (accion=='copiar'){
      //estado queda en 1 porque es una copia, o sea parte desde el inicio
      acuerdo.estado=1
    }
    this.commonService.openDialog<Acuerdo, Acuerdo>(AcuerdoComponent, {
      organizacion: acuerdo.descr_org, parametroVer: paramVer, editarCopiar: (accion=='copiar' || accion=='editar'), addProd:agregaProd, btnCerrar: (paramVer) ? 'Cerrar' : 'Cancelar', title: 'Acuerdo Datos'
    }, { data: acuerdo, height: '95%', disableClose: true })
  }

  openDialogAbrirAcuerdoSellOut(acuerdoSellOut: AcuerdoSellOut, agregaProd: boolean, paramVer: boolean, accion:string) {
    if (accion=='copiar'){
      //estado queda en 1 porque es una copia, o sea parte desde el inicio
      acuerdoSellOut.estado=1
    }
    this.commonService.openDialog<AcuerdoSellOut, AcuerdoSellOut>(AcuerdoSellOutComponent, {
      organizacion: '', parametroVer: paramVer, editarCopiar: (accion=='copiar' || accion=='editar'), btnCerrar: (paramVer) ? 'Cerrar' : 'Cancelar',addProd:agregaProd,  title: 'Acuerdo Datos'
    }, { data: acuerdoSellOut, height: '95%', disableClose: true })
  }
}
