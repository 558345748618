<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'searchBonificacionesSpinner'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>

<table class="tabla" aria-describedby="">
    <tr>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Año</mat-label>
                <mat-select [(value)]="anio">
                    <mat-option [value]="valor" *ngFor="let valor of anios">{{valor}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td class="celda celda_texto" colspan="3">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Mes</mat-label>
                <mat-select [(value)]="mes">
                    <mat-option [value]="valor.valor"
                        *ngFor="let valor of meses, let i=index">{{valor.descr}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td class="celda celda_texto">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Línea</mat-label>
                <mat-select [(ngModel)]="linea">
                    <mat-option [value]="item.valor" *ngFor="let item of lineas"
                        (click)="cargaSublineas()">{{item.descr}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td class="celda_texto ">
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Sub Línea</mat-label>
                <mat-select [(value)]="sublinea">
                    <mat-option [value]="item.valor" *ngFor="let item of sublineas">{{item.descr}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td class="celda celda_texto">
            &nbsp;
        </td>

        <td>
            <button mat-raised-button color="primary" class="pad" (click)="generarExcel()">Generar Excel</button>
            <button mat-raised-button color="primary" class="pad" (click)="buscarBonificaciones()">Buscar</button>
            <button mat-raised-button color="warn" class="pad" (click)="Limpiar()">Limpiar</button>
        </td>
    </tr>
</table>