import { Component, Input, Inject, HostListener } from '@angular/core';
import { ThemePalette } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  @Input() color: ThemePalette = "primary"
  @Input() title!: string
  @Input() message!: string
  @Input() buttonTitle!:string

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    color: ThemePalette
    message: string,
    title: string,
    buttonTitle:string
  }, private mdDialogRef: MatDialogRef<AlertDialogComponent>) {
    this.color = data.color
    this.title = data.title
    this.message = data.message
    this.buttonTitle=data.buttonTitle
  }

  public cancel() {
    this.close(false);
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  
  }
  public confirm() {
    this.close(true);
  }
  
  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }

}
