import * as Interfaces from 'src/app/interfaces/ISimulador'
import { RebateBackendService } from '../services/rebate/rebate.backend.service';
import { Proveedor } from './proveedor';
import { CommonsModule } from 'src/app/services/commons/commons.service'
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SimuladorSellOut } from './simuladorSellOut';
import { SearchType } from "../components/simulador/search-tienda/search-tienda.component";
import { Buffer } from 'buffer';
import { saveAs } from 'file-saver';
import { AuthService } from '../services/auth/auth.service';


export class AcuerdoSellOut {
    public nAcuerdo: number = 0;
    public tpAcuerdo: number = 0;
    public nombreAcuerdo: string = '';
    public descrAcuerdo: string = '';
    public agrAcuerdo: number = 0;
    public subTipoAcuerdo: number = 0;
    public tipoRecupero: number = 0;
    public tipoLiquidacion: number = 0;
    public txtLiquidacion: string = ''
    public proveedor: Proveedor;
    public organizacion: number = 0;
    public isJerarquia: boolean = false;
    public fechaDesde: Date = new Date();
    public fechaHasta: Date = new Date();
    public danadoVendible: boolean = false;
    public tiendas: Interfaces.Tiendas[] = [];
    public marcas: Interfaces.Marca[] = [];
    public pbc: Proveedor[] = [];
    public dv: boolean = false;
    public tabla: Interfaces.FilaTablaSimuladorSellOut[] = []
    public acrdReferencia: number
    public cargaArchivo: boolean = false;
    public file!: File;
    public fileBase64: string = "";
    public nombreArchivo: string = '';
    public old_acuerdo: number = 0;
    public accion: string = '';
    public origen!: string;
    public estado: number = 0;
    private user_tech_key!: string;
    public country: string = '';

    constructor(private backend: RebateBackendService,
        private commons: CommonsModule,
        private auth: AuthService) {
        this.nAcuerdo = 0
        this.proveedor = new Proveedor(backend)
        this.acrdReferencia = 0
        this.auth.getUserTechKey().then(valor => { this.user_tech_key = valor })
    }

    public esSimulacion(simulador: SimuladorSellOut) {
        this.proveedor = simulador.proveedor;
        this.isJerarquia = simulador.isJerarquia
        //this.fechaDesde = simulador
        //this.fechaHasta = simulador.fechaHasta
        this.danadoVendible = simulador.danadoVendible
        //this.organizacion=simulador.organizacion
        //this.tiendas = simulador.tiendas
        this.marcas = simulador.marcas
        this.pbc = simulador.pbc
        this.dv = simulador.dv
        this.accion = ''
        this.old_acuerdo = 0
        //viene de la simulacion por lo que el estado debe quedar en 1 digitado
        this.estado = 1
        this.tabla = simulador.tabla.map(ele => {
            let resp = Object.assign({}, ele)
            resp.aporte = Math.floor(resp.aporte)
            return resp
        })
    }

    async doGuardar(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let erroresValidacion: Error[] = this.validaPreGuardado();
            if (erroresValidacion.length === 0) {
                let acrdo: Interfaces.AcuerdoSellOutGuardar = {
                    ctpacuerdo: this.tpAcuerdo,
                    cmbacuerdo: this.nombreAcuerdo,
                    vpctechkey: CommonsModule.stringToNumber(this.proveedor.vpc_tech_key.toString()),
                    dvigdesde: Intl.DateTimeFormat('es-CL').format(new Date()),
                    dvighasta: Intl.DateTimeFormat('es-CL').format(new Date()),
                    cagrupador: this.agrAcuerdo,
                    adescacuerdo: this.descrAcuerdo,
                    ctprecupero: this.tipoRecupero,
                    cestado: this.estado,
                    csubtipo: this.subTipoAcuerdo,
                    bauto: 'T', // en duro va T
                    cflagtope: 'F', //va F en duro hay que quitar de la pagina los chech de los topes.
                    cflagdvendible: (this.dv) ? 'T' : 'F',
                    corigen: this.origen, //S en duro
                    nacuerdoref: this.acrdReferencia,
                    usr_tech_key: parseInt(this.user_tech_key),
                    corganizacion: 0,
                    tiendaLocal: "",
                    cproducto: 11, //va 11 en duro
                    codtope: 95, //va en duro 95
                    cliquidacion: 46, //va en duro
                    bonimalifi: 56,//56 va en duro
                    strProductos: this.armarSku(this.tabla),
                    aplicarsobre: 39, //39 en duro
                    tipoliquidacion: this.tipoLiquidacion,
                    txtliquidacion: this.txtLiquidacion,
                    marcas: this.armarMarcas(this.marcas),
                    seller: this.armarPBC(),
                    old_acuerdo: this.old_acuerdo,
                    accion: this.accion,
                    nfile: this.nombreArchivo,
                    file: this.fileBase64,
                }
                if (environment.debug) {
                    console.log(JSON.stringify(acrdo))
                }
                //reject(JSON.stringify(acrdo))
                this.postGuardado(acrdo)
                    .then(data => resolve(data))
                    .catch(reason => {
                        if (reason instanceof HttpErrorResponse) reject(this.showErrors([new Error(reason.message)]))
                        else reject(reason)
                    })
                //resolve(true)
            }
            else {
                reject(this.showErrors(erroresValidacion))
            }
        })
    }

    private showErrors(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private validaPreGuardado(): Error[] {
        let erroresFormulario: Error[] = []
        if (this.fechaDesde == null) {
            erroresFormulario.push(errors.FechaDesdeError);
        }
        if (this.fechaHasta == null) {
            erroresFormulario.push(errors.FechaHastaError);
        }
        if ((this.fechaDesde != null && this.fechaHasta != null) && (this.fechaDesde > this.fechaHasta)) {
            erroresFormulario.push(errors.FechaDesdeMayorHasta);
        }
        if (this.proveedor.nrut.length === 0) {
            erroresFormulario.push(errors.MissingProveedor);
        }
        if (this.nombreAcuerdo.length === 0) {
            erroresFormulario.push(errors.MissinNombreAcuerdo);
        }
        else {
            if (this.nombreAcuerdo.length > 50) {
                erroresFormulario.push(errors.NombreAcuerdoLargo);
            }
        }
        if (this.fileBase64.length === 0) {
            erroresFormulario.push(errors.MissingFileRespaldo);
        }
        if (this.tabla.length === 0) {
            erroresFormulario.push(errors.MissingJerarquiaOrSku);
        }
        if (this.isJerarquia) {
            if ((this.pbc.length === 0 || this.pbc[0].nrut.length === 0) && (this.marcas.length === 0 || this.marcas[0].cmarca.length === 0)) {
                erroresFormulario.push(errors.MissingPBCMarca);
            }
        }
        /*if (this.isJerarquia) {
            if (this.pbc.length === 0 || this.pbc[0].nrut.length === 0) {
                erroresFormulario.push(errors.MissingPBC);
            }
        }*/
        return erroresFormulario;
    }

    private postGuardado(acuerdo: Interfaces.AcuerdoSellOutGuardar): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            //console.log(JSON.stringify(acuerdo))
            let respuesta: Interfaces.GuardarAcuerdoRespuesta;
            this.backend.callBackendPost<Interfaces.GuardarAcuerdoRespuesta>(`/creaAcuerdoSellOutSimulador/`, [acuerdo]).subscribe({
                next: (data) => respuesta = data,
                error: (err) => reject(err),
                complete: () => {
                    if (respuesta.nacuerdo > 0) {
                        this.nAcuerdo = respuesta.nacuerdo
                        resolve(true)
                    } else {
                        reject(errors.ErrorGuardadoAcuerdo)
                    }
                }
            })
        })
    }

    datosAcuerdoSellOut(nAcuerdo: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let anio: number = 0;
            let mes: number = 0;
            let dia: number = 0;
            let fecha: string[] = [];
            this.backend.callBackendGet<Interfaces.AcuerdoSellOut[]>(`/acuerdoSellOut/${nAcuerdo}`).subscribe({
                next: data => {
                    this.nAcuerdo = data[0].nacuerdo
                    this.tpAcuerdo = data[0].tip_id_acuerdo
                    this.nombreAcuerdo = data[0].nombre
                    this.descrAcuerdo = data[0].des_acuerdo
                    this.agrAcuerdo = data[0].c_agrupador
                    this.subTipoAcuerdo = data[0].c_subtipo
                    this.tipoRecupero = data[0].recupero
                    this.tipoLiquidacion = data[0].tipo_liquidacion
                    this.txtLiquidacion = data[0].texto_liquidacion
                    this.proveedor.setValues(data[0].nrut, data[0].des_proveedor, data[0].xdvrut, data[0].vpc_tech_key)
                    this.danadoVendible = (data[0].c_flagdvendible == 'F') ? false : true;
                    this.marcas = data[0].marcas
                    this.estado = data[0].c_estado
                    this.pbc = []
                    data[0].proveedores.forEach(ele => {
                        let proveedor: Proveedor = new Proveedor();
                        proveedor.setValues(ele.nrut, ele.vendor_name, ele.xdvrut, ele.vpc_tech_key);
                        this.pbc.push(proveedor)
                    })
                    this.dv = (data[0].c_flagdvendible == 'F') ? false : true;
                    this.isJerarquia = false;

                    this.tabla = data[0].detalleAcuerdo.map<Interfaces.FilaTablaSimuladorSellOut>(ele => {
                        let fec: string = ele.fdesde
                        fecha = fec.split("-")
                        anio = Number(fecha[2])
                        mes = Number(fecha[1]) - 1
                        dia = Number(fecha[0])
                        let fecDesde: Date = new Date(anio, mes, dia);
                        fec = ele.fhasta
                        fecha = fec.split("-")
                        anio = Number(fecha[2])
                        mes = Number(fecha[1]) - 1
                        dia = Number(fecha[0])
                        let fecHasta: Date = new Date(anio, mes, dia);
                        let tipo: SearchType;
                        (ele.organizacion.toUpperCase().includes('LOCAL')) ? tipo = SearchType.LOCAL : tipo = SearchType.TIENDA
                        let tiendasEnAcuerdo: Interfaces.Tiendas[] = [];
                        if (['7', '8'].includes(String(ele.tipo_org))) {
                            if (ele.tienda_local == null) {
                                tiendasEnAcuerdo = [{ name: (ele.organizacion.toUpperCase().includes('LOCAL')) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
                            }
                            else {
                                ele.tienda_local.forEach(tienda => {
                                    if (tienda.value == "1") {
                                        tiendasEnAcuerdo = [{ name: (ele.organizacion.toUpperCase().includes('LOCAL')) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
                                    }
                                    else {
                                        tiendasEnAcuerdo.push({ name: tienda.name, value: tienda.value });
                                    }
                                })
                            }
                        }
                        else {
                            tiendasEnAcuerdo = ele.tienda_local
                        }
                        this.isJerarquia = (ele.jerarquia.includes('J'))
                        return {
                            jerarquia: ele.jerarquia,
                            prd_lvl_child: ele.prd_lvl_child,
                            name_full: ele.name_full,
                            unidad: ele.unidad.toString(),
                            porcentaje: 0,
                            aporte: ele.porcentaje,
                            aporte_total: (ele.porcentaje * ele.unidad),
                            cod_promocion: '',
                            fecdesde: fecDesde,
                            fechasta: fecHasta,
                            organizacion: {
                                c_parametro: String(ele.tipo_org),
                                a_valor: (ele.organizacion_otros != '') ? ele.organizacion_otros : ele.organizacion,
                                searchType: tipo,
                                path: '',
                                selected: true
                            },
                            tiendaLocal: tiendasEnAcuerdo,
                            descrOrg: '',
                            display: true
                        }
                    })

                    this.acrdReferencia = 0
                    this.nombreArchivo = data[0].nombre_archivo
                    this.fileBase64 = data[0].archivo
                },
                error: err => {
                    reject(err)

                },
                complete: () => {
                    resolve(true)
                }
            }
            )
        })
    }

    private armarSku(datos: Interfaces.FilaTablaSimuladorSellOut[]): string {
        return datos.reduce((prv, curr) => {
            let fec_desde: string = Intl.DateTimeFormat('es-CL').format(curr.fecdesde)
            let fec_hasta: string = Intl.DateTimeFormat('es-CL').format(curr.fechasta)
            prv += (prv == "") ?
                `${curr.jerarquia}:${curr.aporte}:0:${curr.unidad}:${curr.prd_lvl_child}:${curr.name_full}:::${fec_desde}:${fec_hasta}:0:0:0:0:${curr.organizacion.a_valor}:${this.armarOrg(curr.tiendaLocal, curr.organizacion)}:marca:${curr.organizacion.c_parametro}`
                : `|${curr.jerarquia}:${curr.aporte}:0:${curr.unidad}:${curr.prd_lvl_child}:${curr.name_full}:::${fec_desde}:${fec_hasta}:0:0:0:0:${curr.organizacion.a_valor}:${this.armarOrg(curr.tiendaLocal, curr.organizacion)}:marca:${curr.organizacion.c_parametro}`
            return prv
        }, "")
    }

    private armarOrg(organizaciones: Interfaces.Tiendas[], orga: Interfaces.OrganizacionOptions): string {
        if (organizaciones.length == 1) {
            if (orga.c_parametro == "7" && organizaciones[0].value == "0") {
                return "1"
            }
            if (orga.c_parametro == "8" && organizaciones[0].value == "0") {
                return "0"
            }
        }
        return organizaciones.reduce((prv, curr) => {
            prv += (prv == "") ? curr.value : `,${curr.value}`
            return prv
        }, "")
    }

    private armarMarcas(marcasSeleccionadas: Interfaces.Marca[]): string {
        //if (marcasSeleccionadas.length == 0) return "0";
        return marcasSeleccionadas.reduce((prv, curr) => {
            prv += (prv == "") ? curr.cmarca : `;${curr.cmarca}`
            return prv
        }, "")
    }

    private armarPBC(): string {
        return this.pbc.reduce((prv, curr) => {
            prv += (prv == "") ? curr.vpc_tech_key : `;${curr.vpc_tech_key}`
            return prv
        }, "")
    }


    public descargarArchivo(base64: any, fileName: any) {
        let archivo: string[] = fileName.split('.');
        if (archivo.length > 1) {
            let ext = archivo[1];
            let blob = this.base64ToArrayBuffer(base64, 'text/plain');
            console.log(base64)
            saveAs(blob, fileName);
        }
    }


    base64ToArrayBuffer(base64: string, contentType = '') {
        let sliceSize = 512;
        let b64Data = base64.replace(/\s/g, ''); //IE compatibility...
        let binaryString = Buffer.from(b64Data, 'base64').toString('binary')
        let byteArrays = [];
        for (let offset = 0; offset < binaryString.length; offset += sliceSize) {
            let slice = binaryString.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    cleanTabla(limpiaPBC: boolean = true) {
        this.tabla = []
        this.isJerarquia = false;
        if (limpiaPBC) {
            this.pbc = []
            this.marcas = []
        }
    }


    private validaFilas(datos: Interfaces.AddProductStructSellOut[]): Error[] {
        let errs: Error[] = []
        datos.forEach((ele, idx) => {
            if (ele.aporte <= 0) {
                errs.push(new Error(`Fila ${idx + 1}: ` + errors.MissingAporte))
            }
            if (ele.fecDesde == null) {
                errs.push(new Error(`Fila ${idx + 1}: ` + errors.FechaDesdeError))
            }
            else {
                if (ele.fecDesde == new Date(1900, 1, 1)) {
                    errs.push(new Error(`Fila ${idx + 1}: ` + errors.FechaDesdeError))
                }
            }
            if (ele.fecHasta == null) {
                errs.push(new Error(`Fila ${idx + 1}: ` + errors.FechaHastaError))
            }
            let tiendas: string = ''
            tiendas = ele.tiendaLocal.reduce((prv, curr) => {
                prv += (prv == "") ? curr.value : `; ${curr.value}`
                return prv
            }, "")
            if (tiendas == '') {
                errs.push(new Error(`Fila ${idx + 1}: ${errors.MissingLocalesSellOutExcel}`))
            }
        }
        )
        return errs
    }



    private async addProductPromise(ele: Interfaces.AddProductStructSellOut[]): Promise<Interfaces.AddProductStructSellOut[]> {
        let errs: Error[] = []
        let isJerar = false;
        let isSkuJerar = false
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...ele.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J'; //hay J en la tabla por lo que no puedo ingresar productos.

        ele.forEach((prod, idx) => {
            if (prod.sku == '') {
                errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingJerarquiaOrSku))
            }
            if (prod.aporte <= 0) {
                errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingAporte))
            } else {
                if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                    if (prod.aporte % 1 != 0) {
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.AporteDecimal))
                    }
                }
            }
            isSkuJerar = prod.sku.substring(0, 1) == 'J'
            if (isJerar != isSkuJerar) {
                if (isJerar) { //no puede ingresar un producto con J en la tabla
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.ProductosSinjerar))
                }
                if (isSkuJerar) {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.JerarSinProductos))
                }
            }

        })

        if (errs.length == 0) {
            let p1 = new Promise<void>((res, rej) => {
                let locMass: Interfaces.addLocalesMass[] = [];
                ele.forEach((p, i) => {
                    if (p.organizacion.c_parametro == '0') {
                        let tiendas: string = ''
                        tiendas = p.tiendaLocal.reduce((prv, curr) => {
                            prv += (prv == "") ? curr.value : `; ${curr.value}`
                            return prv
                        }, "")
                        locMass.push({ seq: i.toString(), locales: tiendas })
                    }
                })
                if (locMass.length > 0) {
                    this.buscaLocalesMass(locMass)
                        .then(data => {
                            data.forEach((locales, idx) => {
                                let valor: string = locales.secuencia;
                                valor = valor.replaceAll(".", "@")
                                valor = valor.replaceAll(",", ".")
                                valor = valor.replaceAll("@", "")
                                let seq: number = CommonsModule.stringToNumber(valor)

                                if (locales.status == 'error') {
                                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongLocalSellOut));
                                }
                                else {
                                    let child: string = locales.child;
                                    let orgDescr: string = locales.desc_org;
                                    let orgCod: string = locales.tipo_org;
                                    let local: string[] = child.split(',')
                                    ele[seq].tiendaLocal = []
                                    local.forEach(loc => {
                                        let varLoc = loc.split(';');
                                        let tienda: Interfaces.Tiendas = { value: varLoc[0], name: varLoc[1] }
                                        ele[seq].tiendaLocal.push(tienda)
                                    })
                                    ele[seq].organizacion.c_parametro = orgCod
                                    ele[seq].organizacion.a_valor = orgDescr
                                }
                            })
                            if (errs.length > 0) {
                                rej(this.addProductError(errs))
                            }
                            else {
                                res()
                            }
                        })
                        .catch(reason => {
                            rej(this.addProductError(reason))
                        })
                }
                else {
                    res();
                }
            })

            let p2 = new Promise<void>((res, rej) => {
                let pro: Interfaces.AddProductMass[] = [];
                ele.forEach((p, i) => {
                    if (p.name_full == '') {
                        pro.push({ sku: p.sku, seq: i.toString() })
                    }
                })

                this.buscaProductoMass(pro)
                    .then(data => {
                        data.forEach((dataprod, idx) => {
                            if (dataprod.status == 'error') {
                                errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongJerarquiaOrSku));
                            }
                            else {
                                let valor: string = dataprod.secuencia;
                                valor = valor.replaceAll(".", "@")
                                valor = valor.replaceAll(",", ".")
                                valor = valor.replaceAll("@", "")
                                let seq: number = CommonsModule.stringToNumber(valor)  //numero de fila 
                                ele[seq].name_full = dataprod.name_full
                                ele[seq].prd_lvl_child = dataprod.prd_lvl_child
                            }
                        })
                        if (errs.length > 0) {
                            rej(this.addProductError(errs))
                        }
                        else {
                            res()
                        }
                    })
                    .catch(reason => {
                        errs = [];
                        errs.push(new Error(`${(reason as Error)}`));
                        rej(this.addProductError(errs))
                    })
            })

            let results = await Promise.allSettled([p1, p2])
            let errorMsg = results.reduce((prv, curr) => {
                if (curr.status == 'rejected') {
                    return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
                } else {
                    return prv
                }
            }, "")
            if (errorMsg.length > 0) {
                errs.push(new Error(errorMsg))
            }
        }

        return new Promise<Interfaces.AddProductStructSellOut[]>((resolve, reject) => {
            if (errs.length > 0) {
                reject(this.addProductError(errs))
            }
            else {
                resolve(ele)
            }
        })
    }



    async addProducts(data: Interfaces.AddProductStructSellOut[]): Promise<boolean> {
        let errs: Error[] = [];
        errs = this.validaFilas(data)

        return new Promise<boolean>((resolve, reject) => {
            if (errs.length > 0) {
                reject(this.addProductError(errs))
            }
            else {
                this.addProductPromise(data)
                    .then(datos => {
                        this.addProductOnComplete(datos)
                        resolve(true)
                    }, reason => {
                        reject(reason)
                    })
            }
        })
    }

    private addProductError(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private addProductOnComplete(data: Interfaces.AddProductStructSellOut[]): Error | null {
        let isValid = true;
        let isJerar = false;
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...data.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';
        isValid = tmpData.every(sku => (sku.substring(0, 1) == 'J') == isJerar)
        if (isValid) {
            data.forEach(ele => {
                this.tabla.push({
                    jerarquia: ele.sku,
                    prd_lvl_child: ele.prd_lvl_child,
                    name_full: ele.name_full,
                    fecdesde: ele.fecDesde,
                    fechasta: ele.fecHasta,
                    unidad: (ele.unidad != undefined) ? ele.unidad.toString() : "0",
                    aporte: ele.aporte,
                    aporte_total: (ele.unidad != undefined) ? (ele.aporte * ele.unidad) : 0,
                    organizacion: {
                        c_parametro: ele.organizacion.c_parametro,
                        a_valor: this.armarDescrOrgFromLocales(ele.tiendaLocal, ele.organizacion, ele.seltiendas),
                        searchType: ele.organizacion.searchType,
                        path: '',
                        selected: false
                    },
                    descrOrg: ele.descrOrg,
                    tiendaLocal: ele.tiendaLocal,
                    display: true
                })

                /*this.tabla.push({
                    jerarquia: ele.sku,
                    prd_lvl_child: ele.prd_lvl_child,
                    name_full: ele.name_full,
                    fecdesde: ele.fecDesde,
                    fechasta: ele.fecHasta,
                    unidad: (ele.unidad != undefined) ? ele.unidad.toString() : "0",
                    aporte: ele.aporte,
                    aporte_total: (ele.unidad != undefined) ? (ele.aporte * ele.unidad) : 0,
                    organizacion: ele.organizacion,
                    descrOrg: ele.descrOrg,
                    tiendaLocal: ele.tiendaLocal,
                    display: true
                })*/
                if (!this.isJerarquia) this.isJerarquia = (ele.sku.substring(0, 1) == 'J')
            });
            return null
        } else {
            return (isJerar) ? errors.JerarSinProductos : errors.ProductosSinjerar;
        }
    }

    buscaLocalesMass(locales: Interfaces.addLocalesMass[]): Promise<Interfaces.ExcelBuscaLocales[]> {
        return new Promise<Interfaces.ExcelBuscaLocales[]>((resolve, reject) => {
            this.commons.validaLocales(locales).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

    buscaProductoMass(prods: Interfaces.AddProductMass[]): Promise<Interfaces.ProductInfo[]> {

        return new Promise<Interfaces.ProductInfo[]>((resolve, reject) => {
            this.commons.validateProducts(prods).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }


    buscaLocales(locales: string): Promise<Interfaces.TiendasOrgExcel> {
        let tiendasExcel: Interfaces.Tiendas[] = []
        let orgExcel: Interfaces.OrganizacionOptions = { c_parametro: "0", a_valor: '', searchType: SearchType.TIENDA, selected: true, path: '' };

        return new Promise<Interfaces.TiendasOrgExcel>(
            (resolve, reject) => {
                if (locales == '') {
                    reject(errors.MissingLocalesSellOutExcel)
                }
                else {
                    let tiendaOrg: Interfaces.TiendasOrgExcel
                    this.backend.callBackendGet<Interfaces.ExcelBuscaLocales>('/organizaciones/sellout/' + locales).subscribe({
                        next: valor => {
                            let child: string = valor.child;
                            let orgDescr: string = valor.desc_org;
                            let orgCod: string = valor.tipo_org;
                            let local: string[] = child.split(',')
                            local.forEach(loc => {
                                let varLoc = loc.split(';');
                                let tienda: Interfaces.Tiendas = { value: varLoc[0], name: varLoc[1] }
                                tiendasExcel.push(tienda)
                            })

                            orgExcel.c_parametro = orgCod;
                            orgExcel.a_valor = orgDescr;

                            tiendaOrg = {sec:'0', org: orgExcel, tiendas: tiendasExcel }

                            resolve(tiendaOrg)
                        },
                        error: err => {
                            (err instanceof HttpErrorResponse && err.status == 404) ? reject(errors.WrongLocalSellOut) : reject(err)
                        }
                    })
                }
            })
    }

    private armarDescrOrgFromLocales(locales: Interfaces.Tiendas[], orga: Interfaces.OrganizacionOptions, selTiendas: boolean): string {
        if (locales.length > 0) {
            if (selTiendas) {
                return locales.reduce((prv, curr) => {
                    prv += (prv == "") ? curr.name : `, ${curr.name}`
                    return prv
                }, "")
            }
            else {
                //return orga.a_valor
                return locales.reduce((prv, curr) => {
                    prv += (prv == "") ? curr.name : `, ${curr.name}`
                    return prv
                }, "")
            }
        }
        else {
            return "0"
        }
    }

}