<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'verBonificaciones'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<mat-card>
    <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-search-bonificaciones (ListaBonificaciones)="recive($event)" [showDates]="true"
            [estadoSearch]="'0'"></app-search-bonificaciones>
    </mat-card-content>
    <br>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
            <ng-container matColumnDef="num">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">#</th>
                <td mat-cell *matCellDef="let i=index" class="right letraSize">{{i+1}} </td>
            </ng-container>
            <ng-container matColumnDef="n_acuerdo">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Cod. Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="right letraSize link"
                    (click)="verAcuerdo(element.nacuerdo, element.csubtipo)">{{element.nacuerdo}}</td>
            </ng-container>
            <ng-container matColumnDef="nomAcuerdo">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="letraSize link"
                    (click)="verAcuerdo(element.nacuerdo, element.csubtipo)"> {{element.nombreacuerdo | largoMax: 10}}
                </td>
            </ng-container>
            <ng-container matColumnDef="proveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Proveedor</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.nrut}}-{{element.xdvrut}}
                </td>
            </ng-container>
            <ng-container matColumnDef="nomProveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Proveedor</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.vendorname}} </td>
            </ng-container>
            <ng-container matColumnDef="vigDesde">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vig. Desde</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.dvigdesde}} </td>
            </ng-container>
            <ng-container matColumnDef="vigHasta">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vig. Hasta</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.dvighasta}} </td>
            </ng-container>
            <ng-container matColumnDef="fecBnf">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Bnf</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.dultbnf}} </td>
            </ng-container>
            <ng-container matColumnDef="internetBnf">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Internet Bnf</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.internetbnf | countryFormat:
                    country: '0' : true}} </td>
            </ng-container>
            <ng-container matColumnDef="restoCadBnf">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Resto Cad Bnf</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.restobnf | countryFormat:
                    country: '0' : true}} </td>
            </ng-container>
            <ng-container matColumnDef="totalBnf">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Total Bnf</th>
                <td mat-cell *matCellDef="let element" class="letraSize right link"
                    (click)="verBonificaciones(element.nacuerdo, element.nombreacuerdo, element.csubtipo)">
                    {{element.cadenabnf | countryFormat:
                    country: '0' : true}}
                </td>
            </ng-container>
            <ng-container matColumnDef="unidadesBnf">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Unidades Bnf</th>
                <td mat-cell *matCellDef="let element" class="letraSize right link"
                    (click)="verBonificaciones(element.nacuerdo, element.nombreacuerdo, element.csubtipo)">
                    {{element.unibnf | countryFormat: country: '0' : true}} </td>
            </ng-container>
            <ng-container matColumnDef="nDocPago">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Nº Doc Pago</th>
                <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.tliquidacion}}
                </td>
            </ng-container>
            <ng-container matColumnDef="usuario">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Usuario</th>
                <td mat-cell *matCellDef="let element" class="letraSize right">
                    {{element.usrsignon}}
                </td>
            </ng-container>
            <ng-container matColumnDef="export">
                <td mat-footer-cell *matFooterCellDef colspan="14" class="center">
                    <img src='./assets/images/excel-icon.svg' alt="Exportar" class="img" (click)="exportExcel()"/>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['export']"></tr>
        </table>
        <!--<div [hidden]="ver">
                <mat-paginator #paginator [length]="arraysAcuerdos.length" showFirstLastButtons
                    [pageSizeOptions]="[5, 7, 10, 25, 100]" aria-label="Select page">
                </mat-paginator>
            </div>-->
    </mat-card-content>
</mat-card>