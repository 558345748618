import { Component, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { ActivatedRoute } from '@angular/router';
import { VerEstadosAcuerdoComponent } from 'src/app/shared/components/ver-estados-acuerdo/ver-estados-acuerdo.component';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { VerBonificacionesAcuerdoComponent } from 'src/app/shared/components/ver-bonificaciones-acuerdo/ver-bonificaciones-acuerdo.component';
import { CountryFormatPipe } from '../pipes/countryFormat.pipe';
import { VerBnfAcuerdoSellOutComponent } from 'src/app/shared/components/ver-bnf-acuerdo-sell-out/ver-bnf-acuerdo-sell-out.component';

interface listaBonificaciones extends Interfaces.ListadoBonificaciones {
  seleccion: boolean
}
@Component({
  selector: 'app-ver-bonificaciones',
  templateUrl: './ver-bonificaciones.component.html',
  styleUrls: ['./ver-bonificaciones.component.scss']
})
export class VerBonificacionesComponent {

  arraysAcuerdos: listaBonificaciones[] = [];
  dataSource: MatTableDataSource<listaBonificaciones>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public spinnerMessage: string = ''
  public ver: boolean = false;
  public acuerdo!: Acuerdo;
  public acuerdoSellOut!: AcuerdoSellOut;
  public title!: string;
  pipeFormat: CountryFormatPipe;

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['num', 'n_acuerdo', 'nomAcuerdo', 'proveedor', 'nomProveedor', 'vigDesde', 'vigHasta', 'fecBnf', 'internetBnf', 'restoCadBnf', 'totalBnf', 'unidadesBnf', 'nDocPago', 'usuario'];
    this.dataSource = new MatTableDataSource<listaBonificaciones>(this.arraysAcuerdos);
    this.pipeFormat = new CountryFormatPipe();
  }

  ngOnInit() {
    this.arraysAcuerdos = [];
    this.setTitle();
  }

  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  recive(arrraysAcuerdos: listaBonificaciones[]) {
    this.arraysAcuerdos = arrraysAcuerdos;
    this.dataSource = new MatTableDataSource<listaBonificaciones>(arrraysAcuerdos);
  }

  verAcuerdo(nacuerdo: number, tipoAcuerdo:number) {
    //aca se va a al backend y se va a crear un objeto simulador con los datos del acuerdo.
    this.spinner.show('verBonificaciones');
    this.spinnerMessage = "Buscando datos de acuerdo..."
    this.cargarAcuerdo(nacuerdo, tipoAcuerdo)
      .then(() => {
        if (tipoAcuerdo==128){
          this.openDialogAbrirAcuerdoSellOut(this.acuerdoSellOut)
        }
        else{
          this.openDialogAbrirAcuerdoFijo(this.acuerdo)
        }
      })
      .catch(err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
        } else {
          if (err instanceof HttpErrorResponse && err.status === 400) {
            this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
          }
          else{
            this.commonService.error('Error', err.message, 'Aceptar', true)
          }
        }
      })
      .finally(
        ()=>{
          this.spinner.hide('verBonificaciones'); 
        }
      )
  }

  async cargarAcuerdo(nacuerdo: number, tipoAcuerdo: number) {
    if (tipoAcuerdo==128){
      this.acuerdoSellOut=new AcuerdoSellOut(this.rbtService,this.commonService, this.auth)
      return new Promise<void>((res, rej) => {
        this.acuerdoSellOut.datosAcuerdoSellOut(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })  
    }
    else{
      this.acuerdo = new Acuerdo(this.rbtService, this.commonService, this.auth);
      return new Promise<void>((res, rej) => {
        this.acuerdo.datosAcuerdoFijo(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })  
    }
  }

  openDialogAbrirAcuerdoFijo(acuerdo: Acuerdo) {
    this.commonService.openDialog<Acuerdo, Acuerdo>(AcuerdoComponent, {
      organizacion: acuerdo.descr_org, parametroVer: true, btnCerrar: 'Cerrar', title: 'Acuerdo Datos'
    }, { data: acuerdo, height: '95%', disableClose: true })
  }

  openDialogAbrirAcuerdoSellOut(acuerdoSellOut: AcuerdoSellOut) {
    this.commonService.openDialog<AcuerdoSellOut, AcuerdoSellOut>(AcuerdoSellOutComponent, {
      organizacion: '', parametroVer: true, btnCerrar: 'Cerrar', title: 'Acuerdo Datos'
    }, { data: acuerdoSellOut, height: '95%', disableClose: true })
  }

  verBonificaciones(nacuerdo: number, descrAcuerdo: string, tipoAcuerdo: number){
    if (tipoAcuerdo==128){
      //llamo a bonificaciones sellout
      let datosAcuerdo: Interfaces.verBonificaciones = { n_acuerdo: nacuerdo, descrAcuerdo: descrAcuerdo };
      this.commonService.openDialog<null, Interfaces.verBonificaciones>(VerBnfAcuerdoSellOutComponent, {}
        , { data: datosAcuerdo, width: '95%', height: '95%', disableClose: true })  
    }
    else{
      let datosAcuerdo: Interfaces.verBonificaciones = { n_acuerdo: nacuerdo, descrAcuerdo: descrAcuerdo };
      this.commonService.openDialog<null, Interfaces.verBonificaciones>(VerBonificacionesAcuerdoComponent, {}
        , { data: datosAcuerdo, width: '95%', height: '95%', disableClose: true })  
    }
  }

  exportExcel() {
    let rows: Object[] = [];
    this.arraysAcuerdos.forEach(element => {
      rows.push({
        "Cod. Acuerdo": element.nacuerdo,
        "Nombre Acuerdo": element.nombreacuerdo,
        "Proveedor": element.nrut + "-" + element.xdvrut,
        "Nombre Proveedor": element.vendorname,
        "Vig. Desde": element.dvigdesde,
        "Vig. Hasta": element.dvighasta,
        "Fecha Bnf": element.dultbnf,
        "Internet Bnf": this.pipeFormat.transform(element.internetbnf,this.country,"0",true),
        "Resto Cad Bnf": this.pipeFormat.transform(element.restobnf,this.country,"0",true),
        "Total Bnf": this.pipeFormat.transform(element.cadenabnf,this.country,"0",true),
        "Unidades Bnf": this.pipeFormat.transform(element.unibnf,this.country,"0",true),
        "Nº Doc Pago": element.tliquidacion,
        "Usuario": element.usrsignon
      })
    });
    CommonsModule.exportExcel(
      "bonificaciones-" +
      Intl.DateTimeFormat('es-ES', {day:'2-digit', month:'2-digit', year:'numeric'}).format(Date.now()) + "_" +
      Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now())
      ,rows);
  }
}
