export const errors = {
    MissingJerarquiaOrSku: new Error('Ingrese Jerarquía/SKU'),
    WrongJerarquiaOrSku: new Error('Jera.Prd / SKU Incorrecto'),
    MissingTiendas: new Error('Falta seleccionar tiendas o locales'),
    MissingAporte: new Error('Ingrese Aporte'),
    AporteDecimal: new Error("Monto no puede tener decimales."),
    JerarSinProductos: new Error("No puede ingresar una Jerarquía con productos"),
    ProductosSinjerar: new Error("No puede ingresar un producto con Jerarquías"),
    MissingProveedor: new Error("Ingrese Proveedor"),
    MissinNombreAcuerdo: new Error("Ingrese nombre de acuerdo"),
    NombreAcuerdoLargo:  new Error("Nombre acuerdo no puede superar los 50 caracteres."),
    MissingFileRespaldo: new Error("Falta Adjuntar respaldo proveedor"),
    FechaDesdeError: new Error("Fecha Desde incorrecta"),
    FechaHastaError: new Error("Fecha Hasta incorrecta"),
    FechaDesdeMayorHasta: new Error("Fecha inicial es mayor que fecha final"),
    ProveedorNoExiste: new Error("Proveedor no existe"),
    ErrorBuscarProveedor: new Error("Error al buscar proveedor"),
    MissingPBC: new Error('Ingrese PBC'),
    MissingPBCMarca: new Error('Debe ingresar PBC o Marca.'),
    ErrorSimulacion: new Error('Error en simulacion'),
    SimulacionSinData: new Error('Simulador no encontró datos buscados.'),
    ErrorGuardadoAcuerdo: new Error('Error al guardar el acuerdo.'),
    ErrorAprobacionAcuerdo: new Error('Error al aprobar.'),
    ErrorEliminacionAcuerdo: new Error('Error al Eliminar.'),
    LoadingExcelError: new Error('Error al cargar archivo'),
    MarcasNoEncontradas: new Error('No existen marcas con los parámetros ingresados.'),
    BonificacionesAcuerdoNoEncontradas: new Error('Bonificaciones para acuerdo no encontradas'),
    AcuerdosNoEncontrados: new Error('No existen acuerdos para los parámetros ingresados.'),
    BonificacionesNoEncontradas: new Error('No existen bonificaciones para los parámetros ingresados.'),
    BnfDesfasadasMesAnio: new Error('Ingrese mes y año a buscar'),
    BnfDesfazadasNoEncontradas:  new Error('No existen bonificaciones para los parámetros ingresados.'),
    BonificacionError: new Error('Error al buscar las bonificaciones.'),
    ProveedoresNoEncontrados: new Error("No existen proveedores con los parámetros ingresados."),
    ProductosSuperanMaximo: new Error('Cantidad de productos supera el máximo permitido'),
    ErrorBuscarAcuerdo: new Error('No existen datos del acuerdo.'),
    UrlNoEncontrada: Error('URL no encontrada'),
    MissingLocalesSellOutExcel: new Error('Línea de archivo no presenta Locales'),
    WrongLocalSellOut: new Error('Local Incorrecto.'), 
    liquidacionesNoEncontradas: new Error('No existen liquidaciones para los parámetros ingresados.'),
    missingCurrency: new Error('Debe ingresar valor de moneda'),
    missingFacturas: new Error('No existen facturas ingresadas.'),
    missingDetDoc: new Error('No existe detalle de documentos.'),
    ErrorGuardadoFactura: new Error('Error en el proceso. Por favor notificar a analista'),
    ErrorMissingAcuerdo: new Error('Ingrese número de Acuerdo'),
    ErrorLiquidacionNotFound: new Error('Liquidación no encontrada'),
    missingNc: new Error('No existen Notas de Crédito ingresadas.'),
    WrongAcuerdo: new Error('Acuerdo no encontrados'),
    MinXCaracteres: (cant: Number, campo: string, tipo: string) => {return new Error(`Ingrese al menos ${cant} caracteres en el campo ${campo} para listar ${tipo}`)}
}