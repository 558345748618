import { Component, OnInit } from '@angular/core';
import { Proveedor } from 'src/app/classes/proveedor';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { DetalleSolicitudesComponent } from 'src/app/shared/components/detalle-solicitudes/detalle-solicitudes.component';

interface BuscaSolicitudes extends Interfaces.BuscarSolicitudes {
  seleccion: boolean
}

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.scss']
})
export class SolicitudesComponent {
  private subscription!: Subscription;
  public spinnerMessage: string = "Loading ..."
  public nSolicitud: string = ''
  public usuario: string ='';
  public fechaDesde!: Date;
  public fechaHasta!: Date;
  public title?: string;
  public arraySolicitudes: BuscaSolicitudes[] = [];
  public dataSource: MatTableDataSource<BuscaSolicitudes>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;


  constructor(
    private activatedroute: ActivatedRoute,
    private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,
    private auth: AuthService,
    ) {
    //tipo proveedor
    this.auth.getCountry().then(value => this.country = value)
    this.dataSource = new MatTableDataSource<BuscaSolicitudes>(this.arraySolicitudes);
    this.columns = ['secuencia', 'tipoDoc', 'fecCreacion', 'usuario','detalle'];

  }

  ngOnInit(): void {
    this.iniciar();
    this.setTitle();
  }

  iniciar() {
    this.nSolicitud = '';
    this.usuario='';
    this.fechaDesde=(null as unknown as Date);
    this.fechaHasta=(null as unknown as Date);
    this.arraySolicitudes=[];
    this.dataSource = new MatTableDataSource<BuscaSolicitudes>(this.arraySolicitudes);
  }

  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }


  buscarSolicitudes(){
    this.spinnerMessage = 'Buscando Solicitudes ...'
    this.spinner.show('searchSolicitudesSpinner')
    let body: string = "";
    if (this.nSolicitud.length==0){
      body=body + "0"
    }
    else{
      body = body + this.nSolicitud;
    }
    (this.usuario=='') ? body = body + '/0' : body = body + '/' + this.usuario;
    //body = body +  '/' + CommonsModule.stringToNumber(this.proveedor.nrut) 

    if (this.fechaDesde == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaDesde);
    }
    if (this.fechaHasta == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaHasta);
    }
    this.srv.callBackendGet<BuscaSolicitudes[]>(`/liquidaciones/consultaliquidacion/${body}`).subscribe({
      next: lista => {
        lista.forEach(liqui => {
          liqui.seleccion = false;
        })
        this.arraySolicitudes=lista;
        this.dataSource = new MatTableDataSource<BuscaSolicitudes>(this.arraySolicitudes);
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.AcuerdosNoEncontrados.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
            this.commons.error('Error', errors.liquidacionesNoEncontradas.message, 'Aceptar', true) :
            this.commons.error('Error', err, 'Aceptar', true);
        }
        this.arraySolicitudes=[];
        this.spinner.hide('searchSolicitudesSpinner')
      },
      complete: () => {
        this.spinner.hide('searchSolicitudesSpinner')
      }
    }
    )

  }

  Limpiar(){
    this.iniciar();
  }  

  someSelected(): boolean {
    return this.arraySolicitudes.some(ele => ele.seleccion) && !this.arraySolicitudes.every(ele => ele.seleccion) && this.arraySolicitudes.length > 0;
  }

  everySelected(): boolean {
    return this.arraySolicitudes.every(ele => ele.seleccion) && this.arraySolicitudes.length > 0;
  }

  setAll(value: boolean) {
    if (this.arraySolicitudes.length>0){
      this.seleccionados = value;
      this.arraySolicitudes.forEach(ele => ele.seleccion = value)  
    }
  }

  check(value: boolean) {
    let acuerdosSeleccionados: BuscaSolicitudes[] = this.arraySolicitudes.filter(acrd => acrd.seleccion);
    if (acuerdosSeleccionados.length>0){
      this.seleccionados=true;
    }
    else{
      this.seleccionados=false;
    }
  }
  
  verDetalle(nSolicitud: number){
    let datosSolicitud: Interfaces.showSolicitud = { nSolicitud: nSolicitud};
    this.commonService.openDialog<null, Interfaces.showSolicitud>(DetalleSolicitudesComponent, {}
      , { data: datosSolicitud, width: '95%', height:'80%', disableClose: true })
  }
}
