import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog'
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import { CurrencyPipe } from '@angular/common';
//import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { CustomDateAdapter } from './classes/customDateAdapter'

//Auth Component
import { AuthModule } from './components/auth/auth.module';

//Custom Components
import { MenuComponent } from './components/menu/menu.component';
import { SimuladorComponent } from './components/simulador/simulador.component';
import { SearchTiendaComponent } from './components/simulador/search-tienda/search-tienda.component'
import { LandingComponent } from './components/landing/landing.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchProveedorComponent } from './components/simulador/search-proveedor/search-proveedor.component';
import { SearchMarcaComponent } from './components/simulador/search-marca/search-marca.component';

//Custom Pipes
import { CountryFormatPipe, CapitalizePipe, CountryDateFormatPipe, LargoMax } from './components/pipes/countryFormat.pipe';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component'
import { IsSelected } from './components/pipes/filter.pipe';
import { AcuerdoComponent } from './shared/components/acuerdo/acuerdo.component';
import { ShowDataComponent } from './components/show-data/show-data.component';
import { AprobarAcuerdoComponent } from './components/aprobar-acuerdo/aprobar-acuerdo.component';
import { SearchAcuerdoComponent } from './components/search-acuerdo/search-acuerdo.component';
import { VerAcuerdosComponent } from './components/ver-acuerdos/ver-acuerdos.component';
import { EliminarAcuerdosComponent } from './components/eliminar-acuerdos/eliminar-acuerdos.component';
import { CopiarAcuerdoComponent } from './components/copiar-acuerdo/copiar-acuerdo.component';
import { SimuladorSellOutComponent } from './components/simulador-sell-out/simulador-sell-out.component';
import { VerEstadosAcuerdoComponent } from './shared/components/ver-estados-acuerdo/ver-estados-acuerdo.component';
import { AcuerdoSellOutComponent } from './shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { SearchBonificacionesComponent } from './components/search-bonificaciones/search-bonificaciones.component';
import { VerBonificacionesComponent } from './components/ver-bonificaciones/ver-bonificaciones.component';
import { VerBonificacionesAcuerdoComponent } from './shared/components/ver-bonificaciones-acuerdo/ver-bonificaciones-acuerdo.component';
import { VerBnfDesfazadasComponent } from './components/ver-bnf-desfazadas/ver-bnf-desfazadas.component';
import { SearchBnfDesfazadasComponent } from './components/search-bnf-desfazadas/search-bnf-desfazadas.component';
import { AltaAcuerdoNuevoComponent } from './components/alta-acuerdo-nuevo/alta-acuerdo-nuevo.component';
import { AltaAcuerdoNuevo2Component } from './components/alta-acuerdo-nuevo2/alta-acuerdo-nuevo2.component';
import { LiquidacionesComponent } from './components/liquidaciones/liquidaciones.component';
import { SolicitudesComponent } from './components/solicitudes/solicitudes.component';
import { DetalleSolicitudesComponent } from './shared/components/detalle-solicitudes/detalle-solicitudes.component';
import { FacturaComponent } from './components/factura/factura.component'
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './classes/timeoutInterceptor';
import { NotaCreditoComponent } from './components/nota-credito/nota-credito.component';
import { VerBnfAcuerdoSellOutComponent } from './shared/components/ver-bnf-acuerdo-sell-out/ver-bnf-acuerdo-sell-out.component'
import { MatTooltipModule} from '@angular/material/tooltip';
//import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
//import { environment } from 'src/environments/environment';
//import { GoogleTagManagerModule } from 'angular-google-tag-manager';

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD-MM-YYYY'
  },
  display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SimuladorComponent,
    SearchTiendaComponent,
    LandingComponent,
    SearchProveedorComponent,
    SearchMarcaComponent,
    CountryFormatPipe,
    CountryDateFormatPipe,
    AlertDialogComponent,
    IsSelected,
    AcuerdoComponent,
    ShowDataComponent,
    CapitalizePipe,
    LargoMax,
    AprobarAcuerdoComponent,
    SearchAcuerdoComponent,
    VerAcuerdosComponent,
    EliminarAcuerdosComponent,
    CopiarAcuerdoComponent,
    SimuladorSellOutComponent,
    VerEstadosAcuerdoComponent,
    AcuerdoSellOutComponent,
    SearchBonificacionesComponent,
    VerBonificacionesComponent,
    VerBonificacionesAcuerdoComponent,
    VerBnfDesfazadasComponent,
    SearchBnfDesfazadasComponent,
    AltaAcuerdoNuevoComponent,
    AltaAcuerdoNuevo2Component,
    LiquidacionesComponent,
    SolicitudesComponent,
    DetalleSolicitudesComponent,
    FacturaComponent,
    NotaCreditoComponent,
    VerBnfAcuerdoSellOutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    AuthModule,
    MatListModule,
    ScrollingModule,
    MatRadioModule,
    NgxSpinnerModule,
    MatProgressBarModule,
    MatGridListModule,
    CurrencyPipe,
    MatTooltipModule,
    //NgxGoogleAnalyticsModule.forRoot(environment.production ? 'GTM-WJC7D95L': ''),
    //NgxGoogleAnalyticsRouterModule
    //GoogleTagManagerModule.forRoot({id: environment.production ? 'GTM-WJC7D95L': ''})
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-mx'},
    {provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true},
    {provide: DEFAULT_TIMEOUT, useValue: 360000},
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    //{provide: 'googleTagManagerId', useValue: environment.production ? 'GTM-WJC7D95L': ''},
    {provide: CountryFormatPipe}
  ],
  
  bootstrap: [AppComponent]
})

export class AppModule { }
