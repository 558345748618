export const dropDownRoutes = {
    'Organizacion':{
        url:'/organizaciones/agrupadorOrganizacion',
        params:[]
    },
    'TipoAcuerdo': {
        url: '/acuerdo/tipos',
        params: []
    },
    'SubTipoAcuerdo': {
        url: '/acuerdo/subtipos/',
        params: ['bloque','tipo']
    },
    'TipoLiquidacion':{
        url:'/acuerdo/tipos/liquidacion/',
        params: ['bloque']
    },
    'TipoAgrupador':{
        url:'/acuerdo/agrupador',
        params:[]
    },
    'TipoRecupero':{
        url:'/acuerdo/tipos/recupero',
        params:[]
    },
    'TipoEstado':{
        url:'/acuerdo/estados',
        params:[]
    },
    'Linea':{
        url:'/jerarquiasDesfasadas',
        params:[]
    },
    'SubLinea':{
        url:'/sublineaDesfasadas/',
        params:['child_linea']
    },
    'Monedas':{
        url:'/liquidacion/parametro/moneda',
        params:[]
    },
    'Parametros':{
        url:'/liquidacion/parametro/consulta',
        params:[]
    },
    'ClaseDoc': {
        url:'/liquidacion/parametro/tipodocumento',
        params:[]
    }, 
    'TipoImpuesto':{
        url:'/liquidacion/parametro/tipoimpuesto',
        params:[]
    },
    'TipoRetencion':{
        url:'/liquidacion/parametro/tiporetencion',
        params:[]
    },
    'TipoDenominacion':{
        url:'/liquidacion/parametro/tipodenominacion',
        params:[]
    }
    
}