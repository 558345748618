import { Component, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { environment } from 'src/environments/environment';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

interface EliminarAcuerdos extends Interfaces.ListadoAcuerdos {
  seleccion: boolean
}

@Component({
  selector: 'app-eliminar-acuerdos',
  templateUrl: './eliminar-acuerdos.component.html',
  styleUrls: ['./eliminar-acuerdos.component.scss']
})


export class EliminarAcuerdosComponent {
  private subscription!: Subscription;
  arraysAcuerdos: EliminarAcuerdos[]=[];
  dataSource: MatTableDataSource<EliminarAcuerdos>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public spinnerMessage:string = ''
  public ver: boolean=false;
  public acuerdo: Acuerdo;
  public acuerdoSellOut!: AcuerdoSellOut;
  public title!:string;
  private user_tech_key!:string;


  @ViewChild('paginator',{static:true}) paginator!: MatPaginator;

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['num','n_acuerdo', 'nomAcuerdo', 'tipoAcuerdo', 'agrupador', 'subtipo', 'vigDesde', 'vigHasta', 'proveedor', 'nomProveedor', 'tipoRecupero', 'bonEsperada', 'seleccion'];
    this.dataSource = new MatTableDataSource<EliminarAcuerdos>(this.arraysAcuerdos);
    this.acuerdo=new Acuerdo(rbtService,commonService,this.auth);
    this.auth.getUserTechKey().then(valor => this.user_tech_key = valor)
  }

  ngOnInit(){
    this.arraysAcuerdos=[];
    this.setTitle();
  }

  private setTitle() {
    this.subscription = this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  recive(arrraysAcuerdos: EliminarAcuerdos[]) {
    this.arraysAcuerdos=arrraysAcuerdos;
    this.dataSource = new MatTableDataSource<EliminarAcuerdos>(arrraysAcuerdos);
    this.seleccionados=false;
  }

  eliminarAcuerdos() {
    this.spinner.show('aprobarAcuerdo');
    this.spinnerMessage = "Realizando Eliminación..."
    let acuerdosxEliminar: EliminarAcuerdos[] = this.arraysAcuerdos.filter(acrd => acrd.seleccion)
    this.doGuardar(acuerdosxEliminar)
    .then(_ => this.commonService.alert('accent',"Acuerdos Eliminados",'Los acuerdos han sido eliminados con éxito ','Aceptar',true).subscribe({
      complete: () => {
        window.location.reload()
      },
      error: (err) => {alert('error')}
    }))
    .catch(reason => this.commonService.error("Error", reason, 'Aceptar', true))
    .finally(() => { this.spinner.hide('aprobarAcuerdo'); })

  }

  async doGuardar(listado: EliminarAcuerdos[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let acrdo: Interfaces.AprobarAcrdos[] = [];
      listado.forEach(ele => {
        acrdo.push({
          nacuerdo: ele.n_acuerdo,
          estado: 1,
          usuario: parseInt(this.user_tech_key)
        })
      })
      if (environment.debug) {
        console.log(JSON.stringify(acrdo))
      }
      this.postGuardado(acrdo)
        .then(data => resolve(data))
        .catch(reason => {
          if (reason instanceof HttpErrorResponse) reject(this.showErrors([new Error(reason.message)]))
          else reject(reason)
        })
      //resolve(true)
    }
    )
  }

  private postGuardado(acuerdo: Interfaces.AprobarAcrdos[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      //console.log(JSON.stringify(acuerdo))
      let respuesta: Interfaces.IResp;
      this.rbtService.callBackendPost<Interfaces.IResp>(`/eliminaAcuerdo/`, acuerdo).subscribe({
        next: (data) => respuesta = data,
        error: (err) => reject(err),
        complete: () => {
          if (respuesta.response.toUpperCase() =='ACUERDO ELIMINADO') {
            resolve(true)
          } else {
            reject(errors.ErrorEliminacionAcuerdo)
          }
        }
      })
    })
  }

  private showErrors(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }

  setAll(value: boolean) {
    if (this.arraysAcuerdos.length>0){
      this.seleccionados = value;
      this.arraysAcuerdos.forEach(ele => ele.seleccion = value)  
    }
  }

  someSelected(): boolean {
    return this.arraysAcuerdos.some(ele => ele.seleccion) && !this.arraysAcuerdos.every(ele => ele.seleccion) && this.arraysAcuerdos.length > 0;
  }

  everySelected(): boolean {
    return this.arraysAcuerdos.every(ele => ele.seleccion) && this.arraysAcuerdos.length > 0;
  }

  check(value: boolean) {
    let acuerdosSeleccionados: EliminarAcuerdos[] = this.arraysAcuerdos.filter(acrd => acrd.seleccion);
    if (acuerdosSeleccionados.length>0){
      this.seleccionados=true;
    }
    else{
      this.seleccionados=false;
    }
  }

  verAcuerdo(nacuerdo: number, tipoAcuerdo:number) {
    //aca se va a al backend y se va a crear un objeto simulador con los datos del acuerdo.
    this.spinner.show('aprobarAcuerdo');
    this.spinnerMessage = "Buscando datos de acuerdo..."
    this.cargarAcuerdo(nacuerdo, tipoAcuerdo)
      .then(() => {
        if (tipoAcuerdo==128){
          this.openDialogAbrirAcuerdoSellOut(this.acuerdoSellOut)
        }
        else{
          this.openDialogAbrirAcuerdoFijo(this.acuerdo)
        }
      })
      .catch(err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
        } else {
          if (err instanceof HttpErrorResponse && err.status === 400) {
            this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
          }
          else{
            this.commonService.error('Error', err.message, 'Aceptar', true)
          }
        }
      })
      .finally(
        ()=>{
          this.spinner.hide('aprobarAcuerdo'); 
        }
      )
  }

  async cargarAcuerdo(nacuerdo: number, tipoAcuerdo: number) {
    if (tipoAcuerdo==128){
      this.acuerdoSellOut=new AcuerdoSellOut(this.rbtService,this.commonService, this.auth)
      return new Promise<void>((res, rej) => {
        this.acuerdoSellOut.datosAcuerdoSellOut(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })      
    }
    else{
      this.acuerdo = new Acuerdo(this.rbtService, this.commonService, this.auth);
      return new Promise<void>((res, rej) => {
        this.acuerdo.datosAcuerdoFijo(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })  
    }
  }

  openDialogAbrirAcuerdoFijo(acuerdo: Acuerdo) {
    this.commonService.openDialog<Acuerdo, Acuerdo>(AcuerdoComponent, {
      organizacion: acuerdo.descr_org, parametroVer: true, addProd: false, btnCerrar: 'Cerrar', title: 'Acuerdo Datos'
    }, { data: acuerdo, height: '95%', disableClose: true })
  }

  openDialogAbrirAcuerdoSellOut(acuerdoSellOut: AcuerdoSellOut) {
    this.commonService.openDialog<AcuerdoSellOut, AcuerdoSellOut>(AcuerdoSellOutComponent, {
      organizacion: '', parametroVer: true, btnCerrar: 'Cerrar', title: 'Acuerdo Datos'
    }, { data: acuerdoSellOut, height: '95%', disableClose: true })
  }



  openDialogCrearAcuerdo() {
    this.commonService.openDialog<Acuerdo, Acuerdo>(AcuerdoComponent, {
      organizacion: '',parametroVer:true, btnCerrar:'Cerrar', title:'Acuerdo Datos'
    }, { data: this.acuerdo, height: '95%', disableClose: true })
  }
}

