import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { RebateBackendService } from './services/rebate/rebate.backend.service';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import m from 'src/app/config/menus'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'rtl-fal-corp-mrch-rebate-frontend';
  
  constructor(auth: AuthService, srv: RebateBackendService, private router: Router) {
    console.log(`Auth Service Initialized: Session Start at ${auth.getSessionStartTime()}`,)
    /*this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
   ).subscribe((e: RouterEvent) => {
     if (e instanceof NavigationEnd) {
      let gtmTag = {
        event: 'page', pageName: e.urlAfterRedirects
      }
      if (environment.production) {
        this.gtmService.pushTag(gtmTag);
      } else {
        console.log(JSON.stringify(gtmTag))
      }
     }
   });*/
  }

  getMenuTitle(path: string): string {
    return m.menus.reduce((prev,curr) => {
      if (path == "/") {
        prev = "Landing"
      } else {
        curr.options.forEach(opt => {
          if (("/"+opt.path) == path) {
            prev = opt.titulo
          }
        })
      }
      return prev  
    },"")
  }
  
}
