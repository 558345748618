import { Component, OnInit, Inject } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-ver-bnf-acuerdo-sell-out',
  templateUrl: './ver-bnf-acuerdo-sell-out.component.html',
  styleUrls: ['./ver-bnf-acuerdo-sell-out.component.scss']
})
export class VerBnfAcuerdoSellOutComponent {
  public spinnerMessage: string = "Loading ..."
  public title: string = 'Consulta Detalle Bonificación ';
  public country!: string;
  public verBonificaciones: Interfaces.ConsultaBnfSellOutAcuerdo[] = [{ 
    secuencia: 0,
    n_acuerdo: 0,
    prd_lvl_number: '',
    prd_name_full: '',
    d_vigdesde:'',
    d_vighasta: '',
    fechaactual: '',
    n_prctaje: 0,
    a_unidad: 0,
    organizacion: '',
    bonificaciontotal: 0,
    unidadesbonificadas: 0
  }];
  public readonly cabeceraTabla: string[] = ['num', 'acuerdo', 'sku', 'descrSku', 'fdesde', 'fhasta', 'aporte', 'topeUni', 'organizaciones', 'bnfTotal', 'UnidadesBnf'];
  public datosAcuerdo:string='';

  constructor(
    public dialogRef: MatDialogRef<VerBnfAcuerdoSellOutComponent>,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public dataAcuerdo: Interfaces.verBonificaciones,

  ) {
    this.auth.getCountry().then(value => this.country = value)
    this.title=  this.title + dataAcuerdo.n_acuerdo + ' ' + dataAcuerdo.descrAcuerdo;

  }

  ngOnInit(): void {
    this.buscarHistorial(this.dataAcuerdo.n_acuerdo)
  }

  buscarHistorial(nAcuerdo:number){
    this.spinner.show('verBonificaciones')
    this.srv.callBackendGet<Interfaces.ConsultaBnfSellOutAcuerdo[]>(`/detalleBonificacionsellout/${nAcuerdo}`).subscribe({
      next: listaBonificaciones =>{
        this.verBonificaciones=listaBonificaciones
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.BonificacionesAcuerdoNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
          this.commons.error('Error', errors.BonificacionesAcuerdoNoEncontradas.message, 'Aceptar', true) :
          this.commons.error(errors.BonificacionesAcuerdoNoEncontradas.message, err.message, 'Aceptar', true);
        }
        this.verBonificaciones=[];
        this.spinner.hide('verBonificaciones')
      },
      complete: ()=>{
        this.spinner.hide('verBonificaciones')
      }
    }
    )
  }

  exportExcel() {
    let rows: Object[] = [];

    this.verBonificaciones.forEach((element,idx) => {
      rows.push({
        '#': idx +1,
        "Acuerdo": element.n_acuerdo,
        "Jerarquía/Sku": element.prd_lvl_number,
        "Descr.Jer/Sku": element.prd_name_full,
        "Fecha Desde": element.d_vigdesde,
        "Fecha Hasta": element.d_vighasta,
        "Aporte $": element.n_prctaje,
        "Tope Unidades": element.a_unidad,
        "Organizaciones": element.organizacion,
        "Bonificación Total $": element.bonificaciontotal,
        "Unidades Bonificadas": element.unidadesbonificadas
      })
    });
    CommonsModule.exportExcel(
      "Consulta Detalle Bonificación " + this.dataAcuerdo.n_acuerdo
      , rows);
  }
}

