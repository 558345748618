<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'verBonificaciones'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<table class="thclose">
  <tr>
      <th class="left">
        <mat-card-title>{{title}}</mat-card-title>
      </th>
      <th class="header-right">
          <button mat-raised-button color="warn" class="pad" [mat-dialog-close]="null">X</button>
      </th>
  </tr>
</table>
<div class="matdialog">
  <img src='./assets/images/excel-icon.svg' alt="Exportar" class="imgExcel" (click)="exportExcel()" />
  <br>
  <table mat-table [dataSource]="verBonificaciones" aria-describedby="Historial de Estados Acuerdo" class="tabla">
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef class="background right">#</th>
      <td mat-cell *matCellDef="let i=index" class="right">{{i+1}}</td>
    </ng-container>
    <ng-container matColumnDef="acuerdo">
      <th mat-header-cell *matHeaderCellDef class="background">Acuerdo</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.nacuerdo}} </td>
    </ng-container>
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef class="background">Jerarquía/Sku</th>
      <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
    </ng-container>
    <ng-container matColumnDef="descrSku">
      <th mat-header-cell *matHeaderCellDef class="background">Descr.Jer/Sku</th>
      <td mat-cell *matCellDef="let element"> {{element.name_full}} </td>
    </ng-container>
    <ng-container matColumnDef="aporte">
      <th mat-header-cell *matHeaderCellDef class="background right">Aporte $</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.aporte | countryFormat:
        country: '0' : true}} </td>
    </ng-container>
    <ng-container matColumnDef="topeMnt">
      <th mat-header-cell *matHeaderCellDef class="background right">Tope Monto $</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.topemonto | countryFormat:
        country: '0' : true}} </td>
    </ng-container>
    <ng-container matColumnDef="topeUni">
      <th mat-header-cell *matHeaderCellDef class="background right">Tope Unidades</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.topeunidades | countryFormat:
        country: '0' : true}} </td>
    </ng-container>
    <ng-container matColumnDef="bnfTotal">
      <th mat-header-cell *matHeaderCellDef class="background right">Bonificación Total $</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.bonificacionTotal | countryFormat:
        country: '0' : true}} </td>
    </ng-container>
    <ng-container matColumnDef="UnidadesBnf">
      <th mat-header-cell *matHeaderCellDef class="background right">Unidades Bonificadas</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.unidadesBonificadas | countryFormat:
        country: '0' : true}} </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
    <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>
  </table>
  <br>
  <br>
</div>