import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { Proveedor } from 'src/app/classes/proveedor';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { elementAt } from 'rxjs';
import { SearchProveedorComponent } from '../simulador/search-proveedor/search-proveedor.component';


interface AprobarAcuerdos extends Interfaces.ListadoAcuerdos {
  seleccion: boolean,
  diff: boolean
}

@Component({
  selector: 'app-search-acuerdo',
  templateUrl: './search-acuerdo.component.html',
  styleUrls: ['./search-acuerdo.component.scss']
})
export class SearchAcuerdoComponent {
  public spinnerMessage: string = "Loading ..."
  public nAcuerdo: string = ''
  public tpAcuerdo: string = ''
  public subTpAcuerdo: string = ''
  public agrAcuerdo: string = ''
  public tpRecupero: string = ''
  public fechaDesde!: Date;
  public fechaHasta!: Date;
  public confirmado: boolean = false;
  public estado: string = '';

  public tipoAcuerdo: Interfaces.TiposAcuerdoOptions[] = [{ c_tpacuerdo: '', a_tpacuerdo: '', a_prfjacuerdo: '' }];
  public subtipoAcuerdo: Interfaces.SubTiposOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public tipoRecupero: Interfaces.TipoRecuperoOptions[] = [{ c_tprecupero: '', a_tprecupero: '' }];
  public tipoAgrupador: Interfaces.AgrupadorOptions[] = [{ c_agrupador: '', a_agrupador: '', c_tpagrupador: '' }];
  public tipoEstados: Interfaces.EstadosOptions[] = [{ c_parametro: '', a_valor: '' }];
  public proveedor: Proveedor;
  public rutFullProv: string = '';
  @Output() listaAcuerdos: EventEmitter<AprobarAcuerdos[]> = new EventEmitter();
  @Input() showDates!: boolean;
  @Input() estadoSearch!: string;
  public arrayAcuerdos: Interfaces.ListadoAcuerdos[] = [];

  constructor(private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,) {
    this.proveedor = new Proveedor(srv) //ver con pancho que puedo hacer para no estar creando nuevamente una variable de
    //tipo proveedor
  }

  ngOnInit(): void {
    this.iniciar();

  }

  iniciar() {
    let largo: number = 0;
    this.nAcuerdo = '';
    this.tpAcuerdo = '';
    this.subTpAcuerdo = '';
    this.agrAcuerdo = '';
    this.tpRecupero = '';
    this.rutFullProv = '';
    this.proveedor = new Proveedor(this.srv);
    this.fechaDesde = (null as unknown as Date);
    this.fechaHasta = (null as unknown as Date);
    this.loading()
      .then(() => {
        this.cargaSubTipos().then(() => {
          this.tipoAcuerdo.unshift({ c_tpacuerdo: '0', a_tpacuerdo: '-- Todos --', a_prfjacuerdo: '' })
          this.tpAcuerdo = this.tipoAcuerdo[0].c_tpacuerdo;
          this.subtipoAcuerdo.unshift({ c_parametro: '0', a_valor: '-- Todos --', c_tpacuerdo: '' });
          this.subTpAcuerdo = this.subtipoAcuerdo[largo].c_parametro;
          this.tipoAgrupador.unshift({ c_agrupador: '0', a_agrupador: '-- Todos --', c_tpagrupador: '' });
          this.agrAcuerdo = this.tipoAgrupador[0].c_agrupador;
          this.tipoRecupero.unshift({ c_tprecupero: '0', a_tprecupero: '-- Todos --' })
          this.tpRecupero = this.tipoRecupero[0].c_tprecupero;
          this.tipoEstados.unshift({ c_parametro: '0', a_valor: '-- Todos --' })
          if (this.estadoSearch.length > 0) {
            if (this.estadoSearch === '3') {
              this.estado = this.armarEstadosEliminar()
            }
            else {
              this.estado = this.estadoSearch;
            }
          }
          else {
            this.estado = this.tipoEstados[0].c_parametro;
          }
        });
      });
    let lista: AprobarAcuerdos[] = [];
    this.listaAcuerdos.emit(lista)
  }

  buscarAcuerdos() {
    this.spinnerMessage = 'Buscando Acuerdos ...'
    this.spinner.show('searchAcuerdoSpinner')
    let body: string = CommonsModule.stringToNumber(this.nAcuerdo.toString()) +
      '/' + this.estado +
      '/' + CommonsModule.stringToNumber(this.proveedor.nrut.toString()) +
      '/' + CommonsModule.stringToNumber(this.agrAcuerdo.toString()) +
      '/' + CommonsModule.stringToNumber(this.tpAcuerdo.toString()) +
      '/' + CommonsModule.stringToNumber(this.subTpAcuerdo.toString()) +
      '/' + CommonsModule.stringToNumber(this.tpRecupero.toString());

    if (this.fechaDesde == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      if (this.estado=="0"){
        this.commons.error('Atención',"Debe ingresar estado.", 'Aceptar', true);
        this.spinner.hide('searchAcuerdoSpinner')
        return
      }
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaDesde);
    }
    if (this.fechaHasta == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      if (this.estado=="0"){
        this.commons.error('Atención',"Debe ingresar estado.", 'Aceptar', true);
        this.spinner.hide('searchAcuerdoSpinner')
        return
      }
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaHasta);
    }


    this.srv.callBackendGet<AprobarAcuerdos[]>(`/acuerdo/buscaAcuerdos/${body}`).subscribe({
      next: lista => {
        lista.forEach(acrd => {
          //no se coloca el tema de los 2 decimales pues el dato viene desde la query redondeado a 0 decimales.
          acrd.seleccion = false;
        })
        this.listaAcuerdos.emit(lista)
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.AcuerdosNoEncontrados.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
            this.commons.error('Error', errors.AcuerdosNoEncontrados.message, 'Aceptar', true) :
            this.commons.error('Error', err, 'Aceptar', true);
        }
        this.listaAcuerdos.emit([]);
        this.spinner.hide('searchAcuerdoSpinner')
      },
      complete: () => {
        this.spinner.hide('searchAcuerdoSpinner')
      }
    }
    )
  }

  async loading() {
    this.spinner.show('searchAcuerdoSpinner');
    this.spinnerMessage = "Cargando datos...";
    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TiposAcuerdoOptions>(DropDownOptions.TipoAcuerdo)
        .then((data) => {
          this.tipoAcuerdo = data;
          this.tpAcuerdo = this.tipoAcuerdo[0].c_tpacuerdo;
          res();
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let p3 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.EstadosOptions>(DropDownOptions.tipoEstado)
        .then((data) => {
          this.tipoEstados = data;
          res();
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })


    let p4 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.AgrupadorOptions>(DropDownOptions.TipoAgrupador)
        .then((data) => {
          this.tipoAgrupador = data;
          res();
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let p5 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoRecuperoOptions>(DropDownOptions.tipoRecupero)
        .then((data) => {
          this.tipoRecupero = data;
          res()
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let results = await Promise.allSettled([p1, p4, p5])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('searchAcuerdoSpinner')
  }

  async cargaSubTipos() {
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.SubTiposOptions>(DropDownOptions.SubTipoAcuerdo, ['1', this.tpAcuerdo.toString()])
        .then((data) => {
          this.subtipoAcuerdo = data;
          res();
        })
        .catch(err => rej('Error Subtipos: ' + err))
    })

    let results1 = await Promise.allSettled([p2])
    let errorMsg = results1.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
  }

  buscarProveedor(event: any) {
    this.spinner.show('searchAcuerdoSpinner');
    this.spinnerMessage = "Verificando Proveedor";
    this.proveedor.setProveedor(event.target.value)
      .then(() => this.rutFullProv = this.proveedor.proveedorFullRut)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          this.commonService.error(errors.ErrorBuscarProveedor.message, (err.status === 404) ? errors.ProveedorNoExiste.message : err.message, 'Aceptar', true)
        } else {
          this.commonService.error(errors.ErrorBuscarProveedor.message, err, 'Aceptar', true)
        }
      })
      .finally(() => this.spinner.hide('searchAcuerdoSpinner'))
  }

  Limpiar() {
    this.iniciar();
  }

  //1,2,4,5
  armarEstadosEliminar(): string {
    let estados=this.tipoEstados.filter(ele=> ele.c_parametro!='3' && ele.c_parametro!='6')
    return estados.reduce((prv, curr) => {
      prv += (prv=='') ? curr.c_parametro : `,${curr.c_parametro}`
      return prv
    }, "")
  }

  openDialogProv() {
    this.proveedor.setValues('','','','')
    this.rutFullProv=''
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { 
          if (result != null){ 
            var provs :Proveedor[]=[result]
            for (let prov of provs){
              this.proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
              this.rutFullProv = this.proveedor.proveedorFullRut
            } 
          }
        }
      }
    )
  }
}
