<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'notaCredito'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="item left-div">
                    <button mat-raised-button [color]="'primary'" class="boton" (click)="fileUpload.click()" matTooltip="Fechas en archivo deben venir en formato DD-MM-YYYY">Cargar
                        Excel</button>
                    <input type="file" [hidden]="true" class="file-input" (change)="uploadExcel($event)"
                        (click)="fileUpload.value=''" [accept]="'.xlsx,.xls'" #fileUpload>
                </div>
                <div class="item left-div">
                    <mat-checkbox [labelPosition]="'after'" [(ngModel)]="checkDoc"
                        (change)="checkDto()">Documento</mat-checkbox>
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
            </div>
            <div class="flex-div">
                <div class="item left-div" *ngIf="paramMoneda">
                    <div class="item left-div">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Moneda</mat-label>
                            <mat-select [(value)]="moneda">
                                <mat-option [value]="item.cod"
                                    *ngFor="let item of monedas, let i=index">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="item left-div">
                        <mat-form-field appearance="outline" [floatLabel]="'always'">
                            <mat-label>Valor moneda</mat-label>
                            <input matInput type="text" [ngModel]="Numero(valorMoneda) | countryFormat: country :'' : false"
                                (ngModelChange)="valorMoneda = $event" (ngModelChange)="validaMonto($event)"
                                inputmode="numeric" style="text-align: right;" class="texto">
                        </mat-form-field>
                    </div>    
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Acción</mat-label>
                        <input matInput type="text" [(ngModel)]="accion" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Documento</mat-label>
                        <input matInput type="text" [(ngModel)]="documento" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="item left-div" *ngIf="paramRetencion">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Clase Doc</mat-label>
                        <mat-select [(value)]="claseDoc">
                            <mat-option [value]="item.tipoDoc"
                                *ngFor="let item of claseDocs, let i=index">{{item.descr}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-div" *ngIf="paramRetencion">
                <div class="item left-div">
                    <mat-checkbox [labelPosition]="'before'" [(ngModel)]="impuesto">Impuesto</mat-checkbox>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" *ngIf="impuesto && paramImpuesto">
                        <mat-label>Tipo Impuesto</mat-label>
                        <mat-select [(value)]="tipoImpuesto">
                            <mat-option [value]="item.valor"
                                *ngFor="let item of tiposImpuestos, let i=index">{{item.piva}}%</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" *ngIf="impuesto">
                        <mat-label>Tipo Retención</mat-label>
                        <mat-select [(value)]="tipoRetencion">
                            <mat-option [value]="item.ctipo_ret"
                                *ngFor="let item of tipoRetenciones, let i=index" (click)="cargarDenominaciones()">{{item.xdesc_tipo}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" *ngIf="impuesto">
                        <mat-label>Tipo denominación</mat-label>
                        <mat-select [(value)]="tipoDenominacion">
                            <mat-option [value]="item.cindicador_ret"
                                *ngFor="let item of tipoDenominaciones, let i=index">{{item.xdenominacion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div" *ngIf="impuesto">
                    <button mat-raised-button (click)="addDenominacion()" color="primary" class="pad">Añadir</button>
                    <button mat-raised-button (click)="showDataTipoDenominaciones()" class="pad">Ver ({{denominacionesSeleccionadas.length}})</button>
                </div>
            </div>
            <div class="flex-div">
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
                <div class="item left-div">
                    <button mat-raised-button color="primary" class="pad" (click)="guardarNc()">Enviar</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="btns">
            <th mat-header-cell *matHeaderCellDef class="center background letraSize">&nbsp;</th>
            <td mat-cell *matCellDef="let element; let i=index" class="center letraSize nowrap">
                <button color="primary" class="padbtns" *ngIf="element.btnAdd1"
                    (click)="agregarSeq(element, i)">+</button>
                <button color="primary" class="padbtns" *ngIf="element.btnDel1" (click)="deleteApendice(i)">-</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">#</th>
            <td mat-cell *matCellDef="let element" class="letraSize" [ngClass]="{'naranjo': (element.rut=='')}">
                {{element.seq}}</td>
        </ng-container>
        <ng-container matColumnDef="rutAcuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize" [ngClass]="{'naranjo': (element.rut=='')}">
                {{element.fullRut}}
            </td>
        </ng-container>
        <ng-container matColumnDef="acuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="letraSize" [ngClass]="{'naranjo': (element.rut=='')}">
                {{element.acuerdo}} </td>
        </ng-container>
        <ng-container matColumnDef="montoAcuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="right letraSize" [ngClass]="{'naranjo': (element.rut=='')}">
                {{element.monto_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="cuentaAC">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cuenta AC</th>
            <td mat-cell *matCellDef="let element" class="letraSize" [ngClass]="{'naranjo': (element.rut=='')}">
                {{element.ceunta_contable}} </td>
        </ng-container>
        <ng-container matColumnDef="agrupador">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Agrupador</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.agrupador" size="4" *ngIf="element.addinput"
                    class="texto" [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.rutFactura" size="10" *ngIf="element.addinput"
                    class="texto" [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto</th>
            <td mat-cell *matCellDef="let element; let idx= index" class="nowrap letraSize">
                <input matInput [attr.id]="'montoFactura' + element.seq" type="text"
                [(ngModel)]="element.montoFactura" (keyup)="onKeyUp($event, 'montoFactura',idx)" (keydown)="keyDown($event)" 
                    inputmode="numeric" style="text-align: right;" *ngIf="element.addinput" class="texto"
                    [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <ng-container matColumnDef="folioRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Folio Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.folioRef" size="10" class="texto"
                    [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <ng-container matColumnDef="factRef" *ngIf="paramFactRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Factura Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.factRef" size="10" class="texto"
                    [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <ng-container matColumnDef="fechaRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap top letraSize">
                <input matInput type="text" placeholder="DD-MM-YYYY" [(ngModel)]="element.fechaRef" size="10"
                    class="texto" [ngClass]="{'naranjo': (element.rut=='')}">
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; let element; columns: columns;"></tr>
    </table>
    <br>
    <br>
    <table mat-table [dataSource]="dataSourceDocNc" aria-describedby="Datos Simulador" *ngIf="checkDoc">
        <ng-container matColumnDef="btns">
            <th mat-header-cell *matHeaderCellDef class="center background letraSize">&nbsp;</th>
            <td mat-cell *matCellDef="let element; let i=index" class="center letraSize nowrap">
                <button color="primary" class="padbtns" *ngIf="element.btnAdd1"
                    (click)="agregarDoc(element, i)">+</button>
                <button color="primary" class="padbtns" *ngIf="element.btnDel1" (click)="deleteDoc(i)">-</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">#</th>
            <td mat-cell *matCellDef="let i= index" class="letraSize">{{i + 1}}</td>
        </ng-container>
        <ng-container matColumnDef="nombreDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.nombre" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="ceco">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">CECO</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.ceco" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="agrDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Agrupador</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.agrupador" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="ctaCont">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cuenta Contable</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.ctaCont" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="rutProv">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut Proveedor</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.rutProv" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="montoDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto</th>
            <td mat-cell *matCellDef="let element; let idx= index" class="nowrap letraSize">
                <input matInput [attr.id]="'montoFactura' + element.seq" type="text"
                    [(ngModel)]="element.monto" size="15" (keyup)="onKeyUp($event, 'monto',idx)" (keydown)="keyDown($event)"
                    style="text-align: right;" class="texto" [size]="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="codOrden">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Código Orden</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.codOrden" size="15">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsDoc"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsDoc;"></tr>
    </table>
</div>
<br>
<br>
<br>