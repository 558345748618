import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from '../services/rebate/rebate.backend.service';

interface Jerarquia {
  Nivel: string;
  Jerarquia: string;
  NombreJerarquia: string;
}

interface Precio {
  Tienda: string;
  Tipo: string;
  Precio: number;
  Finicio: string;
  Ftermino: string;
  Stock: number;
}

interface DatosExtras {
  Linea: string;
  SubLinea: string;
  Clase: string;
  SubClase: string;
  Temporada: string;
  Marca: string;
}

interface Producto {
  Sku: string;
  SkuName: string;
  Dcreacion: string;
  PrecioNormal: number;
  PrecioOriginal: number;
  Status: string;
  Temporada: string;
  Marca: string;
  Jerarquia: Jerarquia[];
  Precios: Precio[];
}

@Component({
  selector: 'app-srx-precios-vigentes',
  templateUrl: './srx-precios-vigentes.component.html',
  styleUrls: ['./srx-precios-vigentes.component.scss']
})

export class SrxPreciosVigentesComponent {

  public readonly cabeceraTabla: string[] = ['tienda', 'tipo', 'vigente', 'finicio', 'ftermino', 'stock'];

  public readonly cabeceraTablaExtras: string[] = ['clase', 'subclase','linea', 'sublinea', 'temporada', 'marca'];
  numeroProducto: string = '';
  descripcionProducto: string = '';
  precioNormal: number = 0;
  precioOriginal: number = 0;

  verSoloOfertas: boolean = false;
  soloProductosConStock: boolean = false;
  precios: Precio[] = [];
  preciosFiltrados: Precio[] = [];

  datosExtras: DatosExtras[] = [];

  datosExtrasInterno: DatosExtras[] = [];

  datosExtrasVisibles: boolean = false;

  public spinnerMessage: string = 'Loading...';

  constructor(private spinner: NgxSpinnerService, 
      private commonService: CommonsModule,
    private backend: RebateBackendService) {
    
  }

  filtrarDatos() {
    this.datosExtras = this.datosExtrasInterno
  }

  filtrarPrecios() {
    this.preciosFiltrados = this.precios.filter(p => 
      (!this.verSoloOfertas || p.Tipo === 'Oferta') &&
      (!this.soloProductosConStock || p.Stock > 0)
    );
  }

  busquedaPrecios() {
    this.spinner.show('precioVigente')
    this.spinnerMessage = "Buscando Datos..."
    this.precios = []
    this.datosExtrasInterno = []
    this.backend.callBackendGet<Producto>(`/srx/precios/vigentes/${this.numeroProducto}`).subscribe({
      next: (data) => {
        //alert(JSON.stringify(data))
        this.numeroProducto = data.Sku
        this.descripcionProducto = data.SkuName
        this.precioNormal = data.PrecioNormal
        this.precioOriginal = data.PrecioOriginal
        for (let index = 0; index < data.Precios.length; index++) {
          let prc = data.Precios[index];
          this.precios.push(
            { 
              Tienda: prc.Tienda, 
              Tipo: prc.Tipo, 
              Precio: prc.Precio, 
              Finicio: prc.Finicio, 
              Ftermino: prc.Ftermino, 
              Stock: prc.Stock 
            }
          )
        }

        let clase = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == 'CLASE')
        })?.Jerarquia
        let claseName = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "CLASE")
        })?.NombreJerarquia

        let sclase = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "SUBCLASE")
        })?.Jerarquia
        let sclaseName = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "SUBCLASE")
        })?.NombreJerarquia

        let linea = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "LINEA")
        })?.Jerarquia
        let lineaName = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "LINEA")
        })?.NombreJerarquia

        let slinea = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "SUBLINEA")
        })?.Jerarquia
        let slineaName = data.Jerarquia.find((jer) => { 
          return (jer.Nivel == "SUBLINEA")
        })?.NombreJerarquia

        this.datosExtrasInterno.push(
          {
            Linea: `${clase} - ${claseName}`,
            SubLinea: `${sclase} - ${sclaseName}`,
            Clase:`${linea} - ${lineaName}`,
            SubClase:`${slinea} - ${slineaName}`,
            Temporada:data.Temporada,
            Marca:data.Marca
          }
        )
        this.filtrarDatos();
        this.filtrarPrecios();
        this.datosExtrasVisibles = true
      },
      error: (err) => {
        if (err.status == 404) {
          this.commonService.error("Error",`Producto no encontrado`,"Cerrar")
        } else {
          this.commonService.error("Error",`Status: ${err.status}`,"Cerrar")
        }
        this.spinner.hide('precioVigente')
      },
      complete: () => {
        this.spinner.hide('precioVigente')
      }
    })
  }
}