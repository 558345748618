import * as Interfaces from 'src/app/interfaces/ISimulador'
import { RebateBackendService } from '../services/rebate/rebate.backend.service';
import { Proveedor } from './proveedor';
import { errors } from 'src/app/constants/constants.errors';
import { CommonsModule } from 'src/app/services/commons/commons.service'
import { HttpErrorResponse } from '@angular/common/http';
import { SearchType } from '../components/simulador/search-tienda/search-tienda.component';
import { Observable, ReplaySubject, elementAt } from 'rxjs';
import { environment } from 'src/environments/environment';
export class SimuladorSellOut {
    private _proveedor: Proveedor;
    public isJerarquia: boolean = false;
    public danadoVendible: boolean = false;
    public jerarquia: string = "";
    public aporteTotal: number = 0;
    public marcas: Interfaces.Marca[] = [];
    public pbc: Proveedor[] = [];
    public dv: boolean = false;
    public tabla: Interfaces.FilaTablaSimuladorSellOut[] = []
    public totalSimulador: number = 0;
    public totalIngresado: number = 0;
    public arrayExcel: Interfaces.TiendasOrgExcel[] = [];
    public country: string = ""

    constructor(private backend: RebateBackendService, private commons: CommonsModule) {
        this._proveedor = new Proveedor(backend)
    }

    updateTable() {
        this.tabla = [...this.tabla]
    }


    get proveedor() { return this._proveedor }

    set proveedor(prov : Proveedor){
        this._proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
    }

    calculaTotal() {
        this.totalSimulador = 0;
        this.tabla.filter(ele => ele.display).forEach(sku => {
            if (sku.aporte > 0) { this.totalSimulador += sku.aporte_total }
        }
        )
    }

    calculoTotalIngresado() {
        this.totalIngresado = 0;
        this.tabla.filter(ele => ele.display).forEach(sku =>
            this.totalIngresado += sku.aporte_total
        )
    }

    cleanTabla() {
        this.tabla = []
        this.isJerarquia = false;
        this.pbc = []
        this.marcas = []
        this.totalSimulador = 0
        this.totalIngresado = 0
    }

    private validaFilas(datos: Interfaces.AddProductStructSellOut[]): Error[] {
        let errs: Error[] = []
        datos.forEach((ele, idx) => {
            if (ele.aporte <= 0) {
                errs.push(new Error(`Fila ${idx + 2}: ` + errors.MissingAporte))
            }
            else{
                if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                        if (ele.aporte % 1 != 0) {
                        errs.push(new Error(`Fila ${idx + 2}: ` + errors.AporteDecimal))
                    }
                }
            }
            if (ele.fecDesde == null) {
                errs.push(new Error(`Fila ${idx + 2}: ` + errors.FechaDesdeError))
            }
            else {
                if (ele.fecDesde == new Date(1900, 1, 1)) {
                    errs.push(new Error(`Fila ${idx + 2}: ` + errors.FechaDesdeError))
                }
            }
            if (ele.fecHasta == null) {
                errs.push(new Error(`Fila ${idx + 2}: ` + errors.FechaHastaError))
            }
            let tiendas: string = ''
            tiendas = ele.tiendaLocal.reduce((prv, curr) => {
                prv += (prv == "") ? curr.value : `; ${curr.value}`
                return prv
            }, "")
            if (tiendas == '') {
                errs.push(new Error(`Fila ${idx + 2}: ${errors.MissingLocalesSellOutExcel}`))
            }
        }
        )
        return errs
    }


    private async addProductPromise(ele: Interfaces.AddProductStructSellOut[]): Promise<Interfaces.AddProductStructSellOut[]> {
        let errs: Error[] = []
        let isJerar = false;
        let isSkuJerar = false
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...ele.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';

        let p1 = new Promise<void>((res, rej) => {
            let locMass: Interfaces.addLocalesMass[] = [];

            ele.forEach((p, i) => {
                if (p.organizacion.c_parametro == '0') {
                    let tiendas: string = ''
                    tiendas = p.tiendaLocal.reduce((prv, curr) => {
                        prv += (prv == "") ? curr.value : `; ${curr.value}`
                        return prv
                    }, "")
                    
                    locMass.push({ seq: i.toString(), locales: tiendas })
                }
            })
            if (locMass.length > 0) {
                this.buscaLocalesMass(locMass)
                    .then(data => {
                        
                        data.forEach((locales, idx) => {
                            let valor: string = locales.secuencia;
                            valor = valor.replaceAll(".", "@")
                            valor = valor.replaceAll(",", ".")
                            valor = valor.replaceAll("@", "")
                            let seq: number = CommonsModule.stringToNumber(valor)

                            if (locales.status == 'error') {
                                errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongLocalSellOut));
                            }
                            else {
                                let child: string = locales.child;
                                let orgDescr: string = locales.desc_org;
                                let orgCod: string = locales.tipo_org;
                                let local: string[] = child.split(',')
                                ele[seq].tiendaLocal=[]
                                local.forEach(loc => {
                                    let varLoc = loc.split(';');
                                    let tienda: Interfaces.Tiendas = { value: varLoc[0], name: varLoc[1] }
                                    
                                    ele[seq].tiendaLocal.push(tienda)
                                })
                                ele[seq].organizacion.c_parametro = orgCod
                                ele[seq].organizacion.a_valor = orgDescr
                            }
                        })
                        if (errs.length > 0) {
                            rej(this.addProductError(errs))
                        }
                        else {
                            console.log(JSON.stringify(ele))
                            res()
                        }
                    })
                    .catch(reason => {
                        errs = [];
                        errs.push(new Error(`${(reason as Error)}`));
                        rej(this.addProductError(errs))
                    })
            }
            else {
                res();
            }
        })

        let p2 = new Promise<void>((res, rej) => {
            let pro: Interfaces.AddProductMass[] = [];
            ele.forEach((p, i) => {
                if (p.name_full == '') {
                    pro.push({ sku: p.sku, seq: i.toString() })
                }

                isSkuJerar = p.sku.substring(0, 1) == 'J'
                if (isJerar!=isSkuJerar){
                    if (isJerar){ //no puede ingresar un producto con J en la tabla
                        errs.push(new Error(`Fila ${i + 2}: ` + errors.ProductosSinjerar))
                    }
                    if (isSkuJerar){
                        errs.push(new Error(`Fila ${i + 2}: ` + errors.JerarSinProductos))
                    }
                }


            })

            if (errs.length == 0) {
            this.buscaProductoMass(pro)
                .then(data => {
                    data.forEach((dataprod, idx) => {
                        if (dataprod.status == 'error') {
                            errs.push(new Error(`Fila ${idx + 2}: ` + errors.WrongJerarquiaOrSku));
                        }
                        else {
                            let valor: string = dataprod.secuencia;
                            valor = valor.replaceAll(".", "@")
                            valor = valor.replaceAll(",", ".")
                            valor = valor.replaceAll("@", "")
                            let seq: number = CommonsModule.stringToNumber(valor)  //numero de fila 
                            ele[seq].name_full = dataprod.name_full
                            ele[seq].prd_lvl_child = dataprod.prd_lvl_child
                        }
                    })
                    if (errs.length > 0) {
                        rej(this.addProductError(errs))
                    }
                    else {
                        res()
                    }
                })
                .catch(reason => {
                    errs = [];
                    errs.push(new Error(`${(reason as Error)}`));
                    rej(this.addProductError(errs))
                })
            }
            else {
                rej(this.addProductError(errs))
            }
        })

        let results = await Promise.allSettled([p1, p2])
        let errorMsg = results.reduce((prv, curr) => {
            if (curr.status == 'rejected') {
                return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
            } else {
                return prv
            }
        }, "")
        if (errorMsg.length>0){
            errs.push(new Error(errorMsg))
        }

        return new Promise<Interfaces.AddProductStructSellOut[]>((resolve, reject) => {
            if (errs.length > 0) {
                reject(this.addProductError(errs))
            }
            else {
                resolve(ele)
            }
        })
    }

    async addProducts(data: Interfaces.AddProductStructSellOut[]): Promise<boolean> {
        //let promises: Promise<Interfaces.AddProductStructSellOut[]>[] = []

        let errs: Error[] = [];
        errs = this.validaFilas(data)

        return new Promise<boolean>((resolve, reject) => {
            if (errs.length > 0) {
                reject(this.addProductError(errs))
            }
            else {
                this.addProductPromise(data)
                    .then(datos => {
                        this.addProductOnComplete(datos)
                        resolve(true)
                    }, reason => {
                        //errs.push(reason)
                        reject(reason)
                        //reject(this.addProductError(errs))
                    })
            }
        })
    }

    private addProductError(errors: Error[]): Error {
        return errors.reduce((prv, curr) => {
            prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
            return prv
        }, new Error())
    }

    private addProductOnComplete(data: Interfaces.AddProductStructSellOut[]): Error | null {
        let isValid = true;
        let isJerar = false;
        const tmpData: string[] = [...this.tabla.map(e => e.jerarquia), ...data.map(e => e.sku)]
        isJerar = tmpData[0].substring(0, 1) == 'J';
        isValid = tmpData.every(sku => (sku.substring(0, 1) == 'J') == isJerar)
        if (isValid) {
            data.forEach(ele => {
                this.tabla.push({
                    jerarquia: ele.sku,
                    prd_lvl_child: ele.prd_lvl_child,
                    name_full: ele.name_full,
                    fecdesde: ele.fecDesde,
                    fechasta: ele.fecHasta,
                    unidad: '',
                    aporte: 0,
                    aporte_total: ele.aporte,
                    organizacion: {
                        c_parametro: ele.organizacion.c_parametro,
                        a_valor: this.armarDescrOrgFromLocales(ele.tiendaLocal, ele.organizacion, ele.seltiendas),
                        searchType: ele.organizacion.searchType,
                        path: '',
                        selected: false
                    },
                    descrOrg: ele.descrOrg,
                    tiendaLocal: ele.tiendaLocal,
                    display: true
                })
                //this.totalIngresado=this.totalIngresado+ele.aporte;
                this.calculoTotalIngresado();
                this.updateTable();
                if (!this.isJerarquia) this.isJerarquia = (ele.sku.substring(0, 1) == 'J')
            });
            return null
        } else {
            return (isJerar) ? errors.JerarSinProductos : errors.ProductosSinjerar;
        }
    }

    async doSimulacion(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let erroresValidacion: Error[] = this.validaPreSimulacion();
            if (erroresValidacion.length === 0) {
                let simulacion: Interfaces.SimulacionSellOut = {
                    cmarcas: this.armarMarcas(this.marcas),
                    cadena_pbc: this.armarPBC(),
                    rut: this.proveedor.nrut.toString(),
                    dvendible: (this.dv ? "1" : "0"),
                    tipojerarquia: (this.isJerarquia ? "J" : "0"),
                    arraySku: this.armarSku(this.tabla)
                }
                if (environment.debug) {
                    console.log(JSON.stringify(simulacion))
                }
                this.postSimulacion(simulacion)
                    .then(data => resolve(data))
                    .catch(reason => {
                        if (reason instanceof HttpErrorResponse) reject(this.addProductError([new Error(reason.message)]).message)
                        else reject(reason)
                    })
                    .finally(() => {
                        this.calculaTotal()
                    }
                    )
            } else {
                reject(this.addProductError(erroresValidacion).message)
            }
        })
    }

    private validaPreSimulacion(): Error[] {
        let erroresFormulario: Error[] = []
        if (this.armarSku(this.tabla).length > 150000) {
            erroresFormulario.push(errors.ProductosSuperanMaximo);
        }
        if (this.proveedor.nrut.length === 0) {
            erroresFormulario.push(errors.MissingProveedor);
        }
        if (this.tabla.length === 0) {
            erroresFormulario.push(errors.MissingJerarquiaOrSku);
        }

        if (this.isJerarquia) {
            if ((this.pbc.length === 0 || this.pbc[0].nrut.length === 0) && (this.marcas.length === 0 || this.marcas[0].cmarca.length === 0)) {
                erroresFormulario.push(errors.MissingPBCMarca);
            }
        }
        return erroresFormulario;
    }

    private postSimulacion(simulacion: Interfaces.SimulacionSellOut): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let respuesta: Interfaces.SimulacionRespuestaSellOut[] = [];

            

            this.backend.callBackendPost<Interfaces.SimulacionRespuestaSellOut[]>(`/simuladorSellOut`, [simulacion]).subscribe({
                next: (data) => {
                    respuesta = data

                },
                error: (err) => reject(err),
                complete: () => {
                    this.tabla = []
                    this.tabla = respuesta.map<Interfaces.FilaTablaSimuladorSellOut>((ele, idx) => {

                        let tien: string[] = ele.lista_child_org.split(",");
                        let descr_tien: string[] = ele.lista_org.split(",");
                        let tiendaLoc: Interfaces.Tiendas[] = [];
                        for (let x: number = 0; x < tien.length; x++) {
                            tiendaLoc.push({ value: tien[x], name: descr_tien[x] })
                        }

                        let org: Interfaces.OrganizacionOptions = {
                            a_valor: ele.lista_org,
                            c_parametro: ele.tipo_org.toString(),
                            searchType: (ele.lista_org.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL,
                            path: '',
                            selected: false
                        }
                        return {
                            aporte: ele.aporte,
                            aporte_total: ele.monto,
                            jerarquia: ele.prd_lvl_number,
                            prd_lvl_child: ele.prd_lvl_child,
                            name_full: ele.prd_name_full,
                            fecdesde: CommonsModule.stringToFecha(ele.fdesde),
                            fechasta: CommonsModule.stringToFecha(ele.fhasta),
                            organizacion: org,
                            descrOrg: ele.lista_org,
                            tiendaLocal: tiendaLoc,
                            unidad: ele.sls_units.toString(),
                            display: (ele.monto == 0 && idx == respuesta.length - 1) ? false : true
                        }
                    })
                    if (respuesta.length > 0) {
                        let indx: number = this.tabla.length - 1;
                        if (this.tabla[indx].aporte == 0) {
                            this.tabla.splice(indx);
                        }
                        this.updateTable();
                        resolve(true)
                    } else {
                        reject(errors.SimulacionSinData)
                    }
                }
            })
        })
    }

    armarFecha(fecha: string): string {
        let fechaFinal: string = '';
        fecha = fecha.replaceAll('/', '-');
        let fec = fecha.split("-");

        fechaFinal = fec[0] + '-' + fec[1].substring(0, 3) + '-' + fec[2]
        console.log(fechaFinal)
        return fechaFinal;
    }

    private armarSku(datos: Interfaces.FilaTablaSimuladorSellOut[]): string {
        return datos.reduce((prv, curr) => {
            let fec_desde: string = this.armarFecha(Intl.DateTimeFormat('es-ES', { day: '2-digit', month: 'short', year: 'numeric' }).format(curr.fecdesde).replaceAll(" ", "-"));

            let fec_hasta: string = this.armarFecha(Intl.DateTimeFormat('es-ES', { day: '2-digit', month: 'short', year: 'numeric' }).format(curr.fechasta).replaceAll(" ", "-"));
            if (curr.display) {
                prv += (prv == "") ?
                    `${curr.prd_lvl_child};${curr.aporte_total};;${fec_desde};${fec_hasta};${this.armarLocales(curr.tiendaLocal, curr.organizacion)};${curr.organizacion.c_parametro}`
                    : `,${curr.prd_lvl_child};${curr.aporte_total};;${fec_desde};${fec_hasta};${this.armarLocales(curr.tiendaLocal, curr.organizacion)};${curr.organizacion.c_parametro}`
            }
            return prv

        }, "")
    }

    private armarLocales(locales: Interfaces.Tiendas[], orga: Interfaces.OrganizacionOptions): string {
        if (locales.length > 0) {
            if (locales.length == 1) {
                if (orga.c_parametro == "7" && locales[0].value == "0") {
                    return "1"
                }
                if (orga.c_parametro == "8" && locales[0].value == "0") {
                    return "0"
                }
            }
            let localArmado: string= locales.reduce((prv, curr) => {
                prv += (prv == "") ? curr.value : `-${curr.value}`
                return prv
            }, "")


            return localArmado;
        }
        else {
            return "0"
        }
    }

    private armarDescrOrgFromLocales(locales: Interfaces.Tiendas[], orga: Interfaces.OrganizacionOptions, selTiendas: boolean): string {
        if (locales.length > 0) {
            if (selTiendas) {
                return locales.reduce((prv, curr) => {
                    prv += (prv == "") ? curr.name : `, ${curr.name}`
                    return prv
                }, "")
            }
            else {
                //return orga.a_valor
                return locales.reduce((prv, curr) => {
                    prv += (prv == "") ? curr.name : `, ${curr.name}`
                    return prv
                }, "")
            }
        }
        else {
            return "0"
        }
    }

    private armarMarcas(marcasSeleccionadas: Interfaces.Marca[]): string {
        if (marcasSeleccionadas.length == 0) return "0";
        return marcasSeleccionadas.reduce((prv, curr) => {
            prv += (prv == "") ? curr.cmarca : `;${curr.cmarca}`
            return prv
        }, "")
    }

    private armarPBC(): string {
        if (this.pbc.length == 0) return "0"
        return this.pbc.reduce((prv, curr) => {
            prv += (prv == "") ? curr.nrut : `,${curr.nrut}`
            return prv
        }, "")
    }

    buscaProductoMass(prods: Interfaces.AddProductMass[]): Promise<Interfaces.ProductInfo[]> {
        return new Promise<Interfaces.ProductInfo[]>((resolve, reject) => {
            this.commons.validateProducts(prods).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

    buscaLocalesMass(locales: Interfaces.addLocalesMass[]): Promise<Interfaces.ExcelBuscaLocales[]> {
        return new Promise<Interfaces.ExcelBuscaLocales[]>((resolve, reject) => {
            this.commons.validaLocales(locales).subscribe({
                next: (validData) => {
                    resolve(validData)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }
}