<ngx-spinner name="simulador" bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Proveedor</mat-label>
                        <input matInput type="text" [(ngModel)]="rutFullProv" (focusout)="buscarProveedor($event)"
                            placeholder="Rut Proveedor">
                        <button mat-icon-button matSuffix (click)="openDialogProv()" matTooltip="Buscar Proveedor">
                            <mat-icon matSuffix>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-label class="padprov"> {{dataSource.proveedor.vendor_name}}</mat-label>
                </div>
                <div class="rigth-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Organización</mat-label>
                        <mat-select [(value)]="dataSource.organizacion">
                            <mat-option [value]="item.c_parametro" (click)="setSearchType(item)"
                                *ngFor="let item of organizaciones">{{item.a_valor}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogTiendas()"
                        class="pad">{{searchButtonTitle}}
                        <mat-icon>list</mat-icon>
                    </button>
                    <button mat-raised-button (click)="showDataTiendas()" class="pad">Ver
                        ({{dataSource.tiendas.length}})</button>
                </div>
            </div>

            <div class="flex-div">
                <div class="left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Desde</mat-label>
                        <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="dataSource.fechaDesde" (dateChange)="validaFecha($event, 'desde')">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Hasta</mat-label>
                        <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="dataSource.fechaHasta" (dateChange)="validaFecha($event, 'hasta')">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                    <input type="file" [hidden]="true" class="file-input" (change)="uploadExcel($event)"
                        (click)="fileUpload.value=''" [accept]="'.xlsx,.xls'" #fileUpload>
                </div>
                <div class="flex-div" *ngIf="dataSource.isJerarquia">
                    <div class="rigth-div">
                        <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogProveedores()">PBC
                            <mat-icon>list</mat-icon>
                        </button>
                        <button mat-raised-button (click)="showDataPBC()" class="pad">Ver
                            ({{dataSource.pbc.length}})</button>
                    </div>
                    <div class="rigth-div">
                        <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogMarcas()"
                            class="pad">Marcas
                            <mat-icon>list</mat-icon>
                        </button>
                        <button mat-raised-button (click)="showDataMarcas()" class="pad">Ver
                            ({{dataSource.marcas.length}})</button>
                    </div>
                </div>
            </div>
            <div class="flex-div">
                <div class="left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Jerarquía/SKU</mat-label>
                        <input matInput type="text" [(ngModel)]="sku">
                        <button mat-icon-button matSuffix (click)="fileUpload.click()"
                            matTooltip="Fechas en archivo deben venir en formato DD-MM-YYYY">
                            <mat-icon matSuffix>attach_file</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-checkbox [labelPosition]="'after'" [(ngModel)]="dataSource.dv">Incluir Dañado
                        Vendible</mat-checkbox>
                </div>
                <div class="right-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Aporte Total</mat-label>
                        <input matInput type="text" [(ngModel)]="aporte" (keyup)="onKeyUp($event)"
                            (keydown)="keyDown($event)" style="text-align: right;">
                        <mat-icon matPrefix>attach_money</mat-icon>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Cod. Promociones</mat-label>
                        <input matInput type="text">
                    </mat-form-field>                     -->
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="left-div">
                <button mat-raised-button [color]="'primary'" class="boton" *ngIf="simular"
                    (click)="realizaSimulacion()">Simular</button>
                <button mat-raised-button [color]="'primary'" class="boton" *ngIf="makeAcuerdo"
                    (click)="openDialogCrearAcuerdo()">Crear Acuerdo</button>
            </div>
            <div class="right-div">
                <mat-label class="monto">Monto Total Ingresado: $ {{this.dataSource.totalIngresado | countryFormat:
                    country :'0' : false}}</mat-label>
                <mat-label class="monto">Monto Total Simulado: $ {{this.dataSource.totalSimulador | countryFormat:
                    country :'0' : false}}</mat-label>
                <button mat-raised-button [color]="'primary'" class="boton" (click)="addProduct()">Agregar</button>
                <button mat-raised-button [color]="'warn'" class="boton" (click)="cleanDataSource()">Limpiar
                    tabla</button>
            </div>
        </mat-card-actions>
    </mat-card>
    <br>
    <table mat-table [dataSource]="tablaSimulador" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="background">#</th>
            <td mat-cell *matCellDef="let i= index"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="jerarquia">
            <th mat-header-cell *matHeaderCellDef class="background">Jerarquía/SKU</th>
            <td mat-cell *matCellDef="let element"> {{element.jerarquia}} </td>
        </ng-container>
        <ng-container matColumnDef="unidad">
            <th mat-header-cell *matHeaderCellDef class="right background">Unidad</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.unidad}} </td>
        </ng-container>
        <ng-container matColumnDef="porcentaje">
            <th mat-header-cell *matHeaderCellDef class="right background">Porcentaje</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.porcentaje | countryFormat: country : '0' :
                false}} </td>
        </ng-container>
        <ng-container matColumnDef="aporte">
            <th mat-header-cell *matHeaderCellDef class="right background">Aporte</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.aporte.toFixed(2) | countryFormat: country: '0' :
                false}} </td>
        </ng-container>
        <ng-container matColumnDef="aporte_total">
            <th mat-header-cell *matHeaderCellDef class="right background">Aporte Total</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.aporte_total.toFixed(2) | countryFormat: country: '0' :
                false}} </td>
        </ng-container>
        <ng-container matColumnDef="cod_promocion">
            <th mat-header-cell *matHeaderCellDef class="background">Promoción</th>
            <td mat-cell *matCellDef="let element"> {{element.cod_promocion}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>-->
        <tr mat-row *matRowDef="let row; let element; columns: cabeceraTabla;"
            [ngClass]="{'naranjo': (element.aporte<=0 && simulado)}"></tr>
    </table>
    <br>
    <br>
</div>