import { Component, HostListener, Output } from '@angular/core';
import m from 'src/app/config/menus'
import { AuthService } from 'src/app/services/auth/auth.service'
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent {
  @Output() userName!: string
  @Output() country!: string
  @Output() user_tech_key!: string
  @Output() jwtToken!: string
  menus = m.menus
  backendStatus?: string;
  criticalErrorMessage?: string;
  

  @HostListener('window:keydown', [ '$event' ])
  onKeyDown(evt: KeyboardEvent) { 
    if (evt.ctrlKey && evt.altKey && (evt.key == 'i' || evt.key == 'I'))
    alert("Version: " + environment.version + "\n" + "User Tech Key: " + this.user_tech_key);
    if (evt.ctrlKey && evt.altKey && (evt.key == 't' || evt.key == 'T'))
    alert("JWT: " + this.jwtToken);
}
  

  constructor(private auth: AuthService, private spinner: NgxSpinnerService) {
    //this.buildMenu()
    this.getUserName()
    if (environment.sse) {
      this.spinner.show('offline')
    }
  }

  buildMenu(country: string): void {
    const roles =  this.auth.getRoles();
    this.menus.forEach(menu => {
      let remove: number[] = [];
      menu.options.forEach((option,index) => {
        if (
          !roles.some(rol => option.data.roles.includes(rol)) 
          || !menu.visible
          || !option.data.countries.includes(country.toUpperCase())) 
          remove.push(index)
      })
      remove.reverse().forEach(idx => menu.options.splice(idx,1))
    })
    let mainRemove: number[] = [];
    this.menus.forEach((menu, index) => {
      if (menu.options.length == 0) mainRemove.push(index)
    })
    mainRemove.reverse().forEach(idx => this.menus.splice(idx,1))
  }

  getUserName(): void {
    this.auth.loadUserProfile().then(prof => {
      this.userName =  prof.firstName + ' ' + prof.lastName;
      this.auth.getCountry().then(country => {
        this.buildMenu(country);
        this.country = 'fi fi-'+country .toLowerCase();
        if (environment.sse) {
          let eventSource = new EventSource(`${environment.backendUrl}/${country.toLowerCase()}/notify`);
          eventSource.onmessage = (event) => {this.setBackendOnline(event)};
          eventSource.onerror = (event) => {this.setBackendOffline(event)}; 
        }
      }).catch(err => {
        //TODO
        this.criticalErrorMessage = err;
        this.spinner.show('criticalError')
      })

      this.auth.getUserTechKey()
      .then(val => {
        this.user_tech_key = val
      })
      .catch(reason => {
        this.criticalErrorMessage = reason;
        this.spinner.show('criticalError')
      })

      this.auth.getToken()
      .then(val => {
        this.jwtToken = val
      })
      .catch(reason => {
        this.criticalErrorMessage = reason;
        this.spinner.show('criticalError')
      })

    }).catch(rej => {
      //TODO
      alert('Rejected' + rej)
    })
  }

  logout(): void {
    this.auth.logout()
  }

  private setBackendOnline(event: MessageEvent<any>) {
    this.spinner.hide('offline')
    //console.log(event)
  }

  private setBackendOffline(event: Event) {
    this.spinner.show('offline')
    console.error(event)
  }
}
