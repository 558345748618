<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'acuerdo'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<table class="thclose">
  <tr>
      <th class="left">
          <mat-card-title>{{title}} {{acuerdo.nAcuerdo}}</mat-card-title>
      </th>
      <th class="header-right">
          <button mat-raised-button color="warn" class="pad" [mat-dialog-close]="acuerdo">X</button>
      </th>
  </tr>
</table>
<div class="matdialog">
  <table class="tabla" aria-describedby="Datos de Acuerdo">
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Tipo Acuerdo</mat-label>
          <mat-select [(ngModel)]="acuerdo.tpAcuerdo" [disabled]="true">
            <mat-option [value]="item.c_tpacuerdo"
              *ngFor="let item of tipoAcuerdo, let i=index">{{item.a_tpacuerdo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td colspan="2">
        <mat-form-field appearance="outline" [floatLabel]="'always'" class="organizacion">
          <mat-label>Organización</mat-label>
          <mat-select [required]="true" [(value)]="acuerdo.organizacion" [disabled]="true">
            <mat-option [value]="item.c_parametro" (click)="setSearchType(item)"
              *ngFor="let item of organizaciones">{{item.a_valor}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogTiendas()" class="pad"
          *ngIf="editarCopiar" [disabled]="true">{{searchButtonTitle}}
          <mat-icon>list</mat-icon>
        </button>
        <button class="pad" mat-raised-button matSuffix [color]="'primary'" (click)="showDataTiendas()">Ver
          ({{acuerdo.tiendas.length}})
          <mat-icon>list</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="acuerdo.isJerarquia">
      <td>

      </td>
      <td colspan="2" class="left filapad">
        <!-- <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogProveedores()"
          *ngIf="editarCopiar">PBC
          <mat-icon>list</mat-icon>
        </button>-->
        <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="showDataPBC()">
          Ver PBC ({{acuerdo.pbc.length}})
          <mat-icon>list</mat-icon>
        </button>
        <!--
          <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogMarcas()" class="pad"
          *ngIf="editarCopiar">Marcas
          <mat-icon>list</mat-icon>
        </button>-->
        <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="showDataMarcas()">
          Ver Marcas ({{acuerdo.marcas.length}})
          <mat-icon>list</mat-icon>
        </button>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Nombre Acuerdo</mat-label>
          <input matInput type="text" placeholder="Ingrese Nombre de Acuerdo" [(ngModel)]="acuerdo.nombreAcuerdo"
            [disabled]="parametroVer">
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="fecha" appearance="outline" [floatLabel]="'always'">
          <mat-label>Vigencia Desde</mat-label>
          <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY" [(ngModel)]="acuerdo.fechaDesde"
            (dateChange)="validaFecha($event, 'desde')" [disabled]="true">
          <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>

        </mat-form-field>
        <mat-form-field class="fecha" appearance="outline" [floatLabel]="'always'">
          <mat-label>Vigencia Hasta</mat-label>
          <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY" [(ngModel)]="acuerdo.fechaHasta"
            (dateChange)="validaFecha($event, 'hasta')" [disabled]="true">
          <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Tipo Recupero</mat-label>
          <mat-select [(ngModel)]="acuerdo.tipoRecupero" [disabled]="parametroVer">
            <mat-option [value]="item.c_tprecupero" *ngFor="let item of tipoRecupero">{{item.a_tprecupero}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Proveedor</mat-label>
          <input matInput type="text" [value]="acuerdo.proveedor.proveedorFullRut" [disabled]="true">
        </mat-form-field>
      </td>
      <td colspan="2">
        <mat-label class="pad">{{acuerdo.proveedor.vendor_name}}</mat-label>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>SubTipo</mat-label>
          <mat-select [(ngModel)]="acuerdo.subTipoAcuerdo" [disabled]="true">
            <mat-option [value]="item.c_parametro" *ngFor="let item of subtipos">{{item.a_valor}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Agrupador</mat-label>
          <mat-select [(ngModel)]="acuerdo.agrAcuerdo" [disabled]="agrDisabled">
            <mat-option [value]="item.c_agrupador" *ngFor="let item of tipoAgrupador">{{item.a_agrupador}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="fileUpload.click()"
          *ngIf="!parametroVer">Respaldo
          Proveedor ({{fileUp}})
          <mat-icon>attach_file</mat-icon>
        </button>
        <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="descargarArchivo()"
          *ngIf="parametroVer">
          Descargar Archivo
          <mat-icon>attach_file</mat-icon>
        </button>

        <input type="file" [hidden]="true" class="file-input" (change)="cargarMail($event)"
          (click)="fileUpload.value=''" [accept]="'.eml,.msg'" #fileUpload>

      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Tipo Liquidación</mat-label>
          <mat-select [(ngModel)]="acuerdo.tipoLiquidacion" [disabled]="parametroVer">
            <mat-option [value]="item.c_parametro" *ngFor="let item of tipoLiquidacion">{{item.a_valor}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Descripción acuerdo</mat-label>
          <textarea matInput rows="3" class="textarea" [(ngModel)]="acuerdo.descrAcuerdo"
            [disabled]="parametroVer"></textarea>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Texto Liquidación</mat-label>
          <textarea matInput rows="3" class="textarea" [(ngModel)]="acuerdo.txtLiquidacion"
            [disabled]="parametroVer"></textarea>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
          <mat-label>Acuerdo Referencia</mat-label>
          <input matInput type="number" inputmode="numeric" [(ngModel)]="acuerdo.acrdReferencia"
            placeholder="Número acuerdo referencia" [disabled]="parametroVer">
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox [labelPosition]="'after'" [checked]="acuerdo.dv" onclick="return false" [disabled]="true">Incluir
          Dañado Vendible</mat-checkbox>
      </td>
      <td>
        <img src='./assets/images/excel-icon.svg' alt="Exportar" class="imgExcel" (click)="exportExcel()" />
      </td>
    </tr>
  </table>
  <table *ngIf="addProd" class="tablaadd" aria-describedby="Agrega productos al acuerdo">
    <tr>
      <td>
        <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
          <mat-label>Sku/Jerarquía</mat-label>
          <input matInput [(ngModel)]="skuJer">
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
          <mat-label>Aporte $</mat-label>
          <input matInput [(ngModel)]="aporte" (keyup)="onKeyUp($event, 'aporte')" (keydown)="keyDown($event)" style="text-align: right;">
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
          <mat-label>Unidades</mat-label>
          <input matInput [(ngModel)]="units" (keyup)="onKeyUp($event, 'units')" (keydown)="keyDown($event)" style="text-align: right;">
        </mat-form-field>
      </td>
      <td>
        <button mat-raised-button [color]="'primary'" class="boton" (click)="addProduct()">Agregar</button>
      </td>
      <td>
        <button mat-raised-button [color]="'warn'" class="boton" (click)="cleanDataSource(false)">Limpiar</button>
      </td>

    </tr>
  </table>
  <br>
  <div class="right padGuardar">
    <button mat-raised-button color="primary" class="pad" (click)="guardarAcuerdo()" cdkFocusInitial
    *ngIf="!parametroVer">Guardar</button>
  </div>
  <!--<button mat-raised-button color="primary" class="pad" [mat-dialog-close]="acuerdo">{{btnCerrar}}</button>-->
  <br>
  <br>

  <table mat-table [dataSource]="acuerdo.tabla" aria-describedby="Datos Simulador">
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef class="right center">#</th>
      <td mat-cell *matCellDef="let i=index" class="right"> {{i+1}} </td>
    </ng-container>
    <ng-container matColumnDef="jerarquia">
      <th mat-header-cell *matHeaderCellDef class="background">Jerarquía Prd/SKU</th>
      <td mat-cell *matCellDef="let element"> {{element.jerarquia}} </td>
    </ng-container>
    <ng-container matColumnDef="descr">
      <th mat-header-cell *matHeaderCellDef class="background">Descr Jerar. Prd/SKU</th>
      <td mat-cell *matCellDef="let element"> {{element.name_full}} </td>
    </ng-container>
    <ng-container matColumnDef="aporte">
      <th mat-header-cell *matHeaderCellDef class="right background">Aporte $</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.aporte.toFixed(2) | countryFormat: country : '0' : false }}
      </td>
    </ng-container>
    <ng-container matColumnDef="tope">
      <th mat-header-cell *matHeaderCellDef class="right background">Tope Monto $</th>
      <td mat-cell *matCellDef="let element" class="right"> 0 </td>
    </ng-container>
    <ng-container matColumnDef="unidad">
      <th mat-header-cell *matHeaderCellDef class="right background">Unidades</th>
      <td mat-cell *matCellDef="let element" class="right"> {{element.unidad | countryFormat: country :'0' : false }}
      </td>
    </ng-container>
    <ng-container matColumnDef="accion" *ngIf="addProd">
      <th mat-header-cell *matHeaderCellDef class="background">Acción</th>
      <td mat-cell *matCellDef="let element; let i=index">
        <img src='./assets/images/borrar.png' class="img" alt="borrar" (click)="eliminar(i)"/>
        <img src='./assets/images/copiar.png' class="img" alt="copiar"  (click)="copiar(i)"/>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
    <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>
  </table>
  <br>
  <br>
</div>