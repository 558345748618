import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

const url = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})
export class RebateBackendService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  private call<T>(obs: Subscriber<T>, headers: HttpHeaders, path: string, method: string, body?: any) {
    this.auth.getCountry()
      .then(country => {
        let ctr = (environment.useCountryPrefix) ? '/' + country : '';
        this.auth.getUserProfileBase64()
          .then(profile => {
            headers = headers.append('x-user-profile',profile);
            //console.log(profile);
            this.http.request(method, url + ctr + path, { observe: 'response', headers: headers, body: body }).subscribe({
              next: (data) => { obs.next(data.body as T) },
              error: (err) => { obs.error(err) },
              complete: () => { obs.complete() }
            })
          })
          .catch(err => obs.error(err))
      })
      .catch(err => obs.error(err))
  }

  callBackendGet<T>(path: string): Observable<T> {
    return new Observable<T>(obs => {
      let headers = new HttpHeaders();
      this.auth.addTokenToHeader(headers).subscribe({
        next: data => headers = data,
        error: err => obs.error(err),
        complete: () => this.call<T>(obs, headers, path, "GET")
        //complete: (res: void) => res=this.call<T>(obs, headers, path, "GET")
      })
    })
  }

  callBackendPost<T>(path: string, body: any[]): Observable<T> {
    return new Observable<T>(obs => {
      let headers = new HttpHeaders()
      headers.append('content-type', 'application/json')
      this.auth.addTokenToHeader(headers).subscribe({
        next: data => headers = data,
        error: err => obs.error(err),
        complete: () => this.call<T>(obs, headers, path, "POST", body)
      })
    })
  }
}
