<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'searchSolicitudesSpinner'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="ancho90">
                        <mat-label>Solcitud</mat-label>
                        <input matInput type="text" [(ngModel)]="nSolicitud">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Usuario</mat-label>
                        <input matInput type="text" [(ngModel)]="usuario">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-div">
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Desde</mat-label>
                        <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="fechaDesde">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Hasta</mat-label>
                        <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="fechaHasta">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="item left-div">
            </div>
            <div class="item left-div">
                <button mat-raised-button color="primary" class="pad" (click)="buscarSolicitudes()">Buscar</button>
                <button mat-raised-button color="warn" class="pad" (click)="Limpiar()">Limpiar</button>
            </div>
        </mat-card-actions>
    </mat-card>
    <br>
    <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="secuencia">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Secuencia Liquidación</th>
            <td mat-cell *matCellDef="let element" class="left letraSize">{{element.nseq_liq}}</td>
        </ng-container>
        <ng-container matColumnDef="tipoDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Tipo Documento</th>
            <td mat-cell *matCellDef="let element" class="letraSize"> {{element.ctipo_modulo}}</td>
        </ng-container>
        <ng-container matColumnDef="fecCreacion">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Creación</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.fecha}} </td>
        </ng-container>
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Usuario</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.usuario}} </td>
        </ng-container>
        <ng-container matColumnDef="detalle">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Detalle</th>
            <td mat-cell *matCellDef="let element" class="letraSize link"
                (click)="verDetalle(element.nseq_liq)">
                Detalle
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        
    </table>

</div>