<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'copiaMasiva'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<table class="tabla pad" aria-describedby="Datos de Acuerdo">
    <tr>
        <th class="title">
            <mat-card-title>{{title}}</mat-card-title>
        </th>
        <th ngClass="header-right" colspan="2">
        </th>
    </tr>
    <tr>
        <td>
            <button mat-raised-button [color]="'primary'" *ngIf="paramporcConf" class="pad" (click)="fileUpload.click()">Cargar Excel</button>
            <input type="file" [hidden]="true" class="file-input" (change)="uploadExcel($event)"
                (click)="fileUpload.value=''" [accept]="'.xlsx,.xls'" #fileUpload>
        </td>
        <td>
            <mat-form-field appearance="outline" [floatLabel]="'always'">
                <mat-label>Tipo Recupero</mat-label>
                <mat-select [(ngModel)]="cmTipoRecupero">
                    <mat-option [value]="item.c_tprecupero"
                        *ngFor="let item of tipoRecupero">{{item.a_tprecupero}}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td>
            <button mat-raised-button class="pad" *ngIf="paramporcConf" matSuffix [color]="'primary'"
                (click)="fileUploadMail.click()">Respaldo
                Proveedor ({{fileUp}})
                <mat-icon>attach_file</mat-icon>
            </button>
            <input type="file" [hidden]="true" class="file-input" (change)="cargarMail($event)"
                (click)="fileUploadMail.value=''" [accept]="'.eml,.msg'" #fileUploadMail>
        </td>
    </tr>
</table>
<br>
<br>
<table class="tabla pad" aria-describedby="Datos de Acuerdo">
    <tr>
        <td class="left mitad">
            <button *ngIf="crearAcuerdo" mat-raised-button [color]="'primary'" class="boton"
                (click)="crearAcuerdos()">Crear Acuerdos</button>
        </td>
        <td class="center mitad">
            <img src='./assets/images/excel-icon.svg' style="cursor: pointer;" alt="Exportar" class="descargarAcuerdos" *ngIf="paramporcConf"
                (click)="exportExcel()" />
        </td>
    </tr>
</table>
<table mat-table [dataSource]="dataSource" class="pad" aria-describedby="Datos Simulador">
    <ng-container matColumnDef="n_acuerdo">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Acuerdo Original</th>
        <td mat-cell *matCellDef="let element" class="left letraSize">{{element.nAcuerdo}} </td>
    </ng-container>
    <ng-container matColumnDef="mesAcuerdo">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Mes Acuerdo</th>
        <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.mes}} </td>
    </ng-container>
    <ng-container matColumnDef="cuenta">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cuenta</th>
        <td mat-cell *matCellDef="let element" class="left nowrap letraSize">{{element.cuenta}}</td>
    </ng-container>
    <ng-container matColumnDef="proveedor">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Proveedor</th>
        <td mat-cell *matCellDef="let element" class="left nowrap letraSize"> {{element.nombreProv | largoMax: 10}}
        </td>
    </ng-container>
    <ng-container matColumnDef="montoAcu">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto Original</th>
        <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.montoAcu}} </td>
    </ng-container>

    <ng-container matColumnDef="montoNuevo">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto Nuevo</th>
        <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.montoAcuNuevo}} </td>
    </ng-container>
    <ng-container matColumnDef="usuario">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Usuario</th>
        <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.usuario | largoMax: 10}} </td>
    </ng-container>
    <ng-container matColumnDef="subLinea">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Sublínea</th>
        <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.sublinea | largoMax: 10}}
        </td>
    </ng-container>
    <ng-container matColumnDef="nomAcuerdoNuevo">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Acuerdo Nuevo</th>
        <td mat-cell *matCellDef="let element" class="left nowrap letraSize"> {{element.nomAcuNuevo}} </td>
    </ng-container>
    <ng-container matColumnDef="maliBoni">
        <th mat-header-cell *matHeaderCellDef class="left background letraSize">Malif/Bonif</th>
        <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.nAcuNuevo}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns; let element" [ngClass]="{'naranjo': (element.status!='ok')}">
    </tr>
</table>