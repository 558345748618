<ngx-spinner name="precioVigente" bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Precios Vigentes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Numero</mat-label>
                        <input matInput type="text" [(ngModel)]="numeroProducto"
                            placeholder="SKU">
                    </mat-form-field>
                    <mat-label class="padprov"> {{ descripcionProducto }}</mat-label>
                </div>
                <div class="right-div">
                    <label>
                        <input type="checkbox" [(ngModel)]="verSoloOfertas" (change)="filtrarPrecios()" />
                        Ver Solo Ofertas
                    </label><br>
                    <label>
                        <input type="checkbox" [(ngModel)]="soloProductosConStock" (change)="filtrarPrecios()" />
                        Solo Prd C/Stock
                    </label>
                </div>
            </div>
            <table mat-table [dataSource]="datosExtras" *ngIf="datosExtrasVisibles">
                <ng-container matColumnDef="linea">
                    <th mat-header-cell *matHeaderCellDef class="background">Linea</th>
                    <td mat-cell *matCellDef="let element"> {{element.Linea}} </td>
                </ng-container>
                <ng-container matColumnDef="sublinea">
                    <th mat-header-cell *matHeaderCellDef class="background">Sub Linea</th>
                    <td mat-cell *matCellDef="let element"> {{element.SubLinea}} </td>
                </ng-container>
                <ng-container matColumnDef="clase">
                    <th mat-header-cell *matHeaderCellDef class="background">Clase</th>
                    <td mat-cell *matCellDef="let element"> {{element.Clase}} </td>
                </ng-container>
                <ng-container matColumnDef="subclase">
                    <th mat-header-cell *matHeaderCellDef class="background">Sub Clase</th>
                    <td mat-cell *matCellDef="let element"> {{element.SubClase}} </td>
                </ng-container>
                <ng-container matColumnDef="temporada">
                    <th mat-header-cell *matHeaderCellDef class="background">Temporada</th>
                    <td mat-cell *matCellDef="let element"> {{element.Temporada}} </td>
                </ng-container>
                <ng-container matColumnDef="marca">
                    <th mat-header-cell *matHeaderCellDef class="background">Marca</th>
                    <td mat-cell *matCellDef="let element"> {{element.Marca}} </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="cabeceraTablaExtras"></tr>
                <tr mat-row *matRowDef="let row; let element; columns: cabeceraTablaExtras;"></tr>
            </table>
        </mat-card-content>
        <mat-card-actions>
            <div class="left-div">
                <mat-label>Precio Normal:</mat-label> {{ precioNormal | currency: 'CLP' : 'symbol-narrow' }}<br>
                <mat-label>Precio Original:</mat-label> {{ precioOriginal | currency: 'CLP' : 'symbol-narrow' }}
            </div>
            <div class="right-div">
                <button mat-raised-button [color]="'primary'" class="boton" (click)="busquedaPrecios()">Ejecutar</button>
            </div>
        </mat-card-actions>
    </mat-card>  
    <br>
    <table mat-table [dataSource]="preciosFiltrados">
        <ng-container matColumnDef="tienda">
            <th mat-header-cell *matHeaderCellDef class="background">Tienda</th>
            <td mat-cell *matCellDef="let element"> {{element.Tienda}} </td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef class="background">Tipo Precio</th>
            <td mat-cell *matCellDef="let element"> {{element.Tipo}} </td>
        </ng-container>
        <ng-container matColumnDef="vigente">
            <th mat-header-cell *matHeaderCellDef class="background">Precio Vigente</th>
            <td mat-cell *matCellDef="let element"> {{element.Precio | currency: 'CLP' : 'symbol-narrow'}} </td>
        </ng-container>
        <ng-container matColumnDef="finicio">
            <th mat-header-cell *matHeaderCellDef class="background">F.Inicio</th>
            <td mat-cell *matCellDef="let element"> {{element.Finicio}} </td>
        </ng-container>
        <ng-container matColumnDef="ftermino">
            <th mat-header-cell *matHeaderCellDef class="background">F.Termino</th>
            <td mat-cell *matCellDef="let element"> {{element.Ftermino}} </td>
        </ng-container>
        <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef class="background">Stock</th>
            <td mat-cell *matCellDef="let element"> {{element.Stock}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
        <tr mat-row *matRowDef="let row; let element; columns: cabeceraTabla;"></tr>
    </table>
  </div>