import { RebateBackendService } from '../services/rebate/rebate.backend.service';
export class Proveedor {
    private _nrut: string = "";
    private _vendor_name: string = "";
    private _xdvrut: string = "";
    private _vpc_tech_key:string="";

    constructor(private backend?: RebateBackendService) {}

    setProveedor(rut: string): Promise<Proveedor> {
        return new Promise<Proveedor>((resolve, reject) => {
            if (rut != '') {
                rut = rut.split("-")[0]
                if (this.backend != undefined)
                this.backend.callBackendGet<Proveedor[]>(`/proveedor/${rut}`).subscribe(
                    {
                        next: data => {
                            this._nrut = data[0].nrut;
                            this._vendor_name = data[0].vendor_name;
                            this._xdvrut = data[0].xdvrut;
                            this._vpc_tech_key=data[0].vpc_tech_key;
                        },
                        error: err => {
                            this._nrut = this._vendor_name = this._xdvrut = this._vpc_tech_key = ''
                            reject(err);
                        },
                        complete: () => resolve(this)
                    }
                )
                else reject("Backend service not declared")
            } else {
                resolve(this)
            }
        })
    };

    get nrut() { return this._nrut };
    get vendor_name() { return this._vendor_name};
    get xdvrut() { return this._xdvrut };
    get vpc_tech_key() { return this._vpc_tech_key};

    get proveedorFullRut() {
        return (this.nrut != '' && this.xdvrut != '') ? `${this.nrut}-${this.xdvrut}` : ''
    }

    setValues(nrut: string,vendor_name: string,xdvrut: string, vpc_tech_key: string) {
        this._nrut = nrut;
        this._vendor_name = vendor_name;
        this._xdvrut = xdvrut;
        this._vpc_tech_key=vpc_tech_key;
    }
    
}