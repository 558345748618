import { Component, Input, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { AcuerdoComponent } from '../../shared/components/acuerdo/acuerdo.component';
import { AcuerdoSellOutComponent } from 'src/app/shared/components/acuerdo-sell-out/acuerdo-sell-out.component';
import { ActivatedRoute } from '@angular/router';
import { VerEstadosAcuerdoComponent } from 'src/app/shared/components/ver-estados-acuerdo/ver-estados-acuerdo.component';
import { errors } from 'src/app/constants/constants.errors';
import { HttpErrorResponse } from '@angular/common/http';
interface AprobarAcuerdos extends Interfaces.ListadoAcuerdos {
  seleccion: boolean
}

@Component({
  selector: 'app-ver-acuerdos',
  templateUrl: './ver-acuerdos.component.html',
  styleUrls: ['./ver-acuerdos.component.scss']
})

export class VerAcuerdosComponent {
  arraysAcuerdos: AprobarAcuerdos[] = [];
  dataSource: MatTableDataSource<AprobarAcuerdos>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public spinnerMessage: string = ''
  public ver: boolean = false;
  public acuerdo!: Acuerdo;
  public acuerdoSellOut!: AcuerdoSellOut;
  public title!: string;

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['num', 'n_acuerdo', 'nomAcuerdo', 'tipoAcuerdo', 'agrupador', 'subtipo', 'vigDesde', 'vigHasta', 'proveedor', 'nomProveedor', 'tipoRecupero', 'fecConfirmado', 'estado'];
    this.dataSource = new MatTableDataSource<AprobarAcuerdos>(this.arraysAcuerdos);
    //this.acuerdo=new Acuerdo(rbtService,commonService);
  }

  ngOnInit() {
    this.arraysAcuerdos = [];

    //this.dataSource.paginator = this.paginator;
    this.setTitle();
  }

  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  recive(arrraysAcuerdos: AprobarAcuerdos[]) {
    this.arraysAcuerdos = arrraysAcuerdos;
    this.dataSource = new MatTableDataSource<AprobarAcuerdos>(arrraysAcuerdos);
    /*this.paginator._intl.itemsPerPageLabel='Acuerdos por Página';
    this.paginator._intl.getRangeLabel=(page: number, pageSize: number, length: number)=>{
      let hasta:number = (page+1) * pageSize;
      let desde: number = hasta - pageSize + 1;
      if (hasta>length){
        hasta=length;
      }
      if (length==0){
        hasta=1;
        desde=1;
      }
      return desde + ' - ' + hasta + ' de ' + length;
    }
    if (this.arraysAcuerdos.length>5){
      this.ver=false
    }
    else{
      this.ver=true;
    }
    this.dataSource.paginator = this.paginator;*/
  }

  verAcuerdo(nacuerdo: number, tipoAcuerdo: number) {
    //aca se va a al backend y se va a crear un objeto simulador con los datos del acuerdo.
    this.spinner.show('verAcuerdo');
    this.spinnerMessage = "Buscando datos de acuerdo..."
    this.cargarAcuerdo(nacuerdo, tipoAcuerdo)
      .then(() => {
        if (tipoAcuerdo == 128) {
          this.openDialogAbrirAcuerdoSellOut(this.acuerdoSellOut)
        }
        else {
          this.openDialogAbrirAcuerdoFijo(this.acuerdo)
        }
      })
      .catch(err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
        } else {
          if (err instanceof HttpErrorResponse && err.status === 400) {
            this.commonService.error('Error', errors.ErrorBuscarAcuerdo.message, 'Aceptar', true)
          }
          else {
            this.commonService.error('Error', err.message, 'Aceptar', true)
          }
        }
      })
      .finally(
        () => {
          this.spinner.hide('verAcuerdo');
        }
      )
  }

  async cargarAcuerdo(nacuerdo: number, tipoAcuerdo: number) {
    if (tipoAcuerdo == 128) {
      this.acuerdoSellOut = new AcuerdoSellOut(this.rbtService, this.commonService, this.auth)
      return new Promise<void>((res, rej) => {
        this.acuerdoSellOut.datosAcuerdoSellOut(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })
    }
    else {
      this.acuerdo = new Acuerdo(this.rbtService, this.commonService, this.auth);
      return new Promise<void>((res, rej) => {
        this.acuerdo.datosAcuerdoFijo(nacuerdo)
          .then((data) => {
            res()
          })
          .catch(
            err => rej(err)
          )
      })
    }
  }

  openDialogAbrirAcuerdoFijo(acuerdo: Acuerdo) {
    this.commonService.openDialog<Acuerdo, Acuerdo>(AcuerdoComponent, {
      organizacion: acuerdo.descr_org, parametroVer: true, addProd: false, editarCopiar:false, btnCerrar: 'Cerrar', title: 'Acuerdo Datos'
    }, { data: acuerdo, height: '95%', disableClose: true })
  }

  openDialogAbrirAcuerdoSellOut(acuerdoSellOut: AcuerdoSellOut) {
    this.commonService.openDialog<AcuerdoSellOut, AcuerdoSellOut>(AcuerdoSellOutComponent, {
      organizacion: '', parametroVer: true, btnCerrar: 'Cerrar', addProd: false, editarCopiar:false, title: 'Acuerdo Datos'
    }, { data: acuerdoSellOut, height: '95%', disableClose: true })
  }

  verEstados(nacuerdo: number, descrAcuerdo: string) {
    let datosAcuerdo: Interfaces.AcuerdosHistorial = { n_acuerdo: nacuerdo, descrAcuerdo: descrAcuerdo };
    this.commonService.openDialog<null, Interfaces.AcuerdosHistorial>(VerEstadosAcuerdoComponent, {}
      , { data: datosAcuerdo, width: '40%', disableClose: true })
  }

  exportExcel() {
    let rows: Object[] = [];
    this.arraysAcuerdos.forEach(element => {
      rows.push({
        "Cod. Acuerdo": element.n_acuerdo,
        "Nombre Acuerdo": element.nombreacuerdo,
        "Tipo Acuerdo": element.a_tpacuerdo,
        "Agrupador": element.a_agrupador,
        "Subtipo": element.a_valor,
        "Vig. Desde": element.d_vigdesde,
        "Vig. Hasta": element.d_vighasta,
        "Proveedor": element.nrut + "-" + element.xdvrut,
        "Nombre Proveedor": element.nombreproveedor,
        "Tipo Recupero": element.a_tprecupero,
        "Fec.Confirmado": element.d_confirmado,
        "Estado": element.a_estado
      })
    });
    CommonsModule.exportExcel(
      "verAcuerdos-" +
      Intl.DateTimeFormat('es-ES', {day:'2-digit', month:'2-digit', year:'numeric'}).format(Date.now()) + "_" +
      Intl.DateTimeFormat('es-ES', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Date.now())
      ,rows);
  }

}
