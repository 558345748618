import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { Proveedor } from 'src/app/classes/proveedor';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { SearchProveedorComponent } from '../simulador/search-proveedor/search-proveedor.component';


interface listadoBon extends Interfaces.ListadoBonificaciones {
  seleccion: boolean
}

@Component({
  selector: 'app-search-bonificaciones',
  templateUrl: './search-bonificaciones.component.html',
  styleUrls: ['./search-bonificaciones.component.scss']
})
export class SearchBonificacionesComponent {
  public spinnerMessage: string = "Loading ..."
  public nAcuerdo: string = ''
  public tpLiquidacion: string = ''
  public userTech: string='';
  public jerSku: string=''
  public fechaDesde!: Date;
  public fechaHasta!: Date;

  public tipoLiquidacion: Interfaces.TipoLiquidacionOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public proveedor: Proveedor;
  public rutFullProv: string = '';
  @Output() ListaBonificaciones: EventEmitter<listadoBon[]> = new EventEmitter();
  @Input() showDates!: boolean;
  @Input() estadoSearch!: string;
  public arrayAcuerdos: Interfaces.ListadoAcuerdos[] = [];

  constructor(private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,) {
    this.proveedor = new Proveedor(srv) //ver con pancho que puedo hacer para no estar creando nuevamente una variable de
    //tipo proveedor
  }

  ngOnInit(): void {
    this.iniciar();

  }

  iniciar() {
    let largo: number = 0;
    this.nAcuerdo = '';
    this.tpLiquidacion = '';
    this.userTech='';
    this.jerSku=''
    this.rutFullProv = '';
    this.proveedor = new Proveedor(this.srv);
    this.fechaDesde=(null as unknown as Date);
    this.fechaHasta=(null as unknown as Date);
    
    this.loading()
      .then(() => {
        this.tipoLiquidacion.unshift({ c_parametro: '0', a_valor: '-- Todos --', c_tpacuerdo: ''})
        this.tpLiquidacion = this.tipoLiquidacion[0].c_parametro;
      });

    let lista: listadoBon[] = [];
    this.ListaBonificaciones.emit(lista)
  }

  buscarAcuerdos() {
    //realizar validaciones de los campos de userTech, jersku, tpLiquidacion
    //cuando vienen vacíos hay que mandar un cero.

    this.spinnerMessage = 'Buscando Acuerdos ...'
    this.spinner.show('searchBonificacionesSpinner')

    let tipoLiquidacion: string= this.tpLiquidacion;

    let body: string = CommonsModule.stringToNumber(this.nAcuerdo.toString()) +
      '/' + CommonsModule.stringToNumber(this.proveedor.nrut.toString());
    
    
    (tipoLiquidacion!='0') ? body = body + '/' + this.tpLiquidacion : body = body + '/0';

    (this.userTech!='') ? body = body + '/' + this.userTech : body = body + '/0';

    (this.jerSku!='') ? body = body + '/' + this.jerSku : body = body + '/0' ;

    if (this.fechaDesde == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaDesde);
    }
    if (this.fechaHasta == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaHasta);
    }
    console.log(body)
    this.srv.callBackendGet<listadoBon[]>(`/bonificacion/${body}`).subscribe({
      next: lista => {
        lista.forEach(acrd => {
          acrd.seleccion = false;
        })
        this.ListaBonificaciones.emit(lista)
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.BonificacionesNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
            this.commons.error('Error', errors.BonificacionesNoEncontradas.message, 'Aceptar', true) :
            this.commons.error('Error', errors.BonificacionError.message, 'Aceptar', true);
        }
        this.ListaBonificaciones.emit([]);
        this.spinner.hide('searchBonificacionesSpinner')
      },
      complete: () => {
        this.spinner.hide('searchBonificacionesSpinner')
      }
    }
    )
  }

  async loading() {
    this.spinner.show('searchBonificacionesSpinner');
    this.spinnerMessage = "Cargando datos...";
    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoLiquidacionOptions>(DropDownOptions.TipoLiquidacion, ['23'])
        .then((data) => {
          this.tipoLiquidacion = data;
          this.tpLiquidacion = this.tipoLiquidacion[0].c_parametro;
          res();
        })
        .catch(err => rej('Error Tipos Liquidación: ' + err))
    })
    let results = await Promise.allSettled([p1])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")
    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('searchBonificacionesSpinner')
  }

  buscarProveedor(event: any) {
    this.spinner.show('searchBonificacionesSpinner');
    this.spinnerMessage = "Verificando Proveedor";
    this.proveedor.setProveedor(event.target.value)
      .then(() => this.rutFullProv = this.proveedor.proveedorFullRut)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          this.commonService.error(errors.ErrorBuscarProveedor.message, (err.status === 404) ? errors.ProveedorNoExiste.message : err.message, 'Aceptar', true)
        } else {
          this.commonService.error(errors.ErrorBuscarProveedor.message, err, 'Aceptar', true)
        }
      })
      .finally(() => this.spinner.hide('searchBonificacionesSpinner'))
  }

  Limpiar() {
    this.iniciar();
  }

  usuarioUpper(){
    this.userTech=this.userTech.toUpperCase();
  }

  openDialogProv() {
    this.proveedor.setValues('','','','')
    this.rutFullProv=''
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { 
          if (result != null){ 
            var provs :Proveedor[]=[result]
            for (let prov of provs){
              this.proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
              this.rutFullProv = this.proveedor.proveedorFullRut
            } 
          }
        }
      }
    )
  }

}
