import { Component, OnInit, Inject } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
@Component({
  selector: 'app-ver-estados-acuerdo',
  templateUrl: './ver-estados-acuerdo.component.html',
  styleUrls: ['./ver-estados-acuerdo.component.scss']
})
export class VerEstadosAcuerdoComponent {
  public spinnerMessage: string = "Loading ..."
  public title: string = 'Historia Acuerdo';
  public estadosAcuerdo: Interfaces.HistorialEstadosAcuerdo[] = [{ estado: '', fecha: '', n_acuerdo: 0, usuario: ''}];
  public readonly cabeceraTabla: string[] = ['estado', 'fecha', 'usuario'];
  public datosAcuerdo:string='';

  constructor(
    public dialogRef: MatDialogRef<VerEstadosAcuerdoComponent>,
    private spinner: NgxSpinnerService,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public dataAcuerdo: Interfaces.AcuerdosHistorial,

  ) {
    this.title= 'Historia Acuerdo ' + dataAcuerdo.n_acuerdo + ' ' + dataAcuerdo.descrAcuerdo;
  }

  ngOnInit(): void {
    this.buscarHistorial(this.dataAcuerdo.n_acuerdo)
  }

  buscarHistorial(nAcuerdo:number){
    this.spinner.show('estadosAcuerdo')
    this.srv.callBackendGet<Interfaces.HistorialEstadosAcuerdo[]>(`/acuerdo/historiaAcuerdo/${nAcuerdo}`).subscribe({
      next: listaMarcas =>{
        this.estadosAcuerdo=listaMarcas
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true) :
          this.commons.error(errors.MarcasNoEncontradas.message, err, 'Aceptar', true);
        }
        this.estadosAcuerdo=[];
        this.spinner.hide('estadosAcuerdo')
      },
      complete: ()=>{
        this.spinner.hide('estadosAcuerdo')
      }
    }
    )
  }
}
