<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'creacionFacturas'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="item left-div">
                    <button mat-raised-button [color]="'primary'" class="boton" (click)="fileUpload.click()" matTooltip="Fechas en archivo deben venir en formato DD-MM-YYYY">Cargar
                        Excel</button>
                    <input type="file" [hidden]="true" class="file-input" (change)="uploadExcel($event)"
                        (click)="fileUpload.value=''" [accept]="'.xlsx,.xls'" #fileUpload>
                </div>
                <div class="item left-div">
                    <mat-checkbox [labelPosition]="'after'" [(ngModel)]="checkDoc"
                        (change)="checkDto()">Documento</mat-checkbox>
                </div>
            </div>
            <div class="flex-div" *ngIf="paramMoneda">
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Moneda</mat-label>
                        <mat-select [(value)]="moneda">
                            <mat-option [value]="item.cod"
                                *ngFor="let item of monedas, let i=index">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Valor moneda</mat-label>
                        <input matInput type="text" [(ngModel)]="valorMoneda" (keyup)="onKeyUp($event, 'moneda',0)" (keydown)="keyDown($event)" inputmode="numeric" style="text-align: right;">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
            </div>
            <div class="flex-div">
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Sección</mat-label>
                        <input matInput type="text" [(ngModel)]="seccion" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Documento</mat-label>
                        <input matInput type="text" [(ngModel)]="documento" [disabled]="true">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Concepto</mat-label>
                        <input matInput type="text" [(ngModel)]="concepto" [disabled]="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-div">
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Plazo</mat-label>
                        <mat-select [(value)]="plazo">
                            <mat-option [value]="item.value"
                                *ngFor="let item of plazos, let i=index">{{item.descr}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    check Nc
                    <mat-checkbox [labelPosition]="'after'" [(ngModel)]="checkNc"
                        (change)="check()">Rebate</mat-checkbox>
                </div>
                <div class="item left-div">
                    <button mat-raised-button color="primary" class="pad" (click)="guardarFactura()">Enviar</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <br>
    <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="btns">
            <th mat-header-cell *matHeaderCellDef class="center background letraSize">&nbsp;</th>
            <td mat-cell *matCellDef="let element; let i=index" class="center letraSize nowrap">
                <button color="primary" class="padbtns" *ngIf="element.btnAdd1"
                    (click)="agregarSeq(element, i)">+</button>
                <button color="primary" class="padbtns" *ngIf="element.btnDel1" (click)="deleteApendice(i)">-</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">#</th>
            <td mat-cell *matCellDef="let element" class="right letraSize" [ngClass]="{'naranjo': (!element.find)}">
                {{element.seq}}</td>
        </ng-container>
        <ng-container matColumnDef="rutAcuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize" [ngClass]="{'naranjo': (!element.find)}">
                {{element.fullRut}}
            </td>
        </ng-container>
        <ng-container matColumnDef="acuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="letraSize" [ngClass]="{'naranjo': (!element.find)}">
                {{element.acuerdo}} </td>
        </ng-container>
        <ng-container matColumnDef="montoAcuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="right letraSize" [ngClass]="{'naranjo': (!element.find)}">
                {{element.monto_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="cuentaAC">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cuenta AC</th>
            <td mat-cell *matCellDef="let element" class="letraSize" [ngClass]="{'naranjo': (!element.find)}">
                {{element.ceunta_contable}} </td>
        </ng-container>
        <ng-container matColumnDef="agrupador">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Agrupador</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.agrupador" size="4" *ngIf="element.addinput"
                    class="texto" [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.rutFactura" size="10" *ngIf="element.addinput"
                    class="texto" [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto</th>
            <td mat-cell *matCellDef="let element; let idx= index" class="nowrap letraSize">
                <input matInput [attr.id]="'montoFactura' + element.seq" type="text"
                    [(ngModel)]="element.montoFactura" (keyup)="onKeyUp($event, 'montoFactura',idx)" (keydown)="keyDown($event)" 
                    size="15"
                    inputmode="numeric" style="text-align: right;" *ngIf="element.addinput" class="texto"
                    [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="glosa">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Glosa</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.glosaFactura" size="20" class="texto" [maxLength]="132"
                    [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="codRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cod. Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.codRef" size="4" *ngIf="!checkNc" class="texto"
                    [ngClass]="{'naranjo': (!element.find)}">
                <mat-label *ngIf="checkNc">{{element.codRef}}</mat-label>
            </td>
        </ng-container>
        <ng-container matColumnDef="folioRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Folio Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.folioRef" size="15" class="texto"
                    [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="fechaRef">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Ref</th>
            <td mat-cell *matCellDef="let element" class="nowrap top letraSize">
                <input matInput type="text" placeholder="DD-MM-YYYY" [(ngModel)]="element.fechaRef" size="10"
                    class="texto" [ngClass]="{'naranjo': (!element.find)}">
            </td>
        </ng-container>
        <ng-container matColumnDef="btns1">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">&nbsp;</th>
            <td mat-cell *matCellDef="let element; let i=index" class="letraSize nowrap">
                <button color="primary" class="padbtns" *ngIf="element.btnAdd2"
                    (click)="agregarApendice(element, i)">+</button>
                <button color="primary" class="padbtns" *ngIf="element.btnDel2" (click)="deleteApendice(i)">-</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; let element; columns: columns;"></tr>
    </table>
    <br>
    <br>
    <table mat-table [dataSource]="dataSourceDocFact" aria-describedby="Datos Simulador" *ngIf="checkDoc">
        <ng-container matColumnDef="btns">
            <th mat-header-cell *matHeaderCellDef class="center background letraSize">&nbsp;</th>
            <td mat-cell *matCellDef="let element; let i=index" class="center letraSize nowrap">
                <button color="primary" class="padbtns" *ngIf="element.btnAdd1"
                    (click)="agregarDoc(element, i)">+</button>
                <button color="primary" class="padbtns" *ngIf="element.btnDel1" (click)="deleteDoc(i)">-</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">#</th>
            <td mat-cell *matCellDef="let i= index" class="letraSize">{{i + 1}}</td>
        </ng-container>
        <ng-container matColumnDef="nombreDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.nombre" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="ceco">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">CECO</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.ceco" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="agrDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Agrupador</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.agrupador" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="ctaCont">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Cuenta Contable</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.ctaCont" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="rutProv">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut Proveedor</th>
            <td mat-cell *matCellDef="let element" class="letraSize">
                <input matInput type="text" [(ngModel)]="element.rutProv" size="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="montoDoc">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Monto</th>
            <td mat-cell *matCellDef="let element; let idx= index" class="nowrap letraSize">
                <input matInput [attr.id]="'montoFactura' + element.seq" type="text"
                    [(ngModel)]="element.monto" size="15" (keyup)="onKeyUp($event, 'monto',idx)" (keydown)="keyDown($event)"
                    style="text-align: right;" class="texto" [size]="15">
            </td>
        </ng-container>
        <ng-container matColumnDef="codOrden">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Código Orden</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize">
                <input matInput type="text" [(ngModel)]="element.codOrden" size="15">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsDoc"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsDoc;"></tr>
    </table>
</div>
<br>
<br>
<br>