<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'verAcuerdo'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<mat-card>
    <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-search-acuerdo (listaAcuerdos)="recive($event)" [showDates]="true"
            [estadoSearch]="'0'"></app-search-acuerdo>
    </mat-card-content>
    <br>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
            <ng-container matColumnDef="num">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">#</th>
                <td mat-cell *matCellDef="let i=index" class="right letraSize">{{i+1}} </td>
            </ng-container>
            <ng-container matColumnDef="n_acuerdo">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Cod. Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="right letraSize link"
                    (click)="verAcuerdo(element.n_acuerdo, element.c_subtipo)">{{element.n_acuerdo}}</td>
            </ng-container>
            <ng-container matColumnDef="nomAcuerdo">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="letraSize link"
                    (click)="verAcuerdo(element.n_acuerdo, element.c_subtipo)"> {{element.nombreacuerdo | largoMax: 10}}
                </td>
            </ng-container>
            <ng-container matColumnDef="tipoAcuerdo">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Tipo Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.a_tpacuerdo}} </td>
            </ng-container>
            <ng-container matColumnDef="agrupador">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Agrupador</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.a_agrupador}} </td>
            </ng-container>
            <ng-container matColumnDef="subtipo">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Subtipo</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.a_valor}}
                </td>
            </ng-container>
            <ng-container matColumnDef="vigDesde">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vig. Desde</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.d_vigdesde}} </td>
            </ng-container>
            <ng-container matColumnDef="vigHasta">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vig. Hasta</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.d_vighasta}} </td>
            </ng-container>
            <ng-container matColumnDef="proveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Proveedor</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.nproveedor}} </td>
            </ng-container>
            <ng-container matColumnDef="nomProveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Proveedor</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.nombreproveedor}} </td>
            </ng-container>
            <ng-container matColumnDef="tipoRecupero">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Tipo Recupero</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.a_tprecupero}} </td>
            </ng-container>
            <ng-container matColumnDef="fecConfirmado">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Fec.Confirmado</th>
                <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.d_confirmado}}
                </td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef class="background letraSize">Estado</th>
                <td mat-cell *matCellDef="let element" class="letraSize link"
                    (click)="verEstados(element.n_acuerdo, element.nombreacuerdo)">
                    {{element.a_estado}}
                </td>
            </ng-container>
            <ng-container matColumnDef="export">
                <td mat-footer-cell *matFooterCellDef colspan="14" class="center">
                    <img src='./assets/images/excel-icon.svg' alt="Exportar" class="img" (click)="exportExcel()"/>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['export']"></tr>
        </table>
        <!--<div [hidden]="ver">
                <mat-paginator #paginator [length]="arraysAcuerdos.length" showFirstLastButtons
                    [pageSizeOptions]="[5, 7, 10, 25, 100]" aria-label="Select page">
                </mat-paginator>
            </div>-->
    </mat-card-content>
</mat-card>