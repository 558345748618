<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'verBonificaciones'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<mat-card>
    <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-search-bnf-desfazadas (ListaBonificaciones)="recive($event)"></app-search-bnf-desfazadas>
    </mat-card-content>
    <br>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
            <ng-container matColumnDef="linea">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Línea</th>
                <td mat-cell *matCellDef="let element" class="left letraSize">{{element.linea}} </td>
            </ng-container>
            <ng-container matColumnDef="sublinea">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Sub Línea</th>
                <td mat-cell *matCellDef="let element" class="left letraSize">
                    {{element.sublinea}}</td>
            </ng-container>
            <ng-container matColumnDef="nacuerdo">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Acuerdo</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> 
                    {{element.acuerdo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="usuario">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Usuario Creador</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> 
                    {{element.usuario | largoMax: 10}}
                </td>
            </ng-container>
            <ng-container matColumnDef="fecapr">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Aprobación</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> 
                    {{element.fecha}}
                </td>
            </ng-container>
            <ng-container matColumnDef="proveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.rut}} </td>
            </ng-container>

            <ng-container matColumnDef="nomProveedor">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Proveedor</th>
                <td mat-cell *matCellDef="let element" class="letraSize"> {{element.proveedor}} </td>
            </ng-container>
            <ng-container matColumnDef="vigDesde">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vigencia Desde</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.fdesde}} </td>
            </ng-container>
            <ng-container matColumnDef="vigHasta">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Vigencia Hasta</th>
                <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.fhasta}} </td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef class="left background letraSize">Estado</th>
                <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.estado}} </td>
            </ng-container>
            <ng-container matColumnDef="semaforo">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Semáforo</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.semaforo | largoMax: 10}} </td>
            </ng-container>
            <ng-container matColumnDef="bnfMes">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Bonificación Mes</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.bonificacion_mes | countryFormat:
                    country: '0' : true}} </td>
            </ng-container>
            <ng-container matColumnDef="bnfDesfazada">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Bonificación Desfazada</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.monto_bonificado | countryFormat:
                    country: '0' : true}} </td>
            </ng-container>
            <ng-container matColumnDef="bnfTotal">
                <th mat-header-cell *matHeaderCellDef class="right background letraSize">Bonificación Total</th>
                <td mat-cell *matCellDef="let element" class="letraSize right"> {{element.monto_desfasadp | countryFormat:
                    country: '0' : true}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <!--<div [hidden]="ver">
                <mat-paginator #paginator [length]="arraysAcuerdos.length" showFirstLastButtons
                    [pageSizeOptions]="[5, 7, 10, 25, 100]" aria-label="Select page">
                </mat-paginator>
            </div>-->
    </mat-card-content>
</mat-card>