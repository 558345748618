import { ChangeDetectorRef, Component, Input, OnInit, Pipe, numberAttribute } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { CountryFormatPipe } from '../pipes/countryFormat.pipe';

interface BuscaLiquidaciones extends Interfaces.BuscarLiquidaciones {
  fullRut: string,
  seleccion: boolean
}

interface listaFacturas extends Interfaces.facturas {
  fullRut: string,
  seleccion: boolean
}

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.scss']
})

export class FacturaComponent implements OnInit {
  public country!: string;
  public dataSource: MatTableDataSource<listaFacturas>;
  public columns: string[];
  public columnsDoc: string[];
  public spinnerMessage: string = "Loading ..."
  public title?: string;
  public checkDoc: boolean = false;
  public parametros: Interfaces.Parametros[] = [];
  public paramMoneda: boolean = false;
  public moneda: string = '0';
  public monedas: Interfaces.Monedas[] = [{ cod: '0', name: 'CLP', defecto: 'T' }];
  public valorMoneda: string = "";
  public margenPermitido: number = 0;
  public seccion: string = 'Solicitud Factura'
  public documento: string = 'Factura'
  public concepto: string = 'Rebate'
  public plazo: string = '0'
  public plazos: Interfaces.Plazos[] = []
  public checkNc: boolean = false;
  public redondeo: boolean = false

  @Input() ListadofacturasSeleccionadas: listaFacturas[] = [];

  public listadoDocFacturas: Interfaces.documentFacturas[] = [];
  public dataSourceDocFact: MatTableDataSource<Interfaces.documentFacturas>;
  private user_tech_key !: string;

  constructor(
    private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,
    private auth: AuthService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.auth.getCountry().then(value => this.country = value)
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
    this.dataSourceDocFact = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocFacturas);
    this.columns = ['btns', 'num', 'rutAcuerdo', 'acuerdo', 'montoAcuerdo', 'cuentaAC', 'agrupador', 'rut', 'monto', 'glosa', 'codRef', 'folioRef', 'fechaRef', 'btns1'];
    this.columnsDoc = ['btns', 'num', 'nombreDoc', 'ceco', 'agrDoc', 'ctaCont', 'rutProv', 'montoDoc', 'codOrden'];
    this.auth.getUserTechKey().then(value => this.user_tech_key = value)
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
    this.title = 'Solicitud Factura'
    this.loading();
    (this.country == 'CL' || this.country == 'CO') ? true : false;
  }

  async loading() {
    this.spinner.show('creacionFacturas');
    this.spinnerMessage = "Cargando datos...";
    this.plazos.push({ value: 'Z030', descr: '30 días' })
    this.plazos.push({ value: 'Z045', descr: '45 días' })
    this.plazos.push({ value: 'Z060', descr: '60 días' })
    this.plazos.push({ value: 'Z090', descr: '90 días' })
    this.plazos.push({ value: 'Z120', descr: '120 días' })
    this.plazo = 'Z030'

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Monedas>(DropDownOptions.monedas)
        .then((data) => {
          this.monedas = data;
          data.forEach(ele => {
            if (ele.defecto == 'T') {
              this.moneda = ele.cod
            }
          })
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    //hago la carga de los parametros como si lo cargara en un dropdown pq lo puedo usar mas facil,
    //ya que todas las pantallas cargan los datos iniciales de esta forma y queda mas limpio el codigo.
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Parametros>(DropDownOptions.parametros)
        .then((data) => {
          this.parametros = data;
          this.parametros.forEach(param => {
            if (param.cparametro == 'IdMoneda' && param.valor == 'T') {
              this.paramMoneda = true;
            }
            if (param.cparametro == 'DELTARBT') {
              this.margenPermitido = CommonsModule.stringToNumber(param.valor.toString())
            }
          })
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let results = await Promise.allSettled([p1, p2])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('creacionFacturas')
  }

  private buscaLiquidacionPromise(datos: listaFacturas[]): Promise<listaFacturas[]> {
    return new Promise<listaFacturas[]>((resolve, reject) => {
      let errs: Error[] = []
      let datosMass: Interfaces.buscaLiquidacionMass[] = [];
      datos.forEach((ele, idx) => {
        datosMass.push({ seq: idx.toString(), acuerdo: ele.acuerdo })
      })

      //console.log(JSON.stringify(datosMass))
      this.commons.buscarLiquidacionMass(datosMass).subscribe({
        next: (validData) => {
          //console.log(JSON.stringify(validData))
          validData.forEach((factura, idx) => {
            if (factura.status == 'error') {
              errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${factura.acuerdo} no encontrado`));
            }
            else {

              let numParts = CommonsModule.numParts(this.country)
              let valorSeq: string = factura.secuencia.toString().replaceAll(numParts.miles, '')
              valorSeq = valorSeq.replaceAll(numParts.decimals, '.')

              let seq: number = CommonsModule.stringToNumber(valorSeq)
              /*respuesta desde api*/
              //console.log(datos[seq].glosaFactura)
              datos[seq].acuerdo = factura.acuerdo
              datos[seq].nombre_acuerdo = factura.nombre_acuerdo
              datos[seq].vendor_name = factura.vendor_name
              datos[seq].rut = factura.rut
              datos[seq].dv = factura.dv
              datos[seq].monto_liqui = factura.monto_liqui
              datos[seq].suma_liqui = factura.suma_liqui
              datos[seq].tipo_liqui = factura.tipo_liqui
              datos[seq].ceunta_contable = factura.ceunta_contable
              datos[seq].fecha_liqui = factura.fecha_liqui
              datos[seq].fullRut = factura.rut + '-' + factura.dv
              datos[seq].rutFactura = factura.rut + '-' + factura.dv

              /*datos desde excel*/
              datos[seq].seq = datos[seq].seq
              datos[seq].seq_dtr = datos[seq].seq_dtr
              datos[seq].seleccion = false
              datos[seq].agrupador = datos[seq].agrupador
              datos[seq].montoFactura = datos[seq].montoFactura.trim()
              datos[seq].glosaFactura = 'Acuerdo Comercial: ' + factura.acuerdo + ' ' + datos[seq].glosaFactura
              datos[seq].codRef = datos[seq].codRef
              datos[seq].folioRef = datos[seq].folioRef
              datos[seq].fechaRef = datos[seq].fechaRef
              datos[seq].btnAdd1 = true
              datos[seq].btnDel1 = true
              datos[seq].btnAdd2 = true
              datos[seq].btnDel2 = false
              datos[seq].addinput = true
              datos[seq].find = (factura.status) ? true : false;
            }
          })

          if (errs.length == 0) {
            resolve(datos)
          }
          else {
            reject(this.addLiquidacionError(errs).message)
          }
        },
        error: (err) => {
          reject(this.addLiquidacionError(errs).message)
        }
      })
    })
  }

  private addLiquidacionError(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }


  async buscarLiquidaciones(data: listaFacturas[]): Promise<boolean> {
    let errs: Error[] = [];

    return new Promise<boolean>((resolve, reject) => {
      this.buscaLiquidacionPromise(data)
        .then(listaFacturas => {

          this.addLiquidacionOnComplete(listaFacturas)
          resolve(true)
        }, reason => {
          //errs.push(reason)
          reject(reason)
          //reject(this.addProductError(errs))
        })
    })
  }


  private addLiquidacionOnComplete(data: listaFacturas[]): Error | null {
    this.ListadofacturasSeleccionadas = data
    //console.log(JSON.stringify(data))
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
    return null
  }

  buscaLiquidacionesMass(datos: listaFacturas[]): Promise<Interfaces.facturas[]> {
    return new Promise<Interfaces.facturas[]>((resolve, reject) => {
      this.commons.buscarLiquidacionMass(datos).subscribe({
        next: (validData) => {
          resolve(validData)
        },
        error: (err) => {
          reject(err)
        }
      })
    })
  }

  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    let fechaRef: string = ''
    if (file) {
      this.spinner.show('creacionFacturas');
      this.spinnerMessage = "Validando archivo Excel ...";
      this.ListadofacturasSeleccionadas = [];
      CommonsModule.readExcel<Interfaces.FacturaExcelStruct>(file)
        .then(data => {
          let cont: number = 0;
          let lista: listaFacturas[] = []
          data.forEach(ele => {
            cont++;
            let monto: string = '';
            if (ele['MONTO'] != undefined) {
              let numParts = CommonsModule.numParts(this.country)
              let valorExcel: string = ele.MONTO.toString().replaceAll(numParts.miles, '')
              valorExcel = valorExcel.replaceAll(numParts.decimals, '.')
              let montoExcel: number = CommonsModule.stringToNumber(valorExcel)
              if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                montoExcel = Math.round(montoExcel)
              }
              monto = this.commonService.transformarMonto(this.country, montoExcel.toString(), 'monto');
            }
            else {
              monto = ''
            }
            fechaRef = ''
            if (ele['FECHA REFERENCIA'] != undefined) {
              fechaRef = CommonsModule.funcDateExcel(CommonsModule.stringToNumber(ele['FECHA REFERENCIA'].toString()))
            }

            lista.push({
              acuerdo: (ele['ACUERDO'] == undefined) ? '' : ele['ACUERDO'].toString(),
              addinput: false,
              agrupador: (ele['AGRUPADOR'] == undefined) ? '' : ele['AGRUPADOR'].toString(),
              btnAdd1: false,
              btnAdd2: false,
              btnDel1: false,
              btnDel2: false,
              ceunta_contable: '',
              codRef: (ele['CODIGO REFERENCIA'] == undefined) ? '' : ele['CODIGO REFERENCIA'].toString(),
              dv: '',
              fecha_liqui: '',
              fechaRef: fechaRef,
              folioRef: (ele['N° REFERENCIA'] == undefined) ? '' : ele['N° REFERENCIA'].toString(),
              fullRut: '',
              glosaFactura: (ele['GLOSA'] == undefined) ? '' : ele['GLOSA'].toString(),
              monto_liqui: '0',
              montoFactura: monto,
              nombre_acuerdo: '',
              rut: '',
              rutFactura: (ele['RUT'] == undefined) ? '' : ele['RUT'].toString(),
              seleccion: false,
              seq: cont.toString(),
              seq_dtr: '1',
              suma_liqui: '0',
              tipo_liqui: '',
              vendor_name: '',
              find: false,
              apendice: false,
              secuencia: cont.toString(),
              status: ""
            });
          })
          this.buscarLiquidaciones(lista)
            .catch(err => {

              this.spinner.hide('creacionFacturas')
              this.commonService.error(errors.LoadingExcelError.message, err, 'Aceptar', true)
            })
            .finally(() => {
              //console.log(JSON.stringify(lista))
              this.spinner.hide('creacionFacturas')
            })
        })
        .catch(reason => {
          this.spinner.hide('creacionFacturas')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }

  guardarFactura() {
    this.spinner.show('creacionFacturas')
    this.spinnerMessage = "Realizando Guardado..."
    this.doGuardar()
      .then(_ => this.commonService.alert('accent', "Proceso Exitoso", 'Proceso se ha realizado con éxito', 'Aceptar', true).subscribe({
        complete: () => {
          window.location.reload()
        }
      }))
      .catch(reason => {
        this.commonService.error("Error", reason, 'Aceptar', true)
      })
      .finally(() => { this.spinner.hide('creacionFacturas'); })
  }

  async doGuardar(): Promise<boolean> {
    let montoMoneda: number = 0
    let valorMoneda = CommonsModule.strToNum(this.country, this.valorMoneda, 'moneda')
    if (!valorMoneda.decimalFound) {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles)
    }
    else {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles + valorMoneda.decimalPart + valorMoneda.decimales)
    }
    return new Promise<boolean>((resolve, reject) => {
      let erroresValidacion: Error[] = this.validaPreGuardado();
      if (erroresValidacion.length === 0) {
        let fact: Interfaces.crearFactura[] = []
        let acuerdo: string = ''
        let cuentaAc: string = ''
        let agr: string = ''
        let rutFact: string = ''
        let montoFactura: number
        let finRegistro: string = 'F'
        this.ListadofacturasSeleccionadas.forEach((ele, index) => {
          let seq: number = index + 1;
          let apendice: number = 1;
          let inHijo: string = '0'
          if (ele.acuerdo != '') {
            //significa que es un apendice de la fila anterior
            acuerdo = ele.acuerdo;
            cuentaAc = ele.ceunta_contable
            agr = ele.agrupador
            const find = ele.rutFactura.indexOf('-')
            if (find != -1) {
              //encontro el -
              rutFact = ele.rutFactura.split('-')[0].trim()
            }
            else {
              rutFact = ele.rutFactura.trim()
            }

            let valorFactura = CommonsModule.strToNum(this.country, ele.montoFactura, 'montoFactura')
            if (!valorFactura.decimalFound) {
              montoFactura = CommonsModule.stringToNumber(valorFactura.miles)
            }
            else {
              montoFactura = CommonsModule.stringToNumber(valorFactura.miles + valorFactura.decimalPart.replaceAll(valorFactura.decimalPart, ".") + valorFactura.decimales)
            }
          }
          else {
            apendice++;
            inHijo = '1'
          }
          if (this.ListadofacturasSeleccionadas.length == index + 1) {
            finRegistro = 'T'
          }


          fact.push(
            {
              secuencia: seq.toString(),
              nacuerdo: acuerdo.toString(),
              cuenta_ac: cuentaAc,
              agrupador: agr,
              rut: rutFact, //rut sin dv
              monto: (montoMoneda == 0) ? montoFactura.toString() : (montoFactura * montoMoneda).toFixed(2), //montoFactura * valor moneda, redondeado
              doc_ref: ele.codRef,
              folio_ref: ele.folioRef,
              fecha_solicitud: ele.fechaRef,
              seq_dtr: apendice.toString(),
              tipo_pago: this.plazo,
              motivo: (this.checkNc) ? 'A90' : 'A28',
              usr_tech_key: this.user_tech_key,
              glosa: ele.glosaFactura,
              in_hijo: inHijo,
              fin_registro: finRegistro,
              tipo_impuesto: "I",
              moneda: this.moneda,
              monto_conversion: montoMoneda.toString(),
              monto_original: montoFactura.toString(), //monto de la factura redondeado
              documento: this.armarDoc(this.listadoDocFacturas)
            })
        })
        if (environment.debug) {
          console.log(JSON.stringify(fact))
        }
        //reject(JSON.stringify(acrdo))
        this.postGuardado(fact)
          .then(data => resolve(data))
          .catch(reason => {
            if (reason instanceof HttpErrorResponse) reject(this.showErrors([new Error(reason.message)]))
            else reject(reason)
          })

      }
      else {
        reject(this.showErrors(erroresValidacion).message)
      }
    })
  }

  private showErrors(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }


  private validaPreGuardado(): Error[] {
    let erroresFormulario: Error[] = []
    //tengo que validar la moneda que viene por defecto
    if (this.paramMoneda) {
      let currXDef = this.monedas.filter(ele => ele.defecto = 'T')
      if (currXDef[0].cod != this.moneda && this.valorMoneda == '') {
        erroresFormulario.push(errors.missingCurrency);
        return erroresFormulario
      }
    }

    //valida facturas
    if (this.ListadofacturasSeleccionadas.length > 0) {
      let montoMax: number = 0;
      let acuerdo: string = ''
      for (let fac of this.ListadofacturasSeleccionadas) {
        if (!fac.apendice) { //si no es un apendice de la fila anterior.
          if (fac.agrupador == '') {
            erroresFormulario.push(new Error(`Debe ingresar agrupador en factura`));
          }
          if (fac.rutFactura == '') {
            erroresFormulario.push(new Error(`Debe ingresar rut en factura`));
          }
          if (fac.montoFactura == '') {
            erroresFormulario.push(new Error(`Debe ingresar monto en factura`));
          }
          else {
            if (acuerdo != fac.acuerdo && fac.acuerdo != '') {
              acuerdo = fac.acuerdo;
              if (!this.validaMontoMax(acuerdo)) {
                erroresFormulario.push(new Error(`Montos ingresados para acuerdo ${acuerdo} son mayores que el máximo permitido`));
              }
            }
          }
        }
        if (fac.glosaFactura == '') {
          //erroresFormulario.push(new Error(`Debe ingresar glosa en factura`));
        }
        else {
          if (fac.glosaFactura.length > 132) {
            erroresFormulario.push(new Error(`Glosa debe ser menor que 132 caracteres`));
          }
        }
        if (fac.codRef == '') {
          //erroresFormulario.push(new Error(`Debe ingresar código referencia en factura`));
        }
        if (fac.folioRef == '') {
          //erroresFormulario.push(new Error(`Debe ingresar folio referencia en factura`));
        }
        if (fac.fechaRef == '') {
          //erroresFormulario.push(new Error(`Debe ingresar fecha referencia en factura`));
        }
        else {
          if (!CommonsModule.dateIsValid(fac.fechaRef)) {
            erroresFormulario.push(new Error(`Debe ingresar fecha referencia válida en factura`));
            fac.fechaRef = '';
          }
        }
        if (erroresFormulario.length > 0) {
          break;
        }
      }
    }
    else {
      erroresFormulario.push(errors.missingFacturas)
    }

    if (erroresFormulario.length == 0) {
      //valida documentos cuando el chechDoc está activado
      if (this.checkDoc && this.listadoDocFacturas.length == 0) {
        erroresFormulario.push(errors.missingDetDoc)
      }
      else {
        for (let docFact of this.listadoDocFacturas) {
          if (docFact.nombre == '') {
            erroresFormulario.push(new Error(`Debe ingresar nombre en documento`));
            break;
          }
          if (docFact.ceco == '') {
            erroresFormulario.push(new Error(`Debe ingresar CECO en documento`));
            break;
          }
          if (docFact.ctaCont == '') {
            erroresFormulario.push(new Error(`Debe ingresar cuenta contable en documento`));
            break;
          }
          if (docFact.rutProv == '') {
            erroresFormulario.push(new Error(`Debe ingresar rut proveedor en documento`));
            break;
          }
          if (docFact.monto == '') {
            erroresFormulario.push(new Error(`Debe ingresar monto en documento`));
            break;
          }
          if (docFact.agrupador == '') {
            erroresFormulario.push(new Error(`Debe ingresar agrupador en documento`));
            break;
          }
          const findAgr = this.ListadofacturasSeleccionadas.findIndex(function (item) {
            return item.agrupador == docFact.agrupador
          })
          if (findAgr == -1) {
            erroresFormulario.push(new Error(`Debe ingresar agrupador válido en documento`));
            break;
          }
          const findRut = this.ListadofacturasSeleccionadas.findIndex(function (item) {
            return item.fullRut == docFact.rutProv
          })
          if (findRut == -1) {
            erroresFormulario.push(new Error(`Debe ingresar rut de proveedor válido en documento`));
            break;
          }
        }
      }
    }
    return erroresFormulario;
  }

  private postGuardado(fact: Interfaces.crearFactura[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let respuesta: Interfaces.GuardarFacturaRespuesta;
      this.srv.callBackendPost<Interfaces.GuardarFacturaRespuesta>(`/liquidaciones/factura`, fact).subscribe({
        next: (data) => respuesta = data,
        error: (err) => reject(err),
        complete: () => {
          if (respuesta.descripcion == 'Proceso exitoso') {
            console.log(respuesta.descripcion)
            resolve(true)
          } else {
            console.log(respuesta.error)
            reject(errors.ErrorGuardadoFactura)
          }
        }
      })
    })
  }

  armarDoc(lista: Interfaces.documentFacturas[]): string {
    return lista.reduce((prv, curr) => {
      let monto: number = CommonsModule.stringToNumber(curr.monto)
      prv += `${curr.nombre}|${curr.ceco}|${curr.agrupador}|${curr.ctaCont}|${curr.rutProv.trim()}|${monto}|${curr.codOrden}|`
      //: `${curr.nombre}|${curr.ceco}|${curr.agrupador}|${curr.ctaCont}|${curr.rutProv.trim()}|${monto}|${curr.codOrden}|`
      return prv
    }, "")
  }

  // validaMonto(indice: number) {
  //   const filterPipe = new CountryFormatPipe();
  //   let valor: string = this.listadoDocFacturas[indice].monto
  //   const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);

  //   valor = valor.replaceAll(".", "@")
  //   valor = valor.replaceAll(",", ".")
  //   valor = valor.replaceAll("@", "")
  //   let num: number = CommonsModule.stringToNumber(valor)
  //   valor = filterPipe.transform(num, this.country,'',this.redondeo)
  //   this.listadoDocFacturas[indice].monto = valor
  // }




  // validaMontoFactura(indice: number) {
  //   let acuerdo: string = '';
  //   let monto_liqui: string = '';
  //   const filterPipe = new CountryFormatPipe();

  //   acuerdo = this.ListadofacturasSeleccionadas[indice].acuerdo
  //   monto_liqui = this.ListadofacturasSeleccionadas[indice].monto_liqui

  //   monto_liqui = monto_liqui.replaceAll(".", "@")
  //   monto_liqui = monto_liqui.replaceAll(",", ".")
  //   monto_liqui = monto_liqui.replaceAll("@", "")

  //   let montoMax: number = CommonsModule.stringToNumber(monto_liqui)
  //   let valor: string = this.ListadofacturasSeleccionadas[indice].montoFactura

  //   valor = valor.replaceAll(".", "@")
  //   valor = valor.replaceAll(",", ".")
  //   valor = valor.replaceAll("@", "")

  //   let num: number = CommonsModule.stringToNumber(valor)
  //   valor = filterPipe.transform(num, this.country, '', this.redondeo)
  //   this.ListadofacturasSeleccionadas[indice].montoFactura = valor
  //   if (!this.validaMontoMax(acuerdo, montoMax)) {
  //     this.ListadofacturasSeleccionadas[indice].montoFactura = filterPipe.transform(0, this.country, '', this.redondeo, '')
  //   }
  // }

  refrescar(indice: number) {
    let cont: number = 0
    let lista: listaFacturas[] = [];


    for (let fact of this.ListadofacturasSeleccionadas) {
      if (cont === indice) {
        let valor: number = CommonsModule.stringToNumber(fact.montoFactura)
        if (valor > 100000) {
          alert("si")
          fact.montoFactura = "0"
        }
      }
      lista.push(fact)
      cont++;
    }
    this.dataSource = new MatTableDataSource<listaFacturas>(lista)
  }


  Numero(valor: string): number {
    valor = valor.toString().replaceAll(".", "@")
    valor = valor.toString().replaceAll(",", ".")
    valor = valor.toString().replaceAll("@", "")
    return CommonsModule.stringToNumber(valor)
  }

  agregarApendice(ele: listaFacturas, index: number) {
    this.ListadofacturasSeleccionadas.splice(index + 1, 0, {
      seq: "",
      seq_dtr: "",
      acuerdo: "",
      ceunta_contable: "",
      dv: "",
      fecha_liqui: "",
      fullRut: "",
      monto_liqui: "",
      nombre_acuerdo: "",
      rut: "",
      suma_liqui: "",
      tipo_liqui: "",
      vendor_name: "",
      seleccion: false,
      agrupador: "",
      rutFactura: "",
      montoFactura: "",
      glosaFactura: "",
      codRef: (this.checkNc) ? '33' : '',
      folioRef: '',
      fechaRef: '',
      btnAdd1: false,
      btnDel1: false,
      btnAdd2: false,
      btnDel2: true,
      addinput: false,
      find: true,
      apendice: true,
      secuencia: "",
      status: "ok"
    })
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
  }

  agregarSeq(ele: listaFacturas, index: number) {
    let secuencia = CommonsModule.stringToNumber(ele.seq.toString()) + 1;
    this.ListadofacturasSeleccionadas.splice(index + 1, 0, {
      seq: secuencia.toString(),
      seq_dtr: "1",
      acuerdo: ele.acuerdo,
      ceunta_contable: ele.ceunta_contable,
      dv: ele.dv,
      fecha_liqui: ele.fecha_liqui,
      fullRut: ele.fullRut,
      monto_liqui: ele.monto_liqui,
      nombre_acuerdo: ele.nombre_acuerdo,
      rut: ele.rut,
      suma_liqui: ele.suma_liqui,
      tipo_liqui: ele.tipo_liqui,
      vendor_name: ele.vendor_name,
      seleccion: false,
      agrupador: "",
      rutFactura: ele.fullRut,
      montoFactura: "",
      glosaFactura: ele.glosaFactura,
      codRef: (this.checkNc) ? '33' : '',
      folioRef: '',
      fechaRef: '',
      btnAdd1: false,
      btnDel1: true,
      btnAdd2: true,
      btnDel2: false,
      addinput: true,
      find: true,
      apendice: false,
      secuencia: "",
      status: "ok"
    })
    this.reordenarSecuencia();
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
  }

  reordenarSecuencia() {
    let secuencia = 1;
    this.ListadofacturasSeleccionadas.forEach(ele => {
      if (ele.fullRut != '') {
        ele.seq = secuencia.toString();
        secuencia++;
      }
    })
  }

  check() {
    if (this.checkNc) {
      this.ListadofacturasSeleccionadas.forEach(fact => {
        fact.codRef = '33'
      })
    }
    else {
      this.ListadofacturasSeleccionadas.forEach(fact => {
        fact.codRef = ''
      })
    }
  }

  deleteApendice(i: number) {
    this.ListadofacturasSeleccionadas.splice(i, 1)
    if (this.ListadofacturasSeleccionadas.length == 0) {
      this.ListadofacturasSeleccionadas = [];
    }
    this.reordenarSecuencia();
    this.dataSource = new MatTableDataSource<listaFacturas>(this.ListadofacturasSeleccionadas);
  }

  checkDto() {
    this.listadoDocFacturas = [];
    if (this.checkDoc) {
      this.listadoDocFacturas.splice(1, 0, {
        nombre: '',
        ceco: '',
        agrupador: '',
        ctaCont: '',
        rutProv: '',
        monto: '',
        codOrden: '',
        btnAdd1: true,
        btnDel1: false
      })
    }
    this.dataSourceDocFact = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocFacturas);
  }

  agregarDoc(ele: listaFacturas, index: number) {
    this.listadoDocFacturas.splice(index + 1, 0, {
      nombre: '',
      ceco: '',
      agrupador: '',
      ctaCont: '',
      rutProv: '',
      monto: '',
      codOrden: '',
      btnAdd1: false,
      btnDel1: true
    })
    this.dataSourceDocFact = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocFacturas);
  }

  deleteDoc(i: number) {
    this.listadoDocFacturas.splice(i, 1)
    if (this.listadoDocFacturas.length == 0) {
      this.listadoDocFacturas = [];
    }
    this.dataSourceDocFact = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocFacturas);
  }

  validaMontoMax(acuerdo: string): boolean {
    //los acuerdos que se suman en '', no son considerados porque no se les puede agregar monto, 
    //y cuando se agregan acuerdos a la grilla que si tienen acuerdo ingresado, estos ya quedan con el monto liqui copiado del anterior.

    let sumaTotal: number = 0;
    let validado: boolean = false;
    let montoMax: number = 0
    let valorFac: number = 0
    let montoMoneda: number = 0

    let valorMoneda = CommonsModule.strToNum(this.country, this.valorMoneda, 'montoLiqui')
    if (!valorMoneda.decimalFound) {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles)
    }
    else {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles + valorMoneda.decimalPart.replaceAll(valorMoneda.decimalPart, ".") + valorMoneda.decimales)
    }

    this.ListadofacturasSeleccionadas.forEach((fac, indx) => {
      if (acuerdo == fac.acuerdo) {
        let monto_liqui = CommonsModule.strToNum(this.country, fac.monto_liqui, 'montoLiqui')
        if (!monto_liqui.decimalFound) {
          montoMax = CommonsModule.stringToNumber(monto_liqui.miles)
        }
        else {
          montoMax = CommonsModule.stringToNumber(monto_liqui.miles + monto_liqui.decimalPart.replaceAll(monto_liqui.decimalPart, ".") + monto_liqui.decimales)
        }

        let monto_fact = CommonsModule.strToNum(this.country, fac.montoFactura, 'montoLiqui')
        if (!monto_fact.decimalFound) {
          valorFac = CommonsModule.stringToNumber(monto_fact.miles)
        }
        else {
          valorFac = CommonsModule.stringToNumber(monto_fact.miles + monto_fact.decimalPart.replaceAll(monto_liqui.decimalPart, ".") + monto_fact.decimales)
        }
        sumaTotal += (montoMoneda > 0) ? Math.round(valorFac * montoMoneda) : valorFac;
      }
    })

    montoMax += this.margenPermitido;
    (montoMax < sumaTotal) ? validado = false //significa que montos ingresados son mayores que el monto maximo permitido
      : validado = true;
    return validado
  }


  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode==='c')) {
      //console.log("si")
    }
    else{
      if ($event.metaKey && (charCode==='v' || charCode==='c')){
        //console.log("si command")
      }
      else{
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }    
      }
    }
  }

  onKeyUp($event: any, variable: string, indice: number) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
      switch (variable) {
        case 'moneda':
          this.valorMoneda = this.commonService.transformarMonto(this.country, this.valorMoneda, 'moneda')
          break
        case 'monto':
          this.listadoDocFacturas[indice].monto = this.commonService.transformarMonto(this.country, this.listadoDocFacturas[indice].monto, 'monto')
          break;
        case 'montoFactura':
          this.ListadofacturasSeleccionadas[indice].montoFactura = this.commonService.transformarMonto(this.country, this.ListadofacturasSeleccionadas[indice].montoFactura, 'monto')
          let acuerdo: string = this.ListadofacturasSeleccionadas[indice].acuerdo
          if (!this.validaMontoMax(acuerdo)) {
            this.ListadofacturasSeleccionadas[indice].montoFactura = ""
          }
          break;

      }
    // }
  }

}
