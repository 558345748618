import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {


    constructor(protected override router: Router, protected override keycloakAngular: KeycloakService, private auth: AuthService) {
        super(router, keycloakAngular)
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloakAngular.login({
                redirectUri: window.location.href
            })
        }

        const country = await this.auth.getCountry()
        const requiredRoles = route.data["roles"];
        const validCountries = route.data["countries"];

        if ((validCountries instanceof Array)) {
            let isValidCountry = validCountries.includes(country.toUpperCase())
            if (validCountries.length === 0) {
                isValidCountry = true
            }
            if (!isValidCountry) {
                return false
            }
        }

        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true
        }

        return requiredRoles.some((role) => this.roles.includes(role))
    }
}