import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { ActivatedRoute } from '@angular/router';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { Subscription } from 'rxjs';
import { errors } from 'src/app/constants/constants.errors';

/* 
  reglas para la creacion masiva
  1. el monto nuevo del acuerdo no puede ser mayor al monto del acuerdo orignal, si es mayor entonces la fila queda en rojo.
     y no se puede crear ningún acuerdo.
  2. si el monto es menor en el procedimiento almacenado quedará.
     si el valor es menor al porcentaje creado como parámetro en la tabla de parámetros entonces tiene que 
        colocar el monto nuevo entre los 3 productos que tengan mayor cantidad de unidades.
     si el monto es mayor a ese porcentaje de parametría entonces, el monto debe divirlo entre todos los productos en partes iguales.
*/

interface listAcuMasiv extends Interfaces.ListAcuCopiaMasiva { }

@Component({
  selector: 'app-copia-masiva',
  templateUrl: './copia-masiva.component.html',
  styleUrls: ['./copia-masiva.component.scss']
})
export class CopiaMasivaComponent {
  private subscription!: Subscription;
  public columns: string[];
  public country!: string;
  public spinnerMessage: string = ''
  public listAcuerdos: listAcuMasiv[] = [];
  public dataSource: MatTableDataSource<listAcuMasiv>;
  public title!: string;
  private user_tech_key!: string;
  public tipoRecupero: Interfaces.TipoRecuperoOptions[] = [{ c_tprecupero: '', a_tprecupero: '' }]
  public cmTipoRecupero: number = 0
  public fileUp: number = 0;
  public parametros: Interfaces.Parametros[] = [];
  public paramporc: number = 0;
  public paramporcConf: boolean= false;
  public crearAcuerdo: boolean = false;
  public fileBase64: string = ''
  public nombreArchivo: string = ''


  constructor(
    private activatedroute: ActivatedRoute,
    private rbtService: RebateBackendService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsModule) {
    this.auth.getCountry().then(value => this.country = value)
    this.columns = ['n_acuerdo', 'mesAcuerdo', 'cuenta', 'proveedor', 'montoAcu', 'montoNuevo', 'usuario', 'subLinea', 'nomAcuerdoNuevo', 'maliBoni'];
    this.dataSource = new MatTableDataSource<listAcuMasiv>(this.listAcuerdos);

    this.auth.getUserTechKey().then(valor => this.user_tech_key = valor)
  }

  ngOnInit() {
    this.listAcuerdos = [];
    this.setTitle();
    this.loading().then(
      () => {
        if (this.paramporc==0) {
          this.commonService.error("Error: Parámetro Porcentaje acuerdo no se encuentra configurado", "Configure el porcentaje de acuerdos para realizar copias masivas", 'Aceptar', true)
          this.paramporcConf=false
        }
        else{
          this.paramporcConf=true;
        }
      }
    )
    this.auth.getCountry().then(value => {
      this.country = value
    })
  }

  private setTitle() {
    this.subscription = this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }


  async loading() {
    this.spinner.show('copiaMasiva');
    this.spinnerMessage = "Cargando datos...";

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Parametros>(DropDownOptions.parametros)
        .then((data) => {
          this.parametros = data;
          this.parametros.forEach(param => {
            if (param.cparametro == 'PORCEACU') {
              this.paramporc = CommonsModule.stringToNumber(param.valor.toString())
            }
          })
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoRecuperoOptions>(DropDownOptions.tipoRecupero)
        .then((data) => {
          this.tipoRecupero = data;
          res()
          this.cmTipoRecupero = CommonsModule.stringToNumber(this.tipoRecupero[0].c_tprecupero.toString());
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })


    let results = await Promise.allSettled([p1, p2])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }

    this.spinner.hide('copiaMasiva')

  }

  cargarMail(event: any) {
    this.spinnerMessage = 'Cargando Archivo...'
    this.spinner.show()
    const file: File = event.target.files[0];
    if (file) {

      let nombreArch: string = file.name;
      let ext = nombreArch.split('.');
      if (ext.length > 1) {
        let extension = ext[ext.length - 1];
        if (extension == 'eml' || extension == 'msg') {
          //this.acuerdo.cargaArchivo = true;
          this.fileUp = 1;
          CommonsModule.convertFile(file).subscribe({
            next: base64string => {
              this.fileBase64 = base64string
              this.nombreArchivo = nombreArch;
            },
            error: err => { this.commonService.error('Error', err, 'Aceptar', true); this.spinner.show() },
            complete: () => this.spinner.hide()
          })

        }
        else {
          this.commonService.error('Error', 'Sólo se aceptan archivos eml o msg', 'Aceptar', true)
        }
      }
      else {
        this.commonService.error('Error', 'Archivo no válido', 'Aceptar', true)
      }

    }
  }

  exportExcel() {
    let rows: Object[] = [];
    this.listAcuerdos.forEach(ele => {
      rows.push({
        "Acuerdo Original": ele.nAcuerdo,
        "Mes Acuerdo": ele.mes,
        "Cuenta": ele.cuenta,
        "Proveedor": ele.nombreProv,
        "Monto Original": ele.montoAcu,
        "Monto Nuevo": ele.montoAcuNuevo,
        "Usuario": ele.usuario,
        "Sublínea": ele.sublinea,
        "Nombre Acuerdo Nuevo": ele.nomAcuNuevo,
        "Malif/Bonif": ele.nAcuNuevo,
      })
    });
    CommonsModule.exportExcel(
      "acuerdosMasivos-" +
      Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(Date.now()) + "_" +
      Intl.DateTimeFormat('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(Date.now())
      , rows);
  }

  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.spinner.show('copiaMasiva');
      this.spinnerMessage = "Validando archivo Excel ...";
      this.listAcuerdos = []
      //console.log(JSON.stringify(data))
      this.dataSource = new MatTableDataSource<listAcuMasiv>(this.listAcuerdos);
      CommonsModule.readExcel<Interfaces.CopiaMasivaExcelStruct>(file)
        .then(data => {
          let cont: number = 0;
          let lista: listAcuMasiv[] = []
          data.forEach(ele => {
            cont++;
            let monto: string = '';
            if (ele['MONTO'] != undefined) {
              let numParts = CommonsModule.numParts(this.country)
              let valorExcel: string = ele.MONTO.toString().replaceAll(numParts.miles, '')
              valorExcel = valorExcel.replaceAll(numParts.decimals, '.')
              let montoExcel: number = CommonsModule.stringToNumber(valorExcel)
              if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                montoExcel = Math.round(montoExcel)
              }
              monto = this.commonService.transformarMonto(this.country, montoExcel.toString(), 'monto');
            }
            else {
              monto = ''
            }
            lista.push({
              seq: cont,
              nAcuerdo: (ele['ACUERDO'] != undefined) ? CommonsModule.stringToNumber(ele['ACUERDO'].toString().trim()) : 0,
              mes: '',
              cuenta: '',
              nombreProv: '',
              usuario: '',
              montoAcu: '',
              sublinea: (ele.SUBLINEA != undefined) ? ele.SUBLINEA.toString() : '',
              nomAcuNuevo: (ele.NOMBRE_NUEVO != undefined) ? ele.NOMBRE_NUEVO.toString().trim() : '',
              nAcuNuevo: 0,
              tpacuerdo: 0,
              montoAcuNuevo: monto.toString().trim(),
              status: ''
            })

          })

          this.buscarAcuerdosMasivos(lista)
            .catch(err => {
              this.spinner.hide('copiaMasiva')
              this.commonService.error(errors.LoadingExcelError.message, err, 'Aceptar', true)
            })
            .finally(() => {
              //console.log(JSON.stringify(lista))
              this.spinner.hide('copiaMasiva')
            })
          //this.spinner.hide('copiaMasiva')
        })
        .catch(reason => {
          this.spinner.hide('copiaMasiva')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }

  private buscaAcuerdoMasivPromise(datos: listAcuMasiv[]): Promise<listAcuMasiv[]> {
    return new Promise<listAcuMasiv[]>((resolve, reject) => {
      let errs: Error[] = []
      let datosMass: Interfaces.buscaAcuerdosMass[] = [];
      datos.forEach((ele, idx) => {
        if (ele.nAcuerdo == 0) {
          errs.push(new Error(`Fila ${idx + 2}: Número de acuerdo no ingresado`));
        }
        if (ele.sublinea.toString().length == 0) {
          errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${ele.nAcuerdo} no tiene ingresada la sublinea`));
        }
        if (ele.nomAcuNuevo.length == 0) {
          errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${ele.nAcuerdo} no tiene ingresado el nombre nuevo.`));
        }
        if (ele.montoAcuNuevo.length == 0) {
          errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${ele.nAcuerdo} no tiene monto nuevo`));
        }
        else {
          let numParts = CommonsModule.numParts(this.country)
          let valorMonto: string = ele.montoAcuNuevo.toString().replaceAll(numParts.miles, '')
          valorMonto = valorMonto.replaceAll(numParts.decimals, '.')

          let monto: number = CommonsModule.stringToNumber(valorMonto)
          if (monto == 0) {
            errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${ele.nAcuerdo} monto nuevo debe ser mayor a cero`));
          }
        }
        if (errs.length == 0) {
          //console.log(JSON.stringify(datos))
          datosMass.push({ seq: idx.toString(), acuerdo: ele.nAcuerdo.toString() })
        }
      })

      if (errs.length == 0) {
        this.commonService.buscarAcuerdoMass(datosMass).subscribe({
          next: (validData) => {
            //console.log(JSON.stringify(validData))
            validData.forEach((acuerdoMasiv, idx) => {
              if (acuerdoMasiv.status == 'error') {
                errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${acuerdoMasiv.nAcuerdo} no encontrado`));
              }
              else {
                let numParts = CommonsModule.numParts(this.country)
                let valorSeq: string = acuerdoMasiv.seq.toString().replaceAll(numParts.miles, '')
                valorSeq = valorSeq.replaceAll(numParts.decimals, '.')
                let seq: number = CommonsModule.stringToNumber(valorSeq)
                datos[seq].nAcuerdo = acuerdoMasiv.nAcuerdo
                datos[seq].mes = acuerdoMasiv.mes
                datos[seq].cuenta = acuerdoMasiv.cuenta
                datos[seq].nombreProv = acuerdoMasiv.nombreProv
                let valorExcel: string = acuerdoMasiv.montoAcu.toString().replaceAll(numParts.miles, '')
                valorExcel = valorExcel.replaceAll(numParts.decimals, '.')
                let montoAcu: number = CommonsModule.stringToNumber(valorExcel)
                if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                  montoAcu = Math.round(montoAcu)
                }
                datos[seq].montoAcu = this.commonService.transformarMonto(this.country, montoAcu.toString(), 'monto');
                datos[seq].usuario = acuerdoMasiv.usuario
                datos[seq].tpacuerdo = acuerdoMasiv.tpacuerdo
                datos[seq].status = acuerdoMasiv.status

                /*datos desde excel*/
                datos[seq].seq = datos[seq].seq
                datos[seq].sublinea = datos[seq].sublinea
                datos[seq].nomAcuNuevo = datos[seq].nomAcuNuevo
                datos[seq].montoAcuNuevo = datos[seq].montoAcuNuevo
              }
            })

            if (errs.length == 0) {
              //console.log(JSON.stringify(datos))
              resolve(datos)
            }
            else {
              reject(this.addAcuerdoMasivError(errs).message)
            }
          },
          error: (err) => {
            reject(this.addAcuerdoMasivError(errs).message)
          }
        })
      }
      else {
        reject(this.addAcuerdoMasivError(errs).message)
      }

    })
  }

  buscarAcuerdosMasivos(datos: listAcuMasiv[]): Promise<boolean> {
    let errs: Error[] = [];
    //console.log(JSON.stringify(datos))
    return new Promise<boolean>((resolve, reject) => {
      this.buscaAcuerdoMasivPromise(datos)
        .then(listaFacturas => {

          this.addBuscaAcuerdosOnComplete(listaFacturas)
          resolve(true)
        }, reason => {
          //errs.push(reason)
          this.crearAcuerdo = false
          reject(reason)
          //reject(this.addProductError(errs))
        })
    })
  }

  private addAcuerdoMasivError(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }

  private addBuscaAcuerdosOnComplete(data: listAcuMasiv[]): Error | null {
    this.listAcuerdos = data
    let numParts = CommonsModule.numParts(this.country)
    //console.log(JSON.stringify(data))
    this.listAcuerdos.forEach((ele, idx) => {
      let montoAcustring: string = ele.montoAcu.toString().replaceAll(numParts.miles, '')
      montoAcustring = montoAcustring.replaceAll(numParts.decimals, '.')
      let montoAcu: number = CommonsModule.stringToNumber(montoAcustring)

      let montoAcuNuevostring: string = ele.montoAcuNuevo.toString().replaceAll(numParts.miles, '')
      montoAcuNuevostring = montoAcuNuevostring.replaceAll(numParts.decimals, '.')
      let montoAcuNuevo: number = CommonsModule.stringToNumber(montoAcuNuevostring)

      if (montoAcu < montoAcuNuevo) {
        ele.status = 'error'
      }
    })
    this.dataSource = new MatTableDataSource<listAcuMasiv>(this.listAcuerdos);
    this.crearAcuerdo = true;
    return null
  }

  private addCreacionOnComplete(data: listAcuMasiv[]): Error | null {
    this.listAcuerdos = data
    this.listAcuerdos.forEach((ele, idx) => {
      if (ele.nAcuNuevo == 0) {
        ele.status = 'error'
      }
    })
    this.dataSource = new MatTableDataSource<listAcuMasiv>(this.listAcuerdos);
    this.crearAcuerdo = true;
    return null
  }



  private crearAcuerdoMass(datos: listAcuMasiv[]): Promise<listAcuMasiv[]> {
    let errs: Error[] = [];
    let numParts = CommonsModule.numParts(this.country)
    return new Promise<listAcuMasiv[]>((resolve, reject) => {
      let datosMass: Interfaces.ListCreaAcuMasiva[] = []

      datos.forEach((ele, idx) => {
        let montoAcustring: string = ele.montoAcuNuevo.toString().replaceAll(numParts.miles, '')
        montoAcustring = montoAcustring.replaceAll(numParts.decimals, '.')
        let montoAcu: number = CommonsModule.stringToNumber(montoAcustring)

        datosMass.push({
          seq: idx.toString(),
          acuerdo: ele.nAcuerdo.toString(),
          tipoAcu: ele.tpacuerdo.toString(),
          tipoRecupero: this.cmTipoRecupero.toString(),
          nombreAcuNuevo: ele.nomAcuNuevo,
          montoAcuNuevo: montoAcu.toString(),
          usuario: this.user_tech_key,
          nfile: this.nombreArchivo,
          file: this.fileBase64,
          status: "ok"
        })
      })

      this.commonService.crearAcuerdoMass(datosMass).subscribe({
        next: (validData) => {
          //console.log(JSON.stringify(validData))
          validData.forEach((creaAcuMasiv) => {
            datos.forEach(acuerdo => {
              if (acuerdo.nAcuerdo == CommonsModule.stringToNumber(creaAcuMasiv.nAcuerdo)) {
                acuerdo.nAcuNuevo = CommonsModule.stringToNumber(creaAcuMasiv.nAcuNuevo)
              }
            })
          })
          resolve(datos)
        },
        error: (err) => {
          //errs.push(new Error(`Fila ${idx + 2}: Acuerdo ${acuerdoMasiv.nAcuerdo} no encontrado`));
          errs.push(new Error(err.message));
          reject(this.addAcuerdoMasivError(errs).message)
        }
      })
    })

  }

  creaAcuMas(datos: listAcuMasiv[]): Promise<boolean> {
    //console.log(JSON.stringify(datos))
    return new Promise<boolean>((resolve, reject) => {
      this.crearAcuerdoMass(datos)
        .then(listaFacturas => {
          this.addCreacionOnComplete(listaFacturas)
          resolve(true)
        }, reason => {
          reject(reason)
          //reject(this.addProductError(errs))
        }
        )
    })
  }

  crearAcuerdos() {
    this.spinner.show('copiaMasiva');
    this.spinnerMessage = "Creando Acuerdos ...";
    let errs: Error[] = [];

    let numParts = CommonsModule.numParts(this.country)
    this.listAcuerdos.forEach((ele, idx) => {
      let montoAcustring: string = ele.montoAcu.toString().replaceAll(numParts.miles, '')
      montoAcustring = montoAcustring.replaceAll(numParts.decimals, '.')
      let montoAcu: number = CommonsModule.stringToNumber(montoAcustring)

      let montoAcuNuevostring: string = ele.montoAcuNuevo.toString().replaceAll(numParts.miles, '')
      montoAcuNuevostring = montoAcuNuevostring.replaceAll(numParts.decimals, '.')
      let montoAcuNuevo: number = CommonsModule.stringToNumber(montoAcuNuevostring)

      if (montoAcu < montoAcuNuevo) {
        errs.push(new Error(`Fila ${idx + 1}: Acuerdo ${ele.nAcuerdo} - Monto Nuevo no puede ser mayor al monto del acuerdo original.`));
      }
    })

    if (this.fileBase64.length === 0) {
      errs.push(errors.MissingFileRespaldo);
    }

    if (errs.length == 0) {
      this.creaAcuMas(this.listAcuerdos)
        .then(_ => {
          let msg: string = ''
          this.listAcuerdos.forEach(ele => {
            msg = msg + "Acuerdo Nº: " + ele.nAcuerdo + ' => Acuerdo Nº: ' + ele.nAcuNuevo + '<br>'
          })

          this.commonService.alert('accent', "Acuerdos Generados", msg, 'Aceptar', true).subscribe({
            complete: () => {
              this.crearAcuerdo = false
            },
            error: (err) => { alert('error') }
          })
        })
        .catch(err => {
          this.spinner.hide('copiaMasiva')
          this.commonService.error(errors.LoadingExcelError.message, err, 'Aceptar', true)
        })
        .finally(() => {
          //console.log(JSON.stringify(lista))
          this.spinner.hide('copiaMasiva')
        })
    }
    else {
      this.spinner.hide('copiaMasiva')
      this.commonService.error(errors.ErrorCreaAcuMasiv.message, this.addAcuerdoMasivError(errs).message, 'Aceptar', true)
    }
  }
}
