import { Component, OnInit, Inject } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-ver-bonificaciones-acuerdo',
  templateUrl: './ver-bonificaciones-acuerdo.component.html',
  styleUrls: ['./ver-bonificaciones-acuerdo.component.scss']
})
export class VerBonificacionesAcuerdoComponent {
  public spinnerMessage: string = "Loading ..."
  public title: string = 'Consulta Detalle Bonificación ';
  public country!: string;
  public verBonificaciones: Interfaces.ConsultaBonificacionesAcuerdo[] = [{ 
    secuencia: 0,
    nacuerdo: 0,
    sku: '',
    name_full: '',
    aporte: 0,
    topemonto: 0,
    topeunidades: 0,
    bonificacionTotal: 0,
    unidadesBonificadas: 0
  }];
  public readonly cabeceraTabla: string[] = ['num', 'acuerdo', 'sku', 'descrSku', 'aporte', 'topeMnt', 'topeUni', 'bnfTotal', 'UnidadesBnf'];
  public datosAcuerdo:string='';

  constructor(
    public dialogRef: MatDialogRef<VerBonificacionesAcuerdoComponent>,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public dataAcuerdo: Interfaces.verBonificaciones,

  ) {
    this.auth.getCountry().then(value => this.country = value)
    this.title=  this.title + dataAcuerdo.n_acuerdo + ' ' + dataAcuerdo.descrAcuerdo;

  }

  ngOnInit(): void {
    this.buscarHistorial(this.dataAcuerdo.n_acuerdo)
  }

  buscarHistorial(nAcuerdo:number){
    this.spinner.show('verBonificaciones')
    this.srv.callBackendGet<Interfaces.ConsultaBonificacionesAcuerdo[]>(`/detalleBonificacion/${nAcuerdo}`).subscribe({
      next: listaBonificaciones =>{
        this.verBonificaciones=listaBonificaciones
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.BonificacionesAcuerdoNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
          this.commons.error('Error', errors.BonificacionesAcuerdoNoEncontradas.message, 'Aceptar', true) :
          this.commons.error(errors.BonificacionesAcuerdoNoEncontradas.message, err.message, 'Aceptar', true);
        }
        this.verBonificaciones=[];
        this.spinner.hide('verBonificaciones')
      },
      complete: ()=>{
        this.spinner.hide('verBonificaciones')
      }
    }
    )
  }

  exportExcel() {
    let rows: Object[] = [];

    this.verBonificaciones.forEach((element,idx) => {
      rows.push({
        '#': idx +1,
        "Acuerdo": element.nacuerdo,
        "Jerarquía/Sku": element.sku,
        "Descr.Jer/Sku": element.name_full,
        "Aporte $": element.aporte,
        "Tope Monto $": 0,
        "Tope Unidades": element.topeunidades,
        "Bonificación Total $": element.bonificacionTotal,
        "Unidades Bonificadas": element.unidadesBonificadas
      })
    });
    CommonsModule.exportExcel(
      "Consulta Detalle Bonificación " + this.dataAcuerdo.n_acuerdo
      , rows);
  }

}

