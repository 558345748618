<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'searchSpinner'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<h1 mat-dialog-title>Seleccionar Proveedor</h1>
<div mat-dialog-content>
  <table aria-describedby="Buscador" class="widthTable">
    <tr>
      <th></th>
    </tr>
    <tr>
      <td class="ancho30">
        <mat-form-field appearance="outline" [floatLabel]="'always'" class="ancho100">
          <mat-label>Rut</mat-label>
          <input matInput type="text" placeholder="Ingrese rut" [(ngModel)]="rutProveedor">
        </mat-form-field>
      </td>
      <td class="ancho70">
        <mat-form-field appearance="outline" [floatLabel]="'always'" class="ancho100">
          <mat-label>Nombre</mat-label>
          <input matInput type="text" placeholder="Ingrese Nombre" [(ngModel)]="nombreProveedor">
        </mat-form-field>
      </td>
      <td>
        <button mat-raised-button color="primary" (click)="buscarProveedores()">Filtrar</button>
      </td>
    </tr>
  </table>
  <table mat-table [dataSource]="dataSource" aria-describedby="Resultados">
    <ng-container matColumnDef="nrut">
      <th mat-header-cell *matHeaderCellDef>Rut/Ciut</th>
      <td mat-cell *matCellDef="let element"> {{element.proveedor.nrut}} </td>
    </ng-container>
    <ng-container matColumnDef="vendor_name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let element"> {{element.proveedor.vendor_name}} </td>
    </ng-container>
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">

      <ng-container matColumnDef="seleccion">
        <th mat-header-cell *matHeaderCellDef>Seleccionado</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            <mat-radio-button [value]="element.seleccion" (change)="onCheckChange(element)"></mat-radio-button>
          </ng-container>
        </td>
      </ng-container>
    </mat-radio-group>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" [mat-dialog-close]="null">Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial *ngIf="seleccionado">Ok</button>
</div>