import { read, utils, writeFile } from 'xlsx';
import { Injectable } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { Observable, config } from 'rxjs';
import { errors } from 'src/app/constants/constants.errors';
import { RebateBackendService } from '../rebate/rebate.backend.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ShowDataComponent } from 'src/app/components/show-data/show-data.component';
import { dropDownRoutes } from 'src/app/constants/contants.dropdown'
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { CountryFormatPipe } from 'src/app/components/pipes/countryFormat.pipe';

const excelFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

export enum SearchType {
  TIENDA,
  LOCAL
}

export enum DropDownOptions {
  Organizacion = 'Organizacion',
  TipoAcuerdo = 'TipoAcuerdo',
  SubTipoAcuerdo = 'SubTipoAcuerdo',
  TipoLiquidacion = 'TipoLiquidacion',
  TipoAgrupador = 'TipoAgrupador',
  tipoRecupero = 'TipoRecupero',
  tipoEstado = 'TipoEstado',
  linea = 'Linea',
  sublinea = 'SubLinea',
  monedas = 'Monedas',
  parametros = 'Parametros',
  claseDoc = 'ClaseDoc',
  tipoImpuesto = 'TipoImpuesto',
  tipoRetencion = 'TipoRetencion',
  tipoDenominacion = 'TipoDenominacion'
}
@Injectable({
  providedIn: 'root',
})

export class CommonsModule {

  constructor(private dialog: MatDialog, private backend: RebateBackendService, private countryPipe: CountryFormatPipe) { }

  /**
   * Load Excel file into an interface arrsy and return a promise
   * @param file Excel file to read
   * @returns Promise with an array of rows, each rows type is <T>
   */
  static async readExcel<T>(file: File): Promise<T[]> {
    return new Promise((resolve, reject) => {
      if (!excelFileTypes.includes(file.type)) reject(new Error("Documento debe ser planilla Excel (.xlsx | .xls)"))
      else {
        file.arrayBuffer().then(data => {
          const wb = read(data)
          const sheetData = utils.sheet_to_json<T>(wb.Sheets[wb.SheetNames[0]], {
            raw: true,
            dateNF: 'dd"/"mm"/"YYYY'
          })
          resolve(sheetData)
        }).catch(reason => {
          reject(reason)
        })
      }
    })
  }

  alert(color: ThemePalette = 'primary', title: string = '', message: string = '', titleButton: string = '', mustNotClose: boolean = false): Observable<any> {
    let instance = this.dialog.open(AlertDialogComponent, {
      width: '75%', disableClose: mustNotClose, data: {
        color: color,
        title: title,
        message: message,
        buttonTitle: titleButton
      }
    });
    return instance.afterClosed()
  }

  error(title: string = '', message: string = '', titleButton: string = '', mustNotClose: boolean = false) {
    this.alert('warn', title, message, titleButton, mustNotClose)
  }

  openDialog<T, S>(component: any, instanceValue?: {}, config?: { disableClose?: boolean, autoFocus?: boolean, height?: string, width?: string, data?: S }): Observable<T> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = config?.disableClose;
    dialogConfig.autoFocus = config?.autoFocus;
    dialogConfig.height = config?.height;
    dialogConfig.width = (config?.width == undefined) ? '95%' : config?.width;
    dialogConfig.data = config?.data;
    dialogConfig.maxHeight = '100%';
    dialogConfig.maxWidth = '100%';
    const dialogRef = this.dialog.open(component, dialogConfig);
    if (instanceValue != undefined) {
      const instance = dialogRef.componentInstance as typeof component;
      (Object.keys(instanceValue) as (keyof typeof instanceValue)[]).forEach(key => {
        instance[key] = instanceValue[key]
      })
    }
    return dialogRef.afterClosed()
  }

  showData(data: any[], columnNames: string[]) {
    if (data.length == 0) return
    let dataToSend = data.map((ele) => {
      let objKey = Object.keys(ele)
      let retVal: { [key: string]: any } = {}
      columnNames.forEach((colName, idx) => {
        retVal[colName] = ele[objKey[idx]]
      })
      return retVal
    })
    console.log(dataToSend)
    this.openDialog<null, null>(ShowDataComponent, { data: dataToSend }, { width: '80%' })
  }

  validateProducts(skus: Interfaces.AddProductMass[]): Observable<Interfaces.ProductInfo[]> {
    return new Observable<Interfaces.ProductInfo[]>(obs => {
      this.backend.callBackendPost<Interfaces.ProductInfo[]>('/consulta/masiva/sku', skus).subscribe({
        next: valor => {
          obs.next(valor)
          obs.complete()
        },
        error: err => {
          (err instanceof HttpErrorResponse && err.status == 404) ? obs.error(errors.WrongJerarquiaOrSku) : obs.error(err)
        }
      })
      return obs
    })
  }

  validaLocales(locales: Interfaces.addLocalesMass[]): Observable<Interfaces.ExcelBuscaLocales[]> {
    return new Observable<Interfaces.ExcelBuscaLocales[]>(obs => {
      this.backend.callBackendPost<Interfaces.ExcelBuscaLocales[]>('/consulta/masiva/locales', locales).subscribe({
        next: valor => {
          obs.next(valor)
          obs.complete()
          return obs
        },
        error: err => {
          (err instanceof HttpErrorResponse && err.status == 404) ? obs.error(errors.WrongLocalSellOut) : obs.error(err)
        }
      })
    }
    )
  }

  buscarLiquidacionMass(acuerdos: Interfaces.buscaLiquidacionMass[]): Observable<Interfaces.facturas[]> {

    return new Observable<Interfaces.facturas[]>(obs => {
      let hasErrors = false;
      let errs: Error[] = [];

      acuerdos.forEach((ele, idx) => {
        if (ele.acuerdo == '') {
          errs.push(new Error((`Fila: ${idx + 1}` + errors.ErrorMissingAcuerdo)))
          hasErrors = true
        }
      })


      if (!hasErrors) {
        this.backend.callBackendPost<Interfaces.facturas[]>(`/consulta/masiva/acuerdos`, acuerdos).subscribe({
          next: (valor) => {
            console.log(JSON.stringify(valor))
            if (valor != null && valor != undefined) {
              obs.next(valor) //: obs.error(errors.ErrorLiquidacionNotFound);
              obs.complete()
            }
            else {
              obs.next(undefined);
            }
          },
          error: err => {
            //alert('pasa igual');
            alert(err.message);
            (err instanceof HttpErrorResponse && err.status == 404) ? obs.error(errors.ErrorLiquidacionNotFound) : obs.error(err)
          }
        })
      } else {
        obs.error(errs)
        obs.complete()
      }
      return obs
    })
  }


  buscarLiquidacion(acuerdo: string): Observable<Interfaces.BuscarLiquidaciones> {
    let body: string = "";
    body = body + acuerdo;
    body = body + '/0/0/0';

    return new Observable<Interfaces.facturas>(obs => {
      let hasErrors = false
      if (acuerdo == '') {
        obs.error(errors.ErrorMissingAcuerdo)
        hasErrors = true
      }
      if (!hasErrors) {
        this.backend.callBackendGet<Interfaces.facturas[]>(`/liquidaciones/liquidacion/${body}`).subscribe({
          next: (valor) => {
            if (valor != null && valor != undefined) {
              obs.next(valor[0]) //: obs.error(errors.ErrorLiquidacionNotFound);
              obs.complete()
            }
            else {
              obs.next(undefined);
              //obs.error(errors.ErrorLiquidacionNotFound)
            }
          },
          error: err => {
            //alert('pasa igual');
            (err instanceof HttpErrorResponse && err.status == 404) ? obs.error(errors.ErrorLiquidacionNotFound) : obs.error(err)
          }
        })
      } else {
        obs.complete()
      }
      return obs
    })
  }


  loadDropDown<T>(option: DropDownOptions, params?: string[]): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      if (dropDownRoutes[option].params.length > 0 && params == undefined) {
        reject("Debe de agregar los parámetros requeridos para campo indicado")
        return
      }
      if (dropDownRoutes[option].params.length > 0 && params != undefined && dropDownRoutes[option].params.length != params.length) {
        reject("Cantidad de parámetros requeridos no es igual a la cantidad de parámetros enviados")
        return
      }
      let url = ''
      if (dropDownRoutes[option].params.length > 0 && params != undefined) {
        url = dropDownRoutes[option].url + params?.join('/')
      }
      else {
        url = dropDownRoutes[option].url
      }

      this.backend.callBackendGet<T[]>(url).subscribe({
        next: data => { resolve(data as T[]) },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            reject(err.message)
          }
          else {
            reject(err)
          }
        }
      })
    })
  }

  static dateIsValid(value: string): boolean {
    let fec: string[] = [];
    if (value.toString().indexOf("/") > -1) {
      //fecha viene con /
      fec = value.split('/')
    }
    else {
      if (value.toString().indexOf("-") > -1) {
        fec = value.toString().split('-')
      }
      else {
        return false;
      }
    }
    if (fec.length != 3) {
      return false
    }

    return true;
  }

  static stringToFecha(value: string) {
    let fec: string[] = [];
    if (value.toString().indexOf("/") > -1) {
      //fecha viene con /
      fec = value.split('/')
    }
    else {
      if (value.toString().indexOf("-") > -1) {
        fec = value.toString().split('-')
      }
      else {
        return (null as unknown as Date);
      }
    }
    if (fec.length != 3) {
      return (null as unknown as Date)
    }
    let fecha: Date;
    if (fec[0].length == 4) {
      fecha = new Date(this.stringToNumber(fec[0]), this.stringToNumber(fec[1]) - 1, this.stringToNumber(fec[2]))
    }
    else {

      fecha = new Date(this.stringToNumber(fec[2]), this.stringToNumber(fec[1]) - 1, this.stringToNumber(fec[0]))
    }
    return fecha;
  }

  static validaFormatoFecha(value: string): boolean {
    let fec: string[] = [];
    if (value.toString().indexOf("/") > -1) {
      //fecha viene con /
      fec = value.toString().split('/')
    }
    else {
      if (value.toString().indexOf("-") > -1) {
        fec = value.toString().split('-')
      }
      else {
        let num: number = this.stringToNumber(value.toString())
        if (num > 0) {
          return true;
        }
        return false
      }
    }
    if (fec.length != 3) {
      return false
    }

    if (fec[0].length == 2 && fec[1].length == 2 && fec[2].length == 4) {
      return true
    }
    else {
      return false;
    }
  }

  static stringToNumber(value: string): number {
    if (value.length > 0) {
      let val: string = value;
      if (!isNaN(Number(val))) {
        return Number(val);
      }
      else {
        return 0
      }
    }
    else {
      return 0
    }
  }

  static redondear(value: number): number {
    return Math.round(value)
  }

  static convertFile(file: File): Observable<string> {
    return new Observable<string>(obs => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64string = String(reader.result)
          .replace('data:', '')
          .replace(/^.+,/, '');
        obs.next(base64string);
        obs.complete()
      }
      reader.onerror = () => {
        obs.error(`Error al cargar archivo: ${file.name}`)
      }
      reader.readAsDataURL(file)
    })

  }

  static async exportExcel(fileName: string, data: Object[]) {
    let workbook = utils.book_new();
    const workSheet = utils.json_to_sheet(data);
    utils.book_append_sheet(workbook, workSheet, `Export`);
    let exportFileName = `${fileName}.xls`;
    writeFile(workbook, exportFileName);
  }

  static numParts(country: string) {
    let group: string = '';
    let decimal: string = ''
    //se va a dejar que todos los paises funcionan como Chile, ya que el sistema anterior funciona de esta forma.
    if (country.toUpperCase()=='CO' || country.toUpperCase()=='PE'){
      country='CL'
    }
    const parts = new Intl.NumberFormat("es-" + country.toUpperCase()).formatToParts(12345.6);
    parts.forEach(part => {
      if (part.type == 'group') {
        group = part.value
      }
      if (part.type == 'decimal') {
        decimal = part.value
      }
    }
    )
    return { miles: group, decimals: decimal }
  }

  static funcDateExcel(value: number): string {
    let numero: number = value - 25569
    numero = numero * 86400000
    let fecha: Date = new Date(numero)
    let year: string = fecha.getUTCFullYear().toString()
    let mes: string = (fecha.getUTCMonth() + 1).toString()
    let dia: string = fecha.getUTCDate().toString()
    if (dia.length < 2) {
      dia = "0" + dia
    }
    if (mes.length < 2) {
      mes = "0" + mes
    }
    let fec: string = dia + '-' + mes + '-' + year
    return fec
  }


  static strToNum(country: string, valor :string, variable: string){
    let parts = this.numParts(country)
    let monto: string = valor
    let montoSeparado: string[]=monto.split(parts.decimals)
    let miles: string="";
    let decimales: string="";
    let decimalFound: boolean
    if (montoSeparado.length>1)
    {
      decimalFound=true
      if ((country.toUpperCase()=="CL" || country.toUpperCase()=="CO" || variable=='units') && (variable!='montoLiqui') ){
        //posee decimales por lo que no pueden escribir montos con decimales en estos paises.
        this.convertFile
        return  {miles: "0", decimales: "0", decimalPart: parts.decimals, decimalFound: decimalFound, error:"No puede ingresar decimales."}
      }
      if (montoSeparado.length>2){
        //tiene mas de un separador de decimales.
        return  {miles: "0", decimales: "0", decimalPart: parts.decimals, decimalFound: decimalFound, error:"No se puede ingresar más de un separador de decimales."}
      }
      else{
        miles= montoSeparado[0].replaceAll(".", "").replaceAll(",","")
        decimales= montoSeparado[1].replaceAll(".", "").replaceAll(",","")    
      }
    }
    else{
      decimalFound=false
      miles= montoSeparado[0].replaceAll(".", "").replaceAll(",","")
      decimales=""
    }

    return {miles: miles, decimales: decimales, decimalPart: parts.decimals, decimalFound: decimalFound, error:""}
  }

  transformarMonto(country: string, monto: string, variable : string) {
    let montoTramsformado: string=""
    let valor = CommonsModule.strToNum(country, monto, variable)
    if (valor.error != "") {
      montoTramsformado=""
      this.error("Error", valor.error, 'Aceptar', true)
    } else {
      if (!valor.decimalFound){
        //no encontró el separador de decimales dentro del monto
        if (valor.miles=="0"){
          montoTramsformado = "0"
        }else{
          montoTramsformado = this.countryPipe.transform(CommonsModule.stringToNumber(valor.miles), country, "", true)
        }
      }
      else{
        //si encontro el separador de decimales.
        if (valor.miles=="0" || valor.miles==""){
          montoTramsformado = "0" + valor.decimalPart + valor.decimales
        }else{
          montoTramsformado = this.countryPipe.transform(CommonsModule.stringToNumber(valor.miles), country, "", true) + valor.decimalPart + valor.decimales
        }
      }
    }
    return montoTramsformado
  }


}
