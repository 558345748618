import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data["shouldReuse"] || false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void {
    if (route.data["shouldReuse"]) {
        if (route.routeConfig?.path != undefined) 
        this.handlers[route.routeConfig.path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig?.path != undefined) {
        return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
    }
    return false
  }

  retrieve(route: ActivatedRouteSnapshot): {} | null {
    if (!route.routeConfig) return null;
    if (route.routeConfig?.path != undefined)
    return this.handlers[route.routeConfig.path];
    return null
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.data["shouldReuse"] || false;
  }

}