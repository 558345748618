
<ngx-spinner bdColor = "rgba(34, 66, 64, 0.8)" size = "large" color = "#fff" name="offline" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white; text-shadow: 2px 2px 5px black; font-weight: bold;" >Rebate Service OFFLINE</p></ngx-spinner>
<ngx-spinner bdColor = "rgba(155, 0, 0, 1)" size = "large" color = "#fff" name="criticalError" type = "ball-scale-multiple" [fullScreen] = "true"><p style="color: white; text-shadow: 2px 2px 5px black; font-weight: bold; text-align: center;" >{{criticalErrorMessage}}</p><p style="color: white; text-shadow: 2px 2px 5px black; font-weight: bold;" >Contáctese con Administración de Sistemas</p></ngx-spinner>
<div class="menu-container-div" [class.mat-elevation-z2]="true">
    <div class="left-div">
        <app-menu [title]="m.titulo" [options]="m.options" *ngFor="let m of menus"></app-menu>
    </div>
    <div class="rigth-div">
        <span class="{{country}}"></span>
        <button mat-button [matMenuTriggerFor]="username">{{userName}}</button>
        <mat-menu #username="matMenu">
            <button mat-menu-item (click)="logout()">Log Out</button>
        </mat-menu>
    </div>
</div>
<router-outlet></router-outlet>