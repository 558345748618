import { KeycloakOptions, KeycloakService } from 'keycloak-angular'
import { keycloakConfig } from '../../config/keycloak'

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
    const options: KeycloakOptions = {
        config: keycloakConfig,
        loadUserProfileAtStartUp: true,
        initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false
        },
        bearerExcludedUrls: []
    };
    return () => keycloak.init(options)
}