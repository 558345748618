import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcuerdoSellOut } from 'src/app/classes/AcuerdoSellOut';
import { Simulador } from 'src/app/classes/simulador';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { NgxSpinnerService } from "ngx-spinner";
import { SimuladorSellOut } from 'src/app/classes/simuladorSellOut';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SearchTiendaComponent, SearchType } from 'src/app/components/simulador/search-tienda/search-tienda.component';
import { errors } from 'src/app/constants/constants.errors';
import { SearchMarcaComponent } from 'src/app/components/simulador/search-marca/search-marca.component';
import { SearchProveedorComponent } from 'src/app/components/simulador/search-proveedor/search-proveedor.component';
import { Proveedor } from 'src/app/classes/proveedor';
import { AuthService } from 'src/app/services/auth/auth.service';


export interface Tile {
  color: string;
  cols: number;
  rows: number;
  template: TemplateRef<any>
}

@Component({
  selector: 'app-acuerdo-sell-out',
  templateUrl: './acuerdo-sell-out.component.html',
  styleUrls: ['./acuerdo-sell-out.component.scss']
})
export class AcuerdoSellOutComponent {
  public acuerdo: AcuerdoSellOut;
  public spinnerMessage: string = "Loading ..."
  public title: string = '';
  public tipoAcuerdo: Interfaces.TiposAcuerdoOptions[] = [{ c_tpacuerdo: '', a_tpacuerdo: '', a_prfjacuerdo: '' }]; //se define un elemento para que no mande error en el selected
  public organizacion!: string;
  public searchButtonTitle: string = ''
  public subtipos: Interfaces.SubTiposOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public tipoRecupero: Interfaces.TipoRecuperoOptions[] = [{ c_tprecupero: '', a_tprecupero: '' }]
  public tipoAgrupador: Interfaces.AgrupadorOptions[] = [{ c_agrupador: '', a_agrupador: '', c_tpagrupador: '' }];
  public tipoLiquidacion: Interfaces.TipoLiquidacionOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public country!: string;
  public cabeceraTabla: string[] = [];
  public fileUp: number = 0;
  public parametroVer!: boolean;
  public btnCerrar!: string;
  public addProd!: boolean;
  public editarCopiar!: boolean

  /*fila de agregar prod/Jer al acuerdo directamente */
  public sku: string = "";
  public fecDesde!: Date;
  public fecHasta!: Date;
  public aporte: string = "";
  public organizaciones!: Interfaces.OrganizacionOptions[]; //= [{a_valor:'tienda',c_parametro:'tienda', searchType:SearchType.TIENDA, selected:false}];
  public tiendas: Interfaces.Tiendas[] = [];
  public seltiendas: boolean = false;
  public org: number = 0;
  public unidades: string = ''


  constructor(
    public dialogRef: MatDialogRef<AcuerdoSellOutComponent>,
    private spinner: NgxSpinnerService,
    private srv: RebateBackendService,
    private auth: AuthService,
    private commons: CommonsModule,
    @Inject(MAT_DIALOG_DATA) public simulador: SimuladorSellOut | AcuerdoSellOut,
    private commonService: CommonsModule
  ) {
    this.acuerdo = new AcuerdoSellOut(srv, commons, auth);
    this.auth.getCountry().then(value => this.country = value)
  }

  ngOnInit(): void {
    //this.searchButtonTitle = this.organizacion.toUpperCase().includes('TIENDA') ? 'Tiendas' : 'Locales';
    this.loading()
      .then(() => {
        this.cargaSubTipos();
      })
      .catch(() => {

      })
      .finally(() => {

      });

    (this.addProd) ?
      this.cabeceraTabla = ['num', 'jerarquia', 'descrjerarquia', 'fecdesde', 'fechasta', 'aporte', 'tope', 'unidad', 'organizacion', 'accion']
      : this.cabeceraTabla = ['num', 'jerarquia', 'descrjerarquia', 'fecdesde', 'fechasta', 'aporte', 'tope', 'unidad', 'organizacion'];

    if (this.simulador instanceof SimuladorSellOut) {
      this.acuerdo.esSimulacion(this.simulador)
    } else {
      this.acuerdo = this.simulador;
      if (this.acuerdo.fileBase64 != '' && this.acuerdo.fileBase64 != null) {
        this.fileUp = 1;
      }
    }

  }

  async cargaSubTipos() {
    this.spinner.show('loadingSpinner');
    this.spinnerMessage = "Cargando datos...";
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.SubTiposOptions>(DropDownOptions.SubTipoAcuerdo, ['1', this.acuerdo.tpAcuerdo.toString()])
        .then((data) => {
          this.subtipos = data;
          if (this.acuerdo.subTipoAcuerdo == 0) {
            this.acuerdo.subTipoAcuerdo = CommonsModule.stringToNumber(this.subtipos.filter(ele => ele.c_parametro == '128')[0].c_parametro.toString())
          }
          res();
        })
        .catch(err => rej('Error Subtipos: ' + err))
    })

    let results1 = await Promise.allSettled([p2])
    let errorMsg = results1.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('loadingSpinner')
  }

  async loading() {
    this.spinner.show('loadingSpinner');
    this.spinnerMessage = "Cargando datos...";

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TiposAcuerdoOptions>(DropDownOptions.TipoAcuerdo)
        .then((data) => {
          this.tipoAcuerdo = data;
          if (this.acuerdo.tpAcuerdo == 0) {
            this.acuerdo.tpAcuerdo = CommonsModule.stringToNumber(this.tipoAcuerdo[0].c_tpacuerdo.toString());
          }
          res();
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let p3 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoLiquidacionOptions>(DropDownOptions.TipoLiquidacion, ['23'])
        .then((data) => {
          this.tipoLiquidacion = data;
          if (this.acuerdo.tipoLiquidacion == 0) {
            this.acuerdo.tipoLiquidacion = CommonsModule.stringToNumber(this.tipoLiquidacion[0].c_parametro.toString());
          }
          res();
        })
        .catch(err => rej('Error Tipos Liquidación: ' + err))
    })

    let p4 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.AgrupadorOptions>(DropDownOptions.TipoAgrupador)
        .then((data) => {
          this.tipoAgrupador = data;
          if (this.acuerdo.agrAcuerdo == 0) {
            this.tipoAgrupador.sort((a, b) => CommonsModule.stringToNumber(a.c_agrupador) - CommonsModule.stringToNumber(b.c_agrupador))
            if (this.acuerdo.agrAcuerdo==0){
              this.acuerdo.agrAcuerdo = CommonsModule.stringToNumber(this.tipoAgrupador[0].c_agrupador.toString());
            }
          }
          res();
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let p5 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoRecuperoOptions>(DropDownOptions.tipoRecupero)
        .then((data) => {
          this.tipoRecupero = data;
          res()
          if (this.acuerdo.tipoRecupero == 0) {
            this.acuerdo.tipoRecupero = CommonsModule.stringToNumber(this.tipoRecupero[0].c_tprecupero.toString());
          }
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.OrganizacionOptions>(DropDownOptions.Organizacion)
        .then((data) => {
          this.organizaciones = data;
          this.organizaciones.forEach((ele, idx) => {
            ele.path = `/organizaciones/opciones/${ele.c_parametro}`
            ele.searchType = (ele.a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
            ele.selected = (idx == 0)
            if (ele.selected) {
              this.org = CommonsModule.stringToNumber(ele.c_parametro.toString())
              this.setSearchType(ele)
            }
          });
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })


    let results = await Promise.allSettled([p1, p2, p3, p4, p5])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('loadingSpinner')
  }



  cargarMail(event: any) {
    this.spinnerMessage = 'Cargando Archivo...'
    this.spinner.show()
    const file: File = event.target.files[0];
    if (file) {
      this.acuerdo.file = file;
      let nombreArchivo: string = file.name;
      let ext = nombreArchivo.split('.');
      if (ext.length > 1) {
        let extension = ext[ext.length - 1];
        if (extension == 'eml' || extension == 'msg') {
          this.acuerdo.cargaArchivo = true;
          this.fileUp = 1;
          CommonsModule.convertFile(file).subscribe({
            next: base64string => {
              this.acuerdo.fileBase64 = base64string;
              this.acuerdo.nombreArchivo = nombreArchivo;
            },
            error: err => { this.commonService.error('Error', err, 'Aceptar', true); this.spinner.show() },
            complete: () => this.spinner.hide()
          })
        }
        else {
          this.commonService.error('Error', 'Sólo se aceptan archivos eml o msg', 'Aceptar', true)
        }
      }
      else {
        this.commonService.error('Error', 'Archivo no válido', 'Aceptar', true)
      }

    }
  }

  guardarAcuerdo() {
    this.spinner.show('acuerdo')
    this.spinnerMessage = "Realizando Guardado..."
    this.acuerdo.origen = 'S'
    this.acuerdo.doGuardar()
      .then(_ => this.commonService.alert('accent', "Acuerdo Generado", 'Acuerdo generado Nº: ' + this.acuerdo.nAcuerdo, 'Aceptar', true).subscribe({
        complete: () => {
          window.location.reload()
        },
        error: (err) => { alert('error') }
      }))
      .catch(reason => this.commonService.error("Error", reason, 'Aceptar', true))
      .finally(() => { this.spinner.hide('acuerdo'); })
  }

  descargarArchivo() {
    this.acuerdo.descargarArchivo(this.acuerdo.fileBase64, this.acuerdo.nombreArchivo)
  }

  showDataTiendas() {
    let colTitle = this.organizacion.toUpperCase().includes('TIENDA') ? 'Locales' : 'Tiendas';
    this.commonService.showData(this.tiendas, [colTitle])
  }

  showDataPBC() {
    let data = this.acuerdo.pbc.map(ele => { return { rut: ele.proveedorFullRut, nombre: ele.vendor_name } })
    this.commonService.showData(data, ["RUT", "Nombre"])
  }

  showDataMarcas() {
    this.commonService.showData(this.acuerdo.marcas, ["Marca", "Descripción"])
  }

  openDialogTiendas() {
    const st = this.organizaciones.filter(ele => ele.selected)[0].searchType;
    this.commonService.openDialog<Interfaces.FilaTablaSearch, null>(SearchTiendaComponent, { searchType: st }).subscribe(
      {
        next: result => {
          if (result != null) this.tiendas = (st == SearchType.LOCAL) ? result.local : result.tienda;
          this.seltiendas = true;
        }
      }
    )
  }

  cleanDataSource(limpiaPBC: boolean = true) {
    this.acuerdo.cleanTabla(limpiaPBC);
  }


  validaFecha(event: any, campo: string) {
    let fecha: string = event.target.value
    if (fecha == null) {
      this.commonService.error("Error", "Fecha inválida", 'Aceptar', true);
      if (campo == 'desde') {
        this.fecDesde = new Date()
      } else {
        this.fecHasta = new Date()
      }
    }
  }

  updateDataSource() {
    this.simulador.tabla = this.simulador.tabla.filter(ele => ele.display)
  }


  addProduct(rows: Interfaces.AddProductStructSellOut[] = [{ sku: this.sku, prd_lvl_child: 0, aporte: 0, name_full: '', fecDesde: this.fecDesde, fecHasta: this.fecHasta, organizacion: this.organizaciones.filter(ele => ele.selected)[0], descrOrg: this.organizaciones.filter(ele => ele.selected)[0].a_valor, tiendaLocal: this.tiendas, seltiendas: this.seltiendas, unidad: CommonsModule.stringToNumber(this.unidades.toString().replaceAll(".", "")) }]) {
    this.spinner.show('simulador')
    this.spinnerMessage = "Verificando Jerarquía/SKU"

    rows.forEach((prod, idx) => {
      let aporte = CommonsModule.strToNum(this.country, this.aporte, 'aporte')
      if (aporte.error != "") {
        this.commonService.error("Error", aporte.error, 'Aceptar', true)
        return
      } else {
        if (!aporte.decimalFound) {
          prod.aporte = CommonsModule.stringToNumber(aporte.miles)
        }
        else {
          prod.aporte = CommonsModule.stringToNumber(aporte.miles + aporte.decimalPart.replaceAll(aporte.decimalPart,".") + aporte.decimales)
        }
      }
    })

    this.acuerdo.addProducts(rows)
      .then(_ => {
        this.sku = '';
        this.aporte = "";
        this.fecDesde = ('' as unknown as Date);
        this.fecHasta = ('' as unknown as Date);
        this.organizaciones[0].searchType = (this.organizaciones[0].a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
        this.organizaciones[0].selected = true;
        if (this.organizaciones[0].selected) {
          this.org = CommonsModule.stringToNumber(this.organizaciones[0].c_parametro.toString())
          if (['7', '8'].includes(String(this.org))) {
            this.tiendas = [{ name: (this.organizaciones[0].searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
          }
        }
        this.seltiendas = false;
        this.unidades = "";
      })
      .catch(reason => {
        this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
      }
      )
      .finally(() => { this.spinner.hide('simulador'); this.updateDataSource() })
  }


  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.spinner.show('simulador');
      this.spinnerMessage = "Validando archivo Excel ...";
      CommonsModule.readExcel<Interfaces.SimuladorExcelStructSellOut>(file)
        //hay que ver como es el archivo de carga para el acuerdoSellOut porque
        //el del simulador tiene el monto total en cambio el acuerdo tiene las unidades y el aporte unitario 
        //por lo que tiene que ser diferente.
        .then(data => {
          this.addProduct(data.map<Interfaces.AddProductStructSellOut>((ele, idx) => {
            let tiendasExcel: Interfaces.Tiendas[] = []
            let orgExcel: Interfaces.OrganizacionOptions = { c_parametro: "0", a_valor: '', searchType: SearchType.TIENDA, selected: true, path: '' };
            let tien: string[] = ele.LOCAL_ID.toString().split(';');
            for (let x: number = 0; x < tien.length; x++) {
              tiendasExcel.push({ value: tien[x], name: '' })
            }
            if (!CommonsModule.validaFormatoFecha(ele.FECHA_COBRO_DESDE)) {
              let indice = idx + 1;
              throw 'Fecha desde ' + ele.FECHA_COBRO_DESDE + ' en línea ' + indice + ' no tiene formato correcto, debe ser DD-MM-YYYY ej: 01-01-2023'
            }
            if (!CommonsModule.validaFormatoFecha(ele.FECHA_COBRO_HASTA)) {
              let indice = idx + 1;
              throw 'Fecha hasta ' + ele.FECHA_COBRO_DESDE + ' en línea ' + indice + ' no tiene formato correcto, debe ser DD-MM-YYYY ej: 01-01-2023'
            }

            let fecDesde: string = ele.FECHA_COBRO_DESDE
            let fechaDesde: Date = CommonsModule.stringToFecha(fecDesde);
            let fecHasta: string = ele.FECHA_COBRO_HASTA
            let FechaHasta: Date = CommonsModule.stringToFecha(fecHasta);
            return {
              sku: String(ele['SKU_ID']),
              aporte: CommonsModule.stringToNumber(ele['MONTO'].toString()),
              prd_lvl_child: 0,
              name_full: '',
              fecDesde: fechaDesde,
              fecHasta: FechaHasta,
              organizacion: orgExcel,
              descrOrg: '',
              tiendaLocal: tiendasExcel,
              seltiendas: false
            }
          }))
        })
        .catch(reason => {
          this.spinner.hide('simulador')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }


  // validaMonto(event: any) {
  //   let group: string = '';
  //   let decimal: string = ''
  //   const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);
  //   parts.forEach(part => {
  //     if (part.type == 'group') {
  //       group = part.value
  //     }
  //     if (part.type == 'decimal') {
  //       decimal = part.value
  //     }
  //   }
  //   )
  //   this.aporte = event;
  //   this.aporte = this.aporte.replaceAll(group, "")
  //   this.aporte = this.aporte.replaceAll(decimal, ".")
  // }

  // validaUnidades(event: any) {
  //   let group: string = '';
  //   let decimal: string = ''
  //   const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);
  //   parts.forEach(part => {
  //     if (part.type == 'group') {
  //       group = part.value
  //     }
  //     if (part.type == 'decimal') {
  //       decimal = part.value
  //     }
  //   }
  //   )
  //   this.unidades = event;
  //   this.unidades = this.unidades.replaceAll(group, "")
  //   this.unidades = this.unidades.replaceAll(decimal, ".")
  // }
  // Numero(valor: string): number {
  //   if (!isNaN(Number(valor))) {
  //     return Number(valor);
  //   }
  //   else {
  //     return 0
  //   }
  // }

  setSearchType(option: Interfaces.OrganizacionOptions) {
    this.organizaciones.forEach(ele => ele.selected = (ele.c_parametro == this.org.toString()))
    this.searchButtonTitle = (option.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    if (['7', '8'].includes(String(this.org.toString()))) {
      this.tiendas = [{ name: (option.searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
    } else {
      this.spinnerMessage = `Buscando ${(option.searchType == SearchType.LOCAL) ? 'locales' : 'tiendas'}...`
      this.spinner.show('simulador');
      this.tiendas = [];
      this.srv.callBackendGet<Interfaces.DatosTiendaLocal[]>(option.path as string).subscribe({
        next: data => {
          data.forEach(ele => {
            this.tiendas.push({
              name: `${ele.org_lvl_number} ${ele.org_name_full}`,
              value: ele.org_lvl_child.toString()
            })
          })
        },
        error: _ => { this.spinner.hide('simulador') },
        complete: () => { this.spinner.hide('simulador') }
      })
    }
  }

  openDialogMarcas() {
    this.commonService.openDialog<Interfaces.Marca[], null>(SearchMarcaComponent).subscribe(
      {
        next: result => { if (result != undefined && result.length > 0) this.acuerdo.marcas = result }
      }
    )
  }

  openDialogProveedores() {
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { if (result != null) this.acuerdo.pbc = [result] }
      }
    )
  }

  eliminar(i: number) {
    this.acuerdo.tabla.splice(i, 1)
    this.updateDataSource();
    if (this.acuerdo.tabla.length == 0) {
      this.cleanDataSource(false);
    }
    if (this.acuerdo.tabla.length == 1 && !this.acuerdo.tabla[0].display) {
      this.cleanDataSource(false);
    }
  }

  copiar(i: number) {
    this.sku = this.acuerdo.tabla[i].jerarquia;
    this.fecDesde = this.acuerdo.tabla[i].fecdesde;
    this.fecHasta = this.acuerdo.tabla[i].fechasta;
    this.aporte = this.commonService.transformarMonto(this.country, this.acuerdo.tabla[i].aporte.toString(), 'aporte')
    this.unidades = this.commonService.transformarMonto(this.country, this.acuerdo.tabla[i].unidad.toString(), 'units')
    this.org = Number(this.acuerdo.tabla[i].organizacion.c_parametro)
    this.organizaciones.forEach(ele => {
      if (ele.c_parametro == this.organizacion.toString()) this.searchButtonTitle = (ele.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    })
    this.tiendas = []
    this.acuerdo.tabla[i].tiendaLocal.forEach(ele => {
      //hay que mandar primero el nombre ya que es solo este elemento el que toma despues para mostrarlo en la 
      //grilla al presionar el ver, si se manda el value muestra el value.
      this.tiendas.push({ name: ele.name, value: ele.value })
    })

  }

  exportExcel() {
    let rows: Object[] = [];

    this.acuerdo.tabla.forEach(element => {
      let fecDesde = element.fecdesde;
      let fecHasta = element.fechasta;

      rows.push({
        "Jerarquía": element.jerarquia,
        "Descr Jerar Prd/Sku": element.name_full,
        "Fecha Desde": Intl.DateTimeFormat('es-CL').format(element.fecdesde),
        "Fecha Hasta": Intl.DateTimeFormat('es-CL').format(element.fechasta),
        "Aporte $": element.aporte,
        "Tope Monto $": 0,
        "Unidades": element.unidad,
        "Organización": element.organizacion.a_valor
      })
    });
    CommonsModule.exportExcel(
      "Unidades bonificadas acuerdo " + this.acuerdo.nAcuerdo
      , rows);
  }

  // keyDown($event: any) {
  //   if ($event.keyCode >= 65 && $event.keyCode <= 90) {
  //     $event.preventDefault();
  //   }
  // }
  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode==='c')) {
      //console.log("si")
    }
    else{
      if ($event.metaKey && (charCode==='v' || charCode==='c')){
        //console.log("si command")
      }
      else{
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }    
      }
    }
  }

  onKeyUp($event: any, variable: string) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
      switch (variable) {
        case 'aporte':
          this.aporte = this.commonService.transformarMonto(this.country, this.aporte, 'aporte')
          break
        case 'units':
          this.unidades = this.commonService.transformarMonto(this.country, this.unidades, 'units')
          break;
      }
    // }
  }
}
