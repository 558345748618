import { Component } from '@angular/core';

@Component({
  selector: 'app-show-data',
  templateUrl: './show-data.component.html',
  styleUrls: ['./show-data.component.scss']
})
export class ShowDataComponent {

  private _data!: any[];
  public columnNames!: string[];

  set data(values: Object[]) { 
    if (values.length == 0) 
    {
      this._data = [];
    } else {
      this.columnNames = Object.keys(values[0]);
      this._data = values;
      console.log(this.columnNames)
      console.log(this._data)
    }
  }

  get data() { return this._data }
}
