import { Component, Input, OnInit, numberAttribute } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { CountryFormatPipe } from '../pipes/countryFormat.pipe';

interface listaNc extends Interfaces.Nc {
  fullRut: string,
  seleccion: boolean
}

interface retDenoSeleccionadas {
  value: string,
  descr: string,
  cclasifica: number
}

@Component({
  selector: 'app-nota-credito',
  templateUrl: './nota-credito.component.html',
  styleUrls: ['./nota-credito.component.scss']
})
export class NotaCreditoComponent {
  public country!: string;
  public dataSource: MatTableDataSource<listaNc>;
  public columns: string[] = [];
  public columnsDoc: string[] = [];
  public spinnerMessage: string = "Loading ..."
  public title?: string;
  public checkDoc: boolean = false;
  public parametros: Interfaces.Parametros[] = [];
  public paramMoneda: boolean = false;
  public moneda: string = '0';
  public monedas: Interfaces.Monedas[] = [{ cod: '0', name: 'CLP', defecto: 'T' }];
  public valorMoneda: string = "";
  public margenPermitido: number = 0;
  public accion: string = 'Nota de Crédito'
  public documento: string = 'NDC'
  public claseDoc: string = ''
  public claseDocs: Interfaces.ClaseDoc[] = [{ tipoDoc: '0', descr: '' }];

  public paramRetencion: boolean = false;

  public impuesto: boolean = false;
  public paramImpuesto: boolean = false;

  public tipoImpuesto: string = ''
  public tiposImpuestos: Interfaces.tipoImpuesto[] = [{ piva: '0', valor: '' }]


  public tipoRetencion: string = ''
  public tipoRetenciones: Interfaces.tipoRetencion[] = [{ ctipo_ret: '0', xdesc_tipo: '' }]

  public tipoDenominacion: string = ''
  public tipoDenominaciones: Interfaces.tipoDenominacion[] = [{ cagrupador: '', cclasifica: 0, cindicador_ret: '', ctipo_ret: '', xdenominacion: '' }]
  public tipoDenominacionesFull: Interfaces.tipoDenominacion[] = [{ cagrupador: '', cclasifica: 0, cindicador_ret: '', ctipo_ret: '', xdenominacion: '' }]
  public denominacionesSeleccionadas: retDenoSeleccionadas[] = [];

  @Input() ListadoNcSeleccionadas: listaNc[] = [];

  public listadoDocsNc: Interfaces.documentFacturas[] = [];
  public dataSourceDocNc: MatTableDataSource<Interfaces.documentFacturas>;
  private user_tech_key !: string;

  public paramFactRef: boolean = false;
  public redondeo: boolean = false

  constructor(
    private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,
    private auth: AuthService,
  ) {
    this.auth.getCountry().then(value => this.country = value)
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
    this.dataSourceDocNc = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocsNc);
    this.auth.getUserTechKey().then(value => this.user_tech_key = value)
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
    this.title = 'Nota de Crédito'
    this.impuesto = false //este sirve para mostrar el check con que va a haber un impuesto, si estra true, muestro los campos de impuesto y retencion.
    this.paramImpuesto = false;//este flag sirve para ver si tengo que mostrar o no el campo del tipo de impuesto.
    this.cargaParametros()
      .then(() => {
        this.loading().then(() => {
          console.log('clase doc' + this.claseDoc)
        })
      })
      .catch(() => {

      })
      .finally(() => {

      });
    this.tipoDenominaciones = [];
    this.tipoDenominaciones.push({ cagrupador: '0', cclasifica: 0, cindicador_ret: '0', ctipo_ret: '0', xdenominacion: 'Seleccione Denominación' })
    this.tipoDenominacion = '0';
    this.columns = ['btns', 'num', 'rutAcuerdo', 'acuerdo', 'montoAcuerdo', 'cuentaAC', 'agrupador', 'rut', 'monto', 'folioRef', 'fechaRef'];
    this.columnsDoc = ['btns', 'num', 'nombreDoc', 'ceco', 'agrDoc', 'ctaCont', 'rutProv', 'montoDoc', 'codOrden'];
    (this.country == 'CL' || this.country == 'CO') ? true : false;
  }


  async cargaParametros() {
    //hago la carga de los parametros como si lo cargara en un dropdown pq lo puedo usar mas facil,
    //ya que todas las pantallas cargan los datos iniciales de esta forma y queda mas limpio el codigo.
    //la carga de parámetros se hace luego de que se carguen los combobox para ver que campos debo mostrar y que 
    //campos no, y en el caso del impuesto es para que primero lo cargue con el valor del combobox y luego ver
    //si tengo que cargarlo segun el parámetro.
    this.spinner.show('notaCredito');
    this.spinnerMessage = "Cargando datos...";
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Parametros>(DropDownOptions.parametros)
        .then((data) => {
          this.parametros = data;
          this.parametros.forEach(param => {
            if (param.cparametro == 'IdMoneda' && param.valor == 'T') {
              this.paramMoneda = true;
            }
            if (param.cparametro == 'DELTARBT') {
              this.margenPermitido = CommonsModule.stringToNumber(param.valor.toString())
            }

            if (param.cparametro == 'RETENCION' && param.valor == 'T') {
              this.paramRetencion = true
              this.impuesto = true; //si el parametro de retencion esta T entonces tengo que dejar check el checkbox de los impuestos.
            }
            if (param.cparametro == 'IVA') {
              //si el parametro está vacio entonces muestro el campo para que lo seleccione,
              //de lo contrario lo oculta y el tipoImpuesto toma el valor que tiene el parametro.
              if (param.valor == '') {
                //valor del parametro de iva esta en null o vacio por lo que tengo que mostrar el campo de impuestos
                this.paramImpuesto = true; //parametro que sirve para ver si muestro o no el select de los tipos de impuestos 
              }
              else {
                //el parametro de iva esta diferente de null o vacio por lo que tengo que ocultar el campo de los tipos de impuestos.
                this.paramImpuesto = false;
                this.tipoImpuesto = param.valor
              }
            }
            if (param.cparametro == 'FACTREF' && param.valor == 'T') {
              this.columns = ['btns', 'num', 'rutAcuerdo', 'acuerdo', 'montoAcuerdo', 'cuentaAC', 'agrupador', 'rut', 'monto', 'folioRef', 'factRef', 'fechaRef'];
              this.paramFactRef = true
            }
          })
          res()
        })
        .catch(err => rej('Error carga de parámetros: ' + err))
    })
    let results = await Promise.allSettled([p2])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('notaCredito')
  }

  async loading() {
    this.spinner.show('notaCredito');
    this.spinnerMessage = "Cargando datos...";

    //tiposMonedas
    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Monedas>(DropDownOptions.monedas)
        .then((data) => {
          this.monedas = data;
          data.forEach(ele => {
            if (ele.defecto == 'T') {
              this.moneda = ele.cod
            }
          })
          res()
        })
        .catch(err => rej('Error tipos Monedas: ' + err))
    })

    let p3 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<object[]>(DropDownOptions.claseDoc)
        .then((data) => {
          this.claseDocs = [];
          Object.keys(data[0]).forEach((llave, idx) => {
            let valor = (data[0] as { [key: string]: any })[llave];
            this.claseDocs.push({ tipoDoc: valor, descr: llave })
            console.log(idx + '---' + valor)
            if (idx == 0) this.claseDoc = valor
          })
          //console.log(this.claseDoc)
          res()
        })
        .catch(err => rej('Error Clase Documentos: ' + err))
    })


    //cargar datos con un objeto json sin que tenga una estructura fija
    //tipoDocumentos
    if (this.paramRetencion) {
      //si el parametro impuesto está true, calculado en la carga de parametros. 
      //entonces tengo que buscar los tipos de impuestos 
      //y las retenciones
      if (this.paramImpuesto) {
        //tipoImpuestos
        let p4 = new Promise<void>((res, rej) => {
          this.commonService.loadDropDown<Interfaces.tipoImpuesto>(DropDownOptions.tipoImpuesto)
            .then((data) => {
              this.tiposImpuestos = data;
              data.forEach((ele, idx) => {
                if (idx == 0) this.tipoImpuesto = ele.valor
              })
              res()
            })
            .catch(err => rej('Error tipos impuestos: ' + err))
        })
        //tiporetencion
        let p5 = new Promise<void>((res, rej) => {
          this.commonService.loadDropDown<Interfaces.tipoRetencion>(DropDownOptions.tipoRetencion)
            .then((data) => {
              this.tipoRetenciones = data;
              this.tipoRetenciones.splice(0, 0, { ctipo_ret: '0', xdesc_tipo: 'Seleccione Tipo Retención' })
              data.forEach((ele, idx) => {
                if (idx == 0) this.tipoRetencion = ele.ctipo_ret
              })
              res()
            })
            .catch(err => rej('Error tipos de retención: ' + err))
        })

        //tipoDenominaciones
        let p6 = new Promise<void>((res, rej) => {
          this.commonService.loadDropDown<Interfaces.tipoDenominacion>(DropDownOptions.tipoDenominacion)
            .then((data) => {
              this.tipoDenominacionesFull = data;
              res()
            })
            .catch(err => rej('Error tipos de denominacion: ' + err))
        })

        let results = await Promise.allSettled([p1, p3, p4, p5, p6])
        let errorMsg = results.reduce((prv, curr) => {
          if (curr.status == 'rejected') {
            return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
          } else {
            return prv
          }
        }, "")
        if (errorMsg != "") {
          this.commonService.error("Error", errorMsg, 'Aceptar', true)
        }
      }
      else {
        //parametro impuesto está false, por lo que solo busco las retenciones
        //los tipos de impuesto no se buscan
        //tiporetencion
        let p5 = new Promise<void>((res, rej) => {
          this.commonService.loadDropDown<Interfaces.tipoRetencion>(DropDownOptions.tipoRetencion)
            .then((data) => {
              this.tipoRetenciones = data;
              this.tipoRetenciones.splice(0, 0, { ctipo_ret: '0', xdesc_tipo: 'Seleccione Tipo Retención' })
              data.forEach((ele, idx) => {
                if (idx == 0) this.tipoRetencion = ele.ctipo_ret
              })
              res()
            })
            .catch(err => rej('Error tipos de retención: ' + err))
        })

        //tipoDenominaciones
        let p6 = new Promise<void>((res, rej) => {
          this.commonService.loadDropDown<Interfaces.tipoDenominacion>(DropDownOptions.tipoDenominacion)
            .then((data) => {
              this.tipoDenominacionesFull = data;
              res()
            })
            .catch(err => rej('Error tipos de denominacion: ' + err))
        })

        let results = await Promise.allSettled([p1, p3, p5, p6])
        let errorMsg = results.reduce((prv, curr) => {
          if (curr.status == 'rejected') {
            return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
          } else {
            return prv
          }
        }, "")
        if (errorMsg != "") {
          this.commonService.error("Error", errorMsg, 'Aceptar', true)
        }

      }

    }
    else {
      // paramRetencion no se encuentra seteado por lo tanto solo se ejecuta carga de datos de las monedas y clasedoc
      let results = await Promise.allSettled([p1, p3])
      let errorMsg = results.reduce((prv, curr) => {
        if (curr.status == 'rejected') {
          return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
        } else {
          return prv
        }
      }, "")
      if (errorMsg != "") {
        this.commonService.error("Error", errorMsg, 'Aceptar', true)
      }
    }

    this.spinner.hide('notaCredito')
  }

  cargarDenominaciones() {
    this.spinner.show('notaCredito');
    this.spinnerMessage = "Cargando datos...";
    this.tipoDenominaciones = this.tipoDenominacionesFull.filter(ele => ele.ctipo_ret == this.tipoRetencion)
    this.tipoDenominaciones.splice(0, 0, { cagrupador: '0', cclasifica: 0, cindicador_ret: '0', ctipo_ret: '0', xdenominacion: 'Seleccione Denominación' })
    this.tipoDenominaciones.forEach((ele, idx) => {
      if (idx == 0) this.tipoDenominacion = ele.cindicador_ret
    })
    this.spinner.hide('notaCredito')
  }


  private buscaLiquidacionPromise(datos: listaNc[]): Promise<listaNc[]> {
    return new Promise<listaNc[]>((resolve, reject) => {
      let errs: Error[] = []
      let datosMass: Interfaces.buscaLiquidacionMass[] = [];
      datos.forEach((ele, idx) => {
        datosMass.push({ seq: idx.toString(), acuerdo: ele.acuerdo })
      })

      if (environment.debug) {
        console.log('datos masivos')
        console.log(JSON.stringify(datosMass))

        console.log('fin masivo')
      }
      this.commons.buscarLiquidacionMass(datosMass).subscribe({
        next: (validData) => {

          validData.forEach((factura, idx) => {
            if (factura.status == 'error') {
              errs.push(new Error(`Fila ${idx + 1}: Acuerdo ${factura.acuerdo} no encontrado`));
            }
            else {
              let valor: string = factura.secuencia;
              valor = valor.replaceAll(".", "@")
              valor = valor.replaceAll(",", ".")
              valor = valor.replaceAll("@", "")
              let seq: number = CommonsModule.stringToNumber(valor)
              datos[seq].seq = datos[seq].seq
              datos[seq].seq_dtr = datos[seq].seq_dtr
              datos[seq].acuerdo = factura.acuerdo
              datos[seq].ceunta_contable = factura.ceunta_contable
              datos[seq].dv = factura.dv
              datos[seq].fecha_liqui = factura.fecha_liqui
              datos[seq].fullRut = factura.rut + '-' + factura.dv
              datos[seq].monto_liqui = factura.monto_liqui.trim()
              datos[seq].nombre_acuerdo = factura.nombre_acuerdo
              datos[seq].rut = factura.rut
              datos[seq].suma_liqui = factura.suma_liqui.trim()
              datos[seq].tipo_liqui = factura.tipo_liqui
              datos[seq].vendor_name = factura.vendor_name
              datos[seq].seleccion = false
              datos[seq].agrupador = datos[seq].agrupador
              datos[seq].rutFactura = factura.rut + '-' + factura.dv
              datos[seq].montoFactura = datos[seq].montoFactura.trim()
              datos[seq].folioRef = datos[seq].folioRef
              datos[seq].fechaRef = datos[seq].fechaRef
              datos[seq].btnAdd1 = true
              datos[seq].btnDel1 = true
              datos[seq].addinput = true
            }
          })
          if (errs.length == 0) {
            resolve(datos)
          }
          else {
            reject(this.addLiquidacionError(errs).message)
          }
        },
        error: (err) => {
          reject(this.addLiquidacionError(errs).message)
        }
      })
    })
  }


  /*private buscaLiquidacionPromise(ele: listaNc, idx: number): Promise<listaNc> {
    return new Promise<listaNc>((resolve, reject) => {
      let errs: Error[] = []
      this.commons.buscarLiquidacion(ele.acuerdo).subscribe({
        next: (validData) => {
          ele.seq = ele.seq;
          ele.seq_dtr = ele.seq_dtr;
          ele.acuerdo = (validData != undefined) ? validData.acuerdo : ele.acuerdo;
          ele.ceunta_contable = (validData != undefined) ? validData.ceunta_contable : '';
          ele.dv = (validData != undefined) ? validData.dv : '';
          ele.fecha_liqui = (validData != undefined) ? validData.fecha_liqui : '';
          ele.fullRut = (validData != undefined) ? validData.rut + '-' + validData.dv : '';
          ele.monto_liqui = (validData != undefined) ? validData.monto_liqui : '0';
          ele.nombre_acuerdo = (validData != undefined) ? validData.nombre_acuerdo : '';
          ele.rut = (validData != undefined) ? validData.rut : '';
          ele.suma_liqui = (validData != undefined) ? validData.suma_liqui : '0';
          ele.tipo_liqui = (validData != undefined) ? validData.tipo_liqui : '';
          ele.vendor_name = (validData != undefined) ? validData.vendor_name : '';
          ele.seleccion = false
          ele.agrupador = (ele.agrupador != undefined) ? ele.agrupador : ''
          ele.rutFactura = (validData != undefined) ? validData.rut + '-' + validData.dv : '';
          ele.montoFactura = (ele.montoFactura != undefined) ? ele.montoFactura : '';
          ele.folioRef = (ele.folioRef != undefined) ? ele.folioRef : ''
          ele.factRef = ''
          ele.fechaRef = (ele.fechaRef != undefined) ? ele.fechaRef : ''
          ele.btnAdd1 = true
          ele.btnDel1 = true
          ele.addinput = true;
          (errs.length == 0) ? resolve(ele) : reject(this.addLiquidacionError(errs).message)
        },
        error: (err) => {
          errs.push(new Error(`Fila ${idx + 1}: ${(err as Error).message}`));
          reject(this.addLiquidacionError(errs).message)
        }
      })
    })
  }*/

  private addLiquidacionError(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }

  async buscarLiquidaciones(data: listaNc[]): Promise<boolean> {
    let errs: Error[] = [];

    return new Promise<boolean>((resolve, reject) => {
      this.buscaLiquidacionPromise(data)
        .then(listaFacturas => {
          this.addLiquidacionOnComplete(listaFacturas)
          resolve(true)
        }, reason => {
          //errs.push(reason)
          reject(reason)
          //reject(this.addProductError(errs))
        })
    })
  }



  /*async buscarLiquidaciones(data: listaNc[]): Promise<boolean> {
    let promises: Promise<listaNc>[] = []
    data.forEach((ele, idx) => {
      promises.push(this.buscaLiquidacionPromise(ele, idx))
    })
    let results = await Promise.allSettled(promises)
    if (results.every(res => res.status == 'fulfilled')) {
      let onCompleteResult = this.addLiquidacionOnComplete(results.map<listaNc>(res => { return (res as PromiseFulfilledResult<listaNc>).value }));
      return (onCompleteResult != null) ? Promise.reject(this.addLiquidacionError([onCompleteResult])) : Promise.resolve(true)
    } else {
      let errors: Error[] = []
      results.filter(res => res.status == 'rejected').forEach(res => {
        errors.push(new Error((res as PromiseRejectedResult).reason))
      })
      return Promise.reject(this.addLiquidacionError(errors))
    }
  }*/

  private addLiquidacionOnComplete(data: listaNc[]): Error | null {
    this.ListadoNcSeleccionadas = data
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
    return null
  }

  uploadExcel(event: any) {
    const file: File = event.target.files[0];
    let fechaRef: string = ''

    if (file) {
      this.spinner.show('notaCredito');
      this.spinnerMessage = "Validando archivo Excel ...";
      this.ListadoNcSeleccionadas = [];
      CommonsModule.readExcel<Interfaces.NcExcelStruct>(file)
        .then(data => {
          let cont: number = 0;
          let lista: listaNc[] = []
          data.forEach(ele => {
            cont++;

            let monto: string = '';
            if (ele['MONTO'] != undefined) {
              let numParts = CommonsModule.numParts(this.country)
              let valorExcel: string = ele.MONTO.toString().replaceAll(numParts.miles, '')
              valorExcel = valorExcel.replaceAll(numParts.decimals, '.')
              let montoExcel: number = CommonsModule.stringToNumber(valorExcel)
              if (this.country.toUpperCase() == 'CL' || this.country.toUpperCase() == 'CO') {
                montoExcel = Math.round(montoExcel)
              }
              monto = this.commonService.transformarMonto(this.country, montoExcel.toString(), 'monto');
            }
            else {
              monto = ''
            }

            fechaRef = ''
            if (ele['FECHA REFERENCIA'] != undefined) {
              fechaRef = CommonsModule.funcDateExcel(CommonsModule.stringToNumber(ele['FECHA REFERENCIA'].toString()))
            }

            lista.push({
              acuerdo: (ele['ACUERDO'] == undefined) ? '' : ele['ACUERDO'].toString(),
              addinput: false,
              agrupador: (ele['AGRUPADOR'] == undefined) ? '' : ele['AGRUPADOR'].toString(),
              btnAdd1: false,
              btnDel1: false,
              ceunta_contable: '',
              dv: '',
              fecha_liqui: '',
              factRef: '',
              fechaRef: fechaRef,
              folioRef: (ele['N° REFERENCIA'] == undefined) ? '' : ele['N° REFERENCIA'].toString(),
              fullRut: '',
              monto_liqui: '0',
              montoFactura: monto,
              nombre_acuerdo: '',
              rut: '',
              rutFactura: (ele['RUT'] == undefined) ? '' : ele['RUT'].toString(),
              seleccion: false,
              seq: cont.toString(),
              seq_dtr: '1',
              suma_liqui: '0',
              tipo_liqui: '',
              vendor_name: '',
              secuencia: cont.toString(),
              status: "ok"
            });
          })
          if (environment.debug) {
            console.log(JSON.stringify(lista))
          }
          this.buscarLiquidaciones(lista)
            .catch(err => {
              this.spinner.hide('notaCredito')
              this.commonService.error(errors.LoadingExcelError.message, err, 'Aceptar', true)
            })
            .finally(() => {
              this.spinner.hide('notaCredito')
            })
        })
        .catch(reason => {
          this.spinner.hide('notaCredito')
          this.commonService.error(errors.LoadingExcelError.message, reason, 'Aceptar', true)
        })
    }
  }

  guardarNc() {
    this.spinner.show('notaCredito')
    this.spinnerMessage = "Realizando Guardado..."
    this.doGuardar()
      .then(_ => this.commonService.alert('accent', "Proceso Exitoso", 'Proceso se ha realizado con éxito', 'Aceptar', true).subscribe({
        complete: () => {
          window.location.reload()
        }
      }))
      .catch(reason => {
        this.commonService.error("Error", reason, 'Aceptar', true)
      })
      .finally(() => { this.spinner.hide('notaCredito'); })
  }

  async doGuardar(): Promise<boolean> {
    let montoMoneda: number = 0
    let valorMoneda = CommonsModule.strToNum(this.country, this.valorMoneda, 'moneda')
    if (!valorMoneda.decimalFound) {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles)
    }
    else {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles + valorMoneda.decimalPart + valorMoneda.decimales)
    }

    return new Promise<boolean>((resolve, reject) => {
      let erroresValidacion: Error[] = this.validaPreGuardado();
      if (erroresValidacion.length === 0) {
        let Nc: Interfaces.crearNc[] = []
        let acuerdo: string = ''
        let cuentaAc: string = ''
        let agr: string = ''
        let rutFact: string = ''
        let montoFactura: number
        let finRegistro: string = 'F'
        let inHijo: string = ''

        this.ListadoNcSeleccionadas.forEach((ele, index) => {
          let seq: number = index + 1;
          let apendice: number = 1;


          if (ele.acuerdo != '') {
            //significa que es un apendice de la fila anterior
            acuerdo = ele.acuerdo;
            cuentaAc = ele.ceunta_contable
            agr = ele.agrupador
            const find = ele.rutFactura.indexOf('-')
            if (find != -1) {
              //encontro el -
              rutFact = ele.rutFactura.split('-')[0].trim()
            }
            else {
              rutFact = ele.rutFactura.trim()
            }
            let valorFactura = CommonsModule.strToNum(this.country, ele.montoFactura, 'montoFactura')
            if (!valorFactura.decimalFound) {
              montoFactura = CommonsModule.stringToNumber(valorFactura.miles)
            }
            else {
              montoFactura = CommonsModule.stringToNumber(valorFactura.miles + valorFactura.decimalPart.replaceAll(valorFactura.decimalPart, ".") + valorFactura.decimales)
            }

          }
          else {
            apendice++;
          }
          if (this.ListadoNcSeleccionadas.length == index + 1) {
            finRegistro = 'T'
          }

          var in_iva: string ='1'
          if (this.paramRetencion) {
            //para CL el parametro esta false y siempre tiene que llevar 1 siempre tiene que ir en 1
            //para PE el parametro esta true pero lo mismo tiene que ir 1, la data de la db esta toda en 1 por lo que no puede cambiar eso.
            //para CO el parametro esta true pero si le desmarcan el tema de impuesto entonces tiene que ir 0

            //si el parametro retencion esta true tengo que validar si el campo impuesto esta true o falso.
            if (!this.impuesto && this.paramImpuesto){ //si desmarcan el tema del check entonces tiene que ir 0
              in_iva="0"
            }

          }

          Nc.push(
            {
              secuencia: seq.toString(),
              nacuerdo: acuerdo.toString(),
              cuenta_ac: cuentaAc,
              agrupador: agr,
              rut: rutFact, //rut sin dv
              monto: (montoMoneda == 0) ? montoFactura.toString() : (montoFactura * montoMoneda).toFixed(2), //montoFactura * valor moneda, redondeado
              folio_ref: ele.folioRef,
              fecha_solicitud: ele.fechaRef,
              seq_dtr: apendice.toString(),
              usr_tech_key: this.user_tech_key,
              in_hijo: inHijo,
              fin_registro: finRegistro,
              in_iva: in_iva,
              retencion: this.armaRetencion(this.denominacionesSeleccionadas),
              clase_documento: this.claseDoc,
              tipo_impuesto: this.tipoImpuesto,
              moneda: this.moneda,
              monto_conversion: montoMoneda.toString(),
              monto_original: montoFactura.toString(), //monto de la factura
              factura_referencia: ele.factRef,
              documento: this.armarDoc(this.listadoDocsNc)
            })
        })
        if (environment.debug) {
          console.log(JSON.stringify(Nc))
        }
        //reject(JSON.stringify(acrdo))
        this.postGuardado(Nc)
          .then(data => resolve(data))
          .catch(reason => {
            if (reason instanceof HttpErrorResponse) reject(this.showErrors([new Error(reason.message)]))
            else reject(reason)
          })
        //resolve(true)
      }
      else {
        reject(this.showErrors(erroresValidacion).message)
      }
    })
  }

  private showErrors(errors: Error[]): Error {
    return errors.reduce((prv, curr) => {
      prv.message += prv.message == '' ? curr.message : '<br>' + curr.message;
      return prv
    }, new Error())
  }

  // validaMontoMax(acuerdo: string, montoMax: number): boolean {
  //   let sumaTotal: number = 0;
  //   let validado: boolean = false; //parte indicando que monto está incorrecto, luego se valida
  //   this.ListadoNcSeleccionadas.forEach((Nc, indx) => {
  //     if (acuerdo == Nc.acuerdo) {
  //       let montoFactura= Nc.montoFactura;
  //       let valorMoneda = this.valorMoneda

  //       montoFactura = montoFactura.replaceAll(".", "@")
  //       montoFactura = montoFactura.replaceAll(",", ".")
  //       montoFactura = montoFactura.replaceAll("@", "")

  //       valorMoneda = valorMoneda.replaceAll(".", "@")
  //       valorMoneda = valorMoneda.replaceAll(",", ".")
  //       valorMoneda = valorMoneda.replaceAll("@", "")

  //       sumaTotal += (this.valorMoneda.length > 0) ? Math.round(CommonsModule.stringToNumber(montoFactura) * CommonsModule.stringToNumber(valorMoneda)) : CommonsModule.stringToNumber(montoFactura);
  //     }
  //   })
  //   montoMax += this.margenPermitido;
  //   if (montoMax >= sumaTotal) validado = true //monto está correcto
  //   return validado
  // }

  private validaPreGuardado(): Error[] {
    let erroresFormulario: Error[] = []
    //tengo que validar la moneda que viene por defecto
    if (this.paramMoneda) {
      let currXDef = this.monedas.filter(ele => ele.defecto = 'T')
      if (currXDef[0].cod != this.moneda && this.valorMoneda == '') {
        erroresFormulario.push(errors.missingCurrency);
        return erroresFormulario
      }
    }

    //valida Notas de Crédito
    if (this.ListadoNcSeleccionadas.length > 0) {
      let montoMax: number = 0;
      let acuerdo: string = ''
      for (let Nc of this.ListadoNcSeleccionadas) {
        if (Nc.agrupador == '') {
          erroresFormulario.push(new Error(`Debe ingresar agrupador en Nota de Crédito`));
        }
        if (Nc.rutFactura == '') {
          erroresFormulario.push(new Error(`Debe ingresar rut en Nota de Crédito`));
        }
        if (Nc.montoFactura == '') {
          erroresFormulario.push(new Error(`Debe ingresar monto en Nota de Crédito`));
        }
        else {
          if (acuerdo != Nc.acuerdo && Nc.acuerdo != '') {
            let monto_fact: string = Nc.monto_liqui;
            acuerdo = Nc.acuerdo;
            if (!this.validaMontoMax(acuerdo)) {
              erroresFormulario.push(new Error(`Montos ingresados para acuerdo ${acuerdo} son mayores que el máximo permitido`));
            }
            /*else {
                erroresFormulario.push(new Error('paso correcto el monto'))
            }*/
          }
        }
        //validar campo factref y folio ref se validan solo si el parámetro paramfactref=true
        if (this.paramFactRef) {
          if (!Nc.factRef.match(/01\-[A-Za-z0-9]{4}\-[0-9]{8}/)) {
            erroresFormulario.push(new Error(`El campo factura ref. con valor ${Nc.factRef} no cumple con el patrón establecido`));
          }
          if (!Nc.folioRef.match(/07\-[A-Za-z0-9]{4}\-[0-9]{8}/)) {
            erroresFormulario.push(new Error(`El campo folio ref. con valor ${Nc.folioRef} no cumple con el patrón establecido`));
          }
        }

        if (Nc.fechaRef == '') {
          erroresFormulario.push(new Error(`Debe ingresar fecha referencia en Nota de Crédito`));
        }
        else {
          if (!CommonsModule.dateIsValid(Nc.fechaRef)) {
            erroresFormulario.push(new Error(`Debe ingresar fecha referencia válida en Nota de Crédito`));
            Nc.fechaRef = '';
          }
        }
        if (erroresFormulario.length > 0) {
          break;
        }
      }
    }
    else {
      erroresFormulario.push(errors.missingNc)
    }

    if (erroresFormulario.length == 0) {
      //valida documentos cuando el chechDoc está activado
      if (this.checkDoc && this.listadoDocsNc.length == 0) {
        erroresFormulario.push(errors.missingDetDoc)
      }
      else {
        for (let docNc of this.listadoDocsNc) {
          if (docNc.nombre == '') {
            erroresFormulario.push(new Error(`Debe ingresar nombre en documento`));
            break;
          }
          if (docNc.ceco == '') {
            erroresFormulario.push(new Error(`Debe ingresar CECO en documento`));
            break;
          }
          if (docNc.ctaCont == '') {
            erroresFormulario.push(new Error(`Debe ingresar cuenta contable en documento`));
            break;
          }
          if (docNc.rutProv == '') {
            erroresFormulario.push(new Error(`Debe ingresar rut proveedor en documento`));
            break;
          }
          if (docNc.monto == '') {
            erroresFormulario.push(new Error(`Debe ingresar monto en documento`));
            break;
          }
          if (docNc.agrupador == '') {
            erroresFormulario.push(new Error(`Debe ingresar agrupador en documento`));
            break;
          }
          const findAgr = this.ListadoNcSeleccionadas.findIndex(function (item) {
            return item.agrupador == docNc.agrupador
          })
          if (findAgr == -1) {
            erroresFormulario.push(new Error(`Debe ingresar agrupador válido en documento`));
            break;
          }
          const findRut = this.ListadoNcSeleccionadas.findIndex(function (item) {
            return item.fullRut == docNc.rutProv
          })
          if (findRut == -1) {
            erroresFormulario.push(new Error(`Debe ingresar rut de proveedor válido en documento`));
            break;
          }
        }
      }
    }
    return erroresFormulario;
  }

  private postGuardado(Nc: Interfaces.crearNc[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let respuesta: Interfaces.GuardarFacturaRespuesta;
      this.srv.callBackendPost<Interfaces.GuardarNcRespuesta>(`/liquidaciones/notacredito`, Nc).subscribe({
        next: (data) => respuesta = data,
        error: (err) => reject(err),
        complete: () => {
          if (respuesta.descripcion == 'Proceso exitoso') {
            console.log(respuesta.descripcion)
            resolve(true)
          } else {
            console.log(respuesta.error)
            reject(errors.ErrorGuardadoFactura)
          }
        }
      })
    })
  }

  armaRetencion(lista: retDenoSeleccionadas[]): string {
    return lista.reduce((prv, curr) => {
      prv += (prv == "") ? curr.value : `,${curr.value}`
      return prv
    }, "")
  }

  armarDoc(lista: Interfaces.documentFacturas[]): string {
    return lista.reduce((prv, curr) => {
      let monto: number = CommonsModule.stringToNumber(curr.monto.toString())
      prv += `${curr.nombre}|${curr.ceco}|${curr.agrupador}|${curr.ctaCont}|${curr.rutProv.trim()}|${monto}|${curr.codOrden}|`
      //: `${curr.nombre}|${curr.ceco}|${curr.agrupador}|${curr.ctaCont}|${curr.rutProv.trim()}|${monto}|${curr.codOrden}|`
      return prv
    }, "")
  }



  Numero(valor: string): number {
    valor = valor.toString().replaceAll(".", "@")
    valor = valor.toString().replaceAll(",", ".")
    valor = valor.toString().replaceAll("@", "")
    return CommonsModule.stringToNumber(valor.toString())
  }

  validaMonto(indice: number) {
    const filterPipe = new CountryFormatPipe();
    let valor: string = this.listadoDocsNc[indice].monto
    const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);

    valor = valor.replaceAll(".", "@")
    valor = valor.replaceAll(",", ".")
    valor = valor.replaceAll("@", "")
    let num: number = CommonsModule.stringToNumber(valor)
    valor = filterPipe.transform(num, this.country, '', this.redondeo)
    this.listadoDocsNc[indice].monto = valor
  }

  // validaMontoFactura(indice: number) {
  //   let acuerdo: string = '';
  //   let monto_liqui: string = '';
  //   const filterPipe = new CountryFormatPipe();

  //   acuerdo = this.ListadoNcSeleccionadas[indice].acuerdo
  //   monto_liqui = this.ListadoNcSeleccionadas[indice].monto_liqui

  //   monto_liqui = monto_liqui.replaceAll(".", "@")
  //   monto_liqui = monto_liqui.replaceAll(",", ".")
  //   monto_liqui = monto_liqui.replaceAll("@", "")

  //   let montoMax: number = CommonsModule.stringToNumber(monto_liqui)
  //   let valor: string = this.ListadoNcSeleccionadas[indice].montoFactura

  //   valor = valor.replaceAll(".", "@")
  //   valor = valor.replaceAll(",", ".")
  //   valor = valor.replaceAll("@", "")

  //   let num: number = CommonsModule.stringToNumber(valor)
  //   valor = filterPipe.transform(num, this.country,'',this.redondeo,'0')
  //   this.ListadoNcSeleccionadas[indice].montoFactura = valor
  //   if (!this.validaMontoMax(acuerdo, montoMax)){
  //     this.ListadoNcSeleccionadas[indice].montoFactura=filterPipe.transform(0, this.country,'',this.redondeo,'')
  //   }
  // }



  agregarApendice(ele: listaNc, index: number) {
    this.ListadoNcSeleccionadas.splice(index + 1, 0, {
      seq: "",
      seq_dtr: "",
      acuerdo: "",
      ceunta_contable: "",
      dv: "",
      fecha_liqui: "",
      fullRut: "",
      monto_liqui: "",
      nombre_acuerdo: "",
      rut: "",
      suma_liqui: "",
      tipo_liqui: "",
      vendor_name: "",
      seleccion: false,
      agrupador: "",
      rutFactura: "",
      montoFactura: "",
      folioRef: '',
      fechaRef: '',
      factRef: '',
      btnAdd1: false,
      btnDel1: false,
      addinput: false,
      secuencia: "",
      status: "ok"
    })
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
  }

  agregarSeq(ele: listaNc, index: number) {
    let secuencia = CommonsModule.stringToNumber(ele.seq) + 1;
    this.ListadoNcSeleccionadas.splice(index + 1, 0, {
      seq: secuencia.toString(),
      seq_dtr: "1",
      acuerdo: ele.acuerdo,
      ceunta_contable: ele.ceunta_contable,
      dv: ele.dv,
      fecha_liqui: ele.fecha_liqui,
      fullRut: ele.fullRut,
      monto_liqui: ele.monto_liqui,
      nombre_acuerdo: ele.nombre_acuerdo,
      rut: ele.rut,
      suma_liqui: ele.suma_liqui,
      tipo_liqui: ele.tipo_liqui,
      vendor_name: ele.vendor_name,
      seleccion: false,
      agrupador: "",
      rutFactura: ele.fullRut,
      montoFactura: "",
      folioRef: '',
      factRef: '',
      fechaRef: '',
      btnAdd1: false,
      btnDel1: true,
      addinput: true,
      secuencia: "",
      status: "ok"
    })
    this.reordenarSecuencia();
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
  }

  reordenarSecuencia() {
    let secuencia = 1;
    this.ListadoNcSeleccionadas.forEach(ele => {
      if (ele.fullRut != '') {
        ele.seq = secuencia.toString();
        secuencia++;
      }
    })
  }

  deleteApendice(i: number) {
    this.ListadoNcSeleccionadas.splice(i, 1)
    if (this.ListadoNcSeleccionadas.length == 0) {
      this.ListadoNcSeleccionadas = [];
    }
    this.reordenarSecuencia();
    this.dataSource = new MatTableDataSource<listaNc>(this.ListadoNcSeleccionadas);
  }

  checkDto() {
    this.listadoDocsNc = [];
    if (this.checkDoc) {
      this.listadoDocsNc.splice(1, 0, {
        nombre: '',
        ceco: '',
        agrupador: '',
        ctaCont: '',
        rutProv: '',
        monto: '',
        codOrden: '',
        btnAdd1: true,
        btnDel1: false
      })
    }
    this.dataSourceDocNc = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocsNc);
  }

  agregarDoc(ele: listaNc, index: number) {
    this.listadoDocsNc.splice(index + 1, 0, {
      nombre: '',
      ceco: '',
      agrupador: '',
      ctaCont: '',
      rutProv: '',
      monto: '',
      codOrden: '',
      btnAdd1: false,
      btnDel1: true
    })
    this.dataSourceDocNc = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocsNc);
  }

  deleteDoc(i: number) {
    this.listadoDocsNc.splice(i, 1)
    if (this.listadoDocsNc.length == 0) {
      this.listadoDocsNc = [];
    }
    this.dataSourceDocNc = new MatTableDataSource<Interfaces.documentFacturas>(this.listadoDocsNc);
  }

  addDenominacion() {
    if (this.tipoRetencion != '0' && this.tipoDenominacion != '0') {
      let cod: string = this.tipoRetencion + '|';
      let descr: string = this.tipoRetenciones.filter(ret => ret.ctipo_ret == this.tipoRetencion)[0].xdesc_tipo
      let ccl: number = 0;
      this.tipoDenominaciones.forEach(deno => {
        if (deno.ctipo_ret == this.tipoRetencion && deno.cindicador_ret == this.tipoDenominacion) {
          cod += deno.cindicador_ret + '|' + deno.cclasifica + '|' + deno.cagrupador;
          descr += ' - ' + deno.xdenominacion
          ccl = deno.cclasifica
        }
      })
      let existe: boolean = (this.denominacionesSeleccionadas.filter(deno => ccl == deno.cclasifica).length > 0) ? true : false;

      if (!existe) {
        this.denominacionesSeleccionadas.push({ descr: descr, value: cod, cclasifica: ccl })
      }
      else {
        this.commonService.error("Error", 'Clase de documento ya se encuentra seleccionado', 'Aceptar', true)
      }
      this.tipoRetencion = '0'
      this.cargarDenominaciones();
      this.tipoDenominacion = '0'
    }
    else {
      this.commonService.error("Error", 'Seleccione tipo de retención y denominación válidos.', 'Aceptar', true)
    }
  }

  showDataTipoDenominaciones() {
    let colTitle = 'Denominaciones Seleccionadas';
    this.commonService.showData(this.denominacionesSeleccionadas, [colTitle])
  }

  validaMontoMax(acuerdo: string): boolean {
    //los acuerdos que se suman en '', no son considerados porque no se les puede agregar monto, 
    //y cuando se agregan acuerdos a la grilla que si tienen acuerdo ingresado, estos ya quedan con el monto liqui copiado del anterior.

    let sumaTotal: number = 0;
    let validado: boolean = false;
    let montoMax: number = 0
    let valorFac: number = 0
    let montoMoneda: number = 0

    let valorMoneda = CommonsModule.strToNum(this.country, this.valorMoneda, 'montoLiqui')
    if (!valorMoneda.decimalFound) {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles)
    }
    else {
      montoMoneda = CommonsModule.stringToNumber(valorMoneda.miles + valorMoneda.decimalPart.replaceAll(valorMoneda.decimalPart, ".") + valorMoneda.decimales)
    }

    this.ListadoNcSeleccionadas.forEach((fac, indx) => {
      if (acuerdo == fac.acuerdo) {
        let monto_liqui = CommonsModule.strToNum(this.country, fac.monto_liqui, 'montoLiqui')
        if (!monto_liqui.decimalFound) {
          montoMax = CommonsModule.stringToNumber(monto_liqui.miles)
        }
        else {
          montoMax = CommonsModule.stringToNumber(monto_liqui.miles + monto_liqui.decimalPart.replaceAll(monto_liqui.decimalPart, ".") + monto_liqui.decimales)
        }

        let monto_fact = CommonsModule.strToNum(this.country, fac.montoFactura, 'montoLiqui')
        if (!monto_fact.decimalFound) {
          valorFac = CommonsModule.stringToNumber(monto_fact.miles)
        }
        else {
          valorFac = CommonsModule.stringToNumber(monto_fact.miles + monto_fact.decimalPart.replaceAll(monto_liqui.decimalPart, ".") + monto_fact.decimales)
        }
        sumaTotal += (montoMoneda > 0) ? Math.round(valorFac * montoMoneda) : valorFac;
      }
    })

    montoMax += this.margenPermitido;
    (montoMax < sumaTotal) ? validado = false //significa que montos ingresados son mayores que el monto maximo permitido
      : validado = true;
    return validado
  }


  /*   
    tengo que ver el tema de la validacion cuando guarda y tengo que ver que cuando carguen los datos desde el excel formatee los montos.
    tambien validar el monto maximo y ver que esté funcionando correctamente segun regla ya agregada, es solo ver que el monto se esté calculando bien segun el codigo de validaMAx
    
  */

  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode === 'c')) {
      //console.log("si")
    }
    else {
      if ($event.metaKey && (charCode === 'v' || charCode === 'c')) {
        //console.log("si command")
      }
      else {
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }
      }
    }
  }

  onKeyUp($event: any, variable: string, indice: number) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
    switch (variable) {
      case 'moneda':
        this.valorMoneda = this.commonService.transformarMonto(this.country, this.valorMoneda, 'moneda')
        break
      case 'monto': //monto grilla inferior.
        this.listadoDocsNc[indice].monto = this.commonService.transformarMonto(this.country, this.listadoDocsNc[indice].monto, 'monto')
        break;
      case 'montoFactura': //monto Nc.
        this.ListadoNcSeleccionadas[indice].montoFactura = this.commonService.transformarMonto(this.country, this.ListadoNcSeleccionadas[indice].montoFactura, 'monto')
        let acuerdo: string = this.ListadoNcSeleccionadas[indice].acuerdo
        if (!this.validaMontoMax(acuerdo)) {
          this.ListadoNcSeleccionadas[indice].montoFactura = ""
        }
        break;

    }
    // }
  }


}
