<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'searchLiquidacionesSpinner'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="mat-container-div" [class.mat-elevation-z2]="true" *ngIf="isLiquidaciones">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-div">
                <div class="itemprov left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="ancho90">
                        <mat-label>Código Acuerdo</mat-label>
                        <input matInput type="text" [(ngModel)]="nAcuerdo">
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Acción</mat-label>
                        <mat-select [(value)]="accion">
                            <mat-option [value]="item.value"
                                *ngFor="let item of acciones, let i=index" (click)="seleccionarAccion()">{{item.descr}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    &nbsp;
                </div>
            </div>
            <div class="flex-div">
                <div class="itemprov left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'" class="padsearchprov">
                        <mat-label>Proveedor</mat-label>
                        <input matInput type="text" [(ngModel)]="rutFullProv" (focusout)="buscarProveedor($event)" placeholder="Rut Proveedor">
                        <button mat-icon-button matSuffix (click)="openDialogProveedores()" matTooltip="Buscar Proveedor">
                            <mat-icon matSuffix>search</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-label class="padprov">{{proveedor.vendor_name}}</mat-label>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Desde</mat-label>
                        <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="fechaDesde">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="item left-div">
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Hasta</mat-label>
                        <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY"
                            [(ngModel)]="fechaHasta">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="itemprov left-div">
            </div>
            <div class="item left-div">
            </div>
            <div class="item left-div">
                <button mat-raised-button color="primary" class="pad" (click)="buscarLiquidaciones()">Buscar</button>
                <button mat-raised-button color="warn" class="pad" (click)="Limpiar()">Limpiar</button>
            </div>
        </mat-card-actions>
    </mat-card>
    <br>
    <table mat-table [dataSource]="dataSource" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="n_acuerdo">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">Cod. Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="right letraSize link">{{element.acuerdo}}</td>
        </ng-container>
        <ng-container matColumnDef="nomAcuerdo">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Nombre Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="letraSize link"> {{element.nombre_acuerdo | largoMax: 10}}</td>
        </ng-container>
        <ng-container matColumnDef="proveedor">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Proveedor</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.vendor_name | largoMax: 10}} </td>
        </ng-container>
        <ng-container matColumnDef="rutProveedor">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Rut Proveedor</th>
            <td mat-cell *matCellDef="let element" class="letraSize"> {{element.fullRut}} </td>
        </ng-container>
        <ng-container matColumnDef="mtoLiqui">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">Monto Liquidación</th>
            <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.monto_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="mtoConta">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">Monto Contabilizado</th>
            <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.suma_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="fecLiqui">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Fecha Liquidación</th>
            <td mat-cell *matCellDef="let element" class="nowrap letraSize"> {{element.fecha_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="tipoLiqui">
            <th mat-header-cell *matHeaderCellDef class="left background letraSize">Tipo Liquidación</th>
            <td mat-cell *matCellDef="let element" class="letraSize"> {{element.tipo_liqui}} </td>
        </ng-container>
        <ng-container matColumnDef="seleccion">
            <th mat-header-cell *matHeaderCellDef class="right background letraSize">
                <mat-checkbox (change)="setAll($event.checked)" [indeterminate]="someSelected()"
                    [checked]="everySelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="right letraSize" (click)="$event.stopPropagation()">
                <mat-checkbox [value]="element.n_acuerdo" [(ngModel)]="element.seleccion"
                    (change)="check($event.checked)">
                </mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>
<div class="mat-container-div" [class.mat-elevation-z2]="true" *ngIf="isFactura">
    <mat-card>
        <mat-card-content>
            <app-factura [ListadofacturasSeleccionadas]="facturasSeleccionadas"></app-factura>
        </mat-card-content>
    </mat-card>
</div>
<div class="mat-container-div" [class.mat-elevation-z2]="true" *ngIf="isNc">
    <mat-card>
        <mat-card-content>
            <app-nota-credito [ListadoNcSeleccionadas]="ncSeleccionadas"></app-nota-credito>
        </mat-card-content>
    </mat-card>
</div>