import { Component, OnInit, Inject } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-detalle-solicitudes',
  templateUrl: './detalle-solicitudes.component.html',
  styleUrls: ['./detalle-solicitudes.component.scss']
})
export class DetalleSolicitudesComponent {
  public spinnerMessage: string = "Loading ..."
  public title: string = 'Historia Acuerdo';
  public detalleSolicitud: Interfaces.DetalleSolicitud[] = [];
  public retencion: Interfaces.retencion[]=[];
  public readonly cabeceraTabla: string[] = ['num', 'secuencia', 'rut','acuerdo', 'monto_sap', 'ncuenta', 'glosa', 'cod_ref', 'foli_ref', 'fecha_creacion', 'documento_sap', 'documento_folio'];
  public readonly cabeceraTablaretencion: string[] = ['tipo_retencion', 'indicador_retencion','retencion_porcentaje', 'retencion_monto'];
  public datosSolicitud:string='';
  public poseeRetencion: boolean= false;
  public country!: string;

  constructor(
    public dialogRef: MatDialogRef<DetalleSolicitudesComponent>,
    private spinner: NgxSpinnerService,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public dataSolicitud: Interfaces.showSolicitud,

  ) {
    this.auth.getCountry().then(value => this.country = value)
    this.datosSolicitud= 'Solicitud detalle: ' + dataSolicitud.nSolicitud;
  }

  ngOnInit(): void {
    this.buscarData(this.dataSolicitud.nSolicitud)
  }

  buscarData(nSolicitud:number){
    this.spinner.show('detalleSolicitud')
    this.srv.callBackendGet<Interfaces.DetalleSolicitud[]>(`/liquidaciones/detalleliquidacion/${nSolicitud}`).subscribe({
      next: detalle =>{
        this.detalleSolicitud=detalle
        this.detalleSolicitud.forEach(ele=>{
          if (ele.retencion.length>0){
            this.retencion=ele.retencion;
            this.poseeRetencion=true;
          }
        })
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
          this.commons.error('Error', errors.MarcasNoEncontradas.message, 'Aceptar', true) :
          this.commons.error(errors.MarcasNoEncontradas.message, err, 'Aceptar', true);
        }
        this.detalleSolicitud=[];
        this.spinner.hide('detalleSolicitud')
      },
      complete: ()=>{
        this.spinner.hide('detalleSolicitud')
      }
    }
    )
  }
}
