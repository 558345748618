<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
  [name]="'detalleSolicitud'">
  <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<table class="thclose">
  <tr>
      <th class="left">
        <mat-card-title>{{datosSolicitud}}</mat-card-title>
      </th>
      <th class="header-right">
          <button mat-raised-button color="warn" class="pad" [mat-dialog-close]="null">X</button>
      </th>
  </tr>
</table>
<div class="matdialog">
  <br>
  <table mat-table [dataSource]="detalleSolicitud" aria-describedby="Historial de Estados Acuerdo" class="tabla">
    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef class="background" ngClass="letraSize">#</th>
      <td mat-cell *matCellDef="let i= index" class="right letraSize"> {{i+1}}</td>
    </ng-container>
    <ng-container matColumnDef="secuencia">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Secuencia Liquidación</th>
      <td mat-cell *matCellDef="let element" class="right nowrap"> {{element.seqliquidacion}} </td>
    </ng-container>
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Rut</th>
      <td mat-cell *matCellDef="let element" class="left nowrap letraSize">{{element.rut}}</td>
    </ng-container>
    <ng-container matColumnDef="acuerdo">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Acuerdo</th>
      <td mat-cell *matCellDef="let element" class="right nowrap letraSize"> {{element.acuerdo}} </td>
    </ng-container>
    <ng-container matColumnDef="monto_sap">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Monto Contabilizado</th>
      <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.monto_sap | countryFormat:
        country: '0' : true}} </td>
    </ng-container>
    <ng-container matColumnDef="ncuenta">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Cuenta AC</th>
      <td mat-cell *matCellDef="let element" class="letraSize"> {{element.ncuenta}} </td>
    </ng-container>
    <ng-container matColumnDef="glosa">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Glosa</th>
      <td mat-cell *matCellDef="let element" class="letraSize"> {{element.glosa}} </td>
    </ng-container>
    <ng-container matColumnDef="cod_ref">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Cod. Ref</th>
      <td mat-cell *matCellDef="let element" class="letraSize"> {{element.cod_ref}} </td>
    </ng-container>
    <ng-container matColumnDef="foli_ref">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Folio Ref</th>
      <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.foli_ref}} </td>
    </ng-container>
    <ng-container matColumnDef="fecha_creacion">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Fecha Creación</th>
      <td mat-cell *matCellDef="let element" class="left nowrap letraSize"> {{element.fecha_creacion}} </td>
    </ng-container>
    <ng-container matColumnDef="documento_sap">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Documento Sap</th>
      <td mat-cell *matCellDef="let element" class="letraSize"> {{element.documento_sap}} </td>
    </ng-container>
    <ng-container matColumnDef="documento_folio">
      <th mat-header-cell *matHeaderCellDef class="background letraSize">Documento Folio</th>
      <td mat-cell *matCellDef="let element" class="letraSize"> {{element.documento_folio}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
    <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>
  </table>
  <div class="retencion" *ngIf="poseeRetencion">
    <br>
    <table aria-describedby="Datos de Acuerdo" class="tabla">
      <tr>
        <th class="title">
          <mat-card-title>Retención</mat-card-title>
        </th>
      </tr>
    </table>
    <br>
    <table mat-table [dataSource]="detalleSolicitud[0].retencion" aria-describedby="Historial de Estados Acuerdo" class="tabla">
      <ng-container matColumnDef="tipo_retencion">
        <th mat-header-cell *matHeaderCellDef class="background letraSize">Tipo retención</th>
        <td mat-cell *matCellDef="let element" class="left letraSize">{{element.tipo_retencion}}</td>
      </ng-container>
      <ng-container matColumnDef="indicador_retencion">
        <th mat-header-cell *matHeaderCellDef class="background letraSize">indicador retencion</th>
        <td mat-cell *matCellDef="let element" class="left letraSize"> {{element.indicador_retencion}} </td>
      </ng-container>
      <ng-container matColumnDef="retencion_porcentaje">
        <th mat-header-cell *matHeaderCellDef class="background right">Retencion porcentaje</th>
        <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.retencion_porcentaje}} </td>
      </ng-container>
      <ng-container matColumnDef="retencion_monto">
        <th mat-header-cell *matHeaderCellDef class="background letraSize right">Retencion monto</th>
        <td mat-cell *matCellDef="let element" class="right letraSize"> {{element.retencion_monto}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="cabeceraTablaretencion"></tr>
      <tr mat-row *matRowDef="let row; columns: cabeceraTablaretencion;"></tr>
    </table>  
  </div>
  <br>
  <br>
</div>