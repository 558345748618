import { Component, OnInit } from '@angular/core';
import { Proveedor } from 'src/app/classes/proveedor';
import { CommonsModule } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { SearchProveedorComponent } from '../simulador/search-proveedor/search-proveedor.component';

interface BuscaLiquidaciones extends Interfaces.BuscarLiquidaciones {
  fullRut: string,
  seleccion: boolean
}

interface listaFacturas extends Interfaces.facturas{
  fullRut: string,
  seleccion: boolean
}

interface listaNc extends Interfaces.Nc {
  fullRut: string,
  seleccion: boolean
}

@Component({
  selector: 'app-liquidaciones',
  templateUrl: './liquidaciones.component.html',
  styleUrls: ['./liquidaciones.component.scss'],
})
export class LiquidacionesComponent {
  private subscription!: Subscription;
  public spinnerMessage: string = "Loading ..."
  public nAcuerdo: string = ''
  public fechaDesde!: Date;
  public fechaHasta!: Date;
  public proveedor: Proveedor;
  public rutFullProv: string = '';
  public accion:string='0';
  public acciones:Interfaces.AccionesLiquidaciones[]=[];
  public title?: string;
  public arrayLiquidaciones: BuscaLiquidaciones[] = [];
  public dataSource: MatTableDataSource<BuscaLiquidaciones>;
  public columns: string[];
  public country!: string;
  public seleccionados: boolean = false;
  public isLiquidaciones: boolean= true;
  public isFactura: boolean=false;
  public isNc: boolean=false;

  //facturas
  public facturasSeleccionadas: listaFacturas[]=[];

  //Nc
  public ncSeleccionadas: listaNc[]=[];


  constructor(
    private activatedroute: ActivatedRoute,
    private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,
    private auth: AuthService,
    private route: Router,
    ) {
    this.proveedor = new Proveedor(srv) //ver con pancho que puedo hacer para no estar creando nuevamente una variable de
    //tipo proveedor
    this.auth.getCountry().then(value => this.country = value)
    this.dataSource = new MatTableDataSource<BuscaLiquidaciones>(this.arrayLiquidaciones);
    this.columns = ['n_acuerdo', 'nomAcuerdo', 'proveedor', 'rutProveedor', 'mtoLiqui', 'mtoConta', 'fecLiqui', 'tipoLiqui', 'seleccion'];
  }

  ngOnInit(): void {
    this.iniciar();
    this.setTitle();
  }

  iniciar() {
    this.acciones=[];
    this.acciones.push({value:'0', descr:'Seleccione...'})
    this.acciones.push({value:'1', descr:'Facturas'})
    this.acciones.push({value:'2', descr:'Notas de Crédito'})
    this.acciones.push({value:'3', descr:'Solicitudes'})

    let largo: number = 0;
    this.nAcuerdo = '';
    this.accion='0'
    this.rutFullProv = '';
    this.proveedor = new Proveedor(this.srv);
    this.fechaDesde=(null as unknown as Date);
    this.fechaHasta=(null as unknown as Date);
    this.arrayLiquidaciones=[];
    this.dataSource = new MatTableDataSource<BuscaLiquidaciones>(this.arrayLiquidaciones);
  }

  private setTitle() {
    this.activatedroute.data.subscribe(data => {
      this.title = data["title"]
    })
  }

  seleccionarAccion(){
    let cont : number=0;
    switch (this.accion){
      case '1':
        //recorro listado de datasource y veo cual está seleccionada,
        //creando a partir de este un arreglo y lo guardo en la variable facturasSeleccionadas.
        this.arrayLiquidaciones.filter(ele => ele.seleccion).forEach(ele=> {
          cont++;
          this.facturasSeleccionadas.push({
            seq: cont.toString(),
            seq_dtr: "1",
            acuerdo: ele.acuerdo,
            ceunta_contable: ele.ceunta_contable,
            dv: ele.dv,
            fecha_liqui: ele.fecha_liqui,
            fullRut: ele.fullRut,
            monto_liqui: ele.monto_liqui,
            nombre_acuerdo: ele.nombre_acuerdo,
            rut: ele.rut,
            suma_liqui: ele.suma_liqui,
            tipo_liqui: ele.tipo_liqui,
            vendor_name: ele.vendor_name,
            seleccion: false,
            agrupador: "",
            rutFactura: ele.fullRut,
            montoFactura: "",
            glosaFactura: 'Acuerdo Comercial: ' + ele.acuerdo,
            codRef: '',
            folioRef: '',
            fechaRef: '',
            btnAdd1: true,
            btnDel1: true,
            btnAdd2: true,
            btnDel2: false,
            addinput: true,
            find: true,
            apendice: false,
            secuencia: cont.toString(),
            status: "ok"
          })
        })
        this.isFactura=true
        this.isLiquidaciones=false;
        this.isNc= false
        break;
      case '2':
        //recorro listado de datasource y veo cual está seleccionada,
        //creando a partir de este un arreglo y lo guardo en la variable facturasSeleccionadas.
        this.arrayLiquidaciones.filter(ele => ele.seleccion).forEach(ele=> {
          cont++;
          this.ncSeleccionadas.push({
            seq: cont.toString(),
            seq_dtr: "1",
            acuerdo: ele.acuerdo,
            ceunta_contable: ele.ceunta_contable,
            dv: ele.dv,
            fecha_liqui: ele.fecha_liqui,
            fullRut: ele.fullRut,
            monto_liqui: ele.monto_liqui,
            nombre_acuerdo: ele.nombre_acuerdo,
            rut: ele.rut,
            suma_liqui: ele.suma_liqui,
            tipo_liqui: ele.tipo_liqui,
            vendor_name: ele.vendor_name,
            seleccion: false,
            agrupador: "",
            rutFactura: ele.fullRut,
            montoFactura: "",
            folioRef: '',
            factRef: '',
            fechaRef: '',
            btnAdd1: true,
            btnDel1: true,
            addinput: true,
            secuencia: cont.toString(),
            status: "ok"
          })
        })
        this.isFactura=false
        this.isLiquidaciones=false;
        this.isNc= true
        break;
      case '3':
        this.route.navigateByUrl('v1/solicitudes')
        break;
    }
  }

  buscarProveedor(event: any) {
    this.spinner.show('searchLiquidacionesSpinner');
    this.spinnerMessage = "Verificando Proveedor";
    this.proveedor.setProveedor(event.target.value)
      .then(() => this.rutFullProv = this.proveedor.proveedorFullRut)
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          this.commonService.error(errors.ErrorBuscarProveedor.message, (err.status === 404) ? errors.ProveedorNoExiste.message : err.message, 'Aceptar', true)
        } else {
          this.commonService.error(errors.ErrorBuscarProveedor.message, err, 'Aceptar', true)
        }
      })
      .finally(() => this.spinner.hide('searchLiquidacionesSpinner'))
  }

  buscarLiquidaciones(){
    this.spinnerMessage = 'Buscando liquidaciones ...'
    this.spinner.show('searchLiquidacionesSpinner')
    let body: string = "";
    if (this.nAcuerdo.length==0){
      body=body + "0"
    }
    else{
      body = body + this.nAcuerdo;
    }
    body = body +  '/' + CommonsModule.stringToNumber(this.proveedor.nrut.toString()) 

    if (this.fechaDesde == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaDesde);
    }
    if (this.fechaHasta == null) {
      body = body + '/' + CommonsModule.stringToNumber('0');
    }
    else {
      body = body + '/' + Intl.DateTimeFormat('es-CL').format(this.fechaHasta);
    }
    this.srv.callBackendGet<BuscaLiquidaciones[]>(`/liquidaciones/liquidacion/${body}`).subscribe({
      next: lista => {
        if (lista == null) {
          this.arrayLiquidaciones=[];
          this.dataSource = new MatTableDataSource<BuscaLiquidaciones>(this.arrayLiquidaciones);
          this.commons.error('Error', errors.liquidacionesNoEncontradas.message, 'Aceptar', true)
        }
        else{
          lista.forEach(liqui => {
            liqui.fullRut=liqui.rut + "-" + liqui.dv;
            liqui.seleccion = false;
          })
          this.arrayLiquidaciones=lista;
          //valores ya vienen con 2 decimales por lo que no se tienen que formatear.
          this.dataSource = new MatTableDataSource<BuscaLiquidaciones>(this.arrayLiquidaciones);  
        }
      },
      error: err => {
        if (err instanceof HttpErrorResponse && err.status === 404) {
          this.commons.error('Error', errors.AcuerdosNoEncontrados.message, 'Aceptar', true);
        } else {
          (err instanceof HttpErrorResponse && err.status === 404) ?
            this.commons.error('Error', errors.liquidacionesNoEncontradas.message, 'Aceptar', true) :
            this.commons.error('Error', err, 'Aceptar', true);
        }
        this.arrayLiquidaciones=[];
        this.spinner.hide('searchLiquidacionesSpinner')
      },
      complete: () => {
        this.spinner.hide('searchLiquidacionesSpinner')
      }
    }
    )

  }

  Limpiar(){
    this.iniciar();
  }  

  someSelected(): boolean {
    return this.arrayLiquidaciones.some(ele => ele.seleccion) && !this.arrayLiquidaciones.every(ele => ele.seleccion) && this.arrayLiquidaciones.length > 0;
  }

  everySelected(): boolean {
    return this.arrayLiquidaciones.every(ele => ele.seleccion) && this.arrayLiquidaciones.length > 0;
  }

  setAll(value: boolean) {
    if (this.arrayLiquidaciones.length>0){
      this.seleccionados = value;
      this.arrayLiquidaciones.forEach(ele => ele.seleccion = value)  
    }
  }

  check(value: boolean) {
    let liquidacionesSeleccionadas: BuscaLiquidaciones[] = this.arrayLiquidaciones.filter(liqui => liqui.seleccion);
    if (liquidacionesSeleccionadas.length>0){
      this.seleccionados=true;
    }
    else{
      this.seleccionados=false;
    }
  }

  openDialogProveedores() {
    this.proveedor.setValues('','','','')
    this.rutFullProv=''
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { 
          if (result != null){ 
            var provs :Proveedor[]=[result]
            for (let prov of provs){
              this.proveedor.setValues(prov.nrut, prov.vendor_name,prov.xdvrut,prov.vpc_tech_key)
              this.rutFullProv = this.proveedor.proveedorFullRut
            } 
          }
        }
      }
    )
  }

}
