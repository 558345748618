import { SimuladorComponent } from 'src/app/components/simulador/simulador.component'
import { AprobarAcuerdoComponent } from '../components/aprobar-acuerdo/aprobar-acuerdo.component'
import { CopiarAcuerdoComponent } from '../components/copiar-acuerdo/copiar-acuerdo.component'
import { EliminarAcuerdosComponent } from '../components/eliminar-acuerdos/eliminar-acuerdos.component'
import { SimuladorSellOutComponent } from '../components/simulador-sell-out/simulador-sell-out.component'
import { VerAcuerdosComponent } from '../components/ver-acuerdos/ver-acuerdos.component'
import { VerBonificacionesComponent } from '../components/ver-bonificaciones/ver-bonificaciones.component'
import { VerBnfDesfazadasComponent } from '../components/ver-bnf-desfazadas/ver-bnf-desfazadas.component'
import { AltaAcuerdoNuevoComponent } from '../components/alta-acuerdo-nuevo/alta-acuerdo-nuevo.component'
import { AltaAcuerdoNuevo2Component } from '../components/alta-acuerdo-nuevo2/alta-acuerdo-nuevo2.component'
import { LiquidacionesComponent } from '../components/liquidaciones/liquidaciones.component'
import { SolicitudesComponent } from '../components/solicitudes/solicitudes.component'
import { CopiaMasivaComponent } from '../components/copia-masiva/copia-masiva.component'
import { SrxPreciosVigentesComponent } from '../srx-precios-vigentes/srx-precios-vigentes.component'

export default {
  menus: [
    {
      titulo: "Consulta",
      options: [
        {
          titulo: "Acuerdos",
          componente: VerAcuerdosComponent,
          path:"v1/verAcuerdos",
          data: { title: "Acuerdos",roles: ['rbt_admin', 'rbt_comercial', 'rbt_finanzas', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
        {
          titulo: "Bonificaciones",
          componente: VerBonificacionesComponent,
          path:"v1/verBonificaciones",
          data: { title: "Bonificaciones",roles: ['rbt_admin', 'rbt_comercial', 'rbt_finanzas', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
      ],
      visible: true,
    },
    {
      titulo: "Ocultos",
      options: [
        {
          titulo: "Solicitudes",
          componente: SolicitudesComponent,
          path:"v1/solicitudes",
          data: {title: "Solicitudes", roles: ['rbt_admin', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
      ],
      visible: false,
    },
    {
      titulo: "Procesos",
      options: [
        {
          titulo: "Liquidaciones",
          componente: LiquidacionesComponent,
          path:"v1/liquidaciones",
          //se crea perfil rbt_comercial_liqui, para que comerciales puedan ver liquidaciones ya que en CL no deben de verla pero en PE si.
          data: {title: "Liquidaciones", roles: ['rbt_admin', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] } 
        },
        {
          titulo: "Aprobar Acuerdo",
          componente: AprobarAcuerdoComponent,
          path:"v1/aprobarAcuerdo",
          data: { title: "Aprobar Acuerdos", roles: ['rbt_admin'], countries: ['CL','PE','CO'] }
        }
        ,
        {
          titulo: "Eliminar Acuerdo",
          componente: EliminarAcuerdosComponent,
          path:"v1/eliminarAcuerdos",
          data: { title: "Eliminar Acuerdos", roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
        {
          titulo: "Alta Acuerdo Nuevo",
          componente: AltaAcuerdoNuevoComponent,
          path:"v1/altaAcuerdoNuevoComponent",
          data: { title: "Alta Acuerdo Nuevo",roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
        {
          titulo: "Alta Acuerdo Nuevo 2",
          componente: AltaAcuerdoNuevo2Component,
          path:"v1/altaAcuerdoNuevo2Component",
          data: { title: "Alta Acuerdo Nuevo 2", roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL'] }
        },
        {
          titulo: "Modificar/Copiar Acuerdos",
          componente: CopiarAcuerdoComponent,
          path:"v1/copiarAcuerdos",
          data: { title: "Modificar/Copiar acuerdos",roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
        {
          titulo: "Simulador Acuerdo",
          componente: SimuladorComponent,
          path: "v1/simulador",
          data: {title: "Simulador", roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL','PE','CO'] }
        },
        {
          titulo: "Simulador Acuerdo Sell Out",
          componente: SimuladorSellOutComponent,
          path:"v1/simulador_sell_out",
          data: {"title": "Simulador Sell Out", roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL']}
        },
        {
          titulo: "Copia Masiva",
          componente: CopiaMasivaComponent,
          path:"v1/copia_masiva",
          data: {"title": "Copia Masiva", roles: ['rbt_admin', 'rbt_comercial', 'rbt_comercial_liqui'], countries: ['CL','PE','CO']}
        }
      ],
      visible: true,
    },
    /*{
      titulo: "Setup",
      options: []
    },*/
    {
      titulo: "Control",
      options: [
        {
          titulo: "Reporte BNF Desfasada",
          componente: VerBnfDesfazadasComponent,
          path:"v1/BonificacionesDesfasadas",
          data: {"title": "Bonificaciones Desfasadas", roles: ['rbt_admin', 'rbt_contabilidad'], countries: ['CL','PE','CO'] }
        },
      ],
      visible: true,
    },
    {
      titulo: "SRX",
      options: [
        {
          titulo: "Precios Vigentes",
          componente: SrxPreciosVigentesComponent,
          path:"v1/srxPreciosVigentes",
          data: { title: "Precios Vigentes",roles: ['rbt_admin'], countries: ['CL'] }
        }
      ],
      visible: true,
    },
  ]
}