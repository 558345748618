import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { Proveedor } from 'src/app/classes/proveedor';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import { errors } from 'src/app/constants/constants.errors';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth/auth.service';

interface listadoBon extends Interfaces.BnfDesfazadas {
  seleccion: boolean
}

interface Meses {
  valor: number,
  descr: string
}

@Component({
  selector: 'app-search-bnf-desfazadas',
  templateUrl: './search-bnf-desfazadas.component.html',
  styleUrls: ['./search-bnf-desfazadas.component.scss']
})
export class SearchBnfDesfazadasComponent {
  public spinnerMessage: string = "Loading ..."
  public anio: number = 0;
  public mes: number = 0;
  public linea: number = 0;
  public sublinea: number = 0;
  public archivo: boolean= false

  public anios: number[] = [];
  public meses: Meses[] = [];
  public lineas: Interfaces.Lineas[] = [{ valor: 0, descr: '' }];
  public sublineas: Interfaces.SubLineas[] = [{ valor: 0, descr: '' }];

  @Output() ListaBonificaciones: EventEmitter<listadoBon[]> = new EventEmitter();
  public arrayAcuerdos: Interfaces.ListadoAcuerdos[] = [];
  private user_tech_key!:string;

  constructor(private srv: RebateBackendService,
    private commonService: CommonsModule,
    private spinner: NgxSpinnerService,
    private commons: CommonsModule,
    private auth: AuthService) {
      this.auth.getUserTechKey().then(valor => this.user_tech_key = valor)
  }

  ngOnInit(): void {
    this.iniciar();
  }

  iniciar() {
    this.anios = [];
    this.anio = new Date().getFullYear();
    for (let x: number = this.anio + 2; x > this.anio - 5; x--) {
      this.anios.push(x);
    }
    this.mes = 0;
    this.meses = [];
    this.meses.push({ valor: 0, descr: 'Seleccione Mes' });
    this.meses.push({ valor: 1, descr: 'Enero' });
    this.meses.push({ valor: 2, descr: 'Febrero' });
    this.meses.push({ valor: 3, descr: 'Marzo' })
    this.meses.push({ valor: 4, descr: 'Abril' })
    this.meses.push({ valor: 5, descr: 'Mayo' })
    this.meses.push({ valor: 6, descr: 'Junio' })
    this.meses.push({ valor: 7, descr: 'Julio' })
    this.meses.push({ valor: 8, descr: 'Agosto' })
    this.meses.push({ valor: 9, descr: 'Septiembre' })
    this.meses.push({ valor: 10, descr: 'Octubre' })
    this.meses.push({ valor: 11, descr: 'Noviembre' })
    this.meses.push({ valor: 12, descr: 'Diciembre' })

    this.sublineas=[];
    this.loading()
      .then(() => {
        this.lineas.unshift({ valor: 0, descr: 'Todas' })
        this.linea = this.lineas[0].valor;
        this.sublineas.unshift({ valor: 0, descr: 'Todas' })
          this.sublinea = this.sublineas[0].valor;  
      });
    let lista: listadoBon[] = [];
    this.ListaBonificaciones.emit(lista)
  }


  generarExcel() {
    this.spinnerMessage = 'Buscando Bonificaciones ...'
    this.spinner.show('searchBonificacionesSpinner')

    if (this.anio != 0 && this.mes != 0) {
      //se envia solamente los 2 ultimos caracteres del año, no se envía completo.
      let annio: string = this.anio.toString().substring(2)

      let isArchivo= (this.archivo) ? 'T': 'F';

      let body: string = this.linea +
        '/' + this.sublinea +
        '/' + annio +
        '/' + this.mes +
        '/' + this.user_tech_key +
        '/'+ isArchivo
        ;


      this.srv.callBackendGet<string>(`/bonificacion/archivo/desfasada/${body}`).subscribe({
        next: lista => {
          if (lista == 'Se enviara archivo a su correo') {
            this.commons.alert('primary', 'Atención', 'Se enviara archivo a su correo', 'Aceptar', true);
          }
          else {
            this.commons.alert('primary', 'Atención', errors.BnfDesfazadasNoEncontradas.message, 'Aceptar', true);
          }
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 404) {
            this.commons.error('Error', errors.UrlNoEncontrada.message, 'Aceptar', true);
          } else {
            (err instanceof HttpErrorResponse && err.status === 404) ?
              this.commons.error('Error', errors.UrlNoEncontrada.message, 'Aceptar', true) :
              this.commons.error('Error', errors.BonificacionError.message, 'Aceptar', true);
          }
          this.ListaBonificaciones.emit([]);
          this.spinner.hide('searchBonificacionesSpinner')
        },
        complete: () => {
          this.spinner.hide('searchBonificacionesSpinner')
        }
      }
      )
    }
    else {
      this.commons.error('Error', errors.BnfDesfasadasMesAnio.message, 'Aceptar', true)
      this.spinner.hide('searchBonificacionesSpinner')
    }
  }


  buscarBonificaciones() {
    this.spinnerMessage = 'Buscando Bonificaciones ...'
    this.spinner.show('searchBonificacionesSpinner')

    if (this.anio != 0 && this.mes != 0) {
      //se envia solamente los 2 ultimos caracteres del año, no se envía completo.
      let annio: string = this.anio.toString().substring(2)

      let isArchivo= (this.archivo) ? 'T': 'F';

      let body: string = this.linea +
        '/' + this.sublinea +
        '/' + annio +
        '/' + this.mes +
        '/' + this.user_tech_key +
        '/'+ isArchivo
        ;


      this.srv.callBackendGet<listadoBon[]>(`/bonificacion/consulta/desfasada/${body}`).subscribe({
        next: lista => {
          if (lista == null) {
            this.ListaBonificaciones.emit([])
            this.commons.alert('primary', 'Atención', errors.BnfDesfazadasNoEncontradas.message, 'Aceptar', true);
          }
          else {
            lista.forEach(acrd => {
              acrd.seleccion = false;
            })
            this.ListaBonificaciones.emit(lista)
          }
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 404) {
            this.commons.error('Error', errors.UrlNoEncontrada.message, 'Aceptar', true);
          } else {
            (err instanceof HttpErrorResponse && err.status === 404) ?
              this.commons.error('Error', errors.UrlNoEncontrada.message, 'Aceptar', true) :
              this.commons.error('Error', errors.BonificacionError.message, 'Aceptar', true);
          }
          this.ListaBonificaciones.emit([]);
          this.spinner.hide('searchBonificacionesSpinner')
        },
        complete: () => {
          this.spinner.hide('searchBonificacionesSpinner')
        }
      }
      )
    }
    else {
      this.commons.error('Error', errors.BnfDesfasadasMesAnio.message, 'Aceptar', true)
      this.spinner.hide('searchBonificacionesSpinner')
    }
  }

  async loading() {
    this.spinner.show('searchBonificacionesSpinner');
    this.spinnerMessage = "Cargando datos...";
    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.Lineas>(DropDownOptions.linea, ['23'])
        .then((data) => {
          this.lineas = data;
          this.linea = this.lineas[0].valor;
          res();
        })
        .catch(err => rej('Error Líneas: ' + err))
    })

    let results = await Promise.allSettled([p1])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('searchBonificacionesSpinner')
  }

  async cargaSublineas() {
      this.sublineas=[];
      this.buscarSubLineas(this.linea)
        .then(() => {
          this.sublineas.unshift({ valor: 0, descr: 'Todas' })
          this.sublinea = this.sublineas[0].valor;  
        });
    
  }


  async buscarSubLineas(linea: number) {
    this.spinner.show('searchBonificacionesSpinner');
    this.spinnerMessage = "Cargando Sub Líneas...";
    if (linea != 0) {
      let p2 = new Promise<void>((res, rej) => {
        this.commonService.loadDropDown<Interfaces.SubLineas>(DropDownOptions.sublinea, [linea.toString()])
          .then((data) => {
            this.sublineas = data;
            this.sublinea = this.sublineas[0].valor;
            res();
          })
          .catch(err => rej('Error Tipos Liquidación: ' + err))
      })

      let results = await Promise.allSettled([p2])
      let errorMsg = results.reduce((prv, curr) => {
        if (curr.status == 'rejected') {
          return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
        } else {
          return prv
        }
      }, "")

      if (errorMsg != "") {
        this.commonService.error("Error", errorMsg, 'Aceptar', true)
      }
    }
    this.spinner.hide('searchBonificacionesSpinner')
  }


  Limpiar() {
    this.iniciar();
  }

}
