<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true"
    [name]="'acuerdo'">
    <p style="color: white"> {{spinnerMessage}} </p>
</ngx-spinner>
<div class="matdialog">
    <table class="tabla" aria-describedby="Datos de Acuerdo">
        <tr>
            <th class="title">
                <mat-card-title>{{title}}</mat-card-title>
            </th>
            <th ngClass="header-right" colspan="2">
            </th>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Tipo Acuerdo</mat-label>
                    <mat-select [(ngModel)]="acuerdo.tpAcuerdo" [disabled]="true">
                        <mat-option [value]="item.c_tpacuerdo"
                            *ngFor="let item of tipoAcuerdo, let i=index">{{item.a_tpacuerdo}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td colspan="2">
                <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogProveedores()"
                    *ngIf="acuerdo.isJerarquia">PBC
                    <mat-icon>list</mat-icon>
                </button>
                <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="showDataPBC()"
                    *ngIf="acuerdo.isJerarquia">
                    Ver PBC ({{acuerdo.pbc.length}})
                    <mat-icon>list</mat-icon>
                </button>
                <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogMarcas()" class="pad"
                    *ngIf="acuerdo.isJerarquia">
                    Marcas
                    <mat-icon>list</mat-icon>
                </button>
                <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="showDataMarcas()"
                    *ngIf="acuerdo.isJerarquia">Ver Marcas ({{acuerdo.marcas.length}})
                    <mat-icon>list</mat-icon>
                </button>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Nombre Acuerdo</mat-label>
                    <input matInput type="text" [required]="true" placeholder="Ingrese Nombre de Acuerdo"
                        [(ngModel)]="acuerdo.nombreAcuerdo" [disabled]="parametroVer" id="nomAcuerdo">
                </mat-form-field>
            </td>
            <td>
            </td>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Tipo Recupero</mat-label>
                    <mat-select [(ngModel)]="acuerdo.tipoRecupero" [required]="true">
                        <mat-option [value]="item.c_tprecupero"
                            *ngFor="let item of tipoRecupero">{{item.a_tprecupero}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Proveedor</mat-label>
                    <input matInput type="text" [(ngModel)]="rutFullProv" (focusout)="buscarProveedor($event)"
                        placeholder="Rut Proveedor" [required]="true">
                    <button mat-icon-button matSuffix (click)="openDialogProv()" matTooltip="Buscar Proveedor">
                        <mat-icon matSuffix>search</mat-icon>
                    </button>
                </mat-form-field>
            </td>
            <td colspan="2">
                <mat-label class="pad">{{acuerdo.proveedor.vendor_name}}</mat-label>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>SubTipo</mat-label>
                    <mat-select [(ngModel)]="acuerdo.subTipoAcuerdo" [disabled]="true">
                        <mat-option [value]="item.c_parametro"
                            *ngFor="let item of subtipos">{{item.a_valor}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Agrupador</mat-label>
                    <mat-select [ngModel]="acuerdo.agrAcuerdo" [required]="true">
                        <mat-option [value]="item.c_agrupador"
                            *ngFor="let item of tipoAgrupador">{{item.a_agrupador}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <button mat-raised-button class="pad" matSuffix [color]="'primary'" (click)="fileUpload.click()">
                    Respaldo Proveedor ({{fileUp}})
                    <mat-icon>attach_file</mat-icon>
                </button>
                <input type="file" [hidden]="true" class="file-input" (change)="cargarMail($event)"
                    (click)="fileUpload.value=''" [accept]="'.eml,.msg'" #fileUpload>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Tipo Liquidación</mat-label>
                    <mat-select [(ngModel)]="acuerdo.tipoLiquidacion" [required]="true">
                        <mat-option [value]="item.c_parametro"
                            *ngFor="let item of tipoLiquidacion">{{item.a_valor}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Descripción acuerdo</mat-label>
                    <textarea matInput rows="3" class="textarea" [(ngModel)]="acuerdo.descrAcuerdo"
                        [disabled]="parametroVer"></textarea>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field appearance="outline" [floatLabel]="'always'">
                    <mat-label>Texto Liquidación</mat-label>
                    <textarea matInput rows="3" class="textarea" [(ngModel)]="acuerdo.txtLiquidacion"
                        [disabled]="parametroVer"></textarea>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field class="full-width" appearance="outline" [floatLabel]="'always'">
                    <mat-label>Acuerdo Referencia</mat-label>
                    <input matInput type="number" inputmode="numeric" [(ngModel)]="acuerdo.acrdReferencia"
                        placeholder="Número acuerdo referencia" [disabled]="parametroVer">
                </mat-form-field>
            </td>
            <td>
                <mat-checkbox [labelPosition]="'after'" [(ngModel)]="acuerdo.dv" [disabled]="parametroVer">Incluir
                    Dañado
                    Vendible</mat-checkbox>
            </td>
            <td>
            </td>
        </tr>
    </table>
    <br>
    <div>
        <table aria-describedby="addproductos" class="tablaAdd">
            <tr>
                <td colspan="5">&nbsp;</td>
            </tr>
            <tr>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Jerarquía/SKU</mat-label>
                        <input matInput type="text" [(ngModel)]="sku">
                        <button mat-icon-button matSuffix (click)="fileUploadExcel.click()"
                            matTooltip="Fechas en archivo deben venir en formato DD-MM-YYYY">
                            <mat-icon matSuffix>attach_file</mat-icon>
                        </button>
                        <input type="file" [hidden]="true" class="file-input" (change)="uploadExcel($event)"
                            (click)="fileUploadExcel.value=''" [accept]="'.xlsx,.xls'" #fileUploadExcel>
                    </mat-form-field>

                </td>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Desde</mat-label>
                        <input matInput [matDatepicker]="dateFromPicker" placeholder="DD-MM-YYYY" [(ngModel)]="fecDesde"
                            (dateChange)="validaFecha($event, 'desde')">
                        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateFromPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Fecha Hasta</mat-label>
                        <input matInput [matDatepicker]="dateToPicker" placeholder="DD-MM-YYYY" [(ngModel)]="fecHasta"
                            (dateChange)="validaFecha($event, 'hasta')">
                        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dateToPicker color="primary"></mat-datepicker>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Unidades</mat-label>
                        <input matInput type="text" [(ngModel)]="unidades" (keyup)="onKeyUp($event, 'units')"
                            (keydown)="keyDown($event)" style="text-align: right;">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Aporte</mat-label>
                        <input matInput type="text" [(ngModel)]="aporte" (keyup)="onKeyUp($event, 'aporte')"
                            (keydown)="keyDown($event)" style="text-align: right;">
                        <mat-icon matPrefix>attach_money</mat-icon>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                </td>
                <td>
                    <mat-form-field appearance="outline" [floatLabel]="'always'">
                        <mat-label>Organización</mat-label>
                        <mat-select [(value)]="org">
                            <mat-option [value]="item.c_parametro" (click)="setSearchType(item)"
                                *ngFor="let item of organizaciones">{{item.a_valor}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <button mat-raised-button matSuffix [color]="'primary'" (click)="openDialogTiendas()"
                        class="pad">{{searchButtonTitle}}
                        <mat-icon>list</mat-icon>
                    </button>
                    <button mat-raised-button (click)="showDataTiendas()" class="pad">Ver ({{tiendas.length}})</button>
                </td>
                <td colspan="2" class="cellCenter">
                    <button mat-raised-button [color]="'primary'" class="padButton"
                        (click)="addProduct()">Agregar</button>
                    <button mat-raised-button [color]="'warn'" class="padButton"
                        (click)="cleanDataSource()">Limpiar</button>
                </td>
            </tr>
        </table>
    </div>
    <br>
    <div class="right padGuardar">
        <button mat-raised-button color="primary" class="pad" (click)="guardarAcuerdo()" cdkFocusInitial>
            Guardar
        </button>
    </div>
    <br>
    <br>

    <table mat-table [dataSource]="acuerdo.tabla" aria-describedby="Datos Simulador">
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="right center">#</th>
            <td mat-cell *matCellDef="let i=index" class="right"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="jerarquia">
            <th mat-header-cell *matHeaderCellDef class="background">Jerarquía Prd/SKU</th>
            <td mat-cell *matCellDef="let element"> {{element.jerarquia}} </td>
        </ng-container>
        <ng-container matColumnDef="descrjerarquia">
            <th mat-header-cell *matHeaderCellDef class="background">Descr Jerar. Prd/SKU</th>
            <td mat-cell *matCellDef="let element"> {{element.name_full}} </td>
        </ng-container>
        <ng-container matColumnDef="fecdesde">
            <th mat-header-cell *matHeaderCellDef class="background">Fecha Desde</th>
            <td mat-cell *matCellDef="let element"> {{element.fecdesde | countryDateFormat: country}} </td>
        </ng-container>
        <ng-container matColumnDef="fechasta">
            <th mat-header-cell *matHeaderCellDef class="background">Fecha Hasta</th>
            <td mat-cell *matCellDef="let element"> {{element.fechasta | countryDateFormat: country}} </td>
        </ng-container>
        <ng-container matColumnDef="aporte">
            <th mat-header-cell *matHeaderCellDef class="right background">Aporte $</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.aporte.toFixed(2) | countryFormat: country : '0' :
                false }}
            </td>
        </ng-container>
        <ng-container matColumnDef="tope">
            <th mat-header-cell *matHeaderCellDef class="right background">Tope $</th>
            <td mat-cell *matCellDef="let element" class="right"> 0 </td>
        </ng-container>
        <ng-container matColumnDef="unidad">
            <th mat-header-cell *matHeaderCellDef class="right background">Unidades</th>
            <td mat-cell *matCellDef="let element" class="right"> {{element.unidad | countryFormat: country :'0' : false
                }}
            </td>
        </ng-container>
        <ng-container matColumnDef="organizacion">
            <th mat-header-cell *matHeaderCellDef class="background">Organización</th>
            <td mat-cell *matCellDef="let element"> {{element.organizacion.a_valor}} </td>
        </ng-container>
        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef class="background">Acción</th>
            <td mat-cell *matCellDef="let element; let i=index;">
                <img src='./assets/images/borrar.png' class="img" alt="borrar" (click)="eliminar(i)" />
                <img src='./assets/images/copiar.png' class="img" alt="copiar" (click)="copiar(i)" />
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="cabeceraTabla"></tr>
        <tr mat-row *matRowDef="let row; columns: cabeceraTabla;"></tr>
    </table>
    <br>
    <br>
</div>