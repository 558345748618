import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Acuerdo } from 'src/app/classes/acuerdo';
import { Simulador } from 'src/app/classes/simulador';
import { CommonsModule, DropDownOptions } from 'src/app/services/commons/commons.service';
import { RebateBackendService } from 'src/app/services/rebate/rebate.backend.service';
import * as Interfaces from 'src/app/interfaces/ISimulador';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SearchTiendaComponent } from 'src/app/components/simulador/search-tienda/search-tienda.component';
import { SearchMarcaComponent } from 'src/app/components/simulador/search-marca/search-marca.component';
import { SearchProveedorComponent } from 'src/app/components/simulador/search-proveedor/search-proveedor.component';
import { Proveedor } from 'src/app/classes/proveedor';
import { CountryFormatPipe } from 'src/app/components/pipes/countryFormat.pipe';


export enum SearchType {
  TIENDA,
  LOCAL
}
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  template: TemplateRef<any>
}

@Component({
  selector: 'app-acuerdo-simulador',
  templateUrl: './acuerdo.component.html',
  styleUrls: ['./acuerdo.component.scss']
})
export class AcuerdoComponent implements OnInit {
  public acuerdo!: Acuerdo;
  public spinnerMessage: string = "Loading ..."
  public title: string = 'Creación de acuerdo';
  public organizaciones!: Interfaces.OrganizacionOptions[];
  public tipoAcuerdo: Interfaces.TiposAcuerdoOptions[] = [{ c_tpacuerdo: '', a_tpacuerdo: '', a_prfjacuerdo: '' }]; //se define un elemento para que no mande error en el selected
  public searchButtonTitle: string = ''
  public subtipos: Interfaces.SubTiposOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public tipoRecupero: Interfaces.TipoRecuperoOptions[] = [{ c_tprecupero: '', a_tprecupero: '' }]
  public tipoAgrupador: Interfaces.AgrupadorOptions[] = [{ c_agrupador: '', a_agrupador: '', c_tpagrupador: '' }];
  public tipoLiquidacion: Interfaces.TipoLiquidacionOptions[] = [{ c_parametro: '', a_valor: '', c_tpacuerdo: '' }];
  public country!: string;
  public cabeceraTabla: string[] = [];
  public fileUp: number = 0;
  public parametroVer!: boolean;
  public btnCerrar!: string;
  public addProd!: boolean;
  public editarCopiar!: boolean
  public agrDisabled: boolean=true;

  /* linea para agregar filas al acuerdo.*/

  public skuJer: string = '';
  public aporte: string = '';
  public units: string = '';

  pipeFormat: CountryFormatPipe;

  /** */
  constructor(
    public dialogRef: MatDialogRef<AcuerdoComponent>,
    private spinner: NgxSpinnerService,
    private srv: RebateBackendService,
    private commons: CommonsModule,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public simulador: Simulador | Acuerdo,
    private commonService: CommonsModule
  ) {
    this.acuerdo = new Acuerdo(srv, commonService, auth)
    this.auth.getCountry().then(value => this.country = value)
    this.pipeFormat = new CountryFormatPipe();
  }

  ngOnInit(): void {
    this.loading()
      .then(() => {
        this.cargaSubTipos();
      })
      .catch(() => {

      })
      .finally(() => {

      })
    if (this.addProd) {
      this.cabeceraTabla = ['num', 'jerarquia', 'descr', 'aporte', 'tope', 'unidad', 'accion']
    }
    else {
      this.cabeceraTabla = ['num', 'jerarquia', 'descr', 'aporte', 'tope', 'unidad']
    }
    if (this.simulador instanceof Simulador) {
      this.acuerdo.esSimulacion(this.simulador)
    } else {
      this.acuerdo = this.simulador;
      if (this.acuerdo.fileBase64 != '' && this.acuerdo.fileBase64 != null) {
        this.fileUp = 1;
      }
    }
  }

  async cargaSubTipos() {
    this.spinner.show('acuerdo');
    this.spinnerMessage = "Cargando datos...";
    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.SubTiposOptions>(DropDownOptions.SubTipoAcuerdo, ['1', this.acuerdo.tpAcuerdo.toString()])
        .then((data) => {
          this.subtipos = data;
          if (this.acuerdo.subTipoAcuerdo == 0) {
            this.acuerdo.subTipoAcuerdo = CommonsModule.stringToNumber(this.subtipos.filter(ele => ele.c_parametro == '110')[0].c_parametro.toString())
          }
          res();
        })
        .catch(err => rej('Error Subtipos: ' + err))
    })

    let results1 = await Promise.allSettled([p2])
    let errorMsg = results1.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('acuerdo')

  }

  async loading() {
    this.spinner.show('acuerdo');
    this.spinnerMessage = "Cargando datos...";

    let p1 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TiposAcuerdoOptions>(DropDownOptions.TipoAcuerdo)
        .then((data) => {
          this.tipoAcuerdo = data;
          if (this.acuerdo.tpAcuerdo == 0) {
            this.acuerdo.tpAcuerdo = CommonsModule.stringToNumber(this.tipoAcuerdo[0].c_tpacuerdo.toString());
          }
          res();
        })
        .catch(err => rej('Error tipos: ' + err))
    })

    let p2 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.OrganizacionOptions>(DropDownOptions.Organizacion)
        .then((data) => {
          this.organizaciones = data;
          this.organizaciones.forEach((ele, idx) => {
            ele.path = `/organizaciones/opciones/${ele.c_parametro}`
            ele.searchType = (ele.a_valor.toUpperCase().includes('TIENDA')) ? SearchType.TIENDA : SearchType.LOCAL
            ele.selected = (ele.c_parametro == this.acuerdo.organizacion.toString())
            if (ele.selected) {
              this.acuerdo.organizacion = CommonsModule.stringToNumber(ele.c_parametro.toString())
              this.searchButtonTitle = (ele.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
            }
          });
          res()
        })
        .catch(err => rej('Error tipos: ' + err))
    })


    let p3 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoLiquidacionOptions>(DropDownOptions.TipoLiquidacion, ['23'])
        .then((data) => {
          this.tipoLiquidacion = data;
          if (this.acuerdo.tipoLiquidacion == 0) {
            this.acuerdo.tipoLiquidacion = CommonsModule.stringToNumber(this.tipoLiquidacion[0].c_parametro.toString());
          }
          res();
        })
        .catch(err => rej('Error Tipos Liquidación: ' + err))
    })

    let p4 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.AgrupadorOptions>(DropDownOptions.TipoAgrupador)
        .then((data) => {
          this.tipoAgrupador = data;
          this.tipoAgrupador.sort((a, b) => CommonsModule.stringToNumber(a.c_agrupador) - CommonsModule.stringToNumber(b.c_agrupador))
          if (this.acuerdo.agrAcuerdo == 0) {
            this.acuerdo.agrAcuerdo = CommonsModule.stringToNumber(this.tipoAgrupador[0].c_agrupador.toString());
          }
          if (this.country.toUpperCase()=="CO"){ //para CO se debe dejar este campo habilitado para copiar/editar y para la creacion desde el simulador
            if (this.editarCopiar || this.simulador instanceof Simulador){
              //se habilita campo de agrupador.
              this.agrDisabled=false;
            }
          }
          res();
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let p5 = new Promise<void>((res, rej) => {
      this.commonService.loadDropDown<Interfaces.TipoRecuperoOptions>(DropDownOptions.tipoRecupero)
        .then((data) => {
          this.tipoRecupero = data;
          if (this.acuerdo.tipoRecupero == 0) {
            this.acuerdo.tipoRecupero = CommonsModule.stringToNumber(this.tipoRecupero[0].c_tprecupero.toString());
          }
          res()
        })
        .catch(err => rej('Error Tipo Agrupador: ' + err))
    })

    let results = await Promise.allSettled([p1, p2, p3, p4, p5])
    let errorMsg = results.reduce((prv, curr) => {
      if (curr.status == 'rejected') {
        return (prv != "") ? `${prv}<br>${curr.reason}` : curr.reason
      } else {
        return prv
      }
    }, "")

    if (errorMsg != "") {
      this.commonService.error("Error", errorMsg, 'Aceptar', true)
    }
    this.spinner.hide('acuerdo')
  }

  cargarMail(event: any) {
    this.spinnerMessage = 'Cargando Archivo...'
    this.spinner.show()
    const file: File = event.target.files[0];
    if (file) {
      this.acuerdo.file = file;
      let nombreArchivo: string = file.name;
      let ext = nombreArchivo.split('.');
      if (ext.length > 1) {
        let extension = ext[ext.length - 1];
        if (extension == 'eml' || extension == 'msg') {
          this.acuerdo.cargaArchivo = true;
          this.fileUp = 1;
          CommonsModule.convertFile(file).subscribe({
            next: base64string => {
              this.acuerdo.fileBase64 = base64string
              this.acuerdo.nombreArchivo = nombreArchivo;
            },
            error: err => { this.commonService.error('Error', err, 'Aceptar', true); this.spinner.show() },
            complete: () => this.spinner.hide()
          })

        }
        else {
          this.commonService.error('Error', 'Sólo se aceptan archivos eml o msg', 'Aceptar', true)
        }
      }
      else {
        this.commonService.error('Error', 'Archivo no válido', 'Aceptar', true)
      }

    }
  }

  guardarAcuerdo() {
    this.spinner.show('acuerdo')
    this.spinnerMessage = "Realizando Guardado..."
    this.acuerdo.origen = 'S'
    console.log(this.acuerdo.agrAcuerdo)
    this.acuerdo.doGuardar()
      .then(_ => this.commonService.alert('accent', "Acuerdo Generado", 'Acuerdo generado Nº: ' + this.acuerdo.nAcuerdo, 'Aceptar', true).subscribe({
        complete: () => {
          window.location.reload()
        },
        error: (err) => { alert('error') }
      }))
      .catch(reason => this.commonService.error("Error", reason, 'Aceptar', true))
      .finally(() => { this.spinner.hide('acuerdo'); })
  }

  descargarArchivo() {
    this.acuerdo.descargarArchivo(this.acuerdo.fileBase64, this.acuerdo.nombreArchivo)
  }

  showDataTiendas() {
    let st = this.organizaciones.filter(ele => ele.selected)[0].searchType
    let colTitle = (st == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    this.commonService.showData(this.acuerdo.tiendas, [colTitle])
  }

  showDataPBC() {
    let data = this.acuerdo.pbc.map(ele => { return { rut: ele.proveedorFullRut, nombre: ele.vendor_name } })
    this.commonService.showData(data, ["RUT", "Nombre"])
  }

  showDataMarcas() {
    this.commonService.showData(this.acuerdo.marcas, ["Marca", "Descripción"])
  }

  updateDataSource() {
    this.simulador.tabla = this.simulador.tabla.filter(ele => ele.display)
  }

  cleanTabla(limpiarPBC: boolean = true) {
    this.simulador.tabla = []
    this.simulador.isJerarquia = false;
    if (limpiarPBC) {
      this.simulador.pbc = []
      this.simulador.marcas = []
    }
  }

  cleanDataSource(limpiarPBC: boolean = true) {
    this.cleanTabla(limpiarPBC);
    this.simulador.tabla = this.simulador.tabla
  }

  addProduct(rows: Interfaces.AddProductStruct[] = [{ sku: this.skuJer, prd_lvl_child: 0, aporte: 0, name_full: '', unidad: 0 }]) {
    this.spinner.show('acuerdo')
    this.spinnerMessage = "Verificando Jerarquía/SKU"

    rows.forEach((prod, idx) => {
      let aporte = CommonsModule.strToNum(this.country, this.aporte, 'aporte')
      if (aporte.error != "") {
        /*this.commonService.error("Error", aporte.error, 'Aceptar', true)
        return*/
      } else {
        if (!aporte.decimalFound) {
          prod.aporte = CommonsModule.stringToNumber(aporte.miles)
        }
        else {
          prod.aporte = CommonsModule.stringToNumber(aporte.miles + aporte.decimalPart.replaceAll(aporte.decimalPart,".") + aporte.decimales)
        }
      }

      let unidades = CommonsModule.strToNum(this.country, this.units, 'aporte')
      if (unidades.error != "") {
        /*this.commonService.error("Error", unidades.error, 'Aceptar', true)
        return*/
      } else {
        if (!aporte.decimalFound) {
          prod.unidad = CommonsModule.stringToNumber(unidades.miles)
        }
        else {
          prod.unidad = CommonsModule.stringToNumber(unidades.miles + unidades.decimalPart.replaceAll(unidades.decimalPart,".") + unidades.decimales)
        }
      }


    })

    this.simulador.addProducts(rows)
      .then(_ => { this.skuJer = ''; this.aporte = ""; this.units = '' })
      .catch(reason => {
        this.commonService.error("Error", (reason as Error).message, 'Aceptar', true);
      }
      )
      .finally(() => { this.spinner.hide('acuerdo'); this.updateDataSource() })

  }

  // validaMonto(event: any, variable: string) {
  //   let group: string = '';
  //   let decimal: string = ''
  //   const parts = new Intl.NumberFormat("es-" + this.country.toUpperCase()).formatToParts(12345.6);
  //   parts.forEach(part => {
  //     if (part.type == 'group') {
  //       group = part.value
  //     }
  //     if (part.type == 'decimal') {
  //       decimal = part.value
  //     }
  //   }
  //   )

  //   switch (variable) {
  //     case 'aporte':
  //       this.aporte = event;
  //       this.aporte = this.aporte.replaceAll(group, "")
  //       this.aporte = this.aporte.replaceAll(decimal, ".")
  //       break
  //     case 'units':
  //       this.units = event;
  //       this.units = this.units.replaceAll(group, "")
  //       this.units = this.units.replaceAll(decimal, ".")
  //       break;
  //   }
  // }

  // Numero(valor: string): number {

  //   if (!isNaN(Number(valor))) {
  //     return Number(valor);
  //   }
  //   else {
  //     return 0
  //   }
  // }

  setSearchType(option: Interfaces.OrganizacionOptions) {

    this.organizaciones.forEach(ele => ele.selected = (ele.c_parametro == this.acuerdo.organizacion.toString()))
    this.searchButtonTitle = (option.searchType == SearchType.LOCAL) ? 'Locales' : 'Tiendas';
    if (['7', '8'].includes(String(this.acuerdo.organizacion.toString()))) {
      this.acuerdo.tiendas = [{ name: (option.searchType == SearchType.LOCAL) ? 'todos los locales' : 'todas las tiendas', value: '0' }];
    } else {
      this.spinnerMessage = `Buscando ${(option.searchType == SearchType.LOCAL) ? 'locales' : 'tiendas'}...`
      this.spinner.show('simulador');
      this.acuerdo.tiendas = [];
      this.srv.callBackendGet<Interfaces.DatosTiendaLocal[]>(option.path as string).subscribe({
        next: data => {
          data.forEach(ele => {
            this.acuerdo.tiendas.push({
              name: `${ele.org_lvl_number} ${ele.org_name_full}`,
              value: ele.org_lvl_child.toString()
            })
          })
        },
        error: _ => { this.spinner.hide('simulador') },
        complete: () => { this.spinner.hide('simulador') }
      })
    }
  }

  openDialogTiendas() {
    const st = this.organizaciones.filter(ele => ele.selected)[0].searchType;
    this.commonService.openDialog<Interfaces.FilaTablaSearch, null>(SearchTiendaComponent, { searchType: st }).subscribe(
      {
        next: result => { if (result != null) this.acuerdo.tiendas = (st == SearchType.LOCAL) ? result.local : result.tienda }
      }
    )
  }

  openDialogMarcas() {
    this.commonService.openDialog<Interfaces.Marca[], null>(SearchMarcaComponent).subscribe(
      {
        next: result => { if (result != undefined && result.length > 0) this.acuerdo.marcas = result }
      }
    )
  }

  openDialogProveedores() {
    this.commonService.openDialog<Proveedor, null>(SearchProveedorComponent).subscribe(
      {
        next: result => { if (result != null) this.acuerdo.pbc = [result] }
      }
    )
  }

  validaFecha(event: any, campo: string) {
    let fecha: string = event.target.value
    if (fecha == null) {
      this.commonService.error("Error", "Fecha inválida", 'Aceptar', true);
      if (campo == 'desde') {
        this.acuerdo.fechaDesde = new Date()
      } else {
        this.acuerdo.fechaHasta = new Date()
      }
    }
  }

  eliminar(i: number) {
    this.acuerdo.tabla.splice(i, 1)
    this.updateDataSource();
    if (this.acuerdo.tabla.length == 0) {
      this.cleanDataSource(false);
    }
    if (this.acuerdo.tabla.length == 1 && !this.acuerdo.tabla[0].display) {
      this.cleanDataSource(false);
    }
  }

  copiar(i: number) {
    this.skuJer = this.acuerdo.tabla[i].jerarquia;
    this.aporte = this.commonService.transformarMonto(this.country, this.acuerdo.tabla[i].aporte.toString(), 'aporte')
    this.units = this.commonService.transformarMonto(this.country, this.acuerdo.tabla[i].unidad.toString(), 'units')
  }

  exportExcel() {
    let rows: Object[] = [];

    this.acuerdo.tabla.forEach(element => {
      rows.push({
        "Jerarquía": element.jerarquia,
        "Descr Jerar Prd/Sku": element.name_full,
        "Aporte $": element.aporte,
        "Tope Monto $": 0,
        "Unidades": element.unidad,
      })
    });
    CommonsModule.exportExcel(
      "Unidades bonificadas acuerdo " + this.acuerdo.nAcuerdo
      , rows);
  }

  // keyDown($event: any) {
  //   if ($event.keyCode >= 65 && $event.keyCode <= 90) {
  //     $event.preventDefault();
  //   }
  // }
  keyDown($event: any) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ($event.ctrlKey && (charCode === 'v' || charCode==='c')) {
      //console.log("si")
    }
    else{
      if ($event.metaKey && (charCode==='v' || charCode==='c')){
        //console.log("si command")
      }
      else{
        if ($event.keyCode >= 65 && $event.keyCode <= 90) {
          $event.preventDefault();
        }    
      }
    }
  }

  onKeyUp($event: any, variable: string) {
    // if ($event.keyCode >= 65 && $event.keyCode <= 90) {
    //   $event.preventDefault();
    // } else {
      switch (variable) {
        case 'aporte':
          this.aporte = this.commonService.transformarMonto(this.country, this.aporte, 'aporte')
          break
        case 'units':
          this.units = this.commonService.transformarMonto(this.country, this.units, 'units')
          break;
      }
    // }
  }

}
